import React, { useState } from 'react';
import { Route, Routes } from 'react-router-dom';
import Products from './Components/Screens/Products';
import './App.css';
import WorkFlow from './Components/Screens/WorkFlow';
import Category from './Components/Screens/Category';
import Menu from './Components/Screens/menu';
import Order from './Components/Screens/Order';
import Register from './Components/Auth/Register';
import Login from './Components/Auth/Login';
import Chat from './Components/Screens/Chat';
import ContactList from './Components/Screens/ContactList';
import ContactListTwo from './Components/Screens/ContactListTwo';
import BroadcastList from './Components/Screens/BroadcastList';
import WorkflowList from './Components/Screens/WorkflowList';
import NewBroadcast from './Components/Screens/NewBroadcast';
import MessageTemplate from './Components/Screens/MessageTemplate';
import OAuthRedirect from './Components/Screens/OAuthredirect';
import ZohoLogin from './Components/Auth/ZohoLogin';
import BroadcastHistory from './Components/Screens/BroadcastHistory';
import UserManagementList from './Components/Screens/UserManagementList';
import UserManagementListEdit from './Components/Screens/UserManagementListEdit';
import IntegrationWorkflow from './Components/Screens/IntegrationWorkflow';
import Subscription from './Components/Screens/Subscription';
import BillingPayment from './Components/Screens/BillingPayment';
import CreateNewContactList from './Components/Screens/CreateNewContactList';
import AddDataToContactList from './Components/Screens/AddDataToContactList';
import MapFields from './Components/Screens/MapFields';
import CreatedContactList from './Components/Screens/CreatedContactList';
import MessageTemplateList from './Components/Screens/MessageTemplateList';
import CreateMessageTemplate from './Components/Screens/CreateMessageTemplate';
import ChooseTemplate from './Components/Screens/ChooseTemplate';
import WorkProgress from './Components/Screens/WorkProgress';
import SmsBroadcast from './Components/Screens/SmsBroadcast';
import SMSFlow from './Components/Screens/SMSFlow/SmsFlow';
import SmsFlowList from './Components/Screens/SMSFlow/SmsFlowList'
import TenantForm from './Components/Screens/AddTenant';
import TenantsList from './Components/Screens/AddUser';

const App = () => {
  return (
    <Routes>
      <Route
        path='/'
        exact
        element={<Login />} />
      <Route
        path='/Register'
        exact
        element={<Register />} />
      <Route
        path='/Products'
        exact
        element={<Products />} />
      <Route
        path='/category'
        exact
        element={<Category />} />

      <Route
        path='/Order'
        exact
        element={<Order />} />
      <Route
        path='/Menu'
        exact
        element={<Menu />} />
      <Route
        path='/Menu'
        exact
        element={<Menu />} />
      <Route
        path='/Chat'
        exact
        element={<Chat />} />
      <Route
        path='/flow'
        exact
        element={<WorkFlow />} />
      <Route
        path='/ContactList'
        exact
        element={<ContactList />} />
      <Route
        path='/ContactListTwo'
        exact
        element={<ContactListTwo />} />
      <Route
        path='/BroadcastList'
        exact
        element={<BroadcastList />} />
      <Route
        path='/WorkflowList'
        exact
        element={<WorkflowList />} />
      <Route
        path='workflow/:id'
        exact
        element={<WorkFlow />} />
      <Route
        path='workflow/'
        exact
        element={<WorkFlow />} />
      <Route
        path='/NewBroadcast'
        exact
        element={<NewBroadcast />} />
      <Route
        path='/MessageTemplate'
        exact
        element={<MessageTemplate />} />
      <Route
        path='/Zoho'
        exact
        element={<ZohoLogin />} />
      <Route
        path='/oauth/callback'
        exact
        element={<OAuthRedirect />} />
      <Route
        path='/BroadcastHistory'
        exact
        element={<BroadcastHistory />} />
      <Route
        path='/UserManagementList'
        exact
        element={<UserManagementList />} />
      <Route
        path='/UserManagementListEdit'
        exact
        element={<UserManagementListEdit />} />
      <Route
        path='/IntegrationWorkflow'
        exact
        element={<IntegrationWorkflow />} />
      <Route
        path='/Subscription'
        exact
        element={<Subscription />} />
      <Route
        path='/BillingPayment'
        exact
        element={<BillingPayment />} />
      <Route
        path='/CreateNewContactList'
        exact
        element={<CreateNewContactList />} />
      <Route
        path='/AddDataToContactList'
        exact
        element={<AddDataToContactList />} />
      <Route
        path='/MapFields'
        exact
        element={<MapFields />} />
      <Route
        path='/CreatedContactList'
        exact
        element={<CreatedContactList />} />
      <Route
        path='/MessageTemplateList'
        exact
        element={<MessageTemplateList />} />
      <Route
        path='/CreateMessageTemplate'
        exact
        element={<CreateMessageTemplate />} />
      <Route
        path='/ChooseTemplate'
        exact
        element={<ChooseTemplate />} />
      <Route
        path='/WorkProgress'
        exact
        element={<WorkProgress />} />
      <Route 
        path='/SmsBroadcast'
        exact
        element={<SmsBroadcast />}
      />
      <Route
        path='/SmsFlow'
        exact
        element={<SMSFlow/>}
      />
      <Route
        path='/SmsFlow/:flowId'
        exact
        element={<SMSFlow/>}
      />
      <Route
        path='/SmsFlowList'
        exact
        element={<SmsFlowList/>}
      />
      <Route
        path='/AddTenant'
        exact
        element={<TenantForm/>} />
      <Route
        path='/AddUser'
        exact
        element={<TenantsList />} />
    </Routes>
    
  );
};

export default App;
