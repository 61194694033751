import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import LogoImg from '../../assets/img/logo.png';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import LoginPageImg1 from '../../assets/img/loginPageImg-1.png';
import Spinner from "react-bootstrap/Spinner";
import { LOGIN, LOGIN_USER } from '../../utility/ConstantAPI';
const Login = () => {
    const [formData, setFormData] = useState({
        email: '',
        password: ''
    });
    const [isLoad,setIsLoad] = useState(false)
    const [passwordVisible, setPasswordVisible] = useState(false);
    const navigate = useNavigate();

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value
        });
    };

    const togglePasswordVisibility = () => {
        setPasswordVisible(!passwordVisible);
    };

    const handleSubmit = async (e) => {
        setIsLoad(true)
        e.preventDefault();
        try {
            const response = await axios.post(LOGIN_USER, formData);
            if(response.data){
                setIsLoad(false)
            }
            // Handle successful login here, e.g., redirect to another page or save the token
            localStorage.setItem('token', response.data.token);
            localStorage.setItem('user',JSON.stringify(response.data))
            navigate('/NewBroadcast');
        } catch (error) {
            console.error('Error logging in:', error);
            // Handle login error here, e.g., show an error message
        }
    };

    return (
        <div className='authPageWrap'>
            <div className='container-fluid'>
                <div className='row justify-content-between align-items-center'>
                    <div className='col-md-5 pad0'>
                        <div className='leftDataWrap'>
                            <h2>Make your business <br/> communications <br/> much easier</h2>
                            <div className='imgWrap'>
                                <img src={LoginPageImg1} className='img-fluid' alt='' />
                            </div>
                        </div>
                    </div>
                    <div className='col-md-5'>
                        <div className='formWrap'>
                            <div className='logoWrap'>
                                <img src={LogoImg} alt='' className='img-fluid' />
                            </div>
                            <div className='title'>
                                <h2>Welcome</h2>
                            </div>
                            <form onSubmit={handleSubmit}>
                                <ul>
                                    <li>
                                        <div className='inputField'>
                                            <input
                                                type='text'
                                                name='email'
                                                placeholder='Email'
                                                value={formData.email}
                                                onChange={handleChange}
                                                required
                                            />
                                        </div>
                                    </li>
                                    <li className='martBtm'>
                                        <div className='inputField'>
                                            <input
                                                type={passwordVisible ? 'text' : 'password'}
                                                name='password'
                                                placeholder='Password'
                                                value={formData.password}
                                                onChange={handleChange}
                                                required
                                            />
                                            <button
                                                type='button'
                                                className={`btn btnShowPwd ${passwordVisible ? 'active' : ''}`}
                                                onClick={togglePasswordVisibility}
                                            >
                                                <svg xmlns="http://www.w3.org/2000/svg" version="1.1" width="16" height="16" x="0" y="0" viewBox="0 0 488.85 488.85"><g><path d="M244.425 98.725c-93.4 0-178.1 51.1-240.6 134.1-5.1 6.8-5.1 16.3 0 23.1 62.5 83.1 147.2 134.2 240.6 134.2s178.1-51.1 240.6-134.1c5.1-6.8 5.1-16.3 0-23.1-62.5-83.1-147.2-134.2-240.6-134.2zm6.7 248.3c-62 3.9-113.2-47.2-109.3-109.3 3.2-51.2 44.7-92.7 95.9-95.9 62-3.9 113.2 47.2 109.3 109.3-3.3 51.1-44.8 92.6-95.9 95.9zm-3.1-47.4c-33.4 2.1-61-25.4-58.8-58.8 1.7-27.6 24.1-49.9 51.7-51.7 33.4-2.1 61 25.4 58.8 58.8-1.8 27.7-24.2 50-51.7 51.7z" fill="#434343" opacity="1" data-original="#434343"></path></g></svg>
                                            </button>
                                            <Link to='/forgot-password' className='forgotPwd'>Forget Password?</Link>
                                        </div>
                                    </li>
                                    <li>
                                        <div className='ctaWrap'>
                                            <button type='submit' className='btn'> {isLoad && <Spinner animation='grow' size='sm' as='span' />} {isLoad ? 'Loading' : 'Log in'}</button>
                                            {/* <p>Not a ccount yet? <Link to='/signup'>Sign up for free</Link></p> */}
                                        </div>
                                    </li>
                                </ul>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Login;
