import React, { useState } from 'react';
import { Formik, Field, Form, FieldArray, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { addGroupData } from '../../services/group-service';
import { useNavigate } from "react-router-dom";
import Spinner from 'react-bootstrap/Spinner';
const DynamicTable = ({ groupFields, tableData, isExcelData, indexes, newTableData}) => {
  let navigate = useNavigate();
  const groupId = groupFields.group_id
  const [selectedRows, setSelectedRows] = useState([]);
  const [error, setError] = useState(false);
  const [isSpinLoad,setIsSpinLoad] = useState(false)
  const predefinedFields = groupFields?.predefined_field_labels?.map(field => ({
    Header: field.custom_label,
    accessor: field.id,
  })) || [];

  const customFields = groupFields?.custom_fields?.map(field => ({
    Header: field.label,
    accessor: field.name,
  })) || [];
  const columns = React.useMemo(() => [
    ...predefinedFields,
    ...customFields,
    { Header: 'Actions', accessor: 'actions' },
  ], [predefinedFields, customFields]);

  const validationSchema = Yup.object().shape({
    rows: Yup.array().of(
      Yup.object().shape(
        columns.slice(1, -1).reduce((acc, column) => {
          acc[column.accessor] = Yup.string().required(`${column.Header} is required`);
          return acc;
        }, {})
      )
    ),
  });

  const handleRowSelect = (index) => {
    setSelectedRows((prev) => {
      const newSelectedRows = [...prev];
      if (newSelectedRows.includes(index)) {
        return newSelectedRows.filter((i) => i !== index);
      } else {
        newSelectedRows.push(index);
      }
      return newSelectedRows;
    });
  };

  const handleSubmit = async (values) => {
    values['group_id'] = groupId
    // Example API call
    await addGroupData(values, onSuccess, onError)
  };

  const onSuccess = (response) => {
    // navigate to Contact List Page 
    setIsSpinLoad(false)
    navigate(`/ContactList`);

  }

  const onError = (error) => {
    setError(error)
    setIsSpinLoad(false)
    // setIsLoading(false);
    console.log("error", error)

  }
  //   const extractValues = (array1, array2) => {
  //     return array2.map(obj => {
  //         const index = parseInt(obj.field_index);
  //         return array1.map(subArray => subArray[index]);
  //     });
  // };

  // const result = extractValues(tableData.filter((_,index) => index !== 0), indexes);
  // console.log(result)
  function createOutput(column, data, field_index) {
    let output = [];

    data.forEach(row => {
        let obj = {};
        field_index.forEach((field, index) => {
            let fieldIndex = parseInt(field.field_index);
            let header = column[index].accessor; // Skipping the first "Select" entry
            obj[header] = row[fieldIndex];
        });
        output.push(obj);
    });

    return output;
}

  return (
    <>
      {isExcelData ? <>
        <table>
          <thead>
            <tr>
              {columns.map((column) => (
                <th key={column.accessor}>{column.Header}</th>
              ))}
            </tr>
          </thead>
          <tbody>
            {tableData.filter((_, index) => index !== 0).map((row, index) => (
              <tr>
                {indexes.map((val) => {
                  return (
                    <>
                      <td>{row[val.field_index]}</td>
                    </>
                  )
                })}
                <td>
                  <div className="ctaWrap">
                    <button type="button" className="btn">
                      <svg width="13" height="14" viewBox="0 0 13 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M1.40625 3.64865H12.0008M5.37922 6.2973V10.2703M8.02787 6.2973V10.2703M2.06841 3.64865L2.73057 11.5946C2.73057 11.9458 2.8701 12.2827 3.11846 12.531C3.36682 12.7794 3.70367 12.9189 4.0549 12.9189H9.3522C9.70343 12.9189 10.0403 12.7794 10.2886 12.531C10.537 12.2827 10.6765 11.9458 10.6765 11.5946L11.3387 3.64865M4.71706 3.64865V1.66216C4.71706 1.48655 4.78682 1.31812 4.911 1.19394C5.03518 1.06976 5.20361 1 5.37922 1H8.02787C8.20349 1 8.37191 1.06976 8.49609 1.19394C8.62027 1.31812 8.69003 1.48655 8.69003 1.66216V3.64865" stroke="#515151" strokeWidth="0.993243" strokeLinecap="round" strokeLinejoin="round" />
                      </svg>
                    </button>
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
        <div className="ctaSave">
          <button type="submit" className="btn" onClick={async() => {
            setIsSpinLoad(true)
            const output = createOutput(columns, tableData, indexes);
            let final = {
              group_id: groupId,
              rows : output
            }
            await addGroupData(final, onSuccess, onError)
          }}> {isSpinLoad && 
            <Spinner
            as="span"
            animation="grow"
            size="sm"
            role="status"
            aria-hidden="true"
        />} {isSpinLoad ?"Loading" : "Save"}</button>
        </div>
      </> :
        <Formik
          initialValues={{ rows: newTableData }}
          validationSchema={validationSchema}
          onSubmit={(values) => {
            handleSubmit(values);
          }}
        >
          {({ values, isSubmitting, touched, errors }) => (
            <Form>
              <table>
                <thead>
                  <tr>
                    {columns.map((column) => (
                      <th key={column.accessor}>{column.Header}</th>
                    ))}
                  </tr>
                </thead>
                <tbody>

                  <FieldArray name="rows">
                    {({ push, remove }) => (
                      <>
                        {values.rows.map((row, index) => (
                          <tr key={index}>
                            {columns.slice(0, -1).map((column) => (
                              <>
                                <td key={column.accessor}>
                                  <Field
                                    name={`rows[${index}].${column.accessor}`}
                                    placeholder={column.Header}
                                  />
                                  {touched.rows?.[index] && errors.rows?.[index]?.[column.accessor] ? (
                                    <div className="error">
                                      {errors.rows[index][column.accessor]}
                                    </div>
                                  ) : null}
                                </td>
                              </>
                            ))}
                            <td>
                              <div className="ctaWrap">
                                <button type="button" className="btn" onClick={() => remove(index)}>
                                  <svg width="13" height="14" viewBox="0 0 13 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M1.40625 3.64865H12.0008M5.37922 6.2973V10.2703M8.02787 6.2973V10.2703M2.06841 3.64865L2.73057 11.5946C2.73057 11.9458 2.8701 12.2827 3.11846 12.531C3.36682 12.7794 3.70367 12.9189 4.0549 12.9189H9.3522C9.70343 12.9189 10.0403 12.7794 10.2886 12.531C10.537 12.2827 10.6765 11.9458 10.6765 11.5946L11.3387 3.64865M4.71706 3.64865V1.66216C4.71706 1.48655 4.78682 1.31812 4.911 1.19394C5.03518 1.06976 5.20361 1 5.37922 1H8.02787C8.20349 1 8.37191 1.06976 8.49609 1.19394C8.62027 1.31812 8.69003 1.48655 8.69003 1.66216V3.64865" stroke="#515151" strokeWidth="0.993243" strokeLinecap="round" strokeLinejoin="round" />
                                  </svg>
                                </button>
                              </div>
                            </td>
                          </tr>
                        ))}
                        <tr>
                          <td colSpan={columns.length}>
                            <button type="button" className='btnAddRow' onClick={() => {
                              
                              push({})}}>
                              Add Row
                            </button>
                          </td>
                        </tr>
                      </>
                    )}
                  </FieldArray>


                </tbody>
              </table>
              <div className="ctaSave">
                <button type="submit" className="btn" disabled={isSubmitting}>Save</button>
              </div>
            </Form>
          )}
        </Formik>
      }
    </>
  );
};

export default DynamicTable;
