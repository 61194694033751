import React from "react";
import { Handle, Position } from 'reactflow';
import Goto from '../../assets/img/Skip to Start.png'
const CategoryNode = ({data,isConnectable}) => {

    return (
        <>
            <div className="gotoNodeWrap">
                <Handle type="target" position={Position.Top} isConnectable={isConnectable} style={{ width: '10px', height: '10px' }} />
                <div className="gotoCard">
                    <div className="headerWrap">
                        <div className="iconWrap">
                            <img src={Goto} alt="" srcset="" />
                        </div>
                        <div className="titleWrap">
                            <h4>Category</h4>
                        </div>
                    </div>

                </div>
                <Handle type="target" position={Position.Left} isConnectable={isConnectable} style={{ width: '10px', height: '10px' }} id="a" />
                <Handle type="source" position={Position.Right} isConnectable={isConnectable} style={{ width: '10px', height: '10px' }} id="c" />
            </div>
        </>
    )
}
export default CategoryNode;