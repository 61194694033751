import React, { useState, useEffect } from 'react';
import Form from 'react-bootstrap/Form';

const ItemCard = (props) => {
    // Retrieve the value from props and store it in another variable
    const { toggleEditSidebar, item, cardFor, isGridView} = props;
    const handleClick = () => {
        toggleEditSidebar(item);        
    };
    return(
        <>
         {isGridView ? (
            <div className='itemWrap'>
                <div className='imgWrap'>
                    <img src={item.image} className='img-fluid'/> 
                    <button className='btnEdit' onClick={handleClick}>
                        <svg width="11" height="12" viewBox="0 0 11 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M7.165 2.08191L0.430163 8.73842C0.393329 8.77609 0.372721 8.82671 0.372742 8.87939V10.9573C0.372742 10.985 0.383735 11.0115 0.403316 11.0311C0.422898 11.0506 0.449471 11.0616 0.477164 11.0616H2.23134C2.28388 11.0609 2.33421 11.0405 2.3723 11.0043L9.28985 4.08674L7.165 2.08191Z" fill="white" />
                            <path d="M9.96359 3.36146L7.95334 1.35128L8.77833 0.526258C8.80437 0.500177 8.8353 0.479442 8.86935 0.465325C8.9034 0.451208 8.93989 0.443909 8.97675 0.443909C9.01361 0.443909 9.05009 0.451208 9.08413 0.465325C9.11818 0.479442 9.14911 0.500177 9.17515 0.526258L10.7886 2.1396C10.8147 2.16564 10.8354 2.19657 10.8495 2.23062C10.8636 2.26466 10.8709 2.30122 10.8709 2.33808C10.8709 2.37493 10.8636 2.41137 10.8495 2.44541C10.8354 2.47946 10.8147 2.51039 10.7886 2.53643L9.96359 3.36146Z" fill="white" />
                        </svg>
                    </button>
                </div>
                <div className='dataWrap'>
                    <div className='topTtl'>
                        <h2>{item.name}</h2>
                        <ul>
                            <li>
                                <p>Ordered : 450</p>
                            </li>
                            <li>
                                <p>3.0</p>
                            </li>
                            <li>
                                <div className='icoWrap'>
                                    <svg width="16" height="16" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <circle cx="9.26925" cy="9.03845" r="8.48901" fill="#F3CB62" />
                                        <path d="M9.26928 4.97852L10.1808 7.78388H13.1305L10.7441 9.51769L11.6557 12.3231L9.26928 10.5892L6.8829 12.3231L7.79441 9.51769L5.40803 7.78388H8.35776L9.26928 4.97852Z" fill="white" />
                                    </svg>
                                </div>
                            </li>
                        </ul>
                    </div>
                    <div className='descWrap'>
                        <p>{item.description}</p>
                    </div>
                    {cardFor ==='products' ? (
                        <div className='btmData'>
                            <div className='leftWrap'>
                                <h3><span>Rs</span> {item.unit_price}</h3>
                                <h4>250g</h4>
                            </div>
                            <div className='ctaWrap'>
                                <button className='btn'>
                                    <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M11.8593 6.84994H7.17328V11.6019H5.30328V6.84994H0.639281V5.15595H5.30328V0.381944H7.17328V5.15595H11.8593V6.84994Z" fill="#B9A7ED" />
                                    </svg>
                                </button>
                            </div>
                        </div>
                        ): null
                    }
                    
                </div>
            </div>
            ) : (
                <tr>
                    <td>
                        <div className="imgWrap">
                            {item.image ? (
                                <img src={item.image} className="img-fluid" alt={item.name} />
                            ) : (
                                <div className="img-placeholder"></div>
                            )}
                        </div>
                    </td>
                    <td className='titleTxt'>
                        <p>{item.name}</p>
                    </td>
                    <td>450</td>
                    <td>3.0</td>
                    <td className='descripTxt'>
                        <p>{item.description}</p>
                    </td>
                    <td>
                        <button className='btnEdit' onClick={handleClick}>
                            <svg width="11" height="12" viewBox="0 0 11 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M7.165 2.08191L0.430163 8.73842C0.393329 8.77609 0.372721 8.82671 0.372742 8.87939V10.9573C0.372742 10.985 0.383735 11.0115 0.403316 11.0311C0.422898 11.0506 0.449471 11.0616 0.477164 11.0616H2.23134C2.28388 11.0609 2.33421 11.0405 2.3723 11.0043L9.28985 4.08674L7.165 2.08191Z" fill="#98A2B3" />
                                <path d="M9.96359 3.36146L7.95334 1.35128L8.77833 0.526258C8.80437 0.500177 8.8353 0.479442 8.86935 0.465325C8.9034 0.451208 8.93989 0.443909 8.97675 0.443909C9.01361 0.443909 9.05009 0.451208 9.08413 0.465325C9.11818 0.479442 9.14911 0.500177 9.17515 0.526258L10.7886 2.1396C10.8147 2.16564 10.8354 2.19657 10.8495 2.23062C10.8636 2.26466 10.8709 2.30122 10.8709 2.33808C10.8709 2.37493 10.8636 2.41137 10.8495 2.44541C10.8354 2.47946 10.8147 2.51039 10.7886 2.53643L9.96359 3.36146Z" fill="#98A2B3" />
                            </svg>
                        </button>
                    </td>
                    {/* {cardFor === 'products' && <td>Rs {item.unit_price}</td>}
                    {cardFor === 'products' && (
                        <td>
                            <button className='btn' onClick={handleClick}>
                                <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M11.8593 6.84994H7.17328V11.6019H5.30328V6.84994H0.639281V5.15595H5.30328V0.381944H7.17328V5.15595H11.8593V6.84994Z" fill="#B9A7ED" />
                                </svg>
                            </button>
                        </td>
                    )} */}
                </tr>
            )}
        </>
    )

}

export default ItemCard;