import React, { useState, useEffect } from 'react';
import Form from 'react-bootstrap/Form';
import CircularProgress from '../Screens/CircularProgress';
import { formatDate, formatDateTime} from '../../utility/CustomFormating'

const BroadcastHistoryDetails = ({onBroadcasttSelect, data}) =>{
    return(
        <div className='broadCastRepTblWrap'>
            <div className='row'>
                <div className='col-md-12 d-flex justify-content-between'>
                    <div className='filterWrap'>
                        <h2>Broadcast List</h2>
                        <div className='sortWrap'>
                            <label>Sorted by</label>
                            <Form.Select aria-label="India">
                                <option value="">Latest</option>
                                <option value="Latest">Latest</option>
                                <option value="Latest">Latest</option>
                                <option value="Latest">Latest</option>
                                <option value="Latest">Latest</option>
                                <option value="Latest">Latest</option>
                            </Form.Select>
                        </div>
                        <button className='btnFilter'>
                            <svg width="24" height="24" viewBox="0 0 31 31" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M29.166 5.24967L25.7493 5.24967M17.2077 5.24967L1.83268 5.24967M29.166 15.4997L12.0827 15.4997M3.54101 15.4997L1.83268 15.4997M29.166 25.7497L27.4577 25.7497M17.2077 25.7497L1.83268 25.7497M24.041 1.83301C24.4941 1.83301 24.9286 2.01299 25.249 2.33337C25.5694 2.65374 25.7493 3.08826 25.7493 3.54134L25.7493 6.95801C25.7493 7.41109 25.5694 7.84561 25.249 8.16598C24.9286 8.48636 24.4941 8.66634 24.041 8.66634L18.916 8.66634C18.4629 8.66634 18.0284 8.48636 17.708 8.16598C17.3877 7.84561 17.2077 7.41109 17.2077 6.95801L17.2077 3.54134C17.2077 3.08826 17.3877 2.65374 17.708 2.33337C18.0284 2.01299 18.4629 1.83301 18.916 1.83301L24.041 1.83301ZM10.3743 12.083C10.8274 12.083 11.2619 12.263 11.5823 12.5834C11.9027 12.9037 12.0827 13.3383 12.0827 13.7913L12.0827 17.208C12.0827 17.6611 11.9027 18.0956 11.5823 18.416C11.2619 18.7364 10.8274 18.9163 10.3743 18.9163L5.24935 18.9163C4.79627 18.9163 4.36175 18.7364 4.04137 18.416C3.721 18.0956 3.54101 17.6611 3.54101 17.208L3.54101 13.7913C3.54101 13.3383 3.721 12.9037 4.04137 12.5834C4.36175 12.263 4.79627 12.083 5.24935 12.083L10.3743 12.083ZM25.7493 22.333C26.2024 22.333 26.6369 22.513 26.9573 22.8334C27.2777 23.1537 27.4577 23.5883 27.4577 24.0413L27.4577 27.458C27.4577 27.9111 27.2777 28.3456 26.9573 28.666C26.6369 28.9864 26.2024 29.1663 25.7493 29.1663L18.916 29.1663C18.4629 29.1663 18.0284 28.9864 17.708 28.666C17.3877 28.3456 17.2077 27.9111 17.2077 27.458L17.2077 24.0413C17.2077 23.5883 17.3877 23.1537 17.708 22.8334C18.0284 22.513 18.4629 22.333 18.916 22.333L25.7493 22.333Z" stroke="#1944F1" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                            </svg>
                        </button>
                        <div className='searchWrap'>
                            <input placeholder='Search' />
                            <button className='btn'>
                                <svg width="14" height="14" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M16.7196 15.644L12.1832 11.1074C12.9065 10.1836 13.3799 9.08939 13.5578 7.92964C13.7357 6.7699 13.612 5.58384 13.1988 4.48571C13.1496 4.33553 13.0861 4.19033 13.0092 4.05228C12.2796 2.51666 11.005 1.30714 9.43333 0.65893C7.86161 0.0107229 6.10497 -0.0296427 4.50497 0.545201C4.35601 0.599368 4.19356 0.666931 4.0446 0.734639C2.48923 1.45516 1.25979 2.7318 0.598186 4.31313C-0.0634155 5.89446 -0.109288 7.66606 0.469579 9.27951C0.521194 9.43312 0.584597 9.58263 0.659183 9.72649C1.20547 10.9088 2.07822 11.9101 3.17461 12.613C4.27099 13.316 5.54545 13.6911 6.84782 13.6941C8.34264 13.6957 9.79546 13.2002 10.9779 12.2857L15.5279 16.8359L16.7196 15.644ZM3.25924 10.5252C2.78587 10.0554 2.41038 9.49646 2.15468 8.88047C1.89899 8.26447 1.7682 7.60379 1.76969 6.93683C1.7682 6.26987 1.89899 5.60919 2.15468 4.99319C2.41038 4.37719 2.78587 3.81793 3.25924 3.34809C3.72909 2.87472 4.28818 2.49956 4.90418 2.24386C5.52017 1.98816 6.18086 1.85721 6.84782 1.8587C7.51478 1.85714 8.17528 1.98783 8.79129 2.24353C9.4073 2.49923 9.96643 2.87466 10.4362 3.34809C11.0621 3.97255 11.5148 4.74918 11.7499 5.60151C11.9849 6.45383 11.9942 7.35266 11.7768 8.20967C11.6648 8.67274 11.4823 9.11575 11.2353 9.52317C10.7843 10.2856 10.1424 10.9175 9.37316 11.3567C8.60388 11.796 7.73366 12.0276 6.84782 12.0285C5.50284 12.0076 4.2176 11.4691 3.25924 10.5252Z" fill="#919191" />
                                </svg>
                            </button>
                        </div>
                    </div>
                    <div className='NewBroadCastctaWrap'>
                        <button className='btn'>
                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M12 24C18.6274 24 24 18.6274 24 12C24 5.37258 18.6274 0 12 0C5.37258 0 0 5.37258 0 12C0 18.6274 5.37258 24 12 24Z" fill="white" />
                                <path d="M17.3545 12.8191H12.8736V17.3555H11.084V12.8191H6.63086V11.1961H11.084V6.63184H12.8736V11.1961H17.3545V12.8191Z" fill="#1944F1" />
                            </svg>
                            New Broadcast
                        </button>
                    </div>
                </div>
            </div>
            <div className='row'>
                <div className='col-md-12'>
                    <div className='tblWrap'>
                        <table>
                            <thead>
                                <tr>
                                    <th>Broadcast Name</th>
                                    <th>Scheduled</th>
                                    <th>Recipients</th>
                                    <th>Successful</th>
                                    <th>Delivered</th> 
                                    <th>Read</th>                                    
                                    {/* <th>Replied</th> */}                                    
                                    <th>Failed</th>
                                    <th>Status</th>
                                    <th>Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                {data.map((val) => (
                                    <tr key={val.broadcast_id}>

                                        {/* name */}
                                        <td>{val.broadcast_name}</td>

                                        {/* Scheduled */}
                                        <td>
                                            <span>{formatDateTime(val.broadcast_created_at)}</span>
                                        </td>


                                        {/* Recipients */}
                                        <td>{val.total_contacts}</td>

                                        {/* Successful */}
                                        <td>{val.total_messages_sent_successfully}</td>

                                        {/* Delivered */}
                                        <td>{val.total_messages_delivered}</td>

                                        {/* Read */}
                                        <td>{val.total_messages_read}</td>
                                        

                                        {/* Replied */}
                                        {/* <td>total_messages_delivered</td> */}


                                        {/*  Failed*/}
                                        <td>{val.total_messages_failed}</td>

                                        {/* Status */}
                                        <td>
                                            <CircularProgress total={val.total_contacts} final={val.total_messages_sent} />
                                        </td>

                                        {/* Action */}
                                        <td>
                                            <button className='btn'>
                                                <svg width="18" height="16" viewBox="0 0 20 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M7 16V10C7 9.73478 6.89464 9.48043 6.70711 9.29289C6.51957 9.10536 6.26522 9 6 9H2C1.73478 9 1.48043 9.10536 1.29289 9.29289C1.10536 9.48043 1 9.73478 1 10V16C1 16.2652 1.10536 16.5196 1.29289 16.7071C1.48043 16.8946 1.73478 17 2 17M7 16C7 16.2652 6.89464 16.5196 6.70711 16.7071C6.51957 16.8946 6.26522 17 6 17H2M7 16C7 16.2652 7.10536 16.5196 7.29289 16.7071C7.48043 16.8946 7.73478 17 8 17H12C12.2652 17 12.5196 16.8946 12.7071 16.7071C12.8946 16.5196 13 16.2652 13 16M7 16V6C7 5.73478 7.10536 5.48043 7.29289 5.29289C7.48043 5.10536 7.73478 5 8 5H12C12.2652 5 12.5196 5.10536 12.7071 5.29289C12.8946 5.48043 13 5.73478 13 6V16M2 17H16M13 16C13 16.2652 13.1054 16.5196 13.2929 16.7071C13.4804 16.8946 13.7348 17 14 17H18C18.2652 17 18.5196 16.8946 18.7071 16.7071C18.8946 16.5196 19 16.2652 19 16V2C19 1.73478 18.8946 1.48043 18.7071 1.29289C18.5196 1.10536 18.2652 1 18 1H14C13.7348 1 13.4804 1.10536 13.2929 1.29289C13.1054 1.48043 13 1.73478 13 2V16Z" stroke="#354052" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" opacity='0.6' />
                                                </svg>
                                            </button>
                                            <button className='btn' onClick={() => onBroadcasttSelect(val.broadcast_id ,val)}>
                                                <svg width="18" height="12" viewBox="0 0 20 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M8 7C8 7.53043 8.21071 8.03914 8.58579 8.41421C8.96086 8.78929 9.46957 9 10 9C10.5304 9 11.0391 8.78929 11.4142 8.41421C11.7893 8.03914 12 7.53043 12 7C12 6.46957 11.7893 5.96086 11.4142 5.58579C11.0391 5.21071 10.5304 5 10 5C9.46957 5 8.96086 5.21071 8.58579 5.58579C8.21071 5.96086 8 6.46957 8 7Z" stroke="#354052" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" opacity='0.6' />
                                                    <path d="M19 7C16.6 11 13.6 13 10 13C6.4 13 3.4 11 1 7C3.4 3 6.4 1 10 1C13.6 1 16.6 3 19 7Z" stroke="#354052" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" opacity='0.6' />
                                                </svg>
                                            </button>
                                        </td>
                                    </tr>                         

                                ))}  



                                

                                {/* new UI */}
                                {/* <tr>
                                    <td>Broadcast 0087</td>
                                    <td>
                                        <span>01-07-2024</span>
                                        <span>07:00PM</span>
                                    </td>
                                    <td>000</td>
                                    <td>000</td>
                                    <td>000</td>
                                    <td>000</td>
                                    <td>000</td>
                                    <td>
                                        <CircularProgress total={100} final={100} />
                                    </td>
                                    <td>
                                        <button className='btn'>
                                            <svg width="18" height="16" viewBox="0 0 20 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M7 16V10C7 9.73478 6.89464 9.48043 6.70711 9.29289C6.51957 9.10536 6.26522 9 6 9H2C1.73478 9 1.48043 9.10536 1.29289 9.29289C1.10536 9.48043 1 9.73478 1 10V16C1 16.2652 1.10536 16.5196 1.29289 16.7071C1.48043 16.8946 1.73478 17 2 17M7 16C7 16.2652 6.89464 16.5196 6.70711 16.7071C6.51957 16.8946 6.26522 17 6 17H2M7 16C7 16.2652 7.10536 16.5196 7.29289 16.7071C7.48043 16.8946 7.73478 17 8 17H12C12.2652 17 12.5196 16.8946 12.7071 16.7071C12.8946 16.5196 13 16.2652 13 16M7 16V6C7 5.73478 7.10536 5.48043 7.29289 5.29289C7.48043 5.10536 7.73478 5 8 5H12C12.2652 5 12.5196 5.10536 12.7071 5.29289C12.8946 5.48043 13 5.73478 13 6V16M2 17H16M13 16C13 16.2652 13.1054 16.5196 13.2929 16.7071C13.4804 16.8946 13.7348 17 14 17H18C18.2652 17 18.5196 16.8946 18.7071 16.7071C18.8946 16.5196 19 16.2652 19 16V2C19 1.73478 18.8946 1.48043 18.7071 1.29289C18.5196 1.10536 18.2652 1 18 1H14C13.7348 1 13.4804 1.10536 13.2929 1.29289C13.1054 1.48043 13 1.73478 13 2V16Z" stroke="#354052" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" opacity='0.6' />
                                            </svg>
                                        </button>
                                        <button className='btn'>
                                            <svg width="18" height="12" viewBox="0 0 20 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M8 7C8 7.53043 8.21071 8.03914 8.58579 8.41421C8.96086 8.78929 9.46957 9 10 9C10.5304 9 11.0391 8.78929 11.4142 8.41421C11.7893 8.03914 12 7.53043 12 7C12 6.46957 11.7893 5.96086 11.4142 5.58579C11.0391 5.21071 10.5304 5 10 5C9.46957 5 8.96086 5.21071 8.58579 5.58579C8.21071 5.96086 8 6.46957 8 7Z" stroke="#354052" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" opacity='0.6' />
                                                <path d="M19 7C16.6 11 13.6 13 10 13C6.4 13 3.4 11 1 7C3.4 3 6.4 1 10 1C13.6 1 16.6 3 19 7Z" stroke="#354052" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" opacity='0.6' />
                                            </svg>
                                        </button>
                                    </td>
                                </tr>
                                <tr>
                                    <td>Broadcast 0087</td>
                                    <td>
                                        <span>01-07-2024</span>
                                        <span>07:00PM</span>
                                    </td>
                                    <td>000</td>
                                    <td>000</td>
                                    <td>000</td>
                                    <td>000</td>
                                    <td>000</td>
                                    <td>
                                        <CircularProgress total={100} final={80} />
                                    </td>
                                    <td>
                                        <button className='btn'>
                                            <svg width="18" height="16" viewBox="0 0 20 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M7 16V10C7 9.73478 6.89464 9.48043 6.70711 9.29289C6.51957 9.10536 6.26522 9 6 9H2C1.73478 9 1.48043 9.10536 1.29289 9.29289C1.10536 9.48043 1 9.73478 1 10V16C1 16.2652 1.10536 16.5196 1.29289 16.7071C1.48043 16.8946 1.73478 17 2 17M7 16C7 16.2652 6.89464 16.5196 6.70711 16.7071C6.51957 16.8946 6.26522 17 6 17H2M7 16C7 16.2652 7.10536 16.5196 7.29289 16.7071C7.48043 16.8946 7.73478 17 8 17H12C12.2652 17 12.5196 16.8946 12.7071 16.7071C12.8946 16.5196 13 16.2652 13 16M7 16V6C7 5.73478 7.10536 5.48043 7.29289 5.29289C7.48043 5.10536 7.73478 5 8 5H12C12.2652 5 12.5196 5.10536 12.7071 5.29289C12.8946 5.48043 13 5.73478 13 6V16M2 17H16M13 16C13 16.2652 13.1054 16.5196 13.2929 16.7071C13.4804 16.8946 13.7348 17 14 17H18C18.2652 17 18.5196 16.8946 18.7071 16.7071C18.8946 16.5196 19 16.2652 19 16V2C19 1.73478 18.8946 1.48043 18.7071 1.29289C18.5196 1.10536 18.2652 1 18 1H14C13.7348 1 13.4804 1.10536 13.2929 1.29289C13.1054 1.48043 13 1.73478 13 2V16Z" stroke="#354052" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" opacity='0.6' />
                                            </svg>
                                        </button>
                                        <button className='btn'>
                                            <svg width="18" height="12" viewBox="0 0 20 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M8 7C8 7.53043 8.21071 8.03914 8.58579 8.41421C8.96086 8.78929 9.46957 9 10 9C10.5304 9 11.0391 8.78929 11.4142 8.41421C11.7893 8.03914 12 7.53043 12 7C12 6.46957 11.7893 5.96086 11.4142 5.58579C11.0391 5.21071 10.5304 5 10 5C9.46957 5 8.96086 5.21071 8.58579 5.58579C8.21071 5.96086 8 6.46957 8 7Z" stroke="#354052" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" opacity='0.6' />
                                                <path d="M19 7C16.6 11 13.6 13 10 13C6.4 13 3.4 11 1 7C3.4 3 6.4 1 10 1C13.6 1 16.6 3 19 7Z" stroke="#354052" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" opacity='0.6' />
                                            </svg>
                                        </button>
                                    </td>
                                </tr> */}
                                {/* new UI */}


                                {/* Old UI */}
                                {/* <tr>
                                    <td>Broadcast 0087</td>
                                    <td>
                                        <span>01-07-2024</span>
                                        <span>07:00PM</span>
                                    </td>
                                    <td>
                                        <CircularProgress total={100} final={80} />
                                    </td>
                                    <td>
                                        <CircularProgress total={100} final={65} />
                                    </td>
                                    <td>
                                        <CircularProgress total={100} final={48} />
                                    </td>
                                    <td>2 Contact</td>
                                    <td>0 Contact</td>
                                    <td>Completed</td>
                                    <td>
                                        <button className='btn'>
                                            <svg width="18" height="16" viewBox="0 0 20 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M7 16V10C7 9.73478 6.89464 9.48043 6.70711 9.29289C6.51957 9.10536 6.26522 9 6 9H2C1.73478 9 1.48043 9.10536 1.29289 9.29289C1.10536 9.48043 1 9.73478 1 10V16C1 16.2652 1.10536 16.5196 1.29289 16.7071C1.48043 16.8946 1.73478 17 2 17M7 16C7 16.2652 6.89464 16.5196 6.70711 16.7071C6.51957 16.8946 6.26522 17 6 17H2M7 16C7 16.2652 7.10536 16.5196 7.29289 16.7071C7.48043 16.8946 7.73478 17 8 17H12C12.2652 17 12.5196 16.8946 12.7071 16.7071C12.8946 16.5196 13 16.2652 13 16M7 16V6C7 5.73478 7.10536 5.48043 7.29289 5.29289C7.48043 5.10536 7.73478 5 8 5H12C12.2652 5 12.5196 5.10536 12.7071 5.29289C12.8946 5.48043 13 5.73478 13 6V16M2 17H16M13 16C13 16.2652 13.1054 16.5196 13.2929 16.7071C13.4804 16.8946 13.7348 17 14 17H18C18.2652 17 18.5196 16.8946 18.7071 16.7071C18.8946 16.5196 19 16.2652 19 16V2C19 1.73478 18.8946 1.48043 18.7071 1.29289C18.5196 1.10536 18.2652 1 18 1H14C13.7348 1 13.4804 1.10536 13.2929 1.29289C13.1054 1.48043 13 1.73478 13 2V16Z" stroke="#354052" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" opacity='0.6' />
                                            </svg>
                                        </button>
                                        <button className='btn'>
                                            <svg width="18" height="12" viewBox="0 0 20 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M8 7C8 7.53043 8.21071 8.03914 8.58579 8.41421C8.96086 8.78929 9.46957 9 10 9C10.5304 9 11.0391 8.78929 11.4142 8.41421C11.7893 8.03914 12 7.53043 12 7C12 6.46957 11.7893 5.96086 11.4142 5.58579C11.0391 5.21071 10.5304 5 10 5C9.46957 5 8.96086 5.21071 8.58579 5.58579C8.21071 5.96086 8 6.46957 8 7Z" stroke="#354052" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" opacity='0.6' />
                                                <path d="M19 7C16.6 11 13.6 13 10 13C6.4 13 3.4 11 1 7C3.4 3 6.4 1 10 1C13.6 1 16.6 3 19 7Z" stroke="#354052" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" opacity='0.6' />
                                            </svg>
                                        </button>
                                    </td>
                                </tr>
                                <tr>
                                    <td>Broadcast 0087</td>
                                    <td>
                                        <span>01-07-2024</span>
                                        <span>07:00PM</span>
                                    </td>
                                    <td>
                                        <CircularProgress total={100} final={80}  />
                                    </td>
                                    <td>
                                        <CircularProgress total={100} final={50}  />
                                    </td>
                                    <td>
                                        <CircularProgress total={100} final={77}  />
                                    </td>
                                    <td>2 Contact</td>
                                    <td>0 Contact</td>
                                    <td>Completed</td>
                                    <td>
                                        <button className='btn'>
                                            <svg width="18" height="16" viewBox="0 0 20 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M7 16V10C7 9.73478 6.89464 9.48043 6.70711 9.29289C6.51957 9.10536 6.26522 9 6 9H2C1.73478 9 1.48043 9.10536 1.29289 9.29289C1.10536 9.48043 1 9.73478 1 10V16C1 16.2652 1.10536 16.5196 1.29289 16.7071C1.48043 16.8946 1.73478 17 2 17M7 16C7 16.2652 6.89464 16.5196 6.70711 16.7071C6.51957 16.8946 6.26522 17 6 17H2M7 16C7 16.2652 7.10536 16.5196 7.29289 16.7071C7.48043 16.8946 7.73478 17 8 17H12C12.2652 17 12.5196 16.8946 12.7071 16.7071C12.8946 16.5196 13 16.2652 13 16M7 16V6C7 5.73478 7.10536 5.48043 7.29289 5.29289C7.48043 5.10536 7.73478 5 8 5H12C12.2652 5 12.5196 5.10536 12.7071 5.29289C12.8946 5.48043 13 5.73478 13 6V16M2 17H16M13 16C13 16.2652 13.1054 16.5196 13.2929 16.7071C13.4804 16.8946 13.7348 17 14 17H18C18.2652 17 18.5196 16.8946 18.7071 16.7071C18.8946 16.5196 19 16.2652 19 16V2C19 1.73478 18.8946 1.48043 18.7071 1.29289C18.5196 1.10536 18.2652 1 18 1H14C13.7348 1 13.4804 1.10536 13.2929 1.29289C13.1054 1.48043 13 1.73478 13 2V16Z" stroke="#354052" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" opacity='0.6' />
                                            </svg>
                                        </button>
                                        <button className='btn'>
                                            <svg width="18" height="12" viewBox="0 0 20 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M8 7C8 7.53043 8.21071 8.03914 8.58579 8.41421C8.96086 8.78929 9.46957 9 10 9C10.5304 9 11.0391 8.78929 11.4142 8.41421C11.7893 8.03914 12 7.53043 12 7C12 6.46957 11.7893 5.96086 11.4142 5.58579C11.0391 5.21071 10.5304 5 10 5C9.46957 5 8.96086 5.21071 8.58579 5.58579C8.21071 5.96086 8 6.46957 8 7Z" stroke="#354052" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" opacity='0.6' />
                                                <path d="M19 7C16.6 11 13.6 13 10 13C6.4 13 3.4 11 1 7C3.4 3 6.4 1 10 1C13.6 1 16.6 3 19 7Z" stroke="#354052" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" opacity='0.6' />
                                            </svg>
                                        </button>
                                    </td>
                                </tr> */}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default BroadcastHistoryDetails;

