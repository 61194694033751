export const COMMON_MAIN_BASE_URL = "https://api.bucify.com"; 
// export const COMMON_MAIN_BASE_URL = "http://localhost:8080"; 
// export const COMMON_MAIN_BASE_URL ='https://whatsapp-new-ecommerce-fcf5f25d862d.herokuapp.com';
export const COMMON_BASE_URL = `${COMMON_MAIN_BASE_URL}/api`;
 export const LOGIN_USER = `${COMMON_BASE_URL}/user/user-login`
//CAtegory API
export const CATEGORIES_BASE_API = `${COMMON_BASE_URL}/category`;
export const GET_ALL_CATEGORIES = `${CATEGORIES_BASE_API}/getCategory`;
export const ADD_NEW_CATEGORIES = `${CATEGORIES_BASE_API}/addCategory`;
export const GET_CATEGORY_BY_ID = (category_id) => 
    `${CATEGORIES_BASE_API}/getCategoryById/${category_id}`;
export const  UPDATE_CATEGORY = (category_id) => `${CATEGORIES_BASE_API}/addCategory?id=${category_id}`

//Products API
export const PRODUCTS_BASE_API = `${COMMON_BASE_URL}/product`;
export const GET_ALL_PRODUCTS = `${PRODUCTS_BASE_API}/getProducts`;
export const ADD_NEW_PRODUCT = `${PRODUCTS_BASE_API}/addproducts`;
export const GET_PRODUCT_BY_ID = (product_id) => 
    `${PRODUCTS_BASE_API}/getProductById/${product_id}`;
export const  UPDATE_PRODUCT = (product_id) => `${PRODUCTS_BASE_API}/addProducts?id=${product_id}`


//menu API
export const MENU_BASE_API = `${COMMON_BASE_URL}/menu`;
export const GET_ALL_MENUS = `${MENU_BASE_API}/getMenu`;
export const ADD_NEW_MENU = `${MENU_BASE_API}/addMenu`;
export const GET_MENU_BY_ID = (menu_id) => 
    `${MENU_BASE_API}/getMenuById/${menu_id}`;
export const  UPDATE_MENU = (menu_id) => `${MENU_BASE_API}/addMenu?id=${menu_id}`

//Order API
export const ORDER_BASE_API = `${COMMON_BASE_URL}/orders`;
export const GET_ALL_ORDERS = `${ORDER_BASE_API}/getOrders`;

// Flow API
export const FLOW_BASE_API = `${COMMON_BASE_URL}/flow`
export const GET_BUTTON_NODE_DATA = (node_id) => `${FLOW_BASE_API}/getButtonNode/${node_id}`
export const UPDATE_BUTTON_NODE_DATA = (node_id) => `${FLOW_BASE_API}/updateButtonNode/?id=${node_id}`
export const GET_LIST_NODE_DATA = (node_id) => `${FLOW_BASE_API}/getListNode/${node_id}`
export const UPDATE_LIST_NODE_DATA = (node_id) => `${FLOW_BASE_API}/updateListNode/?id=${node_id}`
export const GET_MESSAGE_NODE_DATA = (node_id) => `${FLOW_BASE_API}/getMessageNode/${node_id}`
export const UPDATE_MESSAGE_NODE_DATA = (node_id) => `${FLOW_BASE_API}/updateMessageNode/?id=${node_id}`
export const GET_TRIGGER_NODE_DATA = (node_id) => `${FLOW_BASE_API}/getTriggerNode/${node_id}`
export const UPDATE_TRIGGER_NODE_DATA = (node_id) => `${FLOW_BASE_API}/updateTriggerNode/?id=${node_id}`
export const GET_FLOW_LIST = (userId) =>  `${FLOW_BASE_API}/getFlowList/?userId=${userId}`
export const DELETE_FLOW = (flow_id) => `${FLOW_BASE_API}/deleteFlow/?id=${flow_id}`
export const DELETE_NODE = `${FLOW_BASE_API}/deleteNode`
export const ADD_FLOW_NODE = `${FLOW_BASE_API}/addFlowNode`
export const GET_FLOW_BY_ID = (flow_id) =>  `${FLOW_BASE_API}/getFlow/?flowId=${flow_id}`
export const UPDATE_CHAT_BOT_FLOW = `${FLOW_BASE_API}/updateChatBotFlow`
export const ADD_MESSAGE_NODE = `${FLOW_BASE_API}/addMessage`
export const ADD_TRIGGER_NODE = `${FLOW_BASE_API}/addTrigger`
export const ADD_BUTTON_NODE = `${FLOW_BASE_API}/addBtn`
export const ADD_LIST_NODE = `${FLOW_BASE_API}/addList`

// Common API
export const COMMON_BASE_API = `${COMMON_BASE_URL}/common`
export const GET_NODE_TYPES = `${COMMON_BASE_API}/getNodeTypes`
export const GET_CAT_IN_MENU = (menu_id) =>  `${COMMON_BASE_API}/getCatInMenu/?menuId=${menu_id}` 
export const GET_PROD_IN_CAT = (category_id) => `${COMMON_BASE_API}/getProdInCat/?categoryId=${category_id}`


//Predefined Fields
export const COMMON_PRE_API = `${COMMON_BASE_URL}/pre_fields`
export const GET_ALL_PREDEFINED_FIELDS = `${COMMON_PRE_API}/`

//Group APIs
export const COMMON_GROUP_API = `${COMMON_BASE_URL}/group`
export const GROUP_API = (userId) => `${COMMON_GROUP_API}/?userId=${userId}`
export const GET_GROUP_FIELDS_BY_ID = (groupId) =>`${COMMON_GROUP_API}/${groupId}/fields`
export const ADD_DATA_GROUP_API = `${COMMON_GROUP_API}/add_data_to_group`
export const GET_GROUP_BY_GROUP_ID = (groupId) =>  `${COMMON_GROUP_API}/${groupId}`
export const UPDATE_GROUPS_CONTACTS = (groupId) => `${COMMON_GROUP_API}/update_contact/?groupId=${groupId}`
export const DELETE_CONTACT_LIST = (groupId) => `${COMMON_GROUP_API}/${groupId}`

export const SEND_TEMP_MESSAGE = `${COMMON_MAIN_BASE_URL}/send_temp_message`
export const GET_TEMPLATES = `${COMMON_MAIN_BASE_URL}/template`
export const GET_TEMPLATES_BY_CATEGORY = (category) => `${COMMON_MAIN_BASE_URL}/template/?category=${category}`
export const GET_TEMPLATES_BY_LANGUAGE = (language) => `${COMMON_MAIN_BASE_URL}/template/?language=${language}`
export const CREATE_TEMPLATE = `${COMMON_MAIN_BASE_URL}/create_template`
export const BROADCAST_BASE_API = `${COMMON_BASE_URL}/broadCast`
export const GET_BROADCAST_LIST = `${BROADCAST_BASE_API}`
export const GET_OVERVIEW_API = `${BROADCAST_BASE_API}/overview`

export const DELETE_CONTACT =(contact_id,group_id) => `${COMMON_BASE_URL}/contact/delete_contact/?contact_id=${contact_id}&group_id=${group_id}`
export const DELETE_BULK_CONTACT = `${COMMON_GROUP_API}/delete_bulk_contacts`
export const GET_BROADCAST_DETAILS_BY_ID = (id) => `${BROADCAST_BASE_API}/${id}/details`

//SMS Broadcasr

export const SEND_SMS_BROADCAST = `${COMMON_BASE_URL}/sms_broadcast`;

//SMS Flow
export const SMS_FLOW_API = `${COMMON_BASE_URL}/sms_flow`;
export const SAVE_SMS_FLOW = `${SMS_FLOW_API}/save_sms_flow`;
export const GET_SMS_FLOW_LIST_API = `${SMS_FLOW_API}/flow_list`;
export const GET_SMS_FLOW_BY_ID = (flowId) => `${SMS_FLOW_API}/${flowId}`;

export const UPLOAD_TEMPLATE_IMAGE = `${COMMON_MAIN_BASE_URL}/upload_temp_image`
export const UPLOAD_IMAGE = `${COMMON_MAIN_BASE_URL}/upload_image`

//User & Tenants
export const USER_BASE_API = `${COMMON_BASE_URL}/user`;
export const ADD_USER = `${USER_BASE_API}/add-user`;
export const GET_TENANTS = `${USER_BASE_API}/tenants`;
export const ADD_TENANT = `${USER_BASE_API}/add-tenant`;
export const USER_ROLES = `${USER_BASE_API}/roles`;

//zoho 
export const ZOHO_API =`${COMMON_BASE_URL}/zoho`;
export const ZOHO_AUTH_TOKEN = `${ZOHO_API}/oauth/token`;
export const ZOHO_DATA = `${ZOHO_API}/api/data`;
export const ZOHO_SEND_MESSAGE = `${ZOHO_API}/send-message`;
export const ZOHO_UPDATE_CONFIRM_STATUS = `${ZOHO_API}/update-confirm-status`;

// Google
export const GOOGLE_AUTH_API =`${COMMON_MAIN_BASE_URL}/auth/google`;
