import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import Form from 'react-bootstrap/Form';
import { CustomScroll } from "react-custom-scroll";
import { Modal, Button } from 'react-bootstrap';
import Dropdown from 'react-bootstrap/Dropdown';
import Header from '../Common/Header';
import Sidebar from '../Common/Sidebar';
import {getGroupFieldsById} from '../../services/group-service' 
import DynamicTable from './DynamicTable';

const AddDataToContactList = () => {
    const location = useLocation();
    // This method use when sending state 
    // const { groupId } = location.state || {}; // Destructure the groupId from the state
    
    //Query parameter method
    const queryParams = new URLSearchParams(location.search);
    const groupId = queryParams.get('groupId'); // Get the groupId from the query params
    const [isOpen, setIsOpen] = useState(false);
    const [error, setError] = useState(false);
    const [groupFields, setGroupFields] = useState([]);
    const [columns, setColumns] = useState([]);
    // const [data, setData] = useState([]);
    const [isExcelData,setIsExcelData] = useState(false)
    const [rowsToInsert, setRowsToInsert] = useState(5);
    const [tableData, setTableData] = useState([]); // State to hold table data
  
    const toggleSidebar = () => {
        setIsOpen(!isOpen);
    };
    const {data,indexes} = location.state || {}
    useEffect(() => {
        if(location.state){
            setIsExcelData(true)
        }
        const fetchData = async () => {
          try {
            const response = await getGroupFieldsById(groupId);
            setGroupFields(response);
          } catch (error) {
            console.log('Error fetching data:', error);
            setError(error.message);
          }
        };
        fetchData();
    }, [groupId]);
    const handleInsertRows = () => {
        // Create an array of new rows with default values
        const newRows = Array(rowsToInsert).fill({});
    
        // Update table data with new rows
        setTableData((prevData) => [...prevData, ...newRows]);
    };   

    const ErrorBoundary = ({ children }) => {
        try {
          return children;
        } catch (error) {
          return <div>Error: {error.message}</div>;
        }
    };
    return (
        <div className={isOpen ? "app-container sidebar-open" : "app-container"}>
            <Header toggleSidebar={toggleSidebar} />
            {/* <Sidebar isOpen={isOpen} /> */}
            <main className="content newContactListPage">
                <div className='newContactListWrap newListWrap'>
                    <div className='container-fluid ht100 pad0'>
                        <div className='row ht100'>
                            <div className='col-md-12'>
                                <div className='rightPannelWrap'>
                                    <div className='topWrap'>
                                        <div className='ttlWrap'>
                                            <div className='icoWrap'>
                                                <svg width="42" height="42" viewBox="0 0 42 42" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M12.1111 41V38.7778C12.1111 37.599 12.5794 36.4686 13.4129 35.6351C14.2464 34.8016 15.3768 34.3333 16.5556 34.3333H25.4444C26.6232 34.3333 27.7536 34.8016 28.5871 35.6351C29.4206 36.4686 29.8889 37.599 29.8889 38.7778V41M32.1111 16.5556H36.5556C37.7343 16.5556 38.8648 17.0238 39.6983 17.8573C40.5317 18.6908 41 19.8213 41 21V23.2222M1 23.2222V21C1 19.8213 1.46825 18.6908 2.30175 17.8573C3.13524 17.0238 4.2657 16.5556 5.44444 16.5556H9.88889M16.5556 23.2222C16.5556 24.401 17.0238 25.5314 17.8573 26.3649C18.6908 27.1984 19.8213 27.6667 21 27.6667C22.1787 27.6667 23.3092 27.1984 24.1427 26.3649C24.9762 25.5314 25.4444 24.401 25.4444 23.2222C25.4444 22.0435 24.9762 20.913 24.1427 20.0795C23.3092 19.246 22.1787 18.7778 21 18.7778C19.8213 18.7778 18.6908 19.246 17.8573 20.0795C17.0238 20.913 16.5556 22.0435 16.5556 23.2222ZM27.6667 5.44444C27.6667 6.62318 28.1349 7.75365 28.9684 8.58714C29.8019 9.42064 30.9324 9.88889 32.1111 9.88889C33.2899 9.88889 34.4203 9.42064 35.2538 8.58714C36.0873 7.75365 36.5556 6.62318 36.5556 5.44444C36.5556 4.2657 36.0873 3.13524 35.2538 2.30175C34.4203 1.46825 33.2899 1 32.1111 1C30.9324 1 29.8019 1.46825 28.9684 2.30175C28.1349 3.13524 27.6667 4.2657 27.6667 5.44444ZM5.44444 5.44444C5.44444 6.62318 5.9127 7.75365 6.74619 8.58714C7.57969 9.42064 8.71015 9.88889 9.88889 9.88889C11.0676 9.88889 12.1981 9.42064 13.0316 8.58714C13.8651 7.75365 14.3333 6.62318 14.3333 5.44444C14.3333 4.2657 13.8651 3.13524 13.0316 2.30175C12.1981 1.46825 11.0676 1 9.88889 1C8.71015 1 7.57969 1.46825 6.74619 2.30175C5.9127 3.13524 5.44444 4.2657 5.44444 5.44444Z" stroke="#515151" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                                </svg>
                                            </div>
                                            <h2>{groupFields.group_name}</h2>
                                        </div>
                                        <div className='rightActionWrap'>
                                            <div className='selectRowWrap'>
                                                <div className='icoWrap'>
                                                    <svg width="18" height="18" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <path d="M19 7L1 7M19 13L0.999999 13M18 1C18.2652 1 18.5196 1.10536 18.7071 1.29289C18.8946 1.48043 19 1.73478 19 2L19 18C19 18.2652 18.8946 18.5196 18.7071 18.7071C18.5196 18.8946 18.2652 19 18 19L2 19C1.73478 19 1.48043 18.8946 1.29289 18.7071C1.10536 18.5196 0.999999 18.2652 0.999999 18L1 2C1 1.73478 1.10536 1.48043 1.29289 1.29289C1.48043 1.10536 1.73478 0.999999 2 0.999999L18 1Z" stroke="#515151" stroke-linecap="round" stroke-linejoin="round" />
                                                    </svg>
                                                </div>

                                                <Dropdown>
                                                    <Dropdown.Toggle id="dropdown-basic">
                                                    Insert rows
                                                    </Dropdown.Toggle>
                                                    <Dropdown.Menu>
                                                        <div className='insertRowWrap'>
                                                            <div className='insertRow'>
                                                                <span>Insert </span>
                                                                <input 
                                                                type="number"
                                                                value={rowsToInsert} 
                                                                onChange={(e) => setRowsToInsert(parseInt(e.target.value, 10))}
                                                                max="20"
                                                                />
                                                                <span>rows below</span>
                                                            </div>
                                                            <p>Maximum 20 rows</p>
                                                        </div>
                                                        <div className='inserCta'>
                                                            <button className='btn'onClick={handleInsertRows}>Insert</button>
                                                        </div>
                                                    </Dropdown.Menu>
                                                </Dropdown>
                                            </div>
                                            <button className='btnFilter btn'>
                                                <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M17 3L13 3M9 3L1 3M17 9L7 9M3 9L1 9M17 15L16 15M12 15L0.999999 15M13 1L13 5L9 5L9 1L13 1ZM7 7L7 11L3 11L3 7L7 7ZM16 13L16 17L12 17L12 13L16 13Z" stroke="#939393" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                                </svg>
                                            </button>
                                            <button className='btnUpload btn'>
                                                <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M19 4C19 3.20435 18.6839 2.44129 18.1213 1.87868C17.5587 1.31607 16.7956 1 16 1H4C3.20435 1 2.44129 1.31607 1.87868 1.87868C1.31607 2.44129 1 3.20435 1 4M10 13V5M10 5L7 8M10 5L13 8M4 19H16C16.7956 19 17.5587 18.6839 18.1213 18.1213C18.6839 17.5587 19 16.7956 19 16C19 15.2044 18.6839 14.4413 18.1213 13.8787C17.5587 13.3161 16.7956 13 16 13H4C3.20435 13 2.44129 13.3161 1.87868 13.8787C1.31607 14.4413 1 15.2044 1 16C1 16.7956 1.31607 17.5587 1.87868 18.1213C2.44129 18.6839 3.20435 19 4 19Z" stroke="#939393" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                                </svg>
                                            </button>
                                            <div className='searchWrap'>
                                                <input />
                                                <button className='btnSearch'>
                                                    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <path d="M15 15L10.3333 10.3333M1 6.44444C1 7.15942 1.14082 7.86739 1.41443 8.52794C1.68804 9.18849 2.08908 9.78868 2.59464 10.2942C3.1002 10.7998 3.7004 11.2008 4.36095 11.4745C5.0215 11.7481 5.72947 11.8889 6.44444 11.8889C7.15942 11.8889 7.86739 11.7481 8.52794 11.4745C9.18849 11.2008 9.78868 10.7998 10.2942 10.2942C10.7998 9.78868 11.2008 9.18849 11.4745 8.52794C11.7481 7.86739 11.8889 7.15942 11.8889 6.44444C11.8889 5.72947 11.7481 5.0215 11.4745 4.36095C11.2008 3.7004 10.7998 3.1002 10.2942 2.59464C9.78868 2.08908 9.18849 1.68804 8.52794 1.41443C7.86739 1.14082 7.15942 1 6.44444 1C5.72947 1 5.0215 1.14082 4.36095 1.41443C3.7004 1.68804 3.1002 2.08908 2.59464 2.59464C2.08908 3.1002 1.68804 3.7004 1.41443 4.36095C1.14082 5.0215 1 5.72947 1 6.44444Z" stroke="#939393" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                                    </svg>
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='tblWrap'>
                                        <CustomScroll heightRelativeToParent="100%">
                                            {groupFields ? (
                                                <ErrorBoundary>
                                                    <DynamicTable groupFields={groupFields} newTableData = {tableData} tableData={isExcelData ? data:tableData} isExcelData={isExcelData} indexes={isExcelData && indexes} />
                                                </ErrorBoundary>
                                            ) : null}
                                        </CustomScroll>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </main>
        </div>
    );
};

export default AddDataToContactList;

