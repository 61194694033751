import React, { act, createContext, useCallback, useContext, useEffect, useRef, useState } from "react";
import WorkFlowLeftBar, { NodesContext } from "../Common/WorkFlowLeftBar";
import Header from "../Common/Header";
import Sidebar from "../Common/Sidebar";
import WorkFlowRightBar from "../Common/WorkFlowRightBar";
import { CustomScroll } from "react-custom-scroll";
import { Link, json, useNavigate, useParams } from "react-router-dom";
import Start from '../../assets/img/Alpha Flag.png'
import Trigger from '../../assets/img/Network.png'
import Condition from '../../assets/img/Insert White Space.png'
import Goto from '../../assets/img/Skip to Start.png'
import Wait from '../../assets/img/Delivery Time.png'
import Exit from '../../assets/img/Exit.png'
import Message from '../../assets/img/Message.png'
import Activity from '../../assets/img/Workflow.png'
import itemListIco from '../../assets/img/itemList.png'
import Button1 from '../../assets/img/play-button.png'
import Menu from '../../assets/img/menu.png'
import ReactFlow, { Background, Controls, MiniMap, Panel, addEdge, applyEdgeChanges, applyNodeChanges, updateEdge } from "reactflow";
import 'reactflow/dist/style.css';
import StartNode from "./StartNode";
// import { type } from "@testing-library/user-event/dist/type";
import ConditionsNode from "./ConditionsNode";
import TriggerNode from "./TriggerNode";
import GotoNode from "./GotoNode";
import WaitNode from "./WaitNode";
import ListItems from "./ListItems";
import MessageNode from "./MessageNode";
import { v4 as uuidv4 } from 'uuid';
import axios from 'axios'
import { Button } from "react-bootstrap";
import ButtonNode from "./ButtonNode";
import ListNode from "./ListNode";
import Select from 'react-select';
import CategoryNode from "./CategoryNode";
import { add_flow_node, delete_node, get_button_node_data, get_cat_in_menu, get_flow_by_id, get_list_node_data, get_message_node_data, get_node_types, get_prod_in_cat, get_trigger_node_data, update_button_node_data, update_list_node_data, update_message_node_data, update_trigger_node_data } from "../../services/flow_services";
import { get_all_menus } from "../../services/menus-services";
import { get_all_products, get_product_by_id } from "../../services/products_services";
import { ADD_BUTTON_NODE, ADD_LIST_NODE, ADD_MESSAGE_NODE, ADD_TRIGGER_NODE, GET_PRODUCT_BY_ID } from "../../utility/ConstantAPI";
import Spinner from "react-bootstrap/Spinner";
import { spinnerContainerStyle } from "../../utility/CustomFormating";
const initialEdges = []
const initialNodes = []
const listType = [
    { value: 'Menu', label: 'Menu' },
    { value: 'Category', label: "Category" },
    { value: 'Products', label: "Products" }
]

const button_action = [
    { value: 'add_to_cart', label: 'add_to_cart' },
    { value: 'see_more_products', label: 'see_more_products' },
    { value: 'checkout', label: 'checkout' },
    { value: 'print_invoice', label: 'print_invoice' },
    { value: 'past_orders', label: 'past_orders' },
    { value: 'last_order', label: 'last_order' },
    { value: 'last_order_invoice', label: 'last_order_invoice' },
    { value: 'speak_to_human', label: 'speak_to_human' }
]
const nodeTypes = {
    startNode: StartNode,
    conditionNode: ConditionsNode,
    triggerNode: TriggerNode,
    gotoNode: GotoNode,
    // waitNode: WaitNode,
    listItemsNode: ListItems,
    messageNode: MessageNode,
    buttonNode: ButtonNode,
    listNode: ListNode,
    categoryNode: CategoryNode
}
export const NodeInput = createContext(null)
const WorkFlow = () => {
    const [isOpen, setIsOpen] = useState(false);
    const [types, setTypes] = useState([]);
    const [activeNode, setActiveNode] = useState({})
    const [inputValues, setInputValues] = useState([])
    const [menuType, setMenuType] = useState('')
    const [data, setData] = useState([])
    const [selectedOption, setSelectedOption] = useState(null)
    const [selectField, setSelectField] = useState([{ courseSelect: '' }]);
    const [bodyText, setBodyText] = useState('')
    const [footerText, setFooterText] = useState('')
    const [buttonLabel, setButtonLabel] = useState([])
    const [image, setImage] = useState(false)
    const [headerText, setHeaderText] = useState('')
    const [listBodyText, setListBodyText] = useState('')
    const [listFooterText, setListFooterText] = useState('')
    const [listButtonText, setListButtonText] = useState('')
    const [selectedListOption, setSelectedListOption] = useState(null)
    const [productList, setProductList] = useState([])
    const [selectedProduct, setSelectedProduct] = useState()
    const [selectMsgOption, setSelectMsgOption] = useState(null)
    const [productData, setProductData] = useState({})
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState('');
    const [flowName, setFlowName] = useState('')
    const [buttonAction, setButtonAction] = useState([])
    const [isLoad, setIsLoad] = useState(true)
    const [isFlowLoad, setIsFlowLoad] = useState(false)
    const [isSaveLoad,setIsSaveLoad] = useState(false)
    const { id } = useParams();
    const navigate = useNavigate()
    const addFormFields = () => {
        let newField = { courseSelect: '' }
        setSelectField([...selectField, newField])
    }
    const removeFields = (index) => {
        const newFields = selectField.filter((_, idx) => idx !== index);
        setSelectField(newFields);
    }
    const GetNodeTypes = async () => {
        const response = await get_node_types()
        if (response) {
            setIsLoad(false)
            setIsFlowLoad(false)
        }
        setTypes(response)
    }
    useEffect(() => {
        GetNodeTypes();
        getFlowEdge()
    }, [])
    const toggleSidebar = () => {
        setIsOpen(!isOpen);
    };
    const savedFlow = JSON.parse(localStorage.getItem('flowKey'))
    const [nodes, setNodes] = useState(initialNodes);
    const [edges, setEdges] = useState(initialEdges);
    const [newNodes, setNewNodes] = useState([])
    const [rfInstance, setRfInstance] = useState(null);
    const onNodesChange = useCallback(
        (changes) => setNodes((nds) => applyNodeChanges(changes, nds)),
        [],
    );
    const onEdgesChange = useCallback(
        (changes) => setEdges((eds) => applyEdgeChanges(changes, eds)),
        [],
    );
    const onConnect = useCallback(
        (params) => setEdges((eds) => addEdge(params, eds)),
        [setEdges],
    );

    const edgeUpdateSuccessful = useRef(true);
    const onEdgeUpdateStart = useCallback(() => {
        edgeUpdateSuccessful.current = false;
    }, []);
    const onEdgeUpdate = useCallback((oldEdge, newConnection) => {
        edgeUpdateSuccessful.current = true;
        setEdges((els) => updateEdge(oldEdge, newConnection, els));
    }, []);
    const onEdgeUpdateEnd = useCallback((_, edge) => {
        if (!edgeUpdateSuccessful.current) {
            setEdges((eds) => eds.filter((e) => e.id !== edge.id));
        }

        edgeUpdateSuccessful.current = true;
    }, []);

    const onSave = useCallback(() => {
        if (rfInstance) {
            const flow = rfInstance.toObject();
            localStorage.setItem('flowKey', JSON.stringify(flow));
        }
    }, [rfInstance]);

    const generateNodeId = () => {
        return `node-${uuidv4()}`;
    };
    const handleAddNode = (typeName) => {
        setNodes([...nodes, { id: generateNodeId(), position: { x: 0, y: 180 }, data: { label: 'Start', removeNode: removeNode }, type: `${typeName === 'Trigger' ? 'triggerNode' : typeName === 'Start' ? 'startNode' : typeName === 'Category' ? 'categoryNode' : typeName === 'Interactive Button' ? 'buttonNode' : typeName === 'Interacitve List' ? 'listNode' : typeName === 'Message' && 'messageNode'}` }])
    }
    const [buttonNodeData, setButtonNodeData] = useState({})
    const [singleBtnLabel, setSingleBtnLabel] = useState()
    const [listNodeData, setListNodeData] = useState({})
    const getButtonNodeData = async (node_id) => {
        const response = await get_button_node_data(node_id)
        if (response) {
            setImage(response.is_image)
            setBodyText(response.bodyText)
            setFooterText(response.footerText)
            setSingleBtnLabel(response.buttonLabel)
            setButtonNodeData(response)
        }
        else {
            console.log(response)
        }
    }
    const updateNodeButtonData = async (node_id) => {
        let data = {
            'is_image': image,
            'bodyText': bodyText,
            'footerText': footerText,
            'buttonLabel': singleBtnLabel
        }
        const response = await update_button_node_data(data, onSuccess, onError, node_id)
        console.log(response)
    }
    const onSuccess = (response) => {
        console.log(response)
        if (response && response.status === 200) {
            navigate(`/flow`);
            window.location.reload()
        } else {
            console.log('Category update failed');
        }
        setIsLoading(false);
    };
    const onAddFlowSuccess = (response) => {
        if (response) {
            setIsSaveLoad(false)
            localStorage.removeItem('flowkey')
            navigate('/WorkflowList')
        }
        setIsLoading(false);
    }
    const onError = (error) => {
        setError(error.message);
        setIsLoading(false);
    };
    const getListNodeNodeData = async (node_id) => {
        const response = await get_list_node_data(node_id)
        if (response) {
            setHeaderText(response.headerText)
            setListBodyText(response.bodyText)
            setListFooterText(response.footerText)
            setListButtonText(response.buttonText)
            setListNodeData(response)
            if (response.isProduct) {
                setSelectedOption({ value: 'Products', label: "Products" })
                setSelectedMenu({ value: response.menuDetails.id, label: response.menuDetails.name })
                setSelectedCategory({ value: response.categoryDetails.id, label: response.categoryDetails.name })
                let temp = []
                response.listItems.map((val) => {
                    temp.push({ value: val.id, label: val.value })
                })
                setSelectedListOption(temp)
            }
            if (response.isCategory) {
                setSelectedOption({ value: 'Category', label: "Category" })
                setSelectedMenu({ value: response.menuDetails.id, label: response.menuDetails.name })
                let temp = []
                response.listItems.map((val) => {
                    temp.push({ value: val.id, label: val.value })
                })
                setSelectedListOption(temp)
            }
        }
    }
    const updateListNodeData = async (node_id, list) => {
        let data = {
            "headerText": headerText,
            "bodyText": listBodyText,
            "footerText": listFooterText,
            "buttonText": listButtonText,
        }
        await update_list_node_data(data, onSuccess, onError, node_id)
    }
    const [simpleMsg, setSimpleMsg] = useState()
    const [messageNodeData, setMessageNodeData] = useState()
    const getMessageNodeData = async (node_id) => {
        const response = await get_message_node_data(node_id)
        setMessageNodeData(response)
        if (response.isSimpleMsg) {
            setSimpleMsg(response.nodeText)
            setInputValues([...inputValues, { 'node_id': response.id, value: response.nodeText }])
            setSelectMsgOption({ label: "Simple Message", value: 'simple_msg' })
        }
        else {
            setSelectMsgOption({ label: "Interactive Message", value: 'interactive_msg' })
            setSelectedProduct({ value: response.product_id, label: response.title })
            setProductData({ name: response.title, description: response.description, unit_price: response.price })
        }
    }
    const updateMessageNodeData = async (node_id) => {
        let data = {
            nodeText: simpleMsg,
            title: productData.name,
            description: productData.description,
            price: productData.unit_price
        }
        await update_message_node_data(data, onSuccess, onError, node_id)
    }
    const [triggerNodeData, setTriggerNodeData] = useState({})
    const getTriggerNodeData = async (node_id) => {
        const response = await get_trigger_node_data(node_id)
        if (response !== 'Node Not Found') {
            setTriggerNodeData(response)
            setSimpleMsg(response.nodeText)
        }
    }
    const updateTriggerNodeData = async (node_id) => {
        let data = {
            nodeText: simpleMsg
        }
        await update_trigger_node_data(data, onSuccess, onError, node_id)
    }
    const onElementClick = (event, node) => {
        setActiveNode(node)
        if (node.type === 'listNode' || node.type === 'categoryNode') {
            getMenuItems()
        }
        if (node.type === 'buttonNode') {
            getButtonNodeData(node.id)
        }
        if (node.type === 'listNode') {
            getListNodeNodeData(node.id)
        }
        if (node.type === 'messageNode') {
            getMessageNodeData(node.id)
        }
        if (node.type === 'triggerNode') {
            getTriggerNodeData(node.id)
        }
        // Other logic based on the clicked element
    };
    const removeNode = async (id) => {
        let data = { "id": id }
        await delete_node(data, onSuccess, onError)
    }
    const getMenuItems = async (e) => {
        try {
            const response = await get_all_menus();
            if (response) {
                const temp = []
                response.map((val) => (
                    temp.push({ value: val.id, label: val.name })
                ))
                setData(temp)
            }
            else {
                setError(response.message)
            }

        } catch (error) {
            console.error('Error fetching data:', error);
            setError(error.message)

        }

    }
    const handleChange = (value) => {
        setSelectedListOption(value)
    }
    const handleMsgChange = async (value) => {
        setSelectMsgOption(value)
        if (value.value === 'interactive_msg') {
            try {
                const response = await get_all_products();
                console.log(response)
                if (response) {
                    const temp = []
                    response.map((val) => (
                        temp.push({ value: val.id, label: val.name })
                    ))
                    setProductList(temp)
                }
                else {
                    setError(response.message)
                }

            } catch (error) {
                console.error('Error fetching data:', error);
                setError(error.message)

            }
            setIsLoading(false);
        }
    }
    const handleProductChange = async (value) => {
        setSelectedProduct(value)
        console.log(value)
        const response = await get_product_by_id(value.value)
        setProductData(response)
    }
    const [categoryOption, setCategoryOption] = useState([])
    const [productOption, setProductOption] = useState([])
    const [selectedMenu, setSelectedMenu] = useState()
    const [selectedCategory, setSelectedCategory] = useState()
    const handleMenuChange = async (value) => {
        setSelectedMenu(value)
        const response = await get_cat_in_menu(value.value)
        if (response) {
            const temp = []
            response.data.map((val, index) => (
                temp.push({ value: val.id, label: val.name })
            ))
            setCategoryOption(temp)
        }
    }
    const handleCategoryChange = async (value) => {
        setSelectedCategory(value)
        const response = await get_prod_in_cat(value.value)
        if (response) {
            const temp = []
            response.data.map((val, index) => (
                temp.push({ value: val.id, label: val.name })
            ))
            setProductOption(temp)
        }
        // setSelectedOption(value)
    }
    const handleListChange = (value) => {
        setSelectedOption(value)
    }
    const token = localStorage.getItem('token')
    const user = JSON.parse(localStorage.getItem('user'))
    const addFlowNode = async () => {
        if(!flowName){
            setError('Please enter the Flow Name')
        }
        else{
            setIsSaveLoad(true)
            const flow = rfInstance.toObject();
            localStorage.setItem('flowKey', JSON.stringify(flow));
            let values = { flow: JSON.stringify(flow), name: flowName, flowId: id,userId:user.id }
            await add_flow_node(values,token, onAddFlowSuccess, onError)
        }
        
    }
    const getFlowEdge = async () => {
        setIsFlowLoad(true)
        if (id) {
            const response = await get_flow_by_id(id)
            if (response) {
                setEdges(response['edges'])
                setIsFlowLoad(false)
                if (response['nodes'].length === 0) {
                    setNodes(initialNodes)
                }
                else {
                    setNodes(response['nodes'])
                }
                setFlowName(response['flow'].name)
            }
        }

    }
    const addInterBtn = async (node_id, btnlabel) => {
        let data = {
            'flowNodeId': node_id,
            'is_image': image,
            'bodyText': bodyText,
            'footerText': footerText,
            'buttonLabel': btnlabel
        }
        let url = ADD_BUTTON_NODE

        await axios.post(url, data)
            .then(res => {
                console.log(res)
            })
    }
    const addInterList = async (node_id, list) => {
        let data = {
            'node_id': node_id,
            'headerText': headerText,
            'bodyText': listBodyText,
            'footerText': listFooterText,
            'buttonText': listButtonText,
            'list': list
        }
        let url = ADD_LIST_NODE
        await axios.post(url, data)
            .then(res => {
                console.log(res)
            })
    }

    const addTrigger = async (node_id, value) => {
        let data = {
            'node_id': node_id,
            'value': value
        }
        let url = ADD_TRIGGER_NODE
        await axios.post(url, data)
            .then(res => {
                console.log(res)
            })
    }
    const addMessage = async (data) => {
        let url = ADD_MESSAGE_NODE
        await axios.post(url, data)
            .then(res => {
                console.log(res)
            })
    }

    const clearAll = () => {
        setActiveNode({})
        setInputValues([])
        setData([])
        setSelectedOption(null)
        setBodyText('')
        setFooterText('')
        setButtonLabel([])
        setHeaderText('')
        setListBodyText('')
        setListFooterText('')
        setListButtonText('')
        setSelectedListOption(null)
        setProductList([])
        setSelectedProduct()
        setSelectMsgOption()
        setProductData()
        setButtonNodeData({})
        setSingleBtnLabel()
        setListNodeData({})
        setSimpleMsg()
        setMessageNodeData()
    }
    console.log(triggerNodeData)
    console.log("response", types)
    return (
        <>{isLoad ?
            <div style={spinnerContainerStyle}> <Spinner
                animation="border"
                size="lg"
                style={{ width: '5rem', height: '5rem', color: '#1944F1' }}

            />
            </div> :
            <div className={`app-container ${!isOpen ? "appWorkFlow" : "sidebar-open"}`}>
                <Header toggleSidebar={toggleSidebar} />
                <Sidebar isOpen={isOpen} />
                <main className="content">
                    <div className='productListWrap workFlowChartWrap'>
                        <div className='container-fluid'>
                            <div className='row'>
                                {/* Start Left side Node types list */}

                                {/* End Left side Node types list */}
                                {/* Start Middle Flow */}

                                <input type="text" className="flowNameField" name="name" placeholder="Enter Flow Name" value={flowName} disabled={id ? true : false} onChange={(e) => setFlowName(e.target.value)} />
                                {error && <p style={{color:'red'}}>{error}</p> }
                                <div className={activeNode.id ? "col-md-9 d-flex" : "col-md-12 d-flex"}>
                                    <div className="leftCardWrap">
                                        <ul className="nodesList">
                                            {types.map((type, index) => {
                                                return (
                                                    <li>
                                                        <Link onClick={() => handleAddNode(type.typeName)}>
                                                            <div className="iconWrap">
                                                                <img src={
                                                                    type.typeName === 'Start' ? Start :
                                                                        type.typeName === 'Trigger' ? Trigger :
                                                                            type.typeName === 'Button' ? Button1 :
                                                                                type.typeName === 'Menu' ? Menu :
                                                                                    type.typeName === 'Interactive Button' ? Activity :
                                                                                        type.typeName === 'Interacitve List' ? itemListIco :
                                                                                            type.typeName === 'Sms' ? Trigger :
                                                                                                type.typeName === 'Message' && Message

                                                                } className="img-fluid" alt="" />
                                                            </div>
                                                            <div className="dataWrap">{type.typeName}</div>
                                                        </Link>
                                                    </li>
                                                )
                                            })}
                                            {/* <li>
                                                    <Link onClick={() => {
                                                        setNodes([...nodes, { id: generateNodeId(), position: { x: 0, y: 180 }, data: { label: 'Trigger' }, type: 'triggerNode' }])
                                                    }}>
                                                        <div className="iconWrap">
                                                            <img src={Trigger} className="img-fluid" alt="" />
                                                        </div>
                                                        <div className="dataWrap">Interactive Message</div>
                                                    </Link>
                                                </li> */}
                                        </ul>
                                    </div>
                                    {isFlowLoad ?
                                        <div> <Spinner
                                            animation="border"
                                            size="lg"
                                            style={{  color: '#1944F1' }}

                                        />
                                        </div> :
                                        <div className="mainFlowWrap">
                                            <ReactFlow
                                                nodes={newNodes.length > 0 ? newNodes : nodes}
                                                edges={edges !== null && edges}
                                                onNodesChange={onNodesChange}
                                                onEdgesChange={onEdgesChange}
                                                onConnect={onConnect}
                                                nodeTypes={nodeTypes}
                                                snapToGrid
                                                onNodeClick={onElementClick}
                                                onEdgeUpdate={onEdgeUpdate}
                                                onEdgeUpdateStart={onEdgeUpdateStart}
                                                onEdgeUpdateEnd={onEdgeUpdateEnd}
                                                onInit={setRfInstance}
                                            >
                                                <Controls />
                                                <Panel position="top-right">
                                                    <button onClick={addFlowNode} className="pannelBtn">{isSaveLoad && <Spinner size="sm" animation="grow" as="span" />} {isSaveLoad ? "Loading" : "Save"}</button>
                                                    {/* <button onClick={onRestore}>restore</button>
                                                <button onClick={onAdd}>add node</button> */}
                                                </Panel>
                                                {/* <MiniMap /> */}
                                                <Background variant="dots" gap={12} size={1} />
                                            </ReactFlow>
                                        </div>
                                    }
                                </div>
                                {/* End Middle Flow */}

                                {/* Start Right Side Node Type Form */}
                                <NodeInput.Provider value={inputValues}>
                                    {activeNode.data &&
                                        <div className='col-md-3'>
                                            <div className="rightCardWrap">
                                                <ul className="toolsList">
                                                    <li>

                                                    </li>
                                                    <li>
                                                        <>
                                                            {activeNode.type === 'messageNode' ? <>
                                                                <div className="inputCardWrap">
                                                                    <h3>Message</h3>
                                                                </div>
                                                                <div className="inputCardWrap">
                                                                    <div className="triggerBtn">
                                                                        <ul>
                                                                            <h4>Which message type you want ?</h4>
                                                                            <li>
                                                                                <Select
                                                                                    onChange={handleMsgChange}
                                                                                    options={[{ value: 'simple_msg', label: "Simple Message" }, { value: 'interactive_msg', label: 'Interactive Message' }]}
                                                                                    value={selectMsgOption}
                                                                                />
                                                                            </li>
                                                                            {selectMsgOption && selectMsgOption.value === 'simple_msg' &&
                                                                                <>
                                                                                    <li className="flexColumn">
                                                                                        <div className="leftWrap">
                                                                                            <label htmlFor="">Text</label>
                                                                                        </div>
                                                                                        <div className="rightWrap">
                                                                                            <textarea name="" id="" placeholder="text" value={simpleMsg} onChange={(e) => {
                                                                                                setSimpleMsg(e.target.value)
                                                                                                inputValues.map((obj, key) => {
                                                                                                    if (obj.node_id === activeNode.id) {
                                                                                                        inputValues.splice(key, 1)
                                                                                                    }
                                                                                                })
                                                                                                setInputValues([...inputValues, { 'node_id': activeNode.id, value: e.target.value }])
                                                                                            }}></textarea>
                                                                                        </div>
                                                                                    </li>
                                                                                </>}
                                                                            {selectMsgOption && selectMsgOption.value === 'interactive_msg' &&
                                                                                <>
                                                                                    <li>
                                                                                        <h4>Please Select Product </h4>
                                                                                    </li>
                                                                                    <li>
                                                                                        <Select
                                                                                            onChange={handleProductChange}
                                                                                            options={productList}
                                                                                            value={selectedProduct}
                                                                                        />
                                                                                    </li>
                                                                                    {selectedProduct &&
                                                                                        <>
                                                                                            <li>
                                                                                                <div className="leftWrap">
                                                                                                    <label>Product Name</label>
                                                                                                </div>
                                                                                                <div className="rightWrap">
                                                                                                    <h5>{productData.name}</h5>
                                                                                                </div>
                                                                                            </li>
                                                                                            <li>
                                                                                                <div className="leftWrap">
                                                                                                    <label htmlFor="">Description</label>
                                                                                                </div>
                                                                                                <div className="rightWrap">
                                                                                                    <h5>{productData.description}</h5>
                                                                                                </div>
                                                                                            </li>
                                                                                            <li>
                                                                                                <div className="leftWrap">
                                                                                                    <label htmlFor="">Price</label>
                                                                                                </div>
                                                                                                <div className="rightWrap">
                                                                                                    <h5>{productData.unit_price}</h5>
                                                                                                </div>
                                                                                            </li>
                                                                                        </>}
                                                                                </>}
                                                                        </ul>
                                                                    </div>
                                                                    {/* <div className="textField">
                                                                        <textarea name="" id="" placeholder="text" onChange={(e) => {
                                                                            inputValues.map((obj, key) => {
                                                                                if (obj.node_id === activeNode.id) {
                                                                                    inputValues.splice(key, 1)
                                                                                }
                                                                            })
                                                                            setInputValues([...inputValues, { 'node_id': activeNode.id, value: e.target.value }])
                                                                        }}></textarea>
                                                                    </div> */}
                                                                </div>
                                                            </> :
                                                                activeNode.type === 'categoryNode' ? <>
                                                                    <div className="inputCardWrap">
                                                                        <div className="inputCardHeader">
                                                                            <h3>Select Menu To Choose Catgory</h3>
                                                                        </div>
                                                                    </div>
                                                                    <div className="inputCardWrap">
                                                                        <Select
                                                                            onChange={handleMenuChange}
                                                                            options={data}
                                                                        />
                                                                    </div>
                                                                    {categoryOption.length > 0 &&
                                                                        <div className="inputCardWrap">
                                                                            <Select
                                                                                onChange={handleCategoryChange}
                                                                                options={categoryOption}
                                                                                isMulti
                                                                            />
                                                                        </div>
                                                                    }
                                                                </> :
                                                                    activeNode.type === 'listNode' ? <>
                                                                        <div className="inputCardWrap">
                                                                            <h3 className="interListTtl">Interactive List</h3>
                                                                        </div>
                                                                        <div className="inputCardWrap">
                                                                            <div className="triggerBtn overFlowForm">
                                                                                <CustomScroll heightRelativeToParent="100%">
                                                                                    <ul>
                                                                                        <li>
                                                                                            <div className="rightWrap textareaHight">
                                                                                                <textarea name="" id="" value={headerText} onChange={(e) => setHeaderText(e.target.value)} placeholder="Header Text"></textarea>
                                                                                            </div>
                                                                                        </li>
                                                                                        <li>
                                                                                            <div className="rightWrap textareaHight">
                                                                                                <textarea name="" id="" value={listBodyText} onChange={(e) => setListBodyText(e.target.value)} placeholder="Body Text"></textarea>
                                                                                            </div>
                                                                                        </li>
                                                                                        <li>
                                                                                            <div className="rightWrap textareaHight">
                                                                                                <textarea name="" id="" value={listFooterText} onChange={(e) => setListFooterText(e.target.value)} placeholder=" Footer Text"></textarea>
                                                                                            </div>
                                                                                        </li>
                                                                                        <li>
                                                                                            <div className="rightWrap">
                                                                                                <input placeholder="Button Text" type="text" value={listButtonText} onChange={(e) => setListButtonText(e.target.value)} />
                                                                                            </div>
                                                                                        </li>
                                                                                    </ul>
                                                                                    <h3 className="midTtl">Which List You Want Show ?</h3>
                                                                                    <ul>
                                                                                        <li>
                                                                                            <Select
                                                                                                onChange={handleListChange}
                                                                                                options={listType}
                                                                                                value={selectedOption}
                                                                                            />
                                                                                        </li>
                                                                                        {selectedOption && selectedOption.value === 'Menu' && <>
                                                                                            <h4>Select Menu Items To Show</h4>
                                                                                            <li>
                                                                                                <Select
                                                                                                    onChange={handleChange}
                                                                                                    options={data}
                                                                                                    isMulti
                                                                                                />
                                                                                            </li>
                                                                                        </>
                                                                                        }
                                                                                        {selectedOption && selectedOption.value === 'Category' && <>
                                                                                            <h4>Select Menu</h4>
                                                                                            <li>
                                                                                                <Select
                                                                                                    onChange={handleMenuChange}
                                                                                                    options={data}
                                                                                                    value={selectedMenu}
                                                                                                />
                                                                                            </li>
                                                                                            <h4>Select Categories</h4>
                                                                                            <li>
                                                                                                <Select
                                                                                                    onChange={handleChange}
                                                                                                    options={categoryOption}
                                                                                                    value={selectedListOption}
                                                                                                    isMulti
                                                                                                />
                                                                                            </li>
                                                                                        </>}
                                                                                        {selectedOption && selectedOption.value === 'Products' && <>
                                                                                            <h4>Select Menu</h4>
                                                                                            <li>
                                                                                                <Select
                                                                                                    onChange={handleMenuChange}
                                                                                                    options={data}
                                                                                                    value={selectedMenu}
                                                                                                />
                                                                                            </li>
                                                                                            <h4>Select Category</h4>
                                                                                            <li>
                                                                                                <Select
                                                                                                    onChange={handleCategoryChange}
                                                                                                    options={categoryOption}
                                                                                                    value={selectedCategory}
                                                                                                />
                                                                                            </li>
                                                                                            <h4>Select Products</h4>
                                                                                            <li>
                                                                                                <Select
                                                                                                    onChange={handleChange}
                                                                                                    options={productOption}
                                                                                                    value={selectedListOption}
                                                                                                    isMulti
                                                                                                />
                                                                                            </li>
                                                                                        </>}
                                                                                    </ul>
                                                                                </CustomScroll>
                                                                            </div>
                                                                        </div>

                                                                    </>
                                                                        :
                                                                        activeNode.type === 'buttonNode' ? <>
                                                                            <div className="inputCardWrap">
                                                                                <h3>Interactive Button</h3>
                                                                                <p>You can add only three buttons</p>
                                                                            </div>


                                                                            <>
                                                                                <div className="topTitleWrap">
                                                                                </div>
                                                                                <div className="inputCardWrap">
                                                                                    <div className="triggerBtn">
                                                                                        <ul>
                                                                                            <li className="imgWrap">
                                                                                                <div className="leftWrap">
                                                                                                    <label htmlFor="">Image</label>
                                                                                                </div>
                                                                                                <div className="rightWrap">
                                                                                                    {/* <input type="checkbox" name="image" id="" value={image} onChange={(e) => setImage(e.target.checked)} /> */}
                                                                                                    <input type="checkbox" className="custom-checkbox" name="image" id="imageCheckbox" value={image} onChange={(e) => setImage(e.target.checked)} />
                                                                                                    <label htmlFor="imageCheckbox"></label>
                                                                                                </div>
                                                                                            </li>
                                                                                            <li>
                                                                                                <div className="rightWrap textareaHight">
                                                                                                    <textarea name="" id="" value={bodyText} onChange={(e) => setBodyText(e.target.value)} placeholder="Body Text"></textarea>
                                                                                                </div>
                                                                                            </li>
                                                                                            <li>
                                                                                                <div className="rightWrap textareaHight">
                                                                                                    <textarea name="" id="" value={footerText} onChange={(e) => setFooterText(e.target.value)} placeholder="Footer Text"></textarea>
                                                                                                </div>
                                                                                            </li>
                                                                                            {buttonNodeData.buttonLabel ?
                                                                                                <li>
                                                                                                    <div className="rightWrap">
                                                                                                        <input type="text" placeholder="Button label" value={singleBtnLabel} onChange={(e) => setSingleBtnLabel(e.target.value)} />
                                                                                                    </div>
                                                                                                </li>
                                                                                                :
                                                                                                <>
                                                                                                    {selectField.length <= 3 &&
                                                                                                        <div className="addIconWrap">
                                                                                                            <Link className="addBtn" onClick={addFormFields} >
                                                                                                                Add button label<svg version="1.1" width="20" height="20" x="0" y="0" viewBox="0 0 512 512" class=""><g><path fill="#4bae4f" fill-rule="evenodd" d="M256 0C114.8 0 0 114.8 0 256s114.8 256 256 256 256-114.8 256-256S397.2 0 256 0z" clip-rule="evenodd" opacity="1" data-original="#4bae4f" class=""></path><path fill="#ffffff" d="M116 279.6v-47.3c0-4.8 3.9-8.8 8.8-8.8h98.9v-98.8c0-4.8 3.9-8.8 8.8-8.8h47.3c4.8 0 8.7 3.9 8.7 8.8v98.9h98.8c4.8 0 8.8 3.9 8.8 8.8v47.3c0 4.8-3.9 8.7-8.8 8.7h-98.9v98.8c0 4.8-3.9 8.8-8.7 8.8h-47.3c-4.8 0-8.8-3.9-8.8-8.8v-98.9h-98.8c-4.9.1-8.8-3.9-8.8-8.7z" opacity="1" data-original="#ffffff" class=""></path></g></svg>
                                                                                                            </Link>
                                                                                                        </div>
                                                                                                    }
                                                                                                    {selectField.map((val, index) => {
                                                                                                        return (
                                                                                                            <li>
                                                                                                                <div className="rightWrap">
                                                                                                                    <input placeholder="Button label" type="text" onChange={(e) => {
                                                                                                                        let temp = { 'button': `Button${index + 1}`, 'value': e.target.value }
                                                                                                                        setButtonLabel(prevButtonLabel => {
                                                                                                                            const filterButtonLabel = prevButtonLabel.filter(item => item.button !== `Button${index + 1}`)
                                                                                                                            return [...filterButtonLabel, temp]
                                                                                                                        })
                                                                                                                    }} />
                                                                                                                    {/* <Select
                                                                                                                    onChange={(value) => {
                                                                                                                        let temp = {'button':`Button${index+1}`,'value':value}
                                                                                                                        setButtonAction(prevButtonAction => {
                                                                                                                            const filterButtonAction = prevButtonAction.filter(item => item.button !== `Buttton${index+1}`)
                                                                                                                            return [...filterButtonAction,temp]
                                                                                                                        })
                                                                                                                    }}  
                                                                                                                    options={button_action}
                                                                                                                    />
                                                                                                                    <hr /> */}
                                                                                                                </div>
                                                                                                                <div className="topTitleWrap">
                                                                                                                    <Link className="rmvIcon" onClick={() => removeFields(index)}>
                                                                                                                        <svg version="1.1" width="20" height="20" x="0" y="0" viewBox="0 0 512 512" class=""><g><path fill="#f44336" d="M256 0C114.836 0 0 114.836 0 256s114.836 256 256 256 256-114.836 256-256S397.164 0 256 0zm0 0" opacity="1" data-original="#f44336" class=""></path><path fill="#fafafa" d="M350.273 320.105c8.34 8.344 8.34 21.825 0 30.168a21.275 21.275 0 0 1-15.086 6.25c-5.46 0-10.921-2.09-15.082-6.25L256 286.164l-64.105 64.11a21.273 21.273 0 0 1-15.083 6.25 21.275 21.275 0 0 1-15.085-6.25c-8.34-8.344-8.34-21.825 0-30.169L225.836 256l-64.11-64.105c-8.34-8.344-8.34-21.825 0-30.168 8.344-8.34 21.825-8.34 30.169 0L256 225.836l64.105-64.11c8.344-8.34 21.825-8.34 30.168 0 8.34 8.344 8.34 21.825 0 30.169L286.164 256zm0 0" opacity="1" data-original="#fafafa" class=""></path></g></svg>
                                                                                                                    </Link>

                                                                                                                </div>
                                                                                                            </li>
                                                                                                        )

                                                                                                    })}
                                                                                                </>
                                                                                            }
                                                                                        </ul>
                                                                                    </div>
                                                                                </div>
                                                                            </>
                                                                        </>
                                                                            :
                                                                            <>
                                                                                <div className="inputCardWrap">
                                                                                    <div className="inputCardHeader">
                                                                                        <h3>{activeNode.type === 'triggerNode' ? 'Trigger' : activeNode.type === 'messageNode' ? 'Message' : activeNode.type === 'buttonNode' ? 'Button' : ""}</h3>
                                                                                    </div>
                                                                                    <div className="textField">
                                                                                        <textarea name="" id="" placeholder="text" value={simpleMsg} onChange={(e) => {
                                                                                            setSimpleMsg(e.target.value)
                                                                                            inputValues.map((obj, key) => {
                                                                                                if (obj.node_id === activeNode.id) {
                                                                                                    inputValues.splice(key, 1)
                                                                                                }
                                                                                            })
                                                                                            setInputValues([...inputValues, { 'node_id': activeNode.id, value: e.target.value }])
                                                                                        }}>{activeNode.data.value}</textarea>
                                                                                    </div>
                                                                                    {/* } */}
                                                                                </div>
                                                                            </>
                                                            }
                                                        </>
                                                    </li>
                                                </ul>
                                                <div className="rightBtnWrap">
                                                    <Button onClick={() => {
                                                        if (activeNode.type === 'categoryNode') {
                                                            let temp = []
                                                            let newTemp = []
                                                            selectedOption.map((val) => {
                                                                temp.push(val.label)
                                                                newTemp.push({ id: generateNodeId(), position: { x: 0, y: 180 }, data: { label: `${val.label}`, value: `${val.label}` }, type: 'buttonNode' })
                                                            })
                                                            setNodes([...nodes, ...newTemp])
                                                            nodes.filter(item => item.id === activeNode.id)[0]['data']['value'] = temp
                                                        }
                                                        else if (activeNode.type === 'messageNode') {
                                                            if (messageNodeData.nodeText || messageNodeData.title) {
                                                                updateMessageNodeData(activeNode.id)
                                                            }
                                                            else {
                                                                if (selectMsgOption && selectMsgOption.value === 'interactive_msg') {
                                                                    nodes.filter(item => item.id === activeNode.id)[0]['data']['title'] = productData.name
                                                                    nodes.filter(item => item.id === activeNode.id)[0]['data']['description'] = productData.description
                                                                    nodes.filter(item => item.id === activeNode.id)[0]['data']['price'] = productData.unit_price
                                                                    let data = {
                                                                        'node_id': activeNode.id,
                                                                        'title': productData.name,
                                                                        'description': productData.description,
                                                                        'price': productData.unit_price
                                                                    }
                                                                    addMessage(data)
                                                                }
                                                                else {
                                                                    nodes.filter(item => item.id === activeNode.id)[0]['data']['value'] = inputValues.filter(item => item.node_id === activeNode.id)[0].value
                                                                }
                                                            }

                                                        }
                                                        else if (activeNode.type === 'buttonNode') {
                                                            if (buttonNodeData.bodyText) {
                                                                updateNodeButtonData(activeNode.id)
                                                            }
                                                            else {
                                                                nodes.filter(item => item.id === activeNode.id)[0]['data']['button_label'] = buttonLabel[0].value
                                                                nodes.filter(item => item.id === activeNode.id)[0]['data']['body_text'] = bodyText
                                                                nodes.filter(item => item.id === activeNode.id)[0]['data']['footer_text'] = footerText
                                                                let new_temp = []
                                                                buttonLabel.map((item, idx) => {
                                                                    if (idx !== 0) {
                                                                        new_temp.push({ id: generateNodeId(), position: { x: 0, y: 180 }, data: { label: buttonLabel[idx], button_label: buttonLabel[idx].value, body_text: bodyText, footer_text: footerText }, type: 'buttonNode' })
                                                                    }
                                                                })
                                                                addInterBtn(activeNode.id, nodes.filter(item => item.id === activeNode.id)[0]['data']['button_label'])
                                                                new_temp.map((value) => {
                                                                    addInterBtn(value.id, value.data.button_label)
                                                                })
                                                                setNodes([...nodes, ...new_temp])
                                                            }

                                                        }
                                                        else if (activeNode.type === 'listNode') {
                                                            if (listNodeData.headerText) {
                                                                let list = []
                                                                let new_temp = []
                                                                selectedListOption.map((item, idx) => {
                                                                    new_temp.push({ id: generateNodeId(), position: { x: 0, y: 180 }, data: { value: item.label, id: item.value }, type: 'listItemsNode' })
                                                                })
                                                                new_temp.map((item) => {
                                                                    list.push({ value: item.data.value, node_id: item.id, item_id: item.data.id })

                                                                })
                                                                updateListNodeData(activeNode.id, list)
                                                                setNodes([...nodes, ...new_temp])
                                                            }
                                                            else {
                                                                let temp = []
                                                                let new_temp = []
                                                                nodes.filter(item => item.id === activeNode.id)[0]['data']['header_text'] = headerText
                                                                nodes.filter(item => item.id === activeNode.id)[0]['data']['button_text'] = listButtonText
                                                                nodes.filter(item => item.id === activeNode.id)[0]['data']['body_text'] = listBodyText
                                                                nodes.filter(item => item.id === activeNode.id)[0]['data']['footer_text'] = listFooterText
                                                                nodes.filter(item => item.id === activeNode.id)[0]['data']['list_items'] = selectedListOption

                                                                let list = []
                                                                selectedListOption.map((item, idx) => {
                                                                    new_temp.push({ id: generateNodeId(), position: { x: 0, y: 180 }, data: { value: item.label, id: item.value }, type: 'listItemsNode' })
                                                                })
                                                                new_temp.map((item) => {
                                                                    list.push({ value: item.data.value, node_id: item.id, item_id: item.data.id })

                                                                })
                                                                addInterList(activeNode.id, list)
                                                                setNodes([...nodes, ...new_temp])
                                                            }

                                                        }
                                                        else if (activeNode.type === 'triggerNode') {
                                                            if (triggerNodeData.nodeText) {
                                                                updateTriggerNodeData(activeNode.id)
                                                            }
                                                            else {
                                                                nodes.filter(item => item.id === activeNode.id)[0]['data']['value'] = inputValues.filter(item => item.node_id === activeNode.id)[0].value
                                                                addTrigger(activeNode.id, nodes.filter(item => item.id === activeNode.id)[0]['data']['value'])
                                                            }
                                                        }
                                                        else {
                                                            nodes.filter(item => item.id === activeNode.id)[0]['data']['value'] = inputValues.filter(item => item.node_id === activeNode.id)[0].value
                                                        }
                                                        clearAll()
                                                    }} >Save</Button>
                                                    <Button onClick={() => {
                                                        removeNode(activeNode.id)
                                                        clearAll()
                                                    }}>Delete Node</Button>
                                                    <Button onClick={() => {
                                                        clearAll()
                                                    }}>Close</Button>
                                                </div>

                                            </div>
                                        </div>
                                    }
                                </NodeInput.Provider>
                                {/* End Right Side Node Type Form */}


                            </div>
                        </div >
                    </div>
                </main >
            </div >
        }
        </>
    )
}
export default WorkFlow;