import axios from "axios";
import { useNavigate } from "react-router-dom"
import { handleErrorResponseAxios, handleResponseAxios } from "./common-handler"
import { GET_BROADCAST_LIST, GET_OVERVIEW_API, GET_BROADCAST_DETAILS_BY_ID } from "../utility/ConstantAPI";

export async function getAllBroadCast(token){
    // let token = get_auth_token(); 
    let axiosConfig = {
        headers: {
            "Content-Type": "application/json",
            Authorization: `${token}`,
        },
    };
    var url = GET_BROADCAST_LIST
    return axios
        .get(url, axiosConfig)
        .then(handleResponseAxios)
        .catch(function (error) {
            return handleErrorResponseAxios(error)
    });

}

export async function getOverview(token){
    // let token = get_auth_token();
    let axiosConfig = {
        headers: {
            "Content-Type": "application/json",
            Authorization: `${token}`,
        },
    };
    var url = GET_OVERVIEW_API
    return axios
        .get(url, axiosConfig)
        .then(handleResponseAxios)
        .catch(function (error) {
            return handleErrorResponseAxios(error)
    });

}
export async function getBroadcastDetailsById(id){
    // let token = get_auth_token(); 
    let axiosConfig = {
        headers: {
            "Content-Type": "application/json",
            // Authorization: `Bearer ${token}`,
        },
    };
    var url = GET_BROADCAST_DETAILS_BY_ID(id)
    return axios
        .get(url, axiosConfig)
        .then(handleResponseAxios)
        .catch(function (error) {
            return handleErrorResponseAxios(error)
    });

}

