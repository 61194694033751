export const initialNodes = [
    { id: '1', position: { x: 0, y: 0 }, data: { label: 'Welcome Message' } },
    { id: '2', type : 'custom', position: { x: -180, y: 100 }, data: { label: 'Wait' } },
    { id: '3', type: 'textUpdater', position: { x: 0, y: 200 }, data: { value: 123 } },
    {
        id: '4',
        type: 'output',
        targetPosition: 'top',
        position: { x: 300, y: 300 },
        data: { label: 'node 2' },
    },
    {
        id: '5',
        type: 'output',
        targetPosition: 'top',
        position: { x: 300, y: 400 },
        data: { label: 'node 3' },
    },
    {
        id: '6',
        type: 'output',
        targetPosition: 'top',
        position: { x: 300, y: 500 },
        data: { label: 'node 4' },
    },
    
];
export const initialEdges = [
    { id: 'e1-2', source: '1', target: '2', label: 'Clicks on', type: 'step' },
    { id: 'e1-3', source: '1', target: '3', label: 'Clicks on', type: 'step' },
    // { id: 'e3-4', source: '3', target: '4', sourceHandle: 'a' },
    { id: 'e3-5', source: '3', target: '5', sourceHandle: 'b' },
    { id: 'e3-6', source: '3', target: '6', sourceHandle: 'c' },

];

export const anotherNodes = [
    { id: '1', position: { x: 0, y: 0 }, type:'textnode' },
]

export const langauges = [
    { "code": "en_US", "name": "English (US)" },
    { "code": "es_ES", "name": "Spanish (Spain)" },
    { "code": "fr_FR", "name": "French (France)" },
    { "code": "de_DE", "name": "German (Germany)" },
    { "code": "it_IT", "name": "Italian (Italy)" },
    { "code": "pt_PT", "name": "Portuguese (Portugal)" },
    { "code": "nl_NL", "name": "Dutch (Netherlands)" },
    { "code": "pl_PL", "name": "Polish (Poland)" },
    { "code": "ru_RU", "name": "Russian (Russia)" },
    { "code": "ar_AR", "name": "Arabic (Arab World)" },
    { "code": "ja_JP", "name": "Japanese (Japan)" },
    { "code": "zh_CN", "name": "Chinese (Simplified)" },
    { "code": "zh_TW", "name": "Chinese (Traditional)" },
    { "code": "ko_KR", "name": "Korean (Korea)" },
    { "code": "tr_TR", "name": "Turkish (Turkey)" },
    { "code": "sv_SE", "name": "Swedish (Sweden)" },
    { "code": "da_DK", "name": "Danish (Denmark)" },
    { "code": "fi_FI", "name": "Finnish (Finland)" },
    { "code": "no_NO", "name": "Norwegian (Norway)" },
    { "code": "cs_CZ", "name": "Czech (Czech Republic)" },
    { "code": "sk_SK", "name": "Slovak (Slovakia)" },
    { "code": "hu_HU", "name": "Hungarian (Hungary)" },
    { "code": "ro_RO", "name": "Romanian (Romania)" },
    { "code": "bg_BG", "name": "Bulgarian (Bulgaria)" },
    { "code": "hr_HR", "name": "Croatian (Croatia)" },
    { "code": "sl_SI", "name": "Slovenian (Slovenia)" },
    { "code": "lt_LT", "name": "Lithuanian (Lithuania)" },
    { "code": "lv_LV", "name": "Latvian (Latvia)" },
    { "code": "et_EE", "name": "Estonian (Estonia)" },
    { "code": "eu_ES", "name": "Basque (Spain)" },
    { "code": "gl_ES", "name": "Galician (Spain)" },
    { "code": "ca_ES", "name": "Catalan (Spain)" },
    { "code": "sq_AL", "name": "Albanian (Albania)" },
    { "code": "mk_MK", "name": "Macedonian (North Macedonia)" },
    { "code": "sr_RS", "name": "Serbian (Serbia)" },
    { "code": "iw_IL", "name": "Hebrew (Israel)" },
    { "code": "th_TH", "name": "Thai (Thailand)" },
    { "code": "vi_VN", "name": "Vietnamese (Vietnam)" },
    { "code": "ms_MY", "name": "Malay (Malaysia)" },
    { "code": "ta_IN", "name": "Tamil (India)" },
    { "code": "te_IN", "name": "Telugu (India)" },
    { "code": "ml_IN", "name": "Malayalam (India)" },
    { "code": "bn_IN", "name": "Bengali (India)" },
    { "code": "kn_IN", "name": "Kannada (India)" },
    { "code": "mr_IN", "name": "Marathi (India)" },
    { "code": "pa_IN", "name": "Punjabi (India)" },
    { "code": "gu_IN", "name": "Gujarati (India)" }
]

export const token = localStorage.getItem('token')
export const user = JSON.parse(localStorage.getItem('user'))