import React, { useState, useEffect, useNa } from 'react';
import { useNavigate, useParams } from "react-router-dom";
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { CustomScroll } from "react-custom-scroll";
import { Spinner } from "react-bootstrap";
import Button from 'react-bootstrap/Button';
import Select, { StylesConfig } from 'react-select';
import SelectField from './SelectField'; // Import the custom SelectField component
import { get_all_categories } from '../../services/categories-services';
import { add_new_product } from '../../services/products_services';
const AddNewProduct = (props) => {
    const { toggleAddNewSidebar } = props
    const [error, setError] = useState([]);
    const [IsLoading, setIsLoading] = useState(false);
    const [CategoryData, setCategoryData] = useState([]);
    const [selectedImage, setSelectedImage] = useState(null);

    const initialValues = {
        name: "",
        description: "",
        image: null,
        unit: "",
        quantity: "",
        unit_price: "",
        veg: true,
        categories: [],
        image: null
    }

    useEffect(() => {
        fetchCategoryData();
    }, []);

    const fetchCategoryData = async () => {
        try {
            const response = await get_all_categories();
            if (response) {

                // Map menuData to the format expected by react-select
                const itemList = response.map(item => ({
                    value: item.id,
                    label: item.name
                }));
                setCategoryData(itemList);
            }
            else {
                setError(response.message)
            }

        } catch (error) {
            console.error('Error fetching data:', error);
            setError(error.message)

        }
        setIsLoading(false);

    };
    const validationSchema = Yup.object().shape({
        name: Yup.string().required(
            "Error: please add your category name."
        ),
        description: Yup.string().required(
            "Error : please add your category description."
        ),
        image: Yup.mixed().required('Error : Please upload an image'),
        unit: Yup.string().required(
            "Error: please add your product unit."
        ),
        quantity: Yup.number().required(
            "Error: please add your product quantity."
        ),
        unit_price: Yup.string().required(
            "Error: please add your product price."
        ),

    })
    const onSubmit = async (values, { setSubmitting }) => {
        try {
            setIsLoading(true)
            const categoryValues = values.categories;
            const formData = new FormData();
            formData.append('name', values.name)
            formData.append('description', values.description)
            formData.append('unit', values.unit)
            formData.append('quantity', values.quantity)
            formData.append('unit_price', values.unit_price)
            formData.append('veg', values.veg)
            formData.append('categoryId', categoryValues)
            formData.append('image', values.image)
            await add_new_product(formData, onSuccess, onError)
        }
        catch (error) {
            console.error("Error:", error);
        }
    }
    const onSuccess = (response) => {
        toggleAddNewSidebar();
        props.updateCategoryPage();
        setIsLoading(false);
    }

    const onError = (error) => {
        setError(error)
        setIsLoading(false);

    }
    return (
        // <div className='asideBarWrap is-sticky'>
        //     <div className='topTTlWrap'>
        //         <button className='btn' onClick={toggleAddNewSidebar}>
        //             <svg width="13" height="22" viewBox="0 0 13 22" fill="none" xmlns="http://www.w3.org/2000/svg">
        //                 <path d="M10.9368 2L2 10.9368L10.9368 19.8735" stroke="#ADA7A7" stroke-width="4" stroke-linecap="round" strokeLinejoin="round" />
        //             </svg>
        //         </button>
        //         <h2>Add New Product</h2>
        //     </div>
        //     <Formik
        //         enableReinitialize
        //         initialValues={initialValues}
        //         validationSchema={validationSchema}
        //         onSubmit={onSubmit}
        //     >
        //         {({ values, errors, status, touched, setFieldValue, setValues, isSubmitting }) => (
        //             <Form>
        //                 <>
        //                     {error ? (
        //                         <p>{error.message}</p>
        //                     ) : null
        //                     }
        //                     <div className='imgWrap'>
        //                         <img src={selectedImage ? URL.createObjectURL(selectedImage) : 'https://www.qwikcilver.com/wp-content/uploads/2019/01/dummy-post-square-1-thegem-blog-masonry.jpg'} style={{ width: '-webkit-fill-available', height: '-webkit-fill-available' }} />
        //                         <input
        //                             type="file"
        //                             accept="image/*"
        //                             style={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%', opacity: 0, cursor: 'pointer' }}
        //                             onChange={(event) => {
        //                                 setSelectedImage(event.currentTarget.files[0]);
        //                                 setFieldValue("image", event.currentTarget.files[0]);
        //                             }}
        //                         />
        //                     </div>
        //                     <div className='btmDataWrap productBtm'>
        //                         <ul>
        //                             <li className='inputFields'>
        //                                 <Field
        //                                     type="text"
        //                                     name="name"
        //                                     placeholder="New Product Name"
        //                                     className={"form-control" +
        //                                         (errors.name &&
        //                                             touched.name
        //                                             ? " is-invalid"
        //                                             : "")
        //                                     }
        //                                 />
        //                                 <ErrorMessage
        //                                     name="name"
        //                                     component="div"
        //                                     className="invalid-feedback"
        //                                 />
        //                             </li>
        //                             <li className='full'>
        //                                 <Field
        //                                     as="textarea"
        //                                     name="description"
        //                                     placeholder="Description....."
        //                                     className={"form-control" +
        //                                         (errors.description &&
        //                                             touched.description
        //                                             ? " is-invalid"
        //                                             : "")
        //                                     }
        //                                 />
        //                                 <ErrorMessage
        //                                     name="description"
        //                                     component="div"
        //                                     className="invalid-feedback"
        //                                 />
        //                             </li>
        //                             <li className='inputFields selectWrap'>
        //                                 <SelectField
        //                                     name="categoryId"
        //                                     options={CategoryData}
        //                                     isMulti
        //                                     // label="Select Items"
        //                                     placeholder="Select Category..."

        //                                 />
        //                             </li>
        //                             <li className='inputFields'>
        //                                 <Field
        //                                     type="text"
        //                                     name="unit"
        //                                     placeholder="Product Unit"
        //                                     className={"form-control" +
        //                                         (errors.name &&
        //                                             touched.name
        //                                             ? " is-invalid"
        //                                             : "")
        //                                     }
        //                                 />
        //                                 <ErrorMessage
        //                                     name="name"
        //                                     component="div"
        //                                     className="invalid-feedback"
        //                                 />
        //                             </li>
        //                             <li className='inputFields'>
        //                                 <Field
        //                                     type="number"
        //                                     name="quantity"
        //                                     placeholder="Product Quantity"
        //                                     className={"form-control" +
        //                                         (errors.name &&
        //                                             touched.name
        //                                             ? " is-invalid"
        //                                             : "")
        //                                     }
        //                                 />
        //                                 <ErrorMessage
        //                                     name="name"
        //                                     component="div"
        //                                     className="invalid-feedback"
        //                                 />
        //                             </li>
        //                             <li className='inputFields'>
        //                                 <Field
        //                                     type="text"
        //                                     name="unit_price"
        //                                     placeholder="Product Unit Price"
        //                                     className={"form-control" +
        //                                         (errors.name &&
        //                                             touched.name
        //                                             ? " is-invalid"
        //                                             : "")
        //                                     }
        //                                 />
        //                                 <ErrorMessage
        //                                     name="unit_price"
        //                                     component="div"
        //                                     className="invalid-feedback"
        //                                 />
        //                             </li>
        //                             <li>
        //                                 <Field type="checkbox" name="veg" />
        //                                 <label htmlFor="">Veg</label>
        //                             </li>
        //                         </ul>
        <div className='asideBarWrap is-sticky'>

            <div className='topTTlWrap'>
                <button className='btn' onClick={toggleAddNewSidebar}>
                    <svg width="13" height="22" viewBox="0 0 13 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M10.9368 2L2 10.9368L10.9368 19.8735" stroke="#ADA7A7" strokeWidth="4" strokeLinecap="round" strokeLinejoin="round" />
                    </svg>
                </button>
                <h2>Add New Product</h2>
            </div>
            <Formik
                enableReinitialize
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={onSubmit}
            >
                {({ values, errors, status, touched, setFieldValue, setValues, isSubmitting }) => (
                    <Form>
                        <>
                            {error && <p>{error.message}</p>}
                            <div className='imgWrap'>
                                <img src={selectedImage ? URL.createObjectURL(selectedImage) : 'https://www.qwikcilver.com/wp-content/uploads/2019/01/dummy-post-square-1-thegem-blog-masonry.jpg'} style={{ width: '-webkit-fill-available', height: '-webkit-fill-available' }} />
                                <input
                                    type="file"
                                    accept="image/*"
                                    style={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%', opacity: 0, cursor: 'pointer' }}
                                    onChange={(event) => {
                                        setSelectedImage(event.currentTarget.files[0]);
                                        setFieldValue("image", event.currentTarget.files[0]);
                                    }}
                                    name='image'
                                />
                            </div>
                            <div className='btmDataWrap productBtm'>
                                <CustomScroll heightRelativeToParent="100%">
                                    <ul>
                                        <li className='inputFields'>
                                            <Field
                                                type="text"
                                                name="name"
                                                placeholder="New Product Name"
                                                className={"form-control" +
                                                    (errors.name &&
                                                        touched.name
                                                        ? " is-invalid"
                                                        : "")
                                                }
                                            />
                                            <ErrorMessage
                                                name="name"
                                                component="div"
                                                className="invalid-feedback"
                                            />
                                        </li>
                                        <li className='inputFields'>
                                            <Field
                                                as="textarea"
                                                name="description"
                                                placeholder="Description....."
                                                className={"form-control" +
                                                    (errors.description &&
                                                        touched.description
                                                        ? " is-invalid"
                                                        : "")
                                                }
                                            />
                                            <ErrorMessage
                                                name="description"
                                                component="div"
                                                className="invalid-feedback"
                                            />
                                        </li>
                                        <li className='inputFields selectWrap'>
                                            <SelectField
                                                name="categories"
                                                options={CategoryData}
                                                isMulti={false}
                                                value={[]}
                                                // label="Select Items"
                                                placeholder="Select Category..."

                                            />
                                        </li>
                                        <li className='inputFields'>
                                            <Field
                                                type="text"
                                                name="unit"
                                                placeholder="Product Unit"
                                                className={"form-control" +
                                                    (errors.name &&
                                                        touched.name
                                                        ? " is-invalid"
                                                        : "")
                                                }
                                            />
                                            <ErrorMessage
                                                name="name"
                                                component="div"
                                                className="invalid-feedback"
                                            />
                                        </li>
                                        <li className='inputFields'>
                                            <Field
                                                type="number"
                                                name="quantity"
                                                placeholder="Product Quantity"
                                                className={"form-control" +
                                                    (errors.name &&
                                                        touched.name
                                                        ? " is-invalid"
                                                        : "")
                                                }
                                            />
                                            <ErrorMessage
                                                name="name"
                                                component="div"
                                                className="invalid-feedback"
                                            />
                                        </li>
                                        <li className='inputFields'>
                                            <Field
                                                type="text"
                                                name="unit_price"
                                                placeholder="Product Unit Price"
                                                className={"form-control" +
                                                    (errors.name &&
                                                        touched.name
                                                        ? " is-invalid"
                                                        : "")
                                                }
                                            />
                                            <ErrorMessage
                                                name="unit_price"
                                                component="div"
                                                className="invalid-feedback"
                                            />
                                        </li>
                                        <li className='checkBoxWrap'>
                                            <Field type="checkbox" name="veg" />
                                            <label htmlFor="">Veg</label>
                                        </li>
                                    </ul>
                                    <div className='ctaWrap'>
                                        <Button type="submit" className="btn" >
                                            {IsLoading &&
                                                <Spinner
                                                    as="span"
                                                    variant="light"
                                                    size="sm"
                                                    role="status"
                                                    aria-hidden="true"
                                                    animation="border"
                                                    className="me-2" />
                                            }
                                            Apply
                                        </Button>
                                    </div>
                                </CustomScroll>
                            </div>
                        </>
                    </Form>
                )}
            </Formik>

        </div>
    )
}
export default AddNewProduct;

