import React, { useState, useEffect } from 'react';
import Form from 'react-bootstrap/Form';
import { CustomScroll } from "react-custom-scroll";
import Header from '../../Components/Common/Header';
import Sidebar from '../../Components/Common/Sidebar';
import CrmIco from '../../assets/img/crmIco.png';


const IntegrationWorkflow = () => {
    const [isOpen, setIsOpen] = useState(false);
    const [isOn, setIsOn] = useState(true);
    const toggleSidebar = () => {
        setIsOpen(!isOpen);
    };
    const handleToggle = () => {
        setIsOn(!isOn);
    };

    return (
        <div className={isOpen ? "app-container sidebar-open" : "app-container"}>
            <Header toggleSidebar={toggleSidebar} />
            <Sidebar isOpen={isOpen} />
            <main className="content IntWorkflowPage">
                <div className='IntWorkflowWrap'>
                    <div className='container-fluid pad0px'>
                        <div className='row'>
                            <div className='col-md-12 pad0px'>
                                <div className='topDataWrap'>
                                    <div className='titleWrap'>
                                        <h2>Integration and Workflow</h2>
                                        <p>Explore and mange app integration</p>
                                    </div>
                                    <div className='rightDataWrap'>
                                        <ul>
                                            <li>
                                                <button className='btn active'>View all</button>
                                            </li>
                                            <li>
                                                <button className='btn'>Active</button>
                                            </li>
                                            <li>
                                                <button className='btn'>Inactive</button>
                                            </li>
                                            <li>
                                                <button className='btn'>Archived</button>
                                            </li>
                                        </ul>
                                        <button className='btnAddNew'>
                                            <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M10.0326 19.822C15.4081 19.822 19.7659 15.4643 19.7659 10.0887C19.7659 4.71321 15.4081 0.355469 10.0326 0.355469C4.65705 0.355469 0.299316 4.71321 0.299316 10.0887C0.299316 15.4643 4.65705 19.822 10.0326 19.822Z" fill="white" />
                                                <path d="M14.3758 10.7534H10.7413V14.4329H9.28969V10.7534H5.67773V9.43693H9.28969V5.73486H10.7413V9.43693H14.3758V10.7534Z" fill="#1944F1" />
                                            </svg>
                                            New Integration
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='row justify-content-center'>
                            <div className='col-xl-11'>
                                <div className='IntWorkflowListWrap'>
                                    <div className='row'>
                                        <div className='col-md-4 padRtLt'>
                                            <div className='itemWrap'>
                                                <div className='topWrap'>
                                                    <div className='icoWrap'>
                                                        <img src={CrmIco} className='img-fluid' alt=''/>
                                                    </div>
                                                    <div className='switchBtnWrap'>
                                                        <div className={`switch ${isOn ? 'on' : 'off'}`} onClick={handleToggle}>
                                                            <div className="switchBtn"></div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className='dataWrap'>
                                                    <h2>Zoho CRM</h2>
                                                    <p>Finding the best software for your business is now faster & easier. Book Free Demo Now!</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='col-md-4 padRtLt'>
                                            <div className='itemWrap'>
                                                <div className='topWrap'>
                                                    <div className='icoWrap'>
                                                        <img src={CrmIco} className='img-fluid' alt=''/>
                                                    </div>
                                                    <div className='switchBtnWrap'>
                                                        <div className={`switch ${isOn ? 'on' : 'off'}`} onClick={handleToggle}>
                                                            <div className="switchBtn"></div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className='dataWrap'>
                                                    <h2>Zoho CRM</h2>
                                                    <p>Finding the best software for your business is now faster & easier. Book Free Demo Now!</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='col-md-4 padRtLt'>
                                            <div className='itemWrap'>
                                                <div className='topWrap'>
                                                    <div className='icoWrap'>
                                                        <img src={CrmIco} className='img-fluid' alt=''/>
                                                    </div>
                                                    <div className='switchBtnWrap'>
                                                        <div className={`switch ${isOn ? 'on' : 'off'}`} onClick={handleToggle}>
                                                            <div className="switchBtn"></div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className='dataWrap'>
                                                    <h2>Zoho CRM</h2>
                                                    <p>Finding the best software for your business is now faster & easier. Book Free Demo Now!</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='col-md-4 padRtLt'>
                                            <div className='itemWrap'>
                                                <div className='topWrap'>
                                                    <div className='icoWrap'>
                                                        <img src={CrmIco} className='img-fluid' alt=''/>
                                                    </div>
                                                    <div className='switchBtnWrap'>
                                                        <div className={`switch ${isOn ? 'on' : 'off'}`} onClick={handleToggle}>
                                                            <div className="switchBtn"></div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className='dataWrap'>
                                                    <h2>Zoho CRM</h2>
                                                    <p>Finding the best software for your business is now faster & easier. Book Free Demo Now!</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='col-md-4 padRtLt'>
                                            <div className='itemWrap'>
                                                <div className='topWrap'>
                                                    <div className='icoWrap'>
                                                        <img src={CrmIco} className='img-fluid' alt=''/>
                                                    </div>
                                                    <div className='switchBtnWrap'>
                                                        <div className={`switch ${isOn ? 'on' : 'off'}`} onClick={handleToggle}>
                                                            <div className="switchBtn"></div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className='dataWrap'>
                                                    <h2>Zoho CRM</h2>
                                                    <p>Finding the best software for your business is now faster & easier. Book Free Demo Now!</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='col-md-4 padRtLt'>
                                            <div className='itemWrap'>
                                                <div className='topWrap'>
                                                    <div className='icoWrap'>
                                                        <img src={CrmIco} className='img-fluid' alt=''/>
                                                    </div>
                                                    <div className='switchBtnWrap'>
                                                        <div className={`switch ${isOn ? 'on' : 'off'}`} onClick={handleToggle}>
                                                            <div className="switchBtn"></div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className='dataWrap'>
                                                    <h2>Zoho CRM</h2>
                                                    <p>Finding the best software for your business is now faster & easier. Book Free Demo Now!</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </main>
        </div>
    );
};

export default IntegrationWorkflow;

