import React, { useState, useEffect } from 'react';
import { useNavigate } from "react-router-dom";
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { Spinner } from "react-bootstrap";
import Button from 'react-bootstrap/Button';
import { get_my_category, get_menu_by_id, update_menu } from '../../services/menus-services';
import SelectField from './SelectField'; // Import the custom SelectField component

const EditMenu = ({ toggleEditSidebar, item_data }) => {
    const navigate = useNavigate();

    const [error, setError] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [categoryData, setCategoryData] = useState([]);
    const [data, setData] = useState(null);
    const [selectedImage, setSelectedImage] = useState(null);
    const [initialValues, setInitialValues] = useState({
        name: "",
        description: "",
        categories: [],
        active: null,
    });

    useEffect(() => {
        const fetchData = async () => {
            try {
                await Promise.all([
                    fetchCategoryData(),
                    fetchMenu()
                ]);
            } catch (error) {
                console.error('Error fetching data:', error);
                setError(error.message);
            }
            setIsLoading(false);
        };

        fetchData();
    }, []);

    const fetchCategoryData = async () => {
        try {
            const response = await get_my_category();
            if (response) {
                const itemList = response.map(item => ({
                    value: item.id,
                    label: item.name
                }));
                setCategoryData(itemList);
            } else {
                setError(response.message);
            }
        } catch (error) {
            console.error('Error fetching category data:', error);
            setError(error.message);
        }
    };

    const fetchMenu = async () => {
        try {
            const response = await get_menu_by_id(item_data.id);
            if (response) {
                setData(response);
            } else {
                setError(response.message);
            }
        } catch (error) {
            console.error('Error fetching category data:', error);
            setError(error.message);
        }
    };

    useEffect(() => {
        if (data) {
            const {
                name = "",
                description = "",
                categories = [],
                active = null
            } = data;

            setInitialValues({
                name,
                description,
                categories,
                active
            });
        }
    }, [data]);

    const validationSchema = Yup.object().shape({
        name: Yup.string().required('Please enter the category name.'),
        description: Yup.string().required('Please enter the category description.'),
        categories: Yup.array().min(1, 'Please select at least one category.')
    });

    const onSubmit = async (values) => {
        setIsLoading(true);
        try {
            const formData = new FormData();
            formData.append('name', values.name);
            formData.append('description', values.description);
            if (selectedImage) {
                formData.append('image', selectedImage);
            }
            formData.append('categories', JSON.stringify(values.categories.map(category => category.value)));

            await update_menu(item_data.id, formData, onSuccess, onError);
        } catch (error) {
            console.error('Error updating category:', error);
            setError(error.message);
            setIsLoading(false);
        }
    };

    const onSuccess = (response) => {
        if (response && response.status === 200) {
            navigate(`/DepartmentList`);
        } else {
            console.log('Category update failed');
        }
        setIsLoading(false);
    };

    const onError = (error) => {
        setError(error.message);
        setIsLoading(false);
    };

    return (
        <div className='asideBarWrap is-sticky'>
            <div className='topTTlWrap'>
                <button className='btn' onClick={toggleEditSidebar}>
                    <svg width="13" height="22" viewBox="0 0 13 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M10.9368 2L2 10.9368L10.9368 19.8735" stroke="#ADA7A7" strokeWidth="4" strokeLinecap="round" strokeLinejoin="round" />
                    </svg>
                </button>
                <h2>Edit Category</h2>
            </div>
            <Formik
                enableReinitialize
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={onSubmit}
            >
                {({ values, errors, touched, setFieldValue, isSubmitting }) => (
                    <Form>

                        {error ? (
                            <p>{error.message}</p>
                        ) : null
                        }
                        <div className='imgWrap'>
                            <img src={selectedImage ? URL.createObjectURL(selectedImage) : item_data.image} style={{ width: '-webkit-fill-available', height: '-webkit-fill-available' }} />
                            {/* <button className='btnEdit'>
                                    <svg width="11" height="12" viewBox="0 0 11 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M7.165 2.08191L0.430163 8.73842C0.393329 8.77609 0.372721 8.82671 0.372742 8.87939V10.9573C0.372742 10.985 0.383735 11.0115 0.403316 11.0311C0.422898 11.0506 0.449471 11.0616 0.477164 11.0616H2.23134C2.28388 11.0609 2.33421 11.0405 2.3723 11.0043L9.28985 4.08674L7.165 2.08191Z" fill="white" />
                                        <path d="M9.96359 3.36146L7.95334 1.35128L8.77833 0.526258C8.80437 0.500177 8.8353 0.479442 8.86935 0.465325C8.9034 0.451208 8.93989 0.443909 8.97675 0.443909C9.01361 0.443909 9.05009 0.451208 9.08413 0.465325C9.11818 0.479442 9.14911 0.500177 9.17515 0.526258L10.7886 2.1396C10.8147 2.16564 10.8354 2.19657 10.8495 2.23062C10.8636 2.26466 10.8709 2.30122 10.8709 2.33808C10.8709 2.37493 10.8636 2.41137 10.8495 2.44541C10.8354 2.47946 10.8147 2.51039 10.7886 2.53643L9.96359 3.36146Z" fill="white" />
                                    </svg>
                                </button> */}
                            <input
                                type="file"
                                accept="image/*"
                                style={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%', opacity: 0, cursor: 'pointer' }}
                                onChange={(event) => {
                                    setSelectedImage(event.currentTarget.files[0]);
                                    setFieldValue("image", event.currentTarget.files[0]);
                                }}
                            // value={item_data.image}
                            />
                        </div>
                        <div className='btmDataWrap'>
                            <ul>
                                <li className='inputFields'>
                                    <Field
                                        type="text"
                                        name="name"
                                        placeholder="New Category Name"
                                        className={"form-control" +
                                            (errors.name && touched.name ? " is-invalid" : "")
                                        }
                                    />
                                    <ErrorMessage
                                        name="name"
                                        component="div"
                                        className="invalid-feedback"
                                    />
                                </li>
                                <li className='inputFields'>
                                    <Field
                                        as="textarea"
                                        name="description"
                                        placeholder="Description....."
                                        className={"form-control" +
                                            (errors.description && touched.description ? " is-invalid" : "")
                                        }
                                    />
                                    <ErrorMessage
                                        name="description"
                                        component="div"
                                        className="invalid-feedback"
                                    />
                                </li>
                                <li className='inputFields selectWrap'>
                                    <SelectField
                                        name="categories"
                                        options={categoryData}
                                        isMulti
                                        value={values.categories}
                                        placeholder="Select Category..."
                                        onChange={(selectedOptions) => setFieldValue('categories', selectedOptions)}
                                    />
                                    <ErrorMessage
                                        name="categories"
                                        component="div"
                                        className="invalid-feedback"
                                    />
                                </li>

                            </ul>
                            <div className='ctaWrap'>
                                <Button type="submit" className="btn" disabled={isSubmitting}>
                                    {isLoading &&
                                        <Spinner
                                            as="span"
                                            variant="light"
                                            size="sm"
                                            role="status"
                                            aria-hidden="true"
                                            animation="border"
                                            className="me-2"
                                        />
                                    }
                                    Apply
                                </Button>
                            </div>
                        </div>
                    </Form>
                )}
            </Formik>
        </div>
    );
};

export default EditMenu;


