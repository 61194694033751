import React, { useState, useEffect } from 'react';
import ReactFlow, { addEdge, Background, Controls, MiniMap, useNodesState, useEdgesState } from 'react-flow-renderer';
import {useParams, useNavigate } from "react-router-dom";
import CustomNode from './CustomNode';
import { v4 as uuidv4 } from 'uuid';
import Header from "../../Common/Header";
import Sidebar from "../../Common/Sidebar";
import SideDetailbar from "./SideDetailBar"
import {saveSmsFlow, get_sms_flow_by_id} from '../../../services/sms_flow_services'

//Define the custome node 
const nodeTypes = { customNode: CustomNode };

const FlowChart = () => {
  const navigate = useNavigate()
  const {flowId}  = useParams();
  const [isOpen, setIsOpen] = useState(false);
  const toggleSidebar = () => {
        setIsOpen(!isOpen);
      }

  //Initializing empty Node and Edges for black screen    
  const initialNodes = [];
  const initialEdges = [];

  const [nodes, setNodes, onNodesChange] = useNodesState(initialNodes);
  const [edges, setEdges, onEdgesChange] = useEdgesState(initialEdges);
  const [selectedNode, setSelectedNode] = useState(null);

  const [flowName, setFlowName] = useState('')

  useEffect(() => {
    // Fetch the flow data
    async function fetchFlow() {
      try {
        const response = await get_sms_flow_by_id(flowId);
  
        const initializedNodes = response.nodes.map(initializeNode);
  
        setNodes(initializedNodes);
        setEdges(response.edges || []);
        setFlowName(response.flowName || '');
      } catch (error) {
        console.error('Failed to fetch flow:', error);
      }
    }
  
    fetchFlow();
  }, [flowId]);
  
  //This function is used to add the addOptionToNode function in each node while creteing and fetching node from database
  const initializeNode = (node) => ({
    ...node,
    data: {
      ...node.data,
      addOption: (nodeId) => addOptionToNode(nodeId)
    }
  });

  // Handler to create a new node
  const createNewNode = () => {
    const newNode = initializeNode({
      id: uuidv4(),
      type: 'customNode',
      data: { bodyText: '', options: [] },
      position: { x: Math.random() * 400, y: Math.random() * 400 }
    });

    // setNodes((nds) => nds.concat(newNode));  
    setNodes((nds) => (nds || []).concat(newNode));
  };
  

  // Handler to add a new option to a node
  const addOptionToNode = (nodeId) => {
    setNodes((nds) =>
      nds.map((node) => {
        if (node.id === nodeId) {
          const newOption = { 
            label:"",
            //  `Option ${node.data.options.length + 1}`
            optionBodyText:"",
            id: uuidv4()
           };
          return {
            ...node,
            data: {
              ...node.data,
              options: [...node.data.options, newOption]
            }
          };
        }
        return node;
      })
    );
  };


  const onConnect = (params) => {
    const { source, sourceHandle, target } = params;
  
    // Find the source node from your nodes array
    const sourceNode = nodes.find(node => node.id === source);
  
    if (sourceNode) {
      // Find the specific option in the source node that matches the source handle
      const optionIndex = sourceNode.data.options.findIndex(option => option.id === sourceHandle);
  
      if (optionIndex !== -1) {
        // Update the targetNodeId for the specific option
        sourceNode.data.options[optionIndex].targetNodeId = target;
  
        // Now update the state to reflect the changes in nodes
        setNodes(prevNodes => prevNodes.map(node => {
          if (node.id === sourceNode.id) {
            return { ...node, data: { ...node.data, options: [...sourceNode.data.options] } };
          }
          return node;
        }));
      }
    }
  
    // Also add the new edge to the state (assuming you handle edges separately)
    setEdges(prevEdges => addEdge(params, prevEdges));
  };

  // Function to update selected node data. To add the data in the node
  const updateNodeData = (nodeId, updatedData) => {
    setNodes((nds) => {
      const updatedNodes = nds.map((node) => {
        if (node.id === nodeId) {
          const updatedNode = { ...node, data: { ...node.data, ...updatedData } };
          
          // If the node is selected, update the selectedNode state as well
          if (nodeId === selectedNode?.id) {
            setSelectedNode(updatedNode);
          }
  
          return updatedNode;
        }
        return node;
      });
  
      return updatedNodes;
    });
  };
  

  // Event handler for node click
  const onNodeClick = (event, node) => {
    setSelectedNode(node);
  };


  // Example onSubmit handler for saving the flow
  const handleSaveFlow = async () => {
    try {
      const flowData = {
        flowName: flowName,  // Get from state
        nodes: nodes,        // Get from state
      };

      await saveSmsFlow(flowData, onSuccess, onError);

      // alert('Flow saved successfully!');
    } catch (error) {
      // console.error('Failed to save flow:', error);
      alert('Failed to save flow.', error);
    }
  };

  const onSuccess = (response) => {
    if (response && response.status_code === 200) {
        navigate(`/SmsFlowList`);
        // window.location.reload()
    } else {
      alert('Failed to save flow.');
    }
    // setIsLoading(false);
  }

  const onError = (error) => {
    // setError(error)
    // setIsLoading(false);
    console.log(error)

  }

  return (

    <div className={isOpen ? "app-container sidebar-open" : "app-container"}> 
      <Header toggleSidebar={toggleSidebar} />
      {/* <Sidebar isOpen={isOpen} /> */}
      <main className="content smsFlowPage">
        <div className='smsFlowWrap'>
          <div className='container-fluid pad0'>   
            <div className='row martBtm'>
              <div className='col-md-12 pad0 d-flex'>
                <div className='topFieldWrap'>
                  <input
                    type="text"
                    name="flow_name"
                    placeholder="Enter Flow Name"
                    value={flowName}
                    // disabled={id ? true : false} 
                    onChange={(e) => setFlowName(e.target.value)}
                  />
                </div>
                <div className='topCtaWrap'>
                  <button onClick={createNewNode} className="btn">Create New Node</button>
                  <button onClick={handleSaveFlow} className="btn">Save</button>
                </div>
              </div>
            </div>       
            <div className='row'>
              <div className={selectedNode ? "col-md-9 d-flex" : "col-md-12 d-flex"}>
                <div className="mainFlowWrap">
                  <ReactFlow
                    nodes={nodes}
                    edges={edges}
                    onNodesChange={onNodesChange}
                    onEdgesChange={onEdgesChange}
                    onConnect={onConnect}
                    onNodeClick={onNodeClick}
                    nodeTypes={nodeTypes}
                    // fitView
                  >
                    <MiniMap />
                    <Controls />
                    <Background />
                  </ReactFlow>
                </div>
              </div>
              {selectedNode && (
                <div className='col-md-3 pad0'>
                  <SideDetailbar
                      selectedNode={selectedNode}
                      updateNodeData={updateNodeData}
                      setSelectedNode={setSelectedNode}
                    />
                </div>
              )}
            </div>
          </div>
        </div>

      </main>
    
    </div>


  );
};

export default FlowChart;

