import React, { useState, useEffect } from 'react';
import Form from 'react-bootstrap/Form';
import { CustomScroll } from "react-custom-scroll";
import Header from '../../Components/Common/Header';
import Sidebar from '../../Components/Common/Sidebar';

const ContactListTwo = () => {
    const [isOpen, setIsOpen] = useState(false);
    const toggleSidebar = () => {
        setIsOpen(!isOpen);
    };
    return (
        <div className={isOpen ? "app-container sidebar-open" : "app-container"}>
            <Header toggleSidebar={toggleSidebar} />
            <Sidebar isOpen={isOpen} />
            <main className="content contactListPage">
                <div className='contactListWrap'>
                    <div className='topDataWrap'>
                        <div className='container'>
                            <div className='row align-items-center'>
                                <div className='col-md-12 d-flex justify-content-between'>
                                    <div className='leftContentWrap'>
                                        <div className='titleWrap'>
                                            <h2>New List</h2>
                                            <button className='btn'>
                                                <svg width="8" height="9" viewBox="0 0 8 9" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M5.33838 1.00095L7.00664 2.66921L7.17346 2.50239C7.63414 2.04171 7.63414 1.29481 7.17346 0.834129C6.71279 0.373452 5.96588 0.373452 5.5052 0.834129L5.33838 1.00095Z" fill="#98A2B3" />
                                                    <path d="M5.0622 1.33447L0.0434171 6.29265C0.0296822 6.30629 0.0187666 6.32236 0.0113226 6.34022C0.00387871 6.35809 1.09954e-05 6.37724 0 6.39659V7.94559C0 7.96612 0.00812864 7.98582 0.0226453 8.00033C0.0371619 8.01485 0.0568553 8.02282 0.077385 8.02282H1.38601C1.42477 8.02279 1.46197 8.00747 1.48946 7.98013L6.64297 2.8258L5.0622 1.33447Z" fill="#98A2B3" />
                                                </svg>
                                            </button>
                                        </div>
                                        <div className='filterWrap'>
                                            <Form.Select aria-label="Filter">
                                                <option value="">Filter</option>
                                                <option value="1">1</option>
                                                <option value="2">2</option>
                                                <option value="3">3</option>
                                                <option value="4">4</option>
                                                <option value="5">5</option>
                                            </Form.Select>
                                        </div>
                                        <div className='filterWrap mart0px'>
                                            <Form.Select aria-label="Search">
                                                <option value="">Search</option>
                                                <option value="1">1</option>
                                                <option value="2">2</option>
                                                <option value="3">3</option>
                                                <option value="4">4</option>
                                                <option value="5">5</option>
                                            </Form.Select>
                                        </div>
                                    </div>
                                    <div className='rightWrap'>
                                        <button className='btn'>
                                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M12 24C18.6274 24 24 18.6274 24 12C24 5.37258 18.6274 0 12 0C5.37258 0 0 5.37258 0 12C0 18.6274 5.37258 24 12 24Z" fill="white" />
                                                <path d="M17.355 12.8191H12.8741V17.3555H11.0845V12.8191H6.63135V11.1961H11.0845V6.63184H12.8741V11.1961H17.355V12.8191Z" fill="#1944F1" />
                                            </svg>
                                            Add New Contact
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='contactListTbl'>
                        <table>
                            <tbody>
                                <tr>
                                    <td><input type="checkbox"/></td>
                                    <td>Neeraj Pande</td>
                                    <td>+91 8865874578</td>
                                    <td>neerajoande@gmail.com</td>
                                    <td>Pune</td>
                                    <td>
                                        <button className='btn'>
                                            <svg version="1.1" width="20" height="20" x="0" y="0" viewBox="0 0 512 512">
                                                <g><path fill="#00000080" d="M256 0C114.836 0 0 114.836 0 256s114.836 256 256 256 256-114.836 256-256S397.164 0 256 0zm0 0" opacity="1" data-original="#00000080"></path><path fill="#ffffff" d="M350.273 320.105c8.34 8.344 8.34 21.825 0 30.168a21.275 21.275 0 0 1-15.086 6.25c-5.46 0-10.921-2.09-15.082-6.25L256 286.164l-64.105 64.11a21.273 21.273 0 0 1-15.083 6.25 21.275 21.275 0 0 1-15.085-6.25c-8.34-8.344-8.34-21.825 0-30.169L225.836 256l-64.11-64.105c-8.34-8.344-8.34-21.825 0-30.168 8.344-8.34 21.825-8.34 30.169 0L256 225.836l64.105-64.11c8.344-8.34 21.825-8.34 30.168 0 8.34 8.344 8.34 21.825 0 30.169L286.164 256zm0 0" opacity="1" data-original="#ffffff"></path></g>
                                            </svg>
                                            Remove
                                        </button>
                                    </td>
                                </tr>
                                <tr>
                                    <td><input type="checkbox"/></td>
                                    <td>Neeraj Pande</td>
                                    <td>+91 8865874578</td>
                                    <td>neerajoande@gmail.com</td>
                                    <td>Pune</td>
                                    <td>
                                        <button className='btn'>
                                            <svg version="1.1" width="20" height="20" x="0" y="0" viewBox="0 0 512 512">
                                                <g><path fill="#00000080" d="M256 0C114.836 0 0 114.836 0 256s114.836 256 256 256 256-114.836 256-256S397.164 0 256 0zm0 0" opacity="1" data-original="#00000080"></path><path fill="#ffffff" d="M350.273 320.105c8.34 8.344 8.34 21.825 0 30.168a21.275 21.275 0 0 1-15.086 6.25c-5.46 0-10.921-2.09-15.082-6.25L256 286.164l-64.105 64.11a21.273 21.273 0 0 1-15.083 6.25 21.275 21.275 0 0 1-15.085-6.25c-8.34-8.344-8.34-21.825 0-30.169L225.836 256l-64.11-64.105c-8.34-8.344-8.34-21.825 0-30.168 8.344-8.34 21.825-8.34 30.169 0L256 225.836l64.105-64.11c8.344-8.34 21.825-8.34 30.168 0 8.34 8.344 8.34 21.825 0 30.169L286.164 256zm0 0" opacity="1" data-original="#ffffff"></path></g>
                                            </svg>
                                            Remove
                                        </button>
                                    </td>
                                </tr>
                                <tr>
                                    <td><input type="checkbox"/></td>
                                    <td>Neeraj Pande</td>
                                    <td>+91 8865874578</td>
                                    <td>neerajoande@gmail.com</td>
                                    <td>Pune</td>
                                    <td>
                                        <button className='btn'>
                                            <svg version="1.1" width="20" height="20" x="0" y="0" viewBox="0 0 512 512">
                                                <g><path fill="#00000080" d="M256 0C114.836 0 0 114.836 0 256s114.836 256 256 256 256-114.836 256-256S397.164 0 256 0zm0 0" opacity="1" data-original="#00000080"></path><path fill="#ffffff" d="M350.273 320.105c8.34 8.344 8.34 21.825 0 30.168a21.275 21.275 0 0 1-15.086 6.25c-5.46 0-10.921-2.09-15.082-6.25L256 286.164l-64.105 64.11a21.273 21.273 0 0 1-15.083 6.25 21.275 21.275 0 0 1-15.085-6.25c-8.34-8.344-8.34-21.825 0-30.169L225.836 256l-64.11-64.105c-8.34-8.344-8.34-21.825 0-30.168 8.344-8.34 21.825-8.34 30.169 0L256 225.836l64.105-64.11c8.344-8.34 21.825-8.34 30.168 0 8.34 8.344 8.34 21.825 0 30.169L286.164 256zm0 0" opacity="1" data-original="#ffffff"></path></g>
                                            </svg>
                                            Remove
                                        </button>
                                    </td>
                                </tr>
                                <tr>
                                    <td><input type="checkbox"/></td>
                                    <td>Neeraj Pande</td>
                                    <td>+91 8865874578</td>
                                    <td>neerajoande@gmail.com</td>
                                    <td>Pune</td>
                                    <td>
                                        <button className='btn'>
                                            <svg version="1.1" width="20" height="20" x="0" y="0" viewBox="0 0 512 512">
                                                <g><path fill="#00000080" d="M256 0C114.836 0 0 114.836 0 256s114.836 256 256 256 256-114.836 256-256S397.164 0 256 0zm0 0" opacity="1" data-original="#00000080"></path><path fill="#ffffff" d="M350.273 320.105c8.34 8.344 8.34 21.825 0 30.168a21.275 21.275 0 0 1-15.086 6.25c-5.46 0-10.921-2.09-15.082-6.25L256 286.164l-64.105 64.11a21.273 21.273 0 0 1-15.083 6.25 21.275 21.275 0 0 1-15.085-6.25c-8.34-8.344-8.34-21.825 0-30.169L225.836 256l-64.11-64.105c-8.34-8.344-8.34-21.825 0-30.168 8.344-8.34 21.825-8.34 30.169 0L256 225.836l64.105-64.11c8.344-8.34 21.825-8.34 30.168 0 8.34 8.344 8.34 21.825 0 30.169L286.164 256zm0 0" opacity="1" data-original="#ffffff"></path></g>
                                            </svg>
                                            Remove
                                        </button>
                                    </td>
                                </tr>
                                <tr>
                                    <td><input type="checkbox"/></td>
                                    <td>Neeraj Pande</td>
                                    <td>+91 8865874578</td>
                                    <td>neerajoande@gmail.com</td>
                                    <td>Pune</td>
                                    <td>
                                        <button className='btn'>
                                            <svg version="1.1" width="20" height="20" x="0" y="0" viewBox="0 0 512 512">
                                                <g><path fill="#00000080" d="M256 0C114.836 0 0 114.836 0 256s114.836 256 256 256 256-114.836 256-256S397.164 0 256 0zm0 0" opacity="1" data-original="#00000080"></path><path fill="#ffffff" d="M350.273 320.105c8.34 8.344 8.34 21.825 0 30.168a21.275 21.275 0 0 1-15.086 6.25c-5.46 0-10.921-2.09-15.082-6.25L256 286.164l-64.105 64.11a21.273 21.273 0 0 1-15.083 6.25 21.275 21.275 0 0 1-15.085-6.25c-8.34-8.344-8.34-21.825 0-30.169L225.836 256l-64.11-64.105c-8.34-8.344-8.34-21.825 0-30.168 8.344-8.34 21.825-8.34 30.169 0L256 225.836l64.105-64.11c8.344-8.34 21.825-8.34 30.168 0 8.34 8.344 8.34 21.825 0 30.169L286.164 256zm0 0" opacity="1" data-original="#ffffff"></path></g>
                                            </svg>
                                            Remove
                                        </button>
                                    </td>
                                </tr>
                                <tr>
                                    <td><input type="checkbox"/></td>
                                    <td>Neeraj Pande</td>
                                    <td>+91 8865874578</td>
                                    <td>neerajoande@gmail.com</td>
                                    <td>Pune</td>
                                    <td>
                                        <button className='btn'>
                                            <svg version="1.1" width="20" height="20" x="0" y="0" viewBox="0 0 512 512">
                                                <g><path fill="#00000080" d="M256 0C114.836 0 0 114.836 0 256s114.836 256 256 256 256-114.836 256-256S397.164 0 256 0zm0 0" opacity="1" data-original="#00000080"></path><path fill="#ffffff" d="M350.273 320.105c8.34 8.344 8.34 21.825 0 30.168a21.275 21.275 0 0 1-15.086 6.25c-5.46 0-10.921-2.09-15.082-6.25L256 286.164l-64.105 64.11a21.273 21.273 0 0 1-15.083 6.25 21.275 21.275 0 0 1-15.085-6.25c-8.34-8.344-8.34-21.825 0-30.169L225.836 256l-64.11-64.105c-8.34-8.344-8.34-21.825 0-30.168 8.344-8.34 21.825-8.34 30.169 0L256 225.836l64.105-64.11c8.344-8.34 21.825-8.34 30.168 0 8.34 8.344 8.34 21.825 0 30.169L286.164 256zm0 0" opacity="1" data-original="#ffffff"></path></g>
                                            </svg>
                                            Remove
                                        </button>
                                    </td>
                                </tr>
                                <tr>
                                    <td><input type="checkbox"/></td>
                                    <td>Neeraj Pande</td>
                                    <td>+91 8865874578</td>
                                    <td>neerajoande@gmail.com</td>
                                    <td>Pune</td>
                                    <td>
                                        <button className='btn'>
                                            <svg version="1.1" width="20" height="20" x="0" y="0" viewBox="0 0 512 512">
                                                <g><path fill="#00000080" d="M256 0C114.836 0 0 114.836 0 256s114.836 256 256 256 256-114.836 256-256S397.164 0 256 0zm0 0" opacity="1" data-original="#00000080"></path><path fill="#ffffff" d="M350.273 320.105c8.34 8.344 8.34 21.825 0 30.168a21.275 21.275 0 0 1-15.086 6.25c-5.46 0-10.921-2.09-15.082-6.25L256 286.164l-64.105 64.11a21.273 21.273 0 0 1-15.083 6.25 21.275 21.275 0 0 1-15.085-6.25c-8.34-8.344-8.34-21.825 0-30.169L225.836 256l-64.11-64.105c-8.34-8.344-8.34-21.825 0-30.168 8.344-8.34 21.825-8.34 30.169 0L256 225.836l64.105-64.11c8.344-8.34 21.825-8.34 30.168 0 8.34 8.344 8.34 21.825 0 30.169L286.164 256zm0 0" opacity="1" data-original="#ffffff"></path></g>
                                            </svg>
                                            Remove
                                        </button>
                                    </td>
                                </tr>
                                <tr>
                                    <td><input type="checkbox"/></td>
                                    <td>Neeraj Pande</td>
                                    <td>+91 8865874578</td>
                                    <td>neerajoande@gmail.com</td>
                                    <td>Pune</td>
                                    <td>
                                        <button className='btn'>
                                            <svg version="1.1" width="20" height="20" x="0" y="0" viewBox="0 0 512 512">
                                                <g><path fill="#00000080" d="M256 0C114.836 0 0 114.836 0 256s114.836 256 256 256 256-114.836 256-256S397.164 0 256 0zm0 0" opacity="1" data-original="#00000080"></path><path fill="#ffffff" d="M350.273 320.105c8.34 8.344 8.34 21.825 0 30.168a21.275 21.275 0 0 1-15.086 6.25c-5.46 0-10.921-2.09-15.082-6.25L256 286.164l-64.105 64.11a21.273 21.273 0 0 1-15.083 6.25 21.275 21.275 0 0 1-15.085-6.25c-8.34-8.344-8.34-21.825 0-30.169L225.836 256l-64.11-64.105c-8.34-8.344-8.34-21.825 0-30.168 8.344-8.34 21.825-8.34 30.169 0L256 225.836l64.105-64.11c8.344-8.34 21.825-8.34 30.168 0 8.34 8.344 8.34 21.825 0 30.169L286.164 256zm0 0" opacity="1" data-original="#ffffff"></path></g>
                                            </svg>
                                            Remove
                                        </button>
                                    </td>
                                </tr>
                                <tr>
                                    <td><input type="checkbox"/></td>
                                    <td>Neeraj Pande</td>
                                    <td>+91 8865874578</td>
                                    <td>neerajoande@gmail.com</td>
                                    <td>Pune</td>
                                    <td>
                                        <button className='btn'>
                                            <svg version="1.1" width="20" height="20" x="0" y="0" viewBox="0 0 512 512">
                                                <g><path fill="#00000080" d="M256 0C114.836 0 0 114.836 0 256s114.836 256 256 256 256-114.836 256-256S397.164 0 256 0zm0 0" opacity="1" data-original="#00000080"></path><path fill="#ffffff" d="M350.273 320.105c8.34 8.344 8.34 21.825 0 30.168a21.275 21.275 0 0 1-15.086 6.25c-5.46 0-10.921-2.09-15.082-6.25L256 286.164l-64.105 64.11a21.273 21.273 0 0 1-15.083 6.25 21.275 21.275 0 0 1-15.085-6.25c-8.34-8.344-8.34-21.825 0-30.169L225.836 256l-64.11-64.105c-8.34-8.344-8.34-21.825 0-30.168 8.344-8.34 21.825-8.34 30.169 0L256 225.836l64.105-64.11c8.344-8.34 21.825-8.34 30.168 0 8.34 8.344 8.34 21.825 0 30.169L286.164 256zm0 0" opacity="1" data-original="#ffffff"></path></g>
                                            </svg>
                                            Remove
                                        </button>
                                    </td>
                                </tr>
                                <tr>
                                    <td><input type="checkbox"/></td>
                                    <td>Neeraj Pande</td>
                                    <td>+91 8865874578</td>
                                    <td>neerajoande@gmail.com</td>
                                    <td>Pune</td>
                                    <td>
                                        <button className='btn'>
                                            <svg version="1.1" width="20" height="20" x="0" y="0" viewBox="0 0 512 512">
                                                <g><path fill="#00000080" d="M256 0C114.836 0 0 114.836 0 256s114.836 256 256 256 256-114.836 256-256S397.164 0 256 0zm0 0" opacity="1" data-original="#00000080"></path><path fill="#ffffff" d="M350.273 320.105c8.34 8.344 8.34 21.825 0 30.168a21.275 21.275 0 0 1-15.086 6.25c-5.46 0-10.921-2.09-15.082-6.25L256 286.164l-64.105 64.11a21.273 21.273 0 0 1-15.083 6.25 21.275 21.275 0 0 1-15.085-6.25c-8.34-8.344-8.34-21.825 0-30.169L225.836 256l-64.11-64.105c-8.34-8.344-8.34-21.825 0-30.168 8.344-8.34 21.825-8.34 30.169 0L256 225.836l64.105-64.11c8.344-8.34 21.825-8.34 30.168 0 8.34 8.344 8.34 21.825 0 30.169L286.164 256zm0 0" opacity="1" data-original="#ffffff"></path></g>
                                            </svg>
                                            Remove
                                        </button>
                                    </td>
                                </tr>                                
                            </tbody>
                        </table>
                    </div>
                    <div className='addListCtaWrap'>
                        <button className='btn'>Continue</button>
                    </div>
                </div>
            </main>
        </div>
    );
};

export default ContactListTwo;

