import React from 'react';
import Select from 'react-select';
import { useField, ErrorMessage } from 'formik';

const SelectField = ({ options, label, ...props }) => {
    const [field, meta, helpers] = useField(props.name)

    const handleChange = (selectedOptions) => {
        if (Array.isArray(selectedOptions)) {
            helpers.setValue(selectedOptions.map(option => option.value));
        }
        else {
            helpers.setValue(selectedOptions.value)
        }
        //
    };

    // Map IDs to corresponding options
    const selectedOptions = Array.isArray(field.value) ? field.value.map(menuId =>
        options.find(option => option.value === menuId)
    ) : options.find(option => option.value === field.value);

    // Determine whether there are any initial selected options
    const hasInitialSelectedOptions = selectedOptions.length > 0;

    return (
        <>
            {label && <label>{label}</label>}
            <Select
                {...field}
                {...props}
                options={options}
                value={hasInitialSelectedOptions ? selectedOptions : selectedOptions} // Set the initial selected option or null if none
                onChange={handleChange}
                onBlur={() => helpers.setTouched(true)}
                getOptionLabel={(option) => option.label} // Get the label of each option
                getOptionValue={(option) => option.value} // Get the value of each option
                isMulti={props.isMulti}

            />
            <ErrorMessage name={field.name} component="div" className="error" />
        </>
    );
};

export default SelectField;

