import React from "react";
import { Handle, Position } from 'reactflow';
import { Link } from "react-router-dom";

const StartNode = ({ data, isConnectable }) => {

    return (
        <>
            <div className="startNodeWrap">
                <div className="startCard">
                    <div className="labelWrap">
                        <h4>{data.label}</h4>
                    </div>
                </div>
                <Handle
                    type="source"
                    position={Position.Bottom}
                    id="a"
                    color="#FFFFFF"
                    style={{backgroundColor:"#1944F1",height: '10px',
                    width: '10px',}}
                    // style={{ top: '50%', transform: 'translateY(-50%)' }}
                    isConnectable={isConnectable}
                />
            </div>
        </>
    )
}
export default StartNode