import React, { useState, useEffect } from 'react';
import Form from 'react-bootstrap/Form';
import Header from '../../Components/Common/Header';
import Sidebar from '../../Components/Common/Sidebar';
import ProductImg1 from '../../assets/img/product.jpeg';
import { get_all_products } from '../../services/products_services'
import ItemCard from './ItemCard';
import EditProduct from './EditProduct';
import AddNewProduct from './AddNewProducts';

const Product = () => {
    const [isOpen, setIsOpen] = useState(true);
    const [isEditSidebarOpen, setIsEditSidebarOpen] = useState(false);
    const [isAddSidebarOpen, setIsAddSidebarOpen] = useState(false);
    const [isLoading, setIsLoading] = useState(true)
    const [Error, setError] = useState([]);
    const [Data, setData] = useState([]);
    const [EditItemData, setEditData] = useState([]);

    const [isGridView, setIsGridView] = useState(true);

    const handleGridView = () => setIsGridView(true);
    const handleListView = () => setIsGridView(false);

    const cardFor = 'category';

    const toggleSidebar = () => {
        setIsOpen(!isOpen);
    };
    const toggleEditSidebar = (edit_item) => {
        setIsEditSidebarOpen(!isEditSidebarOpen);
        setEditData(edit_item);
    };
    const toggleAddNewSidebar = () => {
        setIsAddSidebarOpen(!isAddSidebarOpen);
    };

    useEffect(() => {
        fetchData();
    }, []);

    const fetchData = async () => {
        try {
            const response = await get_all_products();
            if (response) {
                setData(response);
            }
            else {
                setError(response.message)
            }

        } catch (error) {
            console.error('Error fetching data:', error);
            setError(error.message)

        }
        setIsLoading(false);

    };


    return (
        <div className={isOpen ? "app-container sidebar-open" : "app-container"}>
            <Header />
            <Sidebar isOpen={isOpen} toggleSidebar={toggleSidebar} />
            <main className="content">
                <div className='productListWrap'>
                    <div className='topFilterWrap'>
                        <div className='container'>
                            <div className='row align-items-center'>
                                <div className='col-md-5'>
                                    <div className='leftWrap'>
                                        <div className='arrowIco'>
                                            <button className='btn'>
                                                <svg width="6" height="9" viewBox="0 0 6 9" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M4.99301 0.0371298C5.14218 0.035867 5.28586 0.093251 5.39301 0.197042C5.50368 0.303532 5.56785 0.449265 5.57159 0.602804C5.57534 0.756343 5.51834 0.905352 5.41299 1.01711L2.41299 4.19704L5.43301 7.68703C5.53313 7.80402 5.58298 7.95583 5.57175 8.1094C5.56051 8.26296 5.4891 8.40593 5.37302 8.5071C5.25603 8.60722 5.10422 8.65701 4.95065 8.64577C4.79708 8.63453 4.65417 8.56312 4.55301 8.44704L0.843018 4.16701L4.573 0.227071C4.62505 0.166847 4.68956 0.118522 4.76208 0.0857138C4.83461 0.0529058 4.91342 0.0364588 4.99301 0.0371298Z" fill="#868C9C" />
                                                </svg>
                                            </button>
                                        </div>
                                        <h2>Product</h2>
                                        <form>
                                            <div className='searchWrap'>
                                                <input type='text' placeholder='Search Product' />
                                                <button className='btn'>
                                                    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <path d="M16.28 14.3969L12.93 11.0468C13.4642 10.3646 13.8138 9.55657 13.9451 8.70014C14.0765 7.84371 13.9851 6.96786 13.68 6.15693C13.6437 6.04603 13.5968 5.9388 13.54 5.83686C13.0012 4.70286 12.06 3.80968 10.8994 3.331C9.7387 2.85233 8.44149 2.82252 7.25995 3.24702C7.14995 3.28702 7.02998 3.33691 6.91998 3.38691C5.7714 3.91899 4.8635 4.86174 4.37494 6.02949C3.88637 7.19724 3.85249 8.5055 4.27997 9.69697C4.31808 9.8104 4.3649 9.92081 4.41998 10.027C4.82339 10.9001 5.46789 11.6395 6.27753 12.1586C7.08716 12.6777 8.0283 12.9548 8.99005 12.957C10.0939 12.9581 11.1668 12.5922 12.04 11.9169L15.4 15.277L16.28 14.3969ZM6.34003 10.6169C5.99046 10.2699 5.71317 9.85718 5.52435 9.40229C5.33553 8.9474 5.23895 8.45951 5.24005 7.96699C5.23895 7.47447 5.33553 6.98658 5.52435 6.53169C5.71317 6.07679 5.99046 5.6638 6.34003 5.31684C6.68699 4.96727 7.09986 4.69023 7.55475 4.50141C8.00964 4.31259 8.49753 4.21589 8.99005 4.21699C9.48258 4.21583 9.97033 4.31234 10.4252 4.50117C10.8801 4.68999 11.293 4.96723 11.64 5.31684C12.1022 5.77798 12.4365 6.35149 12.61 6.9809C12.7836 7.61031 12.7905 8.27406 12.6299 8.90693C12.5472 9.24889 12.4124 9.57604 12.23 9.8769C11.897 10.4399 11.423 10.9065 10.8549 11.2309C10.2868 11.5553 9.64421 11.7263 8.99005 11.727C7.99684 11.7116 7.04774 11.3139 6.34003 10.6169Z" fill="#919191" />
                                                    </svg>
                                                </button>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                                <div className='col-md-7'>
                                    <div className='rightWrap'>
                                        <ul>
                                        <li>
                                                <div className='viewWrapCta'>
                                                    <button className={`btn btnGrid ${isGridView ? 'active' : ''}`}  onClick={handleGridView}>
                                                        <svg xmlns="http://www.w3.org/2000/svg" version="1.1" width="16" height="16" x="0" y="0" viewBox="0 0 64 64"><g><path d="M19.7 26.2H7c-3.2 0-5.8-2.6-5.8-5.8V7.7c0-3.2 2.6-5.8 5.8-5.8h12.7c3.2 0 5.8 2.6 5.8 5.8v12.7c0 3.2-2.6 5.8-5.8 5.8zM7 5.4c-1.2 0-2.3 1-2.3 2.3v12.7c0 1.2 1 2.3 2.3 2.3h12.7c1.2 0 2.3-1 2.3-2.3V7.7c0-1.2-1-2.3-2.3-2.3zM57 26.2H44.3c-3.2 0-5.8-2.6-5.8-5.8V7.7c0-3.2 2.6-5.8 5.8-5.8H57c3.2 0 5.8 2.6 5.8 5.8v12.7c0 3.2-2.6 5.8-5.8 5.8zM44.3 5.4c-1.2 0-2.3 1-2.3 2.3v12.7c0 1.2 1 2.3 2.3 2.3H57c1.2 0 2.3-1 2.3-2.3V7.7c0-1.2-1-2.3-2.3-2.3zM19.7 62.1H7c-3.2 0-5.8-2.6-5.8-5.8V43.6c0-3.2 2.6-5.8 5.8-5.8h12.7c3.2 0 5.8 2.6 5.8 5.8v12.7c0 3.2-2.6 5.8-5.8 5.8zM7 41.3c-1.2 0-2.3 1-2.3 2.3v12.7c0 1.2 1 2.3 2.3 2.3h12.7c1.2 0 2.3-1 2.3-2.3V43.6c0-1.2-1-2.3-2.3-2.3zM57 62.1H44.3c-3.2 0-5.8-2.6-5.8-5.8V43.6c0-3.2 2.6-5.8 5.8-5.8H57c3.2 0 5.8 2.6 5.8 5.8v12.7c0 3.2-2.6 5.8-5.8 5.8zM44.3 41.3c-1.2 0-2.3 1-2.3 2.3v12.7c0 1.2 1 2.3 2.3 2.3H57c1.2 0 2.3-1 2.3-2.3V43.6c0-1.2-1-2.3-2.3-2.3z" fill="#939393" opacity="1" data-original="#939393"></path></g></svg>
                                                    </button>
                                                    <button className={`btn btnList ${!isGridView ? 'active' : ''}`}  onClick={handleListView}>
                                                        <svg xmlns="http://www.w3.org/2000/svg" version="1.1" width="16" height="16" x="0" y="0" viewBox="0 0 512 512"><g><path d="M487.938 0H24.062C10.875 0 .125 10.734.125 23.953v88.969c0 13.188 10.75 23.938 23.938 23.938h463.875c13.188 0 23.938-10.75 23.938-23.938V23.953C511.875 10.734 501.125 0 487.938 0zm5.718 118.641H18.344V18.234h475.312zM487.938 187.547H24.062c-13.188 0-23.938 10.75-23.938 23.953v88.984c0 13.203 10.75 23.938 23.938 23.938h463.875c13.188 0 23.938-10.734 23.938-23.922v-89c0-13.203-10.75-23.953-23.937-23.953zm5.718 118.656H18.344V205.781h475.312zM487.938 375.125H24.062c-13.188 0-23.938 10.734-23.938 23.953v88.969C.125 501.25 10.875 512 24.062 512h463.875c13.188 0 23.938-10.75 23.938-23.953v-88.969c0-13.219-10.75-23.953-23.937-23.953zm5.718 118.641H18.344V393.359h475.312z" fill='#939393'></path></g></svg>
                                                    </button>
                                                </div>
                                            </li>
                                            <li>
                                                <button className='btn'>Bestseller</button>
                                            </li>
                                            <li>
                                                <button className='btn'>Rating 4.0+</button>
                                            </li>
                                            <li>
                                                <Form.Select aria-label="Sort by">
                                                    <option>Sort by</option>
                                                    <option value="1">One</option>
                                                    <option value="2">Two</option>
                                                    <option value="3">Three</option>
                                                </Form.Select>
                                            </li>
                                            <li>
                                                <button className='btn'>
                                                    Filter
                                                    <svg xmlns="http://www.w3.org/2000/svg" version="1.1" width="14" height="14" x="0" y="0" viewBox="0 0 24 24"><g><path d="M22 19a1 1 0 0 1-1 1h-9.184a2.982 2.982 0 0 1-5.632 0H3a1 1 0 0 1 0-2h3.184a2.982 2.982 0 0 1 5.632 0H21a1 1 0 0 1 1 1Zm-1-8h-2.184a2.982 2.982 0 0 0-5.632 0H3a1 1 0 0 0 0 2h10.184a2.982 2.982 0 0 0 5.632 0H21a1 1 0 0 0 0-2ZM3 6h5.184a2.982 2.982 0 0 0 5.632 0H21a1 1 0 0 0 0-2h-7.184a2.982 2.982 0 0 0-5.632 0H3a1 1 0 0 0 0 2Z" fill="#989999" opacity="1" data-original="#989999"></path></g></svg>
                                                </button>
                                            </li>
                                        </ul>
                                        <div className='ctaWrap'>
                                            <button className='btn' onClick={toggleAddNewSidebar}>
                                                <div className='icoWrap'>
                                                    <svg width="9" height="8" viewBox="0 0 9 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <path d="M8.37305 4.5769H5.14307V7.84692H3.85303V4.5769H0.643066V3.40698H3.85303V0.116943H5.14307V3.40698H8.37305V4.5769Z" fill="#5F2DF0" />
                                                    </svg>
                                                </div>
                                                Add New Product
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='sortingWrap'>
                        <div className='row'>
                            <div className='col-md-12'>
                                <ul>
                                    <li className='active'>
                                        <button className='btn'>All</button>
                                    </li>
                                    <li>
                                        <button className='btn'>Breakfast</button>
                                    </li>
                                    <li>
                                        <button className='btn'>Starter</button>
                                    </li>
                                    <li>
                                        <button className='btn'>Lunch</button>
                                    </li>
                                    <li>
                                        <button className='btn'>Beverages</button>
                                    </li>
                                    <li>
                                        <button className='btn'>Rating 4.0+</button>
                                    </li>
                                    <li>
                                        <button className='btn'>Bestseller</button>
                                    </li>
                                    <li>
                                        <button className='btn'>Soups</button>
                                    </li>
                                    <li>
                                        <button className='btn'>Rice</button>
                                    </li>
                                    <li>
                                        <button className='btn'>Drinks</button>
                                    </li>
                                    <li>
                                        <button className='btn'>Salads</button>
                                    </li>
                                    <li>
                                        <button className='btn'>Chicken</button>
                                    </li>
                                    <li>
                                        <button className='btn'>Sea Foods</button>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div className='container-fluid'>
                        <div className='row'>
                            <div className='col-md-12'>
                                <div className='productListing'>

                                    <div className='row'>

                                        <div className={(isEditSidebarOpen || isAddSidebarOpen) ? 'col-md-8 col-xl-9' : 'col-md-12 col-xl-12'}>
                                            {isGridView ? (
                                                <div className='row'>
                                                    {Data &&
                                                        Data.map((item, i) => {
                                                            return (
                                                                <div className={(isEditSidebarOpen || isAddSidebarOpen) ? 'col-md-3  padLtRt' : 'col-md-2 padLtRt'} key={i}>
                                                                    <ItemCard
                                                                        toggleEditSidebar={toggleEditSidebar}
                                                                        item={item}
                                                                        cardFor='category'
                                                                        isGridView={isGridView}
                                                                    />
                                                                </div>
                                                            );
                                                        })}

                                                </div>
                                            ) : (
                                                <div className='tblWrap'>
                                                    <table className='table'>
                                                        <thead>
                                                            <tr>
                                                                <th>Image</th>
                                                                <th>Name</th>
                                                                <th>Ordered</th>
                                                                <th>Rating</th>
                                                                <th>Description</th>
                                                                {cardFor === 'category' && <th>Actions</th>}
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {Data && Data.map((item, i) => (
                                                                <ItemCard
                                                                    key={i}
                                                                    toggleEditSidebar={toggleEditSidebar}
                                                                    item={item}
                                                                    cardFor='category'
                                                                    isGridView={isGridView}
                                                                />
                                                            ))}
                                                        </tbody>
                                                    </table>
                                                </div>
                                            )}
                                        </div>


                                        {isEditSidebarOpen && (
                                            <div className='col-md-4 col-xl-3'>
                                                <EditProduct
                                                    toggleEditSidebar={toggleEditSidebar}
                                                    item_data={EditItemData}

                                                />
                                            </div>
                                        )}
                                        {isAddSidebarOpen && (
                                            <div className='col-md-4 col-xl-3'>
                                                <AddNewProduct
                                                    toggleAddNewSidebar={toggleAddNewSidebar}
                                                    updateProductPage={fetchData} // Pass the update function here                                                  

                                                />
                                            </div>
                                        )}

                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </main>
        </div>
    );
};

export default Product;

