import axios from "axios";
import { handleErrorResponseAxios, handleResponseAxios } from "./common-handler";
import {SAVE_SMS_FLOW, GET_SMS_FLOW_LIST_API, GET_SMS_FLOW_BY_ID} from "../utility/ConstantAPI";

export async function saveSmsFlow(values ,onSuccess, onError ) {
    // let token = get_auth_token()
    var url = SAVE_SMS_FLOW;
    let axiosConfig = {
        headers: {
            "Content-Type": "application/json",
            // Authorization: `Bearer ${token}`,
        },
    };

    try {
        let response = await axios.post(url, values, axiosConfig);
        onSuccess(handleResponseAxios(response));
    } catch (error) {
        onError(handleErrorResponseAxios(error));
    }
}

export async function get_sms_flow_list(){
    // let token = get_auth_token();
    let axiosConfig = {
        headers: {
            "Content-Type": "application/json",
            // Authorization: `Bearer ${token}`,
        },
    };
    var url = GET_SMS_FLOW_LIST_API
    return axios
        .get(url, axiosConfig)
        .then(handleResponseAxios)
        .catch(function (error) {
            return handleErrorResponseAxios(error)
    });
}

export async function get_sms_flow_by_id(flowId){
    // let token = get_auth_token();
    let axiosConfig = {
        headers: {
            "Content-Type": "application/json",
            // Authorization: `Bearer ${token}`,
        },
    };
    var url = GET_SMS_FLOW_BY_ID(flowId)
    return axios
        .get(url, axiosConfig)
        .then(handleResponseAxios)
        .catch(function (error) {
            return handleErrorResponseAxios(error)
    });
}