import React from 'react';
import Select, { components } from 'react-select';

// Custom option component
const CustomOption = (props) => {
    const { data, innerRef, innerProps } = props;
    return (
        <div ref={innerRef} {...innerProps} style={{ display: 'flex', alignItems: 'center' }}>
            {data.icon && (
                <span style={{ marginRight: '8px' }}>
                    {data.icon}
                </span>
            )}
            {data.label}
        </div>
    );
};

// Custom dropdown indicator component
const DropdownIndicator = (props) => (
    <components.DropdownIndicator {...props}>
        <svg width="10" height="5" viewBox="0 0 10 5" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M0 0.5L5 5L10 0.5H0Z" fill="black"/>
        </svg>
    </components.DropdownIndicator>
);

const CustomSelect = ({ value, onChange, options }) => {
    const customStyles = {
        control: (provided) => ({
            ...provided,
            display: 'flex',
            alignItems: 'center',
            position: 'relative',
        }),
        dropdownIndicator: (provided) => ({
            ...provided,
            display: 'none', // Hide default indicator
        }),
        indicatorSeparator: (provided) => ({
            ...provided,
            display: 'none',
        }),
    };

    return (
        <div style={{ position: 'relative' }}>
        <Select
            value={value}
            onChange={onChange}
            options={options}
            styles={customStyles}
            components={{ DropdownIndicator, Option: CustomOption }}
        />
            <div style={{
                position: 'absolute',
                right: '10px',
                top: '50%',
                transform: 'translateY(-50%)',
                pointerEvents: 'none',
            }}>
                <svg width="10" height="5" viewBox="0 0 10 5" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M0 0.5L5 5L10 0.5H0Z" fill="black"/>
                </svg>
            </div>
        </div>
    );
};

export default CustomSelect;

