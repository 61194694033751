import React, { useState, useEffect } from 'react';
import { Modal, Button, Form } from 'react-bootstrap';
import axios from 'axios';
import {ADD_USER, USER_ROLES} from '../../utility/ConstantAPI';

const AddUserModal = ({ show, handleClose, tenantId }) => {
  const [user, setUser] = useState({
    name: '',
    email: '',
    mobile: '',
    gender: '',
    password: '',
    role_id: '',       // Change role to role_id
    tenant_id: tenantId,
    address: '',
    account_status: ''
  });

  const [roles, setRoles] = useState([]);

  useEffect(() => {
    // Fetch roles from the database
    const fetchRoles = async () => {
      try {
        const url = USER_ROLES
        const response = await axios.get(url);
        setRoles(response.data);
      } catch (error) {
        console.error('Error fetching roles:', error);
      }
    };

    fetchRoles();
  }, []);

  useEffect(() => {
    setUser((prevUser) => ({
      ...prevUser,
      tenant_id: tenantId
    }));
  }, [tenantId]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setUser({
      ...user,
      [name]: value
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const url = ADD_USER
      const userResponse = await axios.post(url, user);

      // Insert into tenant_admin table
    //   const tenantAdminResponse = await axios.post('http://localhost:8080/api/user/add-tenant-admin', {
    //     user_id: userResponse.data.id,  // Assuming the created user ID is in userResponse.data.id
    //     tenant_id: tenantId
    //   });
    //   console.log('Tenant admin created:', tenantAdminResponse.data);

      handleClose();
    } catch (error) {
      console.error('Error creating user or tenant admin:', error);
    }
  };

  return (
    <Modal show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>Add User</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form onSubmit={handleSubmit}>
          <Form.Group controlId="formName">
            <Form.Label>Name</Form.Label>
            <Form.Control type="text" name="name" value={user.name} onChange={handleChange} required />
          </Form.Group>
          <Form.Group controlId="formEmail">
            <Form.Label>Email</Form.Label>
            <Form.Control type="email" name="email" value={user.email} onChange={handleChange} required />
          </Form.Group>
          <Form.Group controlId="formMobile">
            <Form.Label>Mobile</Form.Label>
            <Form.Control type="text" name="mobile" value={user.mobile} onChange={handleChange} required />
          </Form.Group>
          <Form.Group controlId="formGender">
            <Form.Label>Gender</Form.Label>
            <Form.Control type="text" name="gender" value={user.gender} onChange={handleChange} required />
          </Form.Group>
          <Form.Group controlId="formPassword">
            <Form.Label>Password</Form.Label>
            <Form.Control type="password" name="password" value={user.password} onChange={handleChange} required />
          </Form.Group>
          <Form.Group controlId="formRoleId">
            <Form.Label>Role</Form.Label>
            <Form.Control as="select" name="role_id" value={user.role_id} onChange={handleChange} required>
              <option value="">Select a role</option>
              {roles.map((role) => (
                <option key={role.id} value={role.id}>
                  {role.name}
                </option>
              ))}
            </Form.Control>
          </Form.Group>
          <Form.Group controlId="formAddress">
            <Form.Label>Address</Form.Label>
            <Form.Control type="text" name="address" value={user.address} onChange={handleChange} required />
          </Form.Group>
          <Form.Group controlId="formAccountStatus">
            <Form.Label>Account Status</Form.Label>
            <Form.Control type="text" name="account_status" value={user.account_status} onChange={handleChange} required />
          </Form.Group>
          <Button variant="primary" type="submit">
            Add User
          </Button>
        </Form>
      </Modal.Body>
    </Modal>
  );
};

export default AddUserModal;
