import React, { useState, useEffect } from 'react';
import { Form, Button, Table, Container, Row, Col } from 'react-bootstrap';
import axios from 'axios';
import {GET_TENANTS, ADD_TENANT} from '../../utility/ConstantAPI'

const TenantForm = () => {
  const [tenant, setTenant] = useState({
    name: '',
    description: '',
    type: '',
    is_active: true,
    subscription_tier_id: '1',
    mobile: '',
    created_by: '',
    updated_by: ''
  });

  const [tenants, setTenants] = useState([]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setTenant({
      ...tenant,
      [name]: value
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const url = ADD_TENANT
      const response = await axios.post(url, tenant);
      setTenant({
        name: '',
        description: '',
        type: '',
        is_active: true,
        subscription_tier_id: '1',
        mobile: '',
        created_by: '',
        updated_by: ''
      });
      fetchTenants(); // Fetch tenants after adding a new one
    } catch (error) {
      console.error('Error creating tenant:', error);
    }
  };

  const fetchTenants = async () => {
    try {
    const url = GET_TENANTS
      const response = await axios.get(url);
      setTenants(response.data);
    } catch (error) {
      console.error('Error fetching tenants:', error);
    }
  };

  useEffect(() => {
    fetchTenants();
  }, []);

  return (
    <Container>
      <Row className="mb-4">
        <Col>
          <h2>Create Tenant</h2>
          <Form onSubmit={handleSubmit}>
            <Form.Group controlId="formName" className="mb-3">
              <Form.Label>Name</Form.Label>
              <Form.Control type="text" name="name" value={tenant.name} onChange={handleChange} required />
            </Form.Group>
            <Form.Group controlId="formDescription" className="mb-3">
              <Form.Label>Description</Form.Label>
              <Form.Control as="textarea" name="description" value={tenant.description} onChange={handleChange} required />
            </Form.Group>
            <Form.Group controlId="formType" className="mb-3">
              <Form.Label>Type</Form.Label>
              <Form.Control type="text" name="type" value={tenant.type} onChange={handleChange} required />
            </Form.Group>
            <Form.Group controlId="formActive" className="mb-3">
              <Form.Check type="checkbox" label="Active" name="is_active" checked={tenant.is_active} onChange={(e) => setTenant({ ...tenant, is_active: e.target.checked })} />
            </Form.Group>
            <Form.Group controlId="formMobile" className="mb-3">
              <Form.Label>Mobile</Form.Label>
              <Form.Control type="text" name="mobile" value={tenant.mobile} onChange={handleChange} required />
            </Form.Group>
            <Form.Group controlId="formCreatedBy" className="mb-3">
              <Form.Label>Created By</Form.Label>
              <Form.Control type="text" name="created_by" value={tenant.created_by} onChange={handleChange} required />
            </Form.Group>
            <Form.Group controlId="formUpdatedBy" className="mb-3">
              <Form.Label>Updated By</Form.Label>
              <Form.Control type="text" name="updated_by" value={tenant.updated_by} onChange={handleChange} required />
            </Form.Group>
            <Button variant="primary" type="submit">
              Create Tenant
            </Button>
          </Form>
        </Col>
      </Row>
      <Row>
        <Col>
          <h2>All Tenants</h2>
          <Table striped bordered hover>
            <thead>
              <tr>
                <th>Name</th>
                <th>Description</th>
                <th>Type</th>
                <th>Active</th>
                <th>Mobile</th>
                <th>Created By</th>
                <th>Updated By</th>
              </tr>
            </thead>
            <tbody>
              {tenants.map((tenant) => (
                <tr key={tenant.id}>
                  <td>{tenant.name}</td>
                  <td>{tenant.description}</td>
                  <td>{tenant.type}</td>
                  <td>{tenant.is_active ? 'Yes' : 'No'}</td>
                  <td>{tenant.mobile}</td>
                  <td>{tenant.created_by}</td>
                  <td>{tenant.updated_by}</td>
                </tr>
              ))}
            </tbody>
          </Table>
        </Col>
      </Row>
    </Container>
  );
};

export default TenantForm;
