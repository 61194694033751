import React, { useState, useEffect } from 'react';
import Form from 'react-bootstrap/Form';
import { CustomScroll } from "react-custom-scroll";
import Header from '../../Components/Common/Header';
import Sidebar from '../../Components/Common/Sidebar';
import { useNavigate } from 'react-router-dom';

const ChooseTemplate = () => {
    const [isOpen, setIsOpen] = useState(false);
    const navigate = useNavigate()
    const toggleSidebar = () => {
        setIsOpen(!isOpen);
    };


    return (
        <div className={isOpen ? "app-container sidebar-open" : "app-container"}>
            <Header toggleSidebar={toggleSidebar} />
            {/* <Sidebar isOpen={isOpen} /> */}
            <main className="content chooseTemplatePage">
                <div className='chooseTemplate'>
                    <div className='topDataWrap'>
                        <div className='container-fluid'>
                            <div className='row align-items-center'>
                                <div className='col-md-5'>
                                    <div className='titleWrap'>
                                        <div className='icoWrap'>
                                            <svg width="27" height="19" viewBox="0 0 27 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M18.478 3.91047C18.517 3.87119 18.5637 3.84057 18.6152 3.82008C18.6666 3.79958 18.7217 3.79001 18.777 3.79169C18.8323 3.79337 18.8868 3.80611 18.9369 3.82968C18.987 3.85325 19.0317 3.8868 19.0683 3.92837C20.4084 5.47858 21.145 7.46001 21.1431 9.50918C21.146 11.55 20.4161 13.5241 19.0862 15.0721C19.0496 15.1137 19.0048 15.1472 18.9547 15.1708C18.9046 15.1943 18.8503 15.2071 18.7949 15.2088C18.7396 15.2104 18.6844 15.2009 18.633 15.1804C18.5816 15.1599 18.5349 15.1288 18.4959 15.0895C18.4203 15.0145 18.3764 14.9135 18.3731 14.807C18.3697 14.7006 18.4073 14.5971 18.478 14.5175C19.6755 13.1176 20.3294 11.3335 20.3203 9.49128C20.32 7.64218 19.6605 5.85365 18.4601 4.44715C18.4232 4.41233 18.394 4.37009 18.3747 4.32313C18.3553 4.27618 18.3462 4.22586 18.3479 4.1751C18.3495 4.12434 18.362 4.07437 18.3845 4.02881C18.4069 3.98325 18.4388 3.94275 18.478 3.91047Z" fill="black" />
                                                <path d="M22.5918 0.118526C22.6309 0.0794858 22.6776 0.0489151 22.7291 0.0285694C22.7805 0.00822368 22.8355 -0.00148971 22.8907 0.000185015C22.946 0.00185974 23.0004 0.014755 23.0505 0.0381764C23.1005 0.0615978 23.1453 0.0950907 23.182 0.13643C25.4895 2.71251 26.7636 6.0505 26.7593 9.50895C26.7668 12.9594 25.4992 16.2909 23.1999 18.8636C23.1632 18.9049 23.1184 18.9384 23.0683 18.9618C23.0182 18.9852 22.9639 18.9981 22.9086 18.9998C22.8534 19.0015 22.7983 18.9918 22.7469 18.9714C22.6955 18.9511 22.6488 18.9205 22.6097 18.8815C22.5341 18.8064 22.4902 18.7054 22.4869 18.5989C22.4835 18.4925 22.5211 18.3886 22.5918 18.309C24.7536 15.8883 25.9448 12.7544 25.9365 9.50895C25.9418 6.25543 24.7441 3.11494 22.5739 0.691017C22.5032 0.61139 22.4656 0.5075 22.469 0.40106C22.4723 0.29462 22.5162 0.193583 22.5918 0.118526Z" fill="black" />
                                                <path d="M8.30186 3.91047C8.26283 3.87119 8.21612 3.84057 8.16468 3.82008C8.11325 3.79958 8.05818 3.79001 8.00284 3.79169C7.9475 3.79337 7.89306 3.80611 7.84296 3.82968C7.79286 3.85325 7.74814 3.8868 7.71157 3.92837C6.37144 5.47858 5.63483 7.46001 5.63673 9.50918C5.63383 11.55 6.36375 13.5241 7.69367 15.0721C7.73024 15.1137 7.77501 15.1472 7.82511 15.1708C7.87521 15.1943 7.92959 15.2071 7.98493 15.2088C8.04028 15.2104 8.0954 15.2009 8.14683 15.1804C8.19827 15.1599 8.24493 15.1288 8.28395 15.0895C8.3595 15.0145 8.40344 14.9135 8.40677 14.807C8.4101 14.7006 8.37257 14.5971 8.30186 14.5175C7.10432 13.1176 6.45035 11.3335 6.45949 9.49128C6.45976 7.64218 7.11933 5.85365 8.31971 4.44715C8.35449 4.41112 8.38169 4.36856 8.39967 4.32182C8.41765 4.27509 8.42607 4.22515 8.4244 4.1751C8.42273 4.12505 8.41101 4.076 8.38996 4.03056C8.36891 3.98512 8.33896 3.9441 8.30186 3.91047Z" fill="black" />
                                                <path d="M4.16757 0.118526C4.12842 0.0794858 4.0817 0.0489151 4.03029 0.0285694C3.97888 0.00822368 3.92387 -0.00148971 3.86861 0.000185015C3.81334 0.00185974 3.75897 0.014755 3.70889 0.0381764C3.6588 0.0615978 3.61406 0.0950907 3.57734 0.13643C1.26981 2.71251 -0.00424518 6.0505 3.2968e-05 9.50895C-0.007453 12.9594 1.26017 16.2909 3.55943 18.8636C3.59615 18.9049 3.64095 18.9384 3.69104 18.9618C3.74112 18.9852 3.79544 18.9981 3.8507 18.9998C3.90597 19.0015 3.96103 18.9918 4.01244 18.9714C4.06385 18.9511 4.11057 18.9205 4.14972 18.8815C4.22526 18.8064 4.26921 18.7054 4.27254 18.5989C4.27586 18.4925 4.23828 18.3886 4.16757 18.309C2.00572 15.8883 0.814529 12.7544 0.822798 9.50895C0.817568 6.25543 2.01522 3.11494 4.18547 0.691017C4.22241 0.652536 4.25128 0.606744 4.27041 0.556956C4.28954 0.507167 4.29855 0.454371 4.29688 0.40106C4.29522 0.347749 4.28289 0.294971 4.26069 0.246474C4.23849 0.197977 4.20683 0.154626 4.16757 0.118526Z" fill="black" />
                                                <path d="M10.7148 9.50899C10.7153 8.77859 11.0057 8.07829 11.5222 7.56182C12.0386 7.04535 12.739 6.75487 13.4694 6.75439C14.1998 6.75487 14.9001 7.04535 15.4166 7.56182C15.9331 8.07829 16.2235 8.77859 16.2239 9.50899C16.2235 10.2394 15.9331 10.9397 15.4166 11.4562C14.9001 11.9726 14.1998 12.2631 13.4694 12.2636C12.739 12.2631 12.0386 11.9726 11.5222 11.4562C11.0057 10.9397 10.7153 10.2394 10.7148 9.50899ZM15.3833 9.50899C15.3823 8.99695 15.1785 8.50599 14.8164 8.14392C14.4543 7.78185 13.9635 7.57805 13.4515 7.5771C12.9394 7.57805 12.4486 7.78185 12.0866 8.14392C11.7245 8.50599 11.5207 8.99695 11.5198 9.50899C11.5207 10.021 11.7245 10.512 12.0866 10.8741C12.4486 11.2361 12.9394 11.4399 13.4515 11.4409C13.7054 11.4418 13.9571 11.3922 14.1919 11.2955C14.4267 11.1987 14.64 11.0567 14.8196 10.8771C14.9991 10.6975 15.1414 10.484 15.2381 10.2492C15.3349 10.0144 15.3842 9.76293 15.3833 9.50899Z" fill="black" />
                                            </svg>
                                        </div>
                                        <h2>Create Template</h2>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='tempOptionWrap'>
                        <div className='container-fluid'>
                            <div className='row'>
                                <div className='col-md-12'>
                                    <div className='ttlWrap'>
                                        <h3>Choose how to create a template</h3>
                                    </div>
                                </div>
                            </div>
                            <div className='row'>
                                <div className='col-md-12'>
                                    <ul>
                                        <li>
                                            <div className='itemWrap'>
                                                <div className='imgWrap'></div>
                                                <div className='dataWrap'>
                                                    <div className='topData'>
                                                        <h2>Use a blank template</h2>
                                                        <p>Create your template from scratch. Once you’ve finished creating your template, it must be submitted for review</p>
                                                    </div>
                                                    <div className='ctaWrap'>
                                                        <button className='btn' onClick={() => navigate('/CreateMessageTemplate')}>+ Create new template</button>
                                                    </div>
                                                </div>
                                            </div>
                                        </li>
                                        <li>
                                            <div className='itemWrap'>
                                                <div className='imgWrap'></div>
                                                <div className='dataWrap'>
                                                    <div className='topData'>
                                                        <h2>Browse the WhatsApp template library</h2>
                                                        <p>Get started faster with pre-written utility templates. Use a template as is and it will be available to send immediately, or customize the content to your needs.</p>
                                                    </div>
                                                    <div className='ctaWrap'>
                                                        <button className='btn'>
                                                            <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                <path d="M15 15V17C15 17.5304 14.7893 18.0391 14.4142 18.4142C14.0391 18.7893 13.5304 19 13 19H3C2.46957 19 1.96086 18.7893 1.58579 18.4142C1.21071 18.0391 1 17.5304 1 17V7C1 6.46957 1.21071 5.96086 1.58579 5.58579C1.96086 5.21071 2.46957 5 3 5H5M5 3C5 2.46957 5.21071 1.96086 5.58579 1.58579C5.96086 1.21071 6.46957 1 7 1H17C17.5304 1 18.0391 1.21071 18.4142 1.58579C18.7893 1.96086 19 2.46957 19 3V13C19 13.5304 18.7893 14.0391 18.4142 14.4142C18.0391 14.7893 17.5304 15 17 15H7C6.46957 15 5.96086 14.7893 5.58579 14.4142C5.21071 14.0391 5 13.5304 5 13V3Z" stroke="#1944F1" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                                                <path d="M12 9H8V7H10M12 9V11H14V9M12 9V7M12 9H14M14 9H16V7H12M14 9V7M12 7V5H10V7M12 7H10M10 7V9" stroke="#1944F1" stroke-width="0.8" stroke-linecap="round" stroke-linejoin="round" />
                                                            </svg>
                                                            Browse template
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </main>
        </div>
    );
};

export default ChooseTemplate;

