import React, { useState, useEffect } from 'react';
import Form from 'react-bootstrap/Form';
import BootstrapTable from 'react-bootstrap-table-next';
import Accordion from 'react-bootstrap/Accordion';
import Header from '../Common/Header';
import Sidebar from '../Common/Sidebar';
import { CustomScroll } from "react-custom-scroll";
import {get_all_orders} from '../../services/order-services'
import {customerFormatter, itemCount, formatDate} from './commonFormatters'

const Order = () => {
    const [isOpen, setIsOpen] = useState(true);
    const [isPaySumSidebarOpen, setIsPaySumSidebarOpen] = useState(false);
    const [orderData, setOrderData] = useState([]);
    const [Error, setError] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [selectedOrder, setSelectedOrder] = useState(null);

    const toggleSidebar = () => {
        setIsOpen(!isOpen);
    };
    const togglePaySumSidebar = () => {
        setIsPaySumSidebarOpen(!isPaySumSidebarOpen);
    };

    useEffect(() => {
        fetchData();
    }, []);

    const fetchData = async () => {
        try {            
            const response = await get_all_orders();
            if (response) {
                setOrderData(response);
            }
            else{
                setError(response.message)                
            }                 
                
        } catch (error) {          
            console.error('Error fetching data:', error);
            setError(error.message)
            
        }
        setIsLoading(false); 

    };

    const products = [
        { id: 24587548, name: 'Neeraj Pande', noOfItems: 4, price: 648, orderStatus:'Preparing', phoneNo:'9402354875', payStatus:'Pending',},
        { id: 24587548, name: 'Neeraj Pande', noOfItems: 4, price: 648, orderStatus:'Delivered', phoneNo:'9402354875', payStatus:'Paid',},
        { id: 24587548, name: 'Neeraj Pande', noOfItems: 4, price: 648, orderStatus:'Canceled', phoneNo:'9402354875', payStatus:'Pending',},
        { id: 24587548, name: 'Neeraj Pande', noOfItems: 4, price: 648, orderStatus:'Deliverd', phoneNo:'9402354875', payStatus:'Paidd',},
        { id: 24587548, name: 'Neeraj Pande', noOfItems: 4, price: 648, orderStatus:'Deliverd', phoneNo:'9402354875', payStatus:'Paidd',},
        { id: 24587548, name: 'Neeraj Pande', noOfItems: 4, price: 648, orderStatus:'Deliverd', phoneNo:'9402354875', payStatus:'Paidd',},
        { id: 24587548, name: 'Neeraj Pande', noOfItems: 4, price: 648, orderStatus:'Deliverd', phoneNo:'9402354875', payStatus:'Paidd',},
        { id: 24587548, name: 'Neeraj Pande', noOfItems: 4, price: 648, orderStatus:'Deliverd', phoneNo:'9402354875', payStatus:'Paidd',},
    ];
    const columns = [
        {dataField: 'id',  text: 'Order ID', }, 
        {dataField: 'name', text: 'Name', formatter: customerFormatter("name")}, 
        {dataField: 'noOfItems', text: 'Number of Items',  formatter: itemCount},  
        {dataField: 'total_amount', text: 'Total Amount'},
        {dataField: 'status', text: 'Order Status', formatter: orderStatusFormatter},
        {dataField: 'phoneNo', text: 'Phone Number', formatter: customerFormatter("whatsappNumber")},
        {dataField: 'transaction_status', text: 'Payment status'},
        { text: 'Action', formatter: actionFormatter }
    ];

    function orderStatusFormatter(cell, row) {
        // console.log("Cell value:", cell);
        let color = '';
        switch (cell) {
            case 'pending':
                color = '#E09320';
                break;
            case 'preparing':
                color = '#16A124';
                break;    
            case 'Delivered':
                color = '#16A124';
                break;
            case 'Canceled':
                color = '#E22929';
                break;
            default:
                color = '#000000';
                break;
        }
        // console.log("Color:", color);
        return <span style={{ color }}>{cell}</span>;
    }
    
    function payStatusFormattter(cell, row) {
        // console.log("Cell value:", cell);
        let color = '';
        switch (cell) {
            case 'pending':
                color = '#E09320';
                break;
            case 'Paid':
                color = '#16A124';
                break;
            case 'Canceled':
                color = '#E22929';
                break;
            default:
                color = '#000000';
                break;
        }
        // console.log("Color:", color);
        return <span style={{ color }}>{cell}</span>;
    }

    function handleEditAction(row) {
        setSelectedOrder(row);
        setIsPaySumSidebarOpen(true);
        // setIsPaySumSidebarOpen(!isPaySumSidebarOpen);

    }
    
    
    function actionFormatter(cell, row) {
        return (
            <>
                <button className='btnAction' onClick={() => handleAction(row)}>
                    <svg xmlns="http://www.w3.org/2000/svg" version="1.1" width="14" height="14" x="0" y="0" viewBox="0 0 519.643 519.643"><g><circle cx="259.823" cy="259.866" r="80" fill="#98A2B3" opacity="1" data-original="#98A2B3"></circle><path d="M511.673 237.706c-61.494-74.31-154.579-145.84-251.85-145.84-97.29 0-190.397 71.58-251.85 145.84-10.63 12.84-10.63 31.48 0 44.32 15.45 18.67 47.84 54.71 91.1 86.2 108.949 79.312 212.311 79.487 321.5 0 43.26-31.49 75.65-67.53 91.1-86.2 10.599-12.815 10.654-31.438 0-44.32zm-251.85-89.84c61.76 0 112 50.24 112 112s-50.24 112-112 112-112-50.24-112-112 50.24-112 112-112z" fill="#98A2B3" opacity="1" data-original="#98A2B3"></path></g></svg>
                </button>
                <button className='btnAction' onClick={() => handleEditAction(row)}>
                    <svg xmlns="http://www.w3.org/2000/svg" version="1.1" width="14" height="14" x="0" y="0" viewBox="0 0 492.493 492"><g><path d="M304.14 82.473 33.165 353.469a10.799 10.799 0 0 0-2.816 4.949L.313 478.973a10.716 10.716 0 0 0 2.816 10.136 10.675 10.675 0 0 0 7.527 3.114 10.6 10.6 0 0 0 2.582-.32l120.555-30.04a10.655 10.655 0 0 0 4.95-2.812l271-270.977zM476.875 45.523 446.711 15.36c-20.16-20.16-55.297-20.14-75.434 0l-36.949 36.95 105.598 105.597 36.949-36.949c10.07-10.066 15.617-23.465 15.617-37.715s-5.547-27.648-15.617-37.719zm0 0" fill="#98A2B3" opacity="1" data-original="#98A2B3"></path></g></svg>
                </button>
                <button className='btnAction' onClick={() => handleAction(row)}>
                    <svg xmlns="http://www.w3.org/2000/svg" version="1.1" width="14" height="14" x="0" y="0" viewBox="0 0 384 384"><g><path d="M64 341.333C64 364.907 83.093 384 106.667 384h170.667C300.907 384 320 364.907 320 341.333v-256H64v256zM266.667 21.333 245.333 0H138.667l-21.334 21.333H42.667V64h298.666V21.333z" fill="#98A2B3" opacity="1" data-original="#98A2B3"></path></g></svg>
                </button>
            </>
        );
    }
    
    function handleAction(row) {
        console.log('Action clicked for order ID:', row.id);
    }
    async function updateOrderStatus(id) {
        try {
            // Make API call to update order status
            const response = await fetch(`http://127.0.0.1:8080/api/orders/${id}/status`, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                   
                },
                body: JSON.stringify({
                    status: 'preparing' 
                }),
            });
    
            if (!response.ok) {
                throw new Error('Failed to update order status');
            }
    
            // API responds with updated order data
            const updatedOrder = await response.json();
            // console.log('Order updated successfully:', updatedOrder);
    
            // Optionally, update local state or re-fetch data
            // Example: refetch data to update the UI
            fetchData();  // fetchData updates orderData state
            setIsPaySumSidebarOpen(false);
        } catch (error) {
            console.error('Error updating order status:', error);
            // Handle error state or notify user
        }
    }

    const formattedDate = selectedOrder ? formatDate(selectedOrder.createdAt) : null;
    return (
        <div className={isOpen ? "app-container sidebar-open" : "app-container"}>
            <Header/>
            <Sidebar isOpen={isOpen} toggleSidebar={toggleSidebar} />
            <main className="content">
                <div className='productListWrap'>
                    <div className='topFilterWrap'>
                        <div className='container'>
                            <div className='row align-items-center'>
                                <div className='col-md-5'>
                                    <div className='leftWrap'>
                                        <div className='arrowIco'>
                                            <button className='btn'>
                                                <svg width="6" height="9" viewBox="0 0 6 9" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M4.99301 0.0371298C5.14218 0.035867 5.28586 0.093251 5.39301 0.197042C5.50368 0.303532 5.56785 0.449265 5.57159 0.602804C5.57534 0.756343 5.51834 0.905352 5.41299 1.01711L2.41299 4.19704L5.43301 7.68703C5.53313 7.80402 5.58298 7.95583 5.57175 8.1094C5.56051 8.26296 5.4891 8.40593 5.37302 8.5071C5.25603 8.60722 5.10422 8.65701 4.95065 8.64577C4.79708 8.63453 4.65417 8.56312 4.55301 8.44704L0.843018 4.16701L4.573 0.227071C4.62505 0.166847 4.68956 0.118522 4.76208 0.0857138C4.83461 0.0529058 4.91342 0.0364588 4.99301 0.0371298Z" fill="#868C9C" />
                                                </svg>
                                            </button>
                                        </div>
                                        <h2>Order</h2>
                                        <form>
                                            <div className='searchWrap'>
                                                <input type='text' placeholder='Search by costumer name' />
                                                <button className='btn'>
                                                    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <path d="M16.28 14.3969L12.93 11.0468C13.4642 10.3646 13.8138 9.55657 13.9451 8.70014C14.0765 7.84371 13.9851 6.96786 13.68 6.15693C13.6437 6.04603 13.5968 5.9388 13.54 5.83686C13.0012 4.70286 12.06 3.80968 10.8994 3.331C9.7387 2.85233 8.44149 2.82252 7.25995 3.24702C7.14995 3.28702 7.02998 3.33691 6.91998 3.38691C5.7714 3.91899 4.8635 4.86174 4.37494 6.02949C3.88637 7.19724 3.85249 8.5055 4.27997 9.69697C4.31808 9.8104 4.3649 9.92081 4.41998 10.027C4.82339 10.9001 5.46789 11.6395 6.27753 12.1586C7.08716 12.6777 8.0283 12.9548 8.99005 12.957C10.0939 12.9581 11.1668 12.5922 12.04 11.9169L15.4 15.277L16.28 14.3969ZM6.34003 10.6169C5.99046 10.2699 5.71317 9.85718 5.52435 9.40229C5.33553 8.9474 5.23895 8.45951 5.24005 7.96699C5.23895 7.47447 5.33553 6.98658 5.52435 6.53169C5.71317 6.07679 5.99046 5.6638 6.34003 5.31684C6.68699 4.96727 7.09986 4.69023 7.55475 4.50141C8.00964 4.31259 8.49753 4.21589 8.99005 4.21699C9.48258 4.21583 9.97033 4.31234 10.4252 4.50117C10.8801 4.68999 11.293 4.96723 11.64 5.31684C12.1022 5.77798 12.4365 6.35149 12.61 6.9809C12.7836 7.61031 12.7905 8.27406 12.6299 8.90693C12.5472 9.24889 12.4124 9.57604 12.23 9.8769C11.897 10.4399 11.423 10.9065 10.8549 11.2309C10.2868 11.5553 9.64421 11.7263 8.99005 11.727C7.99684 11.7116 7.04774 11.3139 6.34003 10.6169Z" fill="#919191" />
                                                    </svg>
                                                </button>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                                <div className='col-md-7'>
                                    <div className='rightWrap'>
                                        <ul>
                                            <li>
                                                <Form.Select aria-label="Sort by">
                                                    <option>Sort by</option>
                                                    <option value="1">One</option>
                                                    <option value="2">Two</option>
                                                    <option value="3">Three</option>
                                                </Form.Select>
                                            </li>
                                            <li>
                                                <button className='btn'>
                                                    Filter
                                                    <svg xmlns="http://www.w3.org/2000/svg" version="1.1" width="14" height="14" x="0" y="0" viewBox="0 0 24 24"><g><path d="M22 19a1 1 0 0 1-1 1h-9.184a2.982 2.982 0 0 1-5.632 0H3a1 1 0 0 1 0-2h3.184a2.982 2.982 0 0 1 5.632 0H21a1 1 0 0 1 1 1Zm-1-8h-2.184a2.982 2.982 0 0 0-5.632 0H3a1 1 0 0 0 0 2h10.184a2.982 2.982 0 0 0 5.632 0H21a1 1 0 0 0 0-2ZM3 6h5.184a2.982 2.982 0 0 0 5.632 0H21a1 1 0 0 0 0-2h-7.184a2.982 2.982 0 0 0-5.632 0H3a1 1 0 0 0 0 2Z" fill="#989999" opacity="1" data-original="#989999"></path></g></svg>
                                                </button>
                                            </li>
                                            <li>
                                                <Form.Select aria-label="Weekly">
                                                    <option>Weekly</option>
                                                    <option value="1">One</option>
                                                    <option value="2">Two</option>
                                                    <option value="3">Three</option>
                                                </Form.Select>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='container-fluid'>
                        <div className='row'>
                            <div className='col-md-12'>
                                <div className='orderTblWrap'>
                                    <div className='row'>
                                        <div className={isPaySumSidebarOpen ? 'col-md-8' : 'col-md-12'}>
                                            <div className="table-container">
                                                <BootstrapTable
                                                    keyField="id"
                                                    data={orderData}
                                                    columns={columns}
                                                    // rowEvents={{ onClick: togglePaySumSidebar }}
                                                />
                                            </div>
                                        </div>
                                        {isPaySumSidebarOpen && (
                                            <div className='col-md-4'>
                                               <div className='asideBarWrap is-sticky'>
                                                   <div className='topTtl'>
                                                        <h2>Order Summary</h2>
                                                        {formattedDate && <p>{formattedDate}</p>}
                                                    </div>
                                                    <div className='productTbl'>
                                                        <table>
                                                            <tr>
                                                                <th>Items</th>
                                                                <th>Unit Price</th>
                                                                <th></th>
                                                            </tr>
                                                            {selectedOrder.OrderItems.map((item, key)=>
                                                            (
                                                                <tr>
                                                                <td>
                                                                    <div className='d-flex'>
                                                                        <div className='imgWrap'>
                                                                        <img src={item.product.image} className='img-fluid'/> 
                                                                        </div>
                                                                        <div className='dataWrap'>
                                                                            <h2>{item.product.name}</h2>
                                                                            <p>Quantity :{item.quantity}</p>
                                                                        </div>
                                                                    </div>
                                                                </td>
                                                                <td>{item.product.unit_price}</td>
                                                                <td>{item.amount}</td>
                                                            </tr>
                                                            ))}
                                                            <tr>
                                                                <td colSpan={3} className='bdrTop'>
                                                                    <ul>
                                                                        <li>
                                                                            <p>Subtotal</p>
                                                                            <h4>{selectedOrder.total_amount}</h4>
                                                                        </li>
                                                                        <li>
                                                                            <p>Mobile No. :</p>
                                                                            <h6>+91 9402354875</h6>
                                                                        </li>
                                                                        <li className='full'>
                                                                            <p>Email ID : </p>
                                                                            <h6>neerajpande@gmail.com</h6>
                                                                        </li>
                                                                        <li>
                                                                            <p>Payment :</p>
                                                                            <h6>N/A</h6>
                                                                        </li>
                                                                        <li>
                                                                            <p>Grand total</p>
                                                                            <h3>{selectedOrder.total_amount}</h3>
                                                                        </li>
                                                                    </ul>                                                                
                                                                </td>
                                                            </tr>
                                                        </table>
                                                    </div>
                                                    <Accordion defaultActiveKey="0">
                                                        <Accordion.Item eventKey="0">
                                                            <Accordion.Header>Coustumer Details</Accordion.Header>
                                                            <Accordion.Body>
                                                                <ul>
                                                                    <li>
                                                                        <p>Name : </p>
                                                                        <h6>{selectedOrder.customer.name}</h6>
                                                                    </li>
                                                                    <li>
                                                                        <p>Mobile No. :</p>
                                                                        <h6>+{selectedOrder.customer.whatsappNumber}</h6>
                                                                    </li>
                                                                    {/* <li className='full'>
                                                                        <p>Email ID : </p>
                                                                        <h6>neerajpande@gmail.com</h6>
                                                                    </li> */}
                                                                    <li>
                                                                        <p>Payment :</p>
                                                                        <h6 className='clrPending'>{selectedOrder.transaction_status}</h6>
                                                                    </li>
                                                                    <li>
                                                                        <p>Address :</p>
                                                                        <h6>{selectedOrder.customer.address}</h6>
                                                                    </li>
                                                                    <li>
                                                                        <p>Status :</p>
                                                                        <h6 className='clrPending'>{selectedOrder.status}</h6>
                                                                    </li>
                                                                    <li>
                                                                        <p>Pincode :</p>
                                                                        <h6>411254</h6>
                                                                    </li>
                                                                </ul>
                                                            </Accordion.Body>
                                                        </Accordion.Item>
                                                    </Accordion>
                                                    <div className='ctaWrap'>
                                                        <button className='btn' onClick={() => selectedOrder && updateOrderStatus(selectedOrder.id)}>Accept Order</button>
                                                    </div>
                                                </div>
                                            </div>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </main>
        </div>
    );
};

export default Order;
