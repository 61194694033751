import axios from "axios";
import {useNavigate } from "react-router-dom"
import {handleErrorResponseAxios, handleResponseAxios} from "./common-handler"
import {
    GET_ALL_ORDERS
} from "../utility/ConstantAPI";

export function get_all_orders(){
    // let token = get_auth_token();
    let axiosConfig = {
        headers: {
            "Content-Type": "application/json",
            // Authorization: `Bearer ${token}`,
        },
    };
    var url = GET_ALL_ORDERS
    return axios
        .get(url, axiosConfig)
        .then(handleResponseAxios)
        .catch(function(error){
            return handleErrorResponseAxios(error)
        });

}
