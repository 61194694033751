import React, { useState, useEffect } from 'react';
import { useNavigate } from "react-router-dom";
import { Formik, Form, Field, ErrorMessage } from 'formik';
import { CustomScroll } from "react-custom-scroll";
import * as Yup from 'yup';
import { Spinner } from "react-bootstrap";
import Button from 'react-bootstrap/Button';
import { get_my_category, get_product_by_id, update_product } from '../../services/products_services';
import SelectField from './SelectField'; // Import the custom SelectField component

const EditProduct = (props) => {
    let navigate = useNavigate();
    // Retrieve the value from props and store it in another variable
    const { toggleEditSidebar, item_data } = props;
    const [error, setError] = useState([]);
    const [IsLoading, setIsLoading] = useState(false);
    const [CategoryData, setCategoryData] = useState([]);
    const [Data, setData] = useState([]);
    const [selectedImage, setSelectedImage] = useState(null);
    const [initialValues, setInitialValues] = useState({
        name: "",
        description: "",
        unit: "",
        quantity: "",
        unit_price: "",
        veg: "",
        is_trending: false,
        categories: [],
        active: null,
    });

    useEffect(() => {
        const fetchData = async () => {
            try {
                await Promise.all([
                    fetchCategoryData(),
                    fetchProduct()
                ]);
            } catch (error) {
                console.error('Error fetching data:', error);
                setError(error.message);
            }
            setIsLoading(false);
        };

        fetchData();
    }, []);

    const fetchCategoryData = async () => {
        try {
            const response = await get_my_category();
            if (response) {
                // Map menuData to the format expected by react-select
                const itemList = response.map(item => ({
                    value: item.id,
                    label: item.name
                }));
                setCategoryData(itemList);
            } else {
                setError(response.message);
            }
        } catch (error) {
            console.error('Error fetching data:', error);
            setError(error.message);
        }
    };

    const fetchProduct = async () => {
        try {
            const response = await get_product_by_id(item_data.id);
            if (response) {
                setData(response);
            } else {
                setError(response.message);
            }
        } catch (error) {
            console.error('Error fetching data:', error);
            setError(error.message);
        }
    };

    useEffect(() => {
        if (Data) {
            const {
                name = "",
                description = "",
                unit = "",
                quantity = "",
                unit_price = "",
                veg = "",
                is_trending = false,
                categories = [],
                active = null
            } = Data;

            setInitialValues({
                name,
                description,
                unit,
                quantity,
                unit_price,
                veg,
                is_trending,
                categories,
                active
            });
        }
    }, [Data]);

    const validationSchema = Yup.object().shape({
        name: Yup.string().required("Please enter the product name."),
        description: Yup.string().required("Please enter the product description."),
        unit: Yup.string().required("Please enter the unit."),
        quantity: Yup.number().required("Please enter the quantity.").positive().integer(),
        unit_price: Yup.number().required("Please enter the unit price.").positive(),
        categories: Yup.array().of(Yup.string()).required("Please select a category."),
    });

    const onSubmit = async (values) => {
        setIsLoading(true);
        if (values.categories && Array.isArray(values.categories)) {
            const categoryValues = values.categories.map(category => category);

            // Serialize the array of menu IDs into a string or JSON format
            const categoryIdsString = JSON.stringify(categoryValues);

            const formData = new FormData();
            formData.append('name', values.name);
            formData.append('description', values.description);
            formData.append('unit', values.unit);
            formData.append('quantity', values.quantity);
            formData.append('unit_price', values.unit_price);
            if (values.image !== undefined) {
                formData.append('image', values.image);
            }
            formData.append('categories', categoryIdsString);

            await update_product(item_data.id, formData, onSuccess, onError);
        } else {
            console.error("Invalid 'categories' data:", values.categories);
            // Handle the case where 'categories' is missing or not an array
        }
    };

    const onSuccess = (response) => {
        if (response.code === "200") {
            // Redirect to another URL
            // navigate(`/DepartmentList`);
        } else {
            console.log('Response status is not 200');
        }
        setIsLoading(false);
    };

    const onError = (error) => {
        setError(error);
        setIsLoading(false);
    };

    return (
        <div className='asideBarWrap is-sticky'>
            <div className='topTTlWrap'>
                <button className='btn' onClick={toggleEditSidebar}>
                    <svg width="13" height="22" viewBox="0 0 13 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M10.9368 2L2 10.9368L10.9368 19.8735" stroke="#ADA7A7" strokeWidth="4" strokeLinecap="round" strokeLinejoin="round" />
                    </svg>
                </button>
                <h2>Edit Product</h2>
            </div>
            <Formik
                enableReinitialize
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={onSubmit}
            >
                {({ values, errors, status, touched, setFieldValue, setValues, isSubmitting }) => (
                    <Form>
                        <>
                            {error ? (
                                <p>{error.message}</p>
                            ) : null}
                            <div className='imgWrap'>
                                <img src={selectedImage ? URL.createObjectURL(selectedImage) : item_data.image} style={{ width: '-webkit-fill-available', height: '-webkit-fill-available' }} />
                                <input
                                    type="file"
                                    accept="image/*"
                                    style={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%', opacity: 0, cursor: 'pointer' }}
                                    onChange={(event) => {
                                        setSelectedImage(event.currentTarget.files[0]);
                                        setFieldValue("image", event.currentTarget.files[0]);
                                    }}
                                />
                            </div>
                            <div className='btmDataWrap productBtm'>
                            <CustomScroll heightRelativeToParent="100%">
                                <ul>
                                    <li className='inputFields'>
                                        <Field
                                            type="text"
                                            name="name"
                                            placeholder="New Product Name"
                                            className={"form-control" +
                                                (errors.name &&
                                                    touched.name
                                                    ? " is-invalid"
                                                    : "")
                                            }
                                        />
                                        <ErrorMessage
                                            name="name"
                                            component="div"
                                            className="invalid-feedback"
                                        />
                                    </li>
                                    <li className='inputFields'>
                                        <Field
                                            as="textarea"
                                            name="description"
                                            placeholder="Description....."
                                            className={"form-control" +
                                                (errors.description &&
                                                    touched.description
                                                    ? " is-invalid"
                                                    : "")
                                            }
                                        />
                                        <ErrorMessage
                                            name="description"
                                            component="div"
                                            className="invalid-feedback"
                                        />
                                    </li>

                                    <li className='inputFields'>
                                        <Field
                                            type="text"
                                            name="unit"
                                            placeholder="unit"
                                            className={"form-control" +
                                                (errors.unit &&
                                                    touched.unit
                                                    ? " is-invalid"
                                                    : "")
                                            }
                                        />
                                        <ErrorMessage
                                            name="unit"
                                            component="div"
                                            className="invalid-feedback"
                                        />
                                    </li>

                                    <li className='inputFields'>
                                        <Field
                                            type="text"
                                            name="quantity"
                                            placeholder="quantity"
                                            className={"form-control" +
                                                (errors.quantity &&
                                                    touched.quantity
                                                    ? " is-invalid"
                                                    : "")
                                            }
                                        />
                                        <ErrorMessage
                                            name="quantity"
                                            component="div"
                                            className="invalid-feedback"
                                        />
                                    </li>

                                    <li className='inputFields'>
                                        <Field
                                            type="text"
                                            name="unit_price"
                                            placeholder="unit_price"
                                            className={"form-control" +
                                                (errors.unit_price &&
                                                    touched.unit_price
                                                    ? " is-invalid"
                                                    : "")
                                            }
                                        />
                                        <ErrorMessage
                                            name="unit_price"
                                            component="div"
                                            className="invalid-feedback"
                                        />
                                    </li>
                                    <li className='inputFields selectWrap'>
                                        <SelectField
                                            name="categories"
                                            options={CategoryData}
                                            isMulti
                                            value={values.categories}
                                            // label="Select Items"
                                            placeholder="Select Category..."
                                            onChange={(selectedOptions) => {
                                                setFieldValue("categories", selectedOptions);
                                            }}
                                        />
                                        <ErrorMessage
                                            name="categories"
                                            component="div"
                                            className="invalid-feedback"
                                        />
                                    </li>
                                </ul>
                                <div className='ctaWrap'>
                                    <Button type="submit" className="btn" disabled={isSubmitting}>
                                        {IsLoading &&
                                            <Spinner
                                                as="span"
                                                variant="light"
                                                size="sm"
                                                role="status"
                                                aria-hidden="true"
                                                animation="border"
                                                className="me-2" />
                                        }
                                        Apply
                                    </Button>
                                </div>
                                </CustomScroll>
                            </div>
                        </>
                    </Form>
                )}
            </Formik>
        </div>
    );
};

export default EditProduct;

