import React, { useState, useEffect, useMemo } from 'react';
import Form from 'react-bootstrap/Form';
import { CustomScroll } from "react-custom-scroll";
import { Modal, Button, FormControl } from 'react-bootstrap';
import Header from '../../Components/Common/Header';
import Sidebar from '../../Components/Common/Sidebar';
import { deleteBulkContact, deleteContact, getGroupById, updateGroupContacts } from '../../services/group-service';
import { useLocation, useNavigate } from 'react-router-dom';
import Spinner from 'react-bootstrap/Spinner';
import { spinnerContainerStyle } from '../../utility/CustomFormating';
const CreatedContactList = () => {
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const groupId = queryParams.get('groupId');
    const [isOpen, setIsOpen] = useState(false);
    const [show, setShow] = useState(false);
    const [data, setData] = useState([])
    const [columnsLabel, setColumnsLabel] = useState([])
    const [editRowId, setEditRowId] = useState(null);
    const [editValues, setEditValues] = useState({});
    const [error, setError] = useState()
    const [isDisabled, setIsDisabled] = useState(true)
    const [isDelete, setIsDelete] = useState(false)
    const [groupName, setGroupName] = useState('')
    const [selectAll, setSelectAll] = useState(false);
    const [selectedRows, setSelectedRows] = useState();
    const [rowsData, setRowsData] = useState([])
    const [filteredData, setFilteredData] = useState([]);
    const [searchTerm, setSearchTerm] = useState('');
    const [editedData, setEditedData] = useState([])
    const [isLoad, setIsLoad] = useState(true)
    const token = localStorage.getItem('token')
    const user = JSON.parse(localStorage.getItem('user'))
    const handleSelectAllChange = (e) => {
        setSelectAll(!selectAll);
        if (e.target.checked) {
            let rows = data.map((val) => ({
                contact_id: val.contact_id,
                group_id: groupId
            }))
            setRowsData([...rows])
        }
        setSelectedRows(Array(data.length).fill(!selectAll));
    };
    const handleRowCheckboxChange = (row, index) => {
        const updatedSelectedRows = [...selectedRows];
        updatedSelectedRows[index] = !updatedSelectedRows[index];
        setSelectedRows(updatedSelectedRows);
        if (updatedSelectedRows[index] === true) {
            rowsData.push({ contact_id: row.contact_id, group_id: groupId })
        }
        else {
            rowsData.splice(rowsData.findIndex(a => a.contact_id === row.contact_id), 1)
        }
        setRowsData([...rowsData])
        if (updatedSelectedRows.every(Boolean)) {
            setSelectAll(true);
        } else {
            setSelectAll(false);
        }
    };
    const navigate = useNavigate()
    const toggleSidebar = () => {
        setIsOpen(!isOpen);
    };

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    useEffect(() => {
        fetchData()
    }, [])

    useEffect(() => {
        // Filter the data based on the search term
        const filtered = data.filter(row => {
            return columnsLabel.some(label => {
                const cellValue = row[label]?.value?.toString().toLowerCase() || '';
                return cellValue.includes(searchTerm.toLowerCase());
            });
        });
        setFilteredData(filtered);
    }, [searchTerm, data, columnsLabel]);
    const fetchData = async () => {
        if (!token) {
            setIsLoad(false)
            return false
        }
        const response = await getGroupById(groupId, token)
        setGroupName(response.group_name)
        setColumnsLabel([...columnsLabel, ...response.predefined_field_labels.map(label => label.custom_label), ...response.custom_fields.map(field => field.label)])
        const predefinedFieldLabels = {};
        response.predefined_field_labels.forEach(field => {
            predefinedFieldLabels[field.id] = {
                custom_label: field.custom_label,
                predefined_label: field.predefined_field.title
            };
        });
        const customFields = {};
        response.custom_fields.forEach(field => {
            customFields[field.id] = field.label;
        });
        const transformedContacts = response.contacts.map(contact => {
            const predefinedValues = {};
            const predefinedLabels = {};
            let contact_id = contact.id;
            contact.contact_predefined_values.forEach(value => {
                const label = predefinedFieldLabels[value.predefined_field_label_id].custom_label;
                const predefined_label = predefinedFieldLabels[value.predefined_field_label_id].predefined_label;
                predefinedValues[label] = { value: value.value, id: value.predefined_field_label_id };
                predefinedLabels[predefined_label] = label; // Add predefined_label
            });
            const customValues = {};
            contact.contact_custom_values.forEach(value => {
                const label = customFields[value.custom_field_id];
                customValues[label] = { value: value.value, id: value.custom_field_id };
            });

            return {
                ...predefinedValues,
                ...customValues,
                predefined_label: predefinedLabels,
                contact_id
            };
        });
        setSelectedRows(Array(transformedContacts.length).fill(false))
        setData(transformedContacts)
        setIsLoad(false)
    }

    const handleSearchChange = (e) => {
        setSearchTerm(e.target.value);
    };
    const [inputError, setInputError] = useState()
    const handleChange = (e, col) => {
        let update = editValues[col]
        update['value'] = e.target.value
        if (update['value'].length === 0) {
            update['error'] = 'Field May not be blank'
            setIsDisabled(true)
        }
        else {
            delete update.error
            setIsDisabled(false)
        }
        setEditValues({ ...editValues })
        // setEditValues({
        //   ...editValues,
        //   [col.value]: e.target.value,
        // });
    };
    const handleSave = async () => {
        let rows = []
        await updateGroupContacts({ data: editedData }, groupId, onSuccess, onError)
    }
    // const handleDelete = async (contact_id) => {
    //     if (data.length === 1) {
    //         handleShow()
    //         setIsDelete(contact_id)
    //     }
    //     else {
    //         await deleteContact(contact_id, groupId, onSuccess, onError)
    //     }
    // }
    const [isPopupOpen, setIsPopupOpen] = useState(false);
    const [selectedContactId, setSelectedContactId] = useState(null);
    const handleDeleteClick = (contact_id) => {
        setSelectedContactId(contact_id);
        if (data.length === 1) {
            handleShow()
            setIsDelete(true)
        }
        else {
            setIsPopupOpen(true);
        }
    };
    const handleConfirmDelete = async () => {
        if (isDelete) {
            await deleteContact(selectedContactId, groupId, onDeleteSuccess, onError);
        }
        else {
            await deleteContact(selectedContactId, groupId, onSuccess, onError);
        }
        setIsPopupOpen(false);
    };
    const handleCancelDelete = () => {
        setIsPopupOpen(false);
    };
    const handleDeleteSelected = async () => {

        await deleteBulkContact({ data: rowsData }, onDeleteSuccess, onError)
    }
    const onDeleteSuccess = (response) => {
        navigate('/ContactList')
    }
    const onSuccess = (response) => {
        window.location.reload()
        // console.log(response)
    }
    const onError = (error) => {
        setError(error)
        // setIsLoading(false);
        console.log("error::", error)
    }
    return (
        <>
            {isLoad ?
                <div style={spinnerContainerStyle}>
                    <Spinner animation='border' size='lg' style={{ width: '5rem', height: '5rem', color: '#1944F1' }} />
                </div> : token ?
                    <div className={isOpen ? "app-container sidebar-open" : "app-container"}>
                        <Header toggleSidebar={toggleSidebar} />
                        {/* <Sidebar isOpen={isOpen} /> */}
                        <main className="content contactListPage">
                            <div className='contactListWrap'>
                                <div className='topDataWrap'>
                                    <div className='container-fluid'>
                                        <div className='row align-items-center'>
                                            <div className='col-md-5'>
                                                <div className='titleWrap'>
                                                    <div className='icoWrap'>
                                                        <svg width="45" height="45" viewBox="0 0 45 45" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                            <path d="M13.3889 43V40.7222C13.3889 39.514 13.8688 38.3553 14.7232 37.501C15.5775 36.6466 16.7362 36.1667 17.9444 36.1667H27.0556C28.2638 36.1667 29.4225 36.6466 30.2768 37.501C31.1311 38.3553 31.6111 39.514 31.6111 40.7222V43M33.8889 17.9444H38.4444C39.6527 17.9444 40.8114 18.4244 41.6657 19.2787C42.52 20.1331 43 21.2918 43 22.5V24.7778M2 24.7778V22.5C2 21.2918 2.47996 20.1331 3.33429 19.2787C4.18862 18.4244 5.34735 17.9444 6.55556 17.9444H11.1111M17.9444 24.7778C17.9444 25.986 18.4244 27.1447 19.2787 27.999C20.1331 28.8534 21.2918 29.3333 22.5 29.3333C23.7082 29.3333 24.8669 28.8534 25.7213 27.999C26.5756 27.1447 27.0556 25.986 27.0556 24.7778C27.0556 23.5696 26.5756 22.4108 25.7213 21.5565C24.8669 20.7022 23.7082 20.2222 22.5 20.2222C21.2918 20.2222 20.1331 20.7022 19.2787 21.5565C18.4244 22.4108 17.9444 23.5696 17.9444 24.7778ZM29.3333 6.55556C29.3333 7.76376 29.8133 8.92249 30.6676 9.77682C31.522 10.6312 32.6807 11.1111 33.8889 11.1111C35.0971 11.1111 36.2558 10.6312 37.1102 9.77682C37.9645 8.92249 38.4444 7.76376 38.4444 6.55556C38.4444 5.34735 37.9645 4.18862 37.1102 3.33429C36.2558 2.47996 35.0971 2 33.8889 2C32.6807 2 31.522 2.47996 30.6676 3.33429C29.8133 4.18862 29.3333 5.34735 29.3333 6.55556ZM6.55556 6.55556C6.55556 7.76376 7.03551 8.92249 7.88985 9.77682C8.74418 10.6312 9.9029 11.1111 11.1111 11.1111C12.3193 11.1111 13.478 10.6312 14.3324 9.77682C15.1867 8.92249 15.6667 7.76376 15.6667 6.55556C15.6667 5.34735 15.1867 4.18862 14.3324 3.33429C13.478 2.47996 12.3193 2 11.1111 2C9.9029 2 8.74418 2.47996 7.88985 3.33429C7.03551 4.18862 6.55556 5.34735 6.55556 6.55556Z" stroke="#515151" stroke-width="3" stroke-linecap="round" stroke-linejoin="round" />
                                                        </svg>
                                                    </div>
                                                    <h2>{groupName}</h2>
                                                </div>
                                            </div>
                                            <div className='col-md-7'>
                                                <div className='rightWrap'>
                                                    {/* <button className='btnFilter btn'>
                                                    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <path d="M17 3L13 3M9 3L1 3M17 9L7 9M3 9L1 9M17 15L16 15M12 15L0.999999 15M13 1L13 5L9 5L9 1L13 1ZM7 7L7 11L3 11L3 7L7 7ZM16 13L16 17L12 17L12 13L16 13Z" stroke="#939393" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                                    </svg>
                                                </button>
                                                <button className='btnUpload btn'>
                                                    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <path d="M19 4C19 3.20435 18.6839 2.44129 18.1213 1.87868C17.5587 1.31607 16.7956 1 16 1H4C3.20435 1 2.44129 1.31607 1.87868 1.87868C1.31607 2.44129 1 3.20435 1 4M10 13V5M10 5L7 8M10 5L13 8M4 19H16C16.7956 19 17.5587 18.6839 18.1213 18.1213C18.6839 17.5587 19 16.7956 19 16C19 15.2044 18.6839 14.4413 18.1213 13.8787C17.5587 13.3161 16.7956 13 16 13H4C3.20435 13 2.44129 13.3161 1.87868 13.8787C1.31607 14.4413 1 15.2044 1 16C1 16.7956 1.31607 17.5587 1.87868 18.1213C2.44129 18.6839 3.20435 19 4 19Z" stroke="#939393" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                                    </svg>
                                                </button> */}
                                                    <div className='searchWrap'>
                                                        <input value={searchTerm} onChange={handleSearchChange} />
                                                        <button className='btnSearch'>
                                                            <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                <path d="M15 15L10.3333 10.3333M1 6.44444C1 7.15942 1.14082 7.86739 1.41443 8.52794C1.68804 9.18849 2.08908 9.78868 2.59464 10.2942C3.1002 10.7998 3.7004 11.2008 4.36095 11.4745C5.0215 11.7481 5.72947 11.8889 6.44444 11.8889C7.15942 11.8889 7.86739 11.7481 8.52794 11.4745C9.18849 11.2008 9.78868 10.7998 10.2942 10.2942C10.7998 9.78868 11.2008 9.18849 11.4745 8.52794C11.7481 7.86739 11.8889 7.15942 11.8889 6.44444C11.8889 5.72947 11.7481 5.0215 11.4745 4.36095C11.2008 3.7004 10.7998 3.1002 10.2942 2.59464C9.78868 2.08908 9.18849 1.68804 8.52794 1.41443C7.86739 1.14082 7.15942 1 6.44444 1C5.72947 1 5.0215 1.14082 4.36095 1.41443C3.7004 1.68804 3.1002 2.08908 2.59464 2.59464C2.08908 3.1002 1.68804 3.7004 1.41443 4.36095C1.14082 5.0215 1 5.72947 1 6.44444Z" stroke="#939393" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                                            </svg>
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className='contactListTbl contactListEditTbl'>
                                    <CustomScroll heightRelativeToParent="100%">
                                        <table>
                                            <thead>
                                                <tr>
                                                    <th>
                                                        <input id="checkAll" name="" type="checkbox" checked={selectAll} onChange={handleSelectAllChange} />
                                                    </th>
                                                    {columnsLabel.length > 0 && columnsLabel.map((fields) => (
                                                        <th>{fields}</th>
                                                    ))}
                                                    <th>Action</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {(filteredData.length > 0 ? filteredData : data).map((row, rowIndex) => (
                                                    <>
                                                        <tr>
                                                            <td><input type="checkbox" checked={selectedRows[rowIndex]} onChange={() => handleRowCheckboxChange(row, rowIndex)} /></td>
                                                            {columnsLabel.map((col) => (
                                                                <td id={row[col].id}>{editRowId === rowIndex ? (
                                                                    <>
                                                                        <input
                                                                            type="text"
                                                                            value={editValues[col].value}
                                                                            onChange={(e) => handleChange(e, col)}
                                                                        />
                                                                        {editValues[col].error &&
                                                                            <div className="error">
                                                                                {editValues[col].error}
                                                                            </div>
                                                                        }
                                                                    </>
                                                                ) : (
                                                                    row[col].value
                                                                )}</td>
                                                            ))}
                                                            <td>
                                                                <div className='ctaWrap'>
                                                                    <button className='btn' onClick={() => {
                                                                        setEditRowId(rowIndex)
                                                                        setIsDisabled(false)
                                                                        setEditValues(data[rowIndex]);
                                                                        if (!editedData.includes(data[rowIndex])) {
                                                                            setEditedData([...editedData, data[rowIndex]])
                                                                        }
                                                                    }}>
                                                                        <svg width="13" height="13" viewBox="0 0 13 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                            <path d="M5.63514 2.96345L9.60811 6.93642M1 8.92291L8.7473 1.17561C8.87107 1.05429 9.03749 0.986328 9.21081 0.986328C9.38413 0.986328 9.55055 1.05429 9.67432 1.17561L11.3959 2.89723C11.5173 3.02101 11.5852 3.18742 11.5852 3.36075C11.5852 3.53407 11.5173 3.70048 11.3959 3.82426L3.64865 11.5716H1V8.92291Z" stroke="#515151" stroke-width="0.993243" stroke-linecap="round" stroke-linejoin="round" />
                                                                        </svg>
                                                                    </button>
                                                                    <button className='btn' onClick={() => handleDeleteClick(row.contact_id)}>
                                                                        <svg width="13" height="14" viewBox="0 0 13 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                            <path d="M1.40625 3.64865H12.0008M5.37922 6.2973V10.2703M8.02787 6.2973V10.2703M2.06841 3.64865L2.73057 11.5946C2.73057 11.9458 2.8701 12.2827 3.11846 12.531C3.36682 12.7794 3.70367 12.9189 4.0549 12.9189H9.3522C9.70343 12.9189 10.0403 12.7794 10.2886 12.531C10.537 12.2827 10.6765 11.9458 10.6765 11.5946L11.3387 3.64865M4.71706 3.64865V1.66216C4.71706 1.48655 4.78682 1.31812 4.911 1.19394C5.03518 1.06976 5.20361 1 5.37922 1H8.02787C8.20349 1 8.37191 1.06976 8.49609 1.19394C8.62027 1.31812 8.69003 1.48655 8.69003 1.66216V3.64865" stroke="#515151" stroke-width="0.993243" stroke-linecap="round" stroke-linejoin="round" />
                                                                        </svg>
                                                                    </button>
                                                                </div>
                                                            </td>
                                                        </tr>
                                                    </>
                                                ))}

                                            </tbody>
                                        </table>
                                    </CustomScroll>
                                </div>
                                <div className='ctaSave'>
                                    {rowsData && rowsData.length > 0 &&
                                        <button className='btn' onClick={handleDeleteSelected} >Delete Selected</button>
                                    }
                                    <button className='btn' onClick={handleSave} disabled={isDisabled ? true : false}>Save</button>
                                </div>
                            </div>
                        </main>


                        <Modal show={show} onHide={handleClose} centered className='deletePopupWrap'>
                            <Modal.Header closeButton>
                                <Modal.Title>Confirm Deletion</Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                <h4>Are you sure you want to delete all rows? </h4>
                                <h5>This action will permanently delete the entire contact list.</h5>
                            </Modal.Body>
                            <Modal.Footer>
                                <Button variant="danger" onClick={handleConfirmDelete}>
                                    Yes
                                </Button>
                                <Button variant="secondary" onClick={handleCancelDelete}>
                                    Cancel
                                </Button>
                            </Modal.Footer>
                        </Modal>

                        <Modal show={isPopupOpen} onHide={handleCancelDelete} centered className='deletePopupWrap'>
                            <Modal.Header closeButton>
                                <Modal.Title>Confirm Deletion</Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                <p>Are you sure you want to delete?</p>
                            </Modal.Body>
                            <Modal.Footer>
                                <Button variant="danger" onClick={handleConfirmDelete}>
                                    Yes
                                </Button>
                                <Button variant="secondary" onClick={handleCancelDelete}>
                                    Cancel
                                </Button>
                            </Modal.Footer>
                        </Modal>


                    </div>
                    : <h4>Please Log In</h4>}
        </>
    );
};

export default CreatedContactList;

