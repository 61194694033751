import axios from "axios";
import { useNavigate } from "react-router-dom"
import { handleErrorResponseAxios, handleResponseAxios } from "./common-handler"
import {
    GET_ALL_MENUS,
    ADD_NEW_MENU,
    GET_MENU_BY_ID,
    UPDATE_MENU,
    GET_ALL_CATEGORIES
} from "../utility/ConstantAPI";

export function get_all_menus() {
    // let token = get_auth_token();
    let axiosConfig = {
        headers: {
            "Content-Type": "application/json",
            // Authorization: `Bearer ${token}`,
        },
    };
    var url = GET_ALL_MENUS
    return axios
        .get(url, axiosConfig)
        .then(handleResponseAxios)
        .catch(function (error) {
            return handleErrorResponseAxios(error)
        });

}
export async function add_new_menu(values, onSuccess, onError) {
    // let token = get_auth_token()
    var url = ADD_NEW_MENU;
    let axiosConfig = {
        headers: {
            "Content-Type": "multipart/form-data",
            // Authorization: `Bearer ${token}`,
        },
    };

    try {
        let response = await axios.post(url, values, axiosConfig);
        onSuccess(handleResponseAxios(response));
    } catch (error) {
        onError(handleErrorResponseAxios(error));
    }
}

export function get_menu_by_id(menu_id) {
    // let token = get_auth_token();
    let axiosConfig = {
        headers: {
            "Content-Type": "application/json",
            // Authorization: `Bearer ${token}`,
        },
    };
    var url = GET_MENU_BY_ID(menu_id)
    return axios
        .get(url, axiosConfig)
        .then(handleResponseAxios)
        .catch(function (error) {
            return handleErrorResponseAxios(error)
        });

}



export function get_my_category() {
    // let token = get_auth_token();
    let axiosConfig = {
        headers: {
            "Content-Type": "application/json",
            // Authorization: `Bearer ${token}`,
        },
    };
    var url = GET_ALL_CATEGORIES
    return axios
        .get(url, axiosConfig)
        .then(handleResponseAxios)
        .catch(function (error) {
            return handleErrorResponseAxios(error)
        });

}

export async function update_menu(menu_id, values, onSuccess, onError) {
    let axiosConfig = {
        headers: {
            "Content-Type": "application/json",
            // Authorization: `Bearer ${token}`,
        },
    };
    var url = UPDATE_MENU(menu_id)
    try {
        let response = await axios.post(url, values, axiosConfig);
        onSuccess(handleResponseAxios(response));
    } catch (error) {
        onError(handleErrorResponseAxios(error));
    }
}
