import React, { useEffect, useState } from 'react';
import axios from 'axios';
import '../../assets/css/style.css';
import {ZOHO_AUTH_TOKEN, ZOHO_DATA, ZOHO_SEND_MESSAGE, ZOHO_UPDATE_CONFIRM_STATUS} from '../../utility/ConstantAPI'

const OAuthRedirect = () => {
  const [orders, setOrders] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [selectedContacts, setSelectedContacts] = useState([]);

  useEffect(() => {
    const getCodeAndExchange = async () => {
      const query = new URLSearchParams(window.location.search);
      const authorizationCode = query.get('code');

      if (authorizationCode) {
        try {
          const url = ZOHO_AUTH_TOKEN
          const response = await axios.post(url, {
            code: authorizationCode,
            client_id: '1000.QPYJKRR36L0Y6G8PQY6WAGONDA1W5N',
            client_secret: '61ff5378b2f685668d1a4678e0c800eb841990c87a',
            redirect_uri: 'http://localhost:3000/oauth/callback',
            grant_type: 'authorization_code',
          });

          const { access_token } = response.data;
          await fetchEventOrders(access_token);
        } catch (error) {
          console.error('Error exchanging authorization code for tokens:', error);
          setError('Failed to exchange authorization code for tokens.');
          setLoading(false);
        }
      } else {
        console.error('Authorization code not found');
        setError('Authorization code not found.');
        setLoading(false);
      }
    };

    const fetchEventOrders = async (accessToken) => {
      try {
        const url = ZOHO_DATA
        const response = await axios.get(url, {
          params: { access_token: accessToken },
        });
        setOrders(response.data);
        setLoading(false);
      } catch (error) {
        console.error('Error fetching event orders:', error);
        setError('Failed to fetch event orders.');
        setLoading(false);
      }
    };

    getCodeAndExchange();
  }, []);

  const sendWhatsAppMessage = async (recipientPhoneNumber, templateName, languageCode) => {
    try {
      const url = ZOHO_SEND_MESSAGE
      const response = await axios.post(url, {
        recipientPhoneNumber,
        templateName,
        languageCode
      });
      if (response.status === 200 && response.data.message === 'Message sent successfully') {
        return true; // Indicate success
      } else {
        return false; // Indicate failure
      }

    } catch (error) {
      console.error('Error sending message:', error);
      window.alert('UI : Failed to send message. Please try again.');
    }
  };

  const handleSendWhatsApp = async () => {
    const templateName = 'confirmation_temp';
    const languageCode = 'en_US';
    for (const contact of selectedContacts) {
      const messageSent = await sendWhatsAppMessage(contact.telephone, templateName, languageCode);
      
      if (messageSent) {
        await updateConfirmStatus(contact.order_id,'confirm_status'); 
      }  
    }
    alert('Confirmation messages sent successfully!');
    handleLogin();
  };

  const handleSendReminder = async () => {
    const templateName = 'confirmation_temp';
    const languageCode = 'en_US';
    for (const contact of selectedContacts) {
      const messageSent = await sendWhatsAppMessage(contact.telephone, templateName, languageCode);
      
      if (messageSent) {
        await updateConfirmStatus(contact.order_id,'reminder_status'); 
      }  
    }
    alert('Reminder messages sent successfully!');
    handleLogin();
  };

  const updateConfirmStatus = async (orderId,update_field) => {
    try {
      const url = ZOHO_UPDATE_CONFIRM_STATUS
      await axios.post(url, {
        orderId,
        update_field
      });
    } catch (error) {
      console.error('Error updating confirm status:', error);
    }
  };

  const client_id = '1000.QPYJKRR36L0Y6G8PQY6WAGONDA1W5N';
  const redirect_uri = 'http://localhost:3000/oauth/callback';
  const scope = 'zohobackstage.portal.READ,zohobackstage.event.READ,zohobackstage.attendee.READ,zohobackstage.order.READ';

  const handleLogin = () => {
    const authorizationUrl = `https://accounts.zoho.in/oauth/v2/auth?scope=${encodeURIComponent(scope)}&client_id=${client_id}&response_type=code&redirect_uri=${encodeURIComponent(redirect_uri)}`;
    window.location.href = authorizationUrl;
  };

  const formatPhoneNumber = (phoneNumber) => {
    if (!phoneNumber) {
      console.warn('Phone number is undefined or null:', phoneNumber);
      return null;
    }
  
    phoneNumber = phoneNumber.replace(/\D/g, '');
    
    if (phoneNumber.startsWith('91') && phoneNumber.length === 12) {
      return phoneNumber;
    } else if (phoneNumber.length === 10) {
      return '91' + phoneNumber;
    } else if (phoneNumber.startsWith('0') && phoneNumber.length === 11) {
      return '91' + phoneNumber.slice(1);
    } else {
      console.warn('Invalid phone number format:', phoneNumber);
      return null;
    }
  };
  
  const handleCheckboxChange = (event, contact) => {
    if (!contact.telephone) {
      console.warn('Contact or contact.telephone is undefined:', contact);
      return;
    }
  
    const formattedPhone = formatPhoneNumber(contact.telephone);
    if (formattedPhone) {
      if (event.target.checked) {
        setSelectedContacts([...selectedContacts, { ...contact, telephone: formattedPhone }]);
      } else {
        setSelectedContacts(selectedContacts.filter(item => item.telephone !== formattedPhone));
      }
    }
  };

  return (
    <main className="content eventDtlListPage">
      <div className="eventDtlListWrap">
        <div className="topDataWrap">
          <div className="container-fluid">
            <div className="row align-items-center">
              <div className="col-md-5">
                <div className="titleWrap">
                  <div className="icoWrap">
                    <svg width="34" height="34" viewBox="0 0 13 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path
                        d="M7.875 6.5H12M7.875 9.25H12M7.875 12H12M1 1.6875C1 1.50516 1.07243 1.3303 1.20136 1.20136C1.3303 1.07243 1.50516 1 1.6875 1H11.3125C11.4948 1 11.6697 1.07243 11.7986 1.20136C11.9276 1.3303 12 1.50516 12 1.6875V3.0625C12 3.24484 11.9276 3.4197 11.7986 3.54864C11.6697 3.67757 11.4948 3.75 11.3125 3.75H1.6875C1.50516 3.75 1.3303 3.67757 1.20136 3.54864C1.07243 3.4197 1 3.24484 1 3.0625V1.6875ZM1 7.1875C1 7.00516 1.07243 6.8303 1.20136 6.70136C1.3303 6.57243 1.50516 6.5 1.6875 6.5H4.4375C4.61984 6.5 4.7947 6.57243 4.92364 6.70136C5.05257 6.8303 5.125 7.00516 5.125 7.1875V11.3125C5.125 11.4948 5.05257 11.6697 4.92364 11.7986C4.7947 11.9276 4.61984 12 4.4375 12H1.6875C1.50516 12 1.3303 11.9276 1.20136 11.7986C1.07243 11.6697 1 11.4948 1 11.3125V7.1875Z"
                        stroke="#515151" strokeLinecap="round" strokeLinejoin="round" />
                    </svg>
                  </div>
                  <h2>Event Registration Details</h2>
                </div>
              </div>
              <div className="col-md-7">
                <div className="rightWrap">
                  <div className="searchWrap">
                    <input placeholder="Search..." />
                    <button className="btnSearch">
                      <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M15 15L10.3333 10.3333M1 6.44444C1 7.15942 1.14082 7.86739 1.41443 8.52794C1.68804 9.18849 2.08908 9.78868 2.59464 10.2942C3.1002 10.7998 3.7004 11.2008 4.36095 11.4745C5.0215 11.7481 5.72947 11.8889 6.44444 11.8889C7.15942 11.8889 7.86739 11.7481 8.52794 11.4745C9.18849 11.2008 9.78868 10.7998 10.2942 10.2942C10.7998 9.78868 11.2008 9.18849 11.4745 8.52794C11.7481 7.86739 11.8889 7.15942 11.8889 6.44444C11.8889 5.72947 11.7481 5.0215 11.4745 4.36095C11.2008 3.7004 10.7998 3.1002 10.2942 2.59464C9.78868 2.08908 9.18849 1.68804 8.52794 1.41443C7.86739 1.14082 7.15942 1 6.44444 1C5.72947 1 5.0215 1.14082 4.36095 1.41443C3.7004 1.68804 3.1002 2.08908 2.59464 2.59464C2.08908 3.1002 1.68804 3.7004 1.41443 4.36095C1.14082 5.0215 1 5.72947 1 6.44444Z" stroke="#939393" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                      </svg>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="eventDtlListTbl">
          <table>
            <thead>
              <tr>
                <th>
                  <input id="checkAll" type="checkbox" />
                </th>
                <th>Event Name</th>
                <th>Name</th>
                <th>Mobile No.</th>
                <th>Reg Date</th>
                <th>Confirmation Status</th>
              </tr>
            </thead>
            <tbody>
              {orders.map((item, index) => (
                <tr key={index}>
                  <td>
                    <input
                      type="checkbox"
                      onChange={(e) => handleCheckboxChange(e, item)}
                    />
                  </td>
                  <td>{item.event_name}</td>
                  <td>{`${item.order_details.contact.purchaser_first_name} ${item.order_details.contact.purchaser_last_name}`}</td>
                  <td>{item.telephone}</td>
                  <td>{item.registered_date}</td>
                  <td>{item.confirm_status ? 'Yes' : 'No'}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        <div className="ctaSave">
          <button className="btn btnConfirm" onClick={handleSendWhatsApp}>Confirm</button>
          <button className="btn btnReminder" onClick={handleSendReminder}>Send Reminder</button>
        </div>
      </div>
    </main>
  );
};

export default OAuthRedirect;
