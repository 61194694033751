import React from 'react';
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';

const CircularProgress = ({total, final }) => {
    const percentage = (parseInt(final) / parseInt(total)) * 100;
    const textColor = percentage === 100 ? '#04E762' : '#FCB833';
    const svgCheckmark = `
    <svg width="14" height="11" viewBox="0 0 10 7" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M1 3.66667L3.66667 6.33333L9 1" stroke="#2EB83C" stroke-width="1.01053" stroke-linecap="round" stroke-linejoin="round"/>
    </svg>
`;
    return (
        <div className='CircularProgressBox'>
            <div className='CircularProgressWrap'>
                <div className='CircularProgressOuter'>
                    <div className='CircularProgressInner'>
                    <CircularProgressbar
                            value={percentage}
                            // text={percentage === 100 ? null : `${Math.round(percentage)}%`}
                            styles={buildStyles({
                                textColor: textColor,
                                pathColor: textColor,
                                trailColor: '#F2F5FF',
                            })}
                        />
                        {percentage === 100 && (
                            <div
                                dangerouslySetInnerHTML={{ __html: svgCheckmark }}
                                style={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)' }}
                            />
                        )}
                    </div>
                </div>
            </div>
            <span style={{ color: textColor }}>
                {percentage === 100 ? 'Completed' : 'Pending'}
            </span>
        </div>
    );
};

export default CircularProgress;
