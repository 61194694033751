import React, { useState, useEffect } from 'react';
import Form from 'react-bootstrap/Form';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

const AnlyticsOverView = ({overviewData}) =>{
    const [selectedFromDate, setSelectedFromDate] = useState(new Date());
    const [selectedToDate, setSelectedToDate] = useState(new Date());
    return(
        <div className='row'>
            <div className='col-md-12'>
                <div className='topFiltersWrap'>
                    <div className='row align-items-end'>
                        <div className='col-md-6'>
                            <h2 className='topTTlTxt'>Filter Date </h2>
                            <div className='filterWrap'>
                                <div className='topTitle'>
                                    <button className='btn'>
                                        <svg width="9" height="12" viewBox="0 0 9 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M4.64404 1.06331L8.02473 4.4794C8.19432 4.65127 8.19432 4.9297 8.02473 5.10157C7.94396 5.18548 7.83313 5.2328 7.71739 5.2328C7.60166 5.2328 7.49083 5.18548 7.41006 5.10157L4.77161 2.43679V9.02831C4.77161 9.27144 4.5769 9.46853 4.3367 9.46853C4.09651 9.46853 3.90179 9.27144 3.90179 9.02831V2.43679L1.26335 5.10157C1.18261 5.18528 1.07158 5.23193 0.95601 5.23071C0.840576 5.23126 0.729791 5.18471 0.648675 5.10157C0.479088 4.9297 0.479088 4.65127 0.648675 4.4794L4.02937 1.06331C4.19916 0.891656 4.47424 0.891656 4.64404 1.06331Z" fill="black" />
                                            <path d="M1.43731 10.3724H7.23609C7.47629 10.3724 7.671 10.5695 7.671 10.8127C7.671 11.0558 7.47629 11.2529 7.23609 11.2529H1.43731C1.19712 11.2529 1.0024 11.0558 1.0024 10.8127C1.0024 10.5695 1.19712 10.3724 1.43731 10.3724Z" fill="black" />
                                        </svg>
                                        Export
                                    </button>
                                </div>
                                <ul>
                                    <li>
                                        <div className='inputField fromDateWrap'>
                                            <label>From</label>
                                            <DatePicker
                                                toggleCalendarOnIconClick
                                                selected={selectedFromDate}
                                                // onChange={(date) => setSelectedFromDate(date)}
                                            />
                                        </div>
                                    </li>
                                    <li>
                                        <div className='inputField'>
                                            <label>To</label>
                                            <DatePicker
                                                toggleCalendarOnIconClick
                                                selected={selectedToDate}
                                                // onChange={(date) => setSelectedToDate(date)}
                                            />
                                        </div>
                                    </li>
                                    <li>
                                        <div className='inputField'>
                                            <label>Period</label>
                                            <Form.Select aria-label="Last 5 days">
                                                <option value={'Last 5 days'}>Last 5 days</option>
                                                <option value={'Last 7 days'}>Last 7 days</option>
                                                <option value={'Last 8 days'}>Last 8 days</option>
                                                <option value={'Last 15 days'}>Last 15 days</option>
                                                <option value={'Last 20 days'}>Last 20 days</option>
                                                <option value={'Last 30 days'}>Last 30 days</option>
                                            </Form.Select>
                                        </div>
                                    </li>
                                    <li>
                                        <div className='ctaWrap'>
                                            <button className='btn'>Apply Now</button>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div className='col-md-6'>
                            <h2 className='topTTlTxt'>Overview</h2>
                            <ul className='overviewList'>
                                <li>
                                    <div className='itemWrap'>
                                        <div className='title'>
                                            <h2>{overviewData.total_messages_sent}</h2>
                                        </div>
                                        <div className='icoWrap'>
                                            <svg width="28" height="20" viewBox="0 0 28 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M1.91699 10.0002L9.83366 17.9168L25.667 2.0835" stroke="#1944F1" stroke-width="3" stroke-linecap="round" stroke-linejoin="round" />
                                            </svg>
                                        </div>
                                        <button className='btn'>
                                            Total
                                            <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M7.51234 10.4837V10.4908M7.51234 8.36175C7.83045 8.36271 8.13961 8.25641 8.38987 8.06003C8.64014 7.86365 8.81691 7.58865 8.89164 7.27943C8.96637 6.97022 8.93469 6.64484 8.80173 6.35584C8.66876 6.06685 8.44227 5.8311 8.15883 5.68668C7.87744 5.54253 7.55556 5.49784 7.24554 5.55987C6.93553 5.6219 6.65563 5.787 6.45136 6.02831M7.51234 1.28857C12.605 1.28857 13.8782 2.56175 13.8782 7.65443C13.8782 12.7471 12.605 14.0203 7.51234 14.0203C2.41966 14.0203 1.14648 12.7471 1.14648 7.65443C1.14648 2.56175 2.41966 1.28857 7.51234 1.28857Z" stroke="#A3A3A3" stroke-width="1.41463" stroke-linecap="round" stroke-linejoin="round" />
                                            </svg>
                                        </button>
                                    </div>
                                </li>
                                <li>
                                    <div className='itemWrap'>
                                        <div className='title'>
                                            <h2>{overviewData.total_messages_sent_successfully}</h2>
                                        </div>
                                        <div className='icoWrap'>
                                            <svg width="28" height="20" viewBox="0 0 28 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M1.91699 10.0002L9.83366 17.9168L25.667 2.0835" stroke="#1944F1" stroke-width="3" stroke-linecap="round" stroke-linejoin="round" />
                                            </svg>
                                        </div>
                                        <button className='btn'>
                                            Send
                                            <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M7.51234 10.4837V10.4908M7.51234 8.36175C7.83045 8.36271 8.13961 8.25641 8.38987 8.06003C8.64014 7.86365 8.81691 7.58865 8.89164 7.27943C8.96637 6.97022 8.93469 6.64484 8.80173 6.35584C8.66876 6.06685 8.44227 5.8311 8.15883 5.68668C7.87744 5.54253 7.55556 5.49784 7.24554 5.55987C6.93553 5.6219 6.65563 5.787 6.45136 6.02831M7.51234 1.28857C12.605 1.28857 13.8782 2.56175 13.8782 7.65443C13.8782 12.7471 12.605 14.0203 7.51234 14.0203C2.41966 14.0203 1.14648 12.7471 1.14648 7.65443C1.14648 2.56175 2.41966 1.28857 7.51234 1.28857Z" stroke="#A3A3A3" stroke-width="1.41463" stroke-linecap="round" stroke-linejoin="round" />
                                            </svg>
                                        </button>
                                    </div>
                                </li>
                                <li>
                                    <div className='itemWrap'>
                                        <div className='title'>
                                            <h2>{overviewData.total_messages_delivered}</h2>
                                        </div>
                                        <div className='icoWrap'>
                                            <svg width="36" height="20" viewBox="0 0 36 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M10.0837 10.0002L18.0003 17.9168L33.8337 2.0835M2.16699 10.0002L10.0837 17.9168M18.0003 10.0002L25.917 2.0835" stroke="#1944F1" stroke-width="3" stroke-linecap="round" stroke-linejoin="round" />
                                            </svg>
                                        </div>
                                        <button className='btn'>
                                            Delivered
                                            <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M7.51234 10.4837V10.4908M7.51234 8.36175C7.83045 8.36271 8.13961 8.25641 8.38987 8.06003C8.64014 7.86365 8.81691 7.58865 8.89164 7.27943C8.96637 6.97022 8.93469 6.64484 8.80173 6.35584C8.66876 6.06685 8.44227 5.8311 8.15883 5.68668C7.87744 5.54253 7.55556 5.49784 7.24554 5.55987C6.93553 5.6219 6.65563 5.787 6.45136 6.02831M7.51234 1.28857C12.605 1.28857 13.8782 2.56175 13.8782 7.65443C13.8782 12.7471 12.605 14.0203 7.51234 14.0203C2.41966 14.0203 1.14648 12.7471 1.14648 7.65443C1.14648 2.56175 2.41966 1.28857 7.51234 1.28857Z" stroke="#A3A3A3" stroke-width="1.41463" stroke-linecap="round" stroke-linejoin="round" />
                                            </svg>
                                        </button>
                                    </div>
                                </li>
                                <li>
                                    <div className='itemWrap'>
                                        <div className='title'>
                                            <h2>{overviewData.total_messages_read}</h2>
                                        </div>
                                        <div className='icoWrap'>
                                            <svg width="32" height="22" viewBox="0 0 32 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M12.8333 11C12.8333 11.8399 13.167 12.6453 13.7608 13.2392C14.3547 13.833 15.1601 14.1667 16 14.1667C16.8399 14.1667 17.6453 13.833 18.2392 13.2392C18.833 12.6453 19.1667 11.8399 19.1667 11C19.1667 10.1601 18.833 9.35469 18.2392 8.76083C17.6453 8.16696 16.8399 7.83333 16 7.83333C15.1601 7.83333 14.3547 8.16696 13.7608 8.76083C13.167 9.35469 12.8333 10.1601 12.8333 11Z" stroke="#1944F1" stroke-width="3" stroke-linecap="round" stroke-linejoin="round" />
                                                <path d="M30.25 11C26.45 17.3333 21.7 20.5 16 20.5C10.3 20.5 5.55 17.3333 1.75 11C5.55 4.66667 10.3 1.5 16 1.5C21.7 1.5 26.45 4.66667 30.25 11Z" stroke="#1944F1" stroke-width="3" stroke-linecap="round" stroke-linejoin="round" />
                                            </svg>
                                        </div>
                                        <button className='btn'>
                                            Read
                                            <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M7.51234 10.4837V10.4908M7.51234 8.36175C7.83045 8.36271 8.13961 8.25641 8.38987 8.06003C8.64014 7.86365 8.81691 7.58865 8.89164 7.27943C8.96637 6.97022 8.93469 6.64484 8.80173 6.35584C8.66876 6.06685 8.44227 5.8311 8.15883 5.68668C7.87744 5.54253 7.55556 5.49784 7.24554 5.55987C6.93553 5.6219 6.65563 5.787 6.45136 6.02831M7.51234 1.28857C12.605 1.28857 13.8782 2.56175 13.8782 7.65443C13.8782 12.7471 12.605 14.0203 7.51234 14.0203C2.41966 14.0203 1.14648 12.7471 1.14648 7.65443C1.14648 2.56175 2.41966 1.28857 7.51234 1.28857Z" stroke="#A3A3A3" stroke-width="1.41463" stroke-linecap="round" stroke-linejoin="round" />
                                            </svg>
                                        </button>
                                    </div>
                                </li>
                                <li>
                                    <div className='itemWrap'>
                                        <div className='title'>
                                            <h2>{overviewData.total_messages_failed}</h2>
                                        </div>
                                        <div className='icoWrap'>
                                            <svg width="34" height="33" viewBox="0 0 34 33" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M18.5222 27.4371C15.3468 27.9479 12.091 27.4756 9.19167 26.0834L1.75 27.6667L3.80833 21.4917C0.128667 16.0498 1.5505 9.02771 7.13333 5.06621C12.7162 1.1063 20.7342 1.43088 25.8879 5.82621C29.2572 8.70155 30.7218 12.7343 30.1518 16.6135M31.8333 30.8334L23.9167 22.9167M23.9167 30.8334L31.8333 22.9167" stroke="#1944F1" stroke-width="3" stroke-linecap="round" stroke-linejoin="round" />
                                            </svg>
                                        </div>
                                        <button className='btn'>
                                            Failed
                                            <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M7.51234 10.4837V10.4908M7.51234 8.36175C7.83045 8.36271 8.13961 8.25641 8.38987 8.06003C8.64014 7.86365 8.81691 7.58865 8.89164 7.27943C8.96637 6.97022 8.93469 6.64484 8.80173 6.35584C8.66876 6.06685 8.44227 5.8311 8.15883 5.68668C7.87744 5.54253 7.55556 5.49784 7.24554 5.55987C6.93553 5.6219 6.65563 5.787 6.45136 6.02831M7.51234 1.28857C12.605 1.28857 13.8782 2.56175 13.8782 7.65443C13.8782 12.7471 12.605 14.0203 7.51234 14.0203C2.41966 14.0203 1.14648 12.7471 1.14648 7.65443C1.14648 2.56175 2.41966 1.28857 7.51234 1.28857Z" stroke="#A3A3A3" stroke-width="1.41463" stroke-linecap="round" stroke-linejoin="round" />
                                            </svg>
                                        </button>
                                    </div>
                                </li>                                
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};
export default AnlyticsOverView;
