import React, { useState, useEffect } from 'react';
import Header from "../../Common/Header";
import Sidebar from "../../Common/Sidebar";
import { get_sms_flow_list } from '../../../services/sms_flow_services';
import { useNavigate } from 'react-router-dom';

const SmsFlowList = () => {
    const [isOpen, setIsOpen] = useState(false);
    const [flowList, setFlowList] = useState([])
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState('');
    const navigate = useNavigate()
    useEffect(() => {
        getFlowList()
    }, [])
    const toggleSidebar = () => {
        setIsOpen(!isOpen);
    };
    const getFlowList = async () => {
        const response = await get_sms_flow_list()
        setFlowList(response)
    }
    // const deleteFlowList = async(flow_id) => {
    //     await delete_flow(onSuccess,onError,flow_id)
    // }
    // const onSuccess = (response) => {
    //     console.log(response)
    //     if (response) {
    //         window.location.reload()
    //     } else {
    //         console.log('Category update failed');
    //     }
    //     setIsLoading(false);
    // };

    const onError = (error) => {
        setError(error.message);
        setIsLoading(false);
    };
    return (
        <div className={isOpen ? "app-container sidebar-open" : "app-container"}>
            <Header toggleSidebar={toggleSidebar} />
            <Sidebar isOpen={isOpen} />
            <main className="content workflowListPage">
                <div className='workflowListWrap'>
                    <div className='workflowListTop'>
                        <div className="ctaWrap">
                            <button className='btn' onClick={() => navigate('/SmsFlow')}>Create New Workflow</button>
                        </div>
                    </div>
                    <div className='workflowListTbl'>
                        <table>
                            <thead>
                                <tr>
                                    <th>Workflow Name</th>
                                    <th>Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                {flowList.map((flow) => (
                                    <tr>
                                        <td>{flow.name}</td>
                                        <td>
                                            <button className='btn' onClick={() => navigate(`/SmsFlow/${flow.id}`)} >
                                                <svg xmlns="http://www.w3.org/2000/svg" version="1.1" width="14" height="14" x="0" y="0" viewBox="0 0 519.643 519.643"><g><circle cx="259.823" cy="259.866" r="80" fill="#98A2B3" opacity="1" data-original="#98A2B3"></circle><path d="M511.673 237.706c-61.494-74.31-154.579-145.84-251.85-145.84-97.29 0-190.397 71.58-251.85 145.84-10.63 12.84-10.63 31.48 0 44.32 15.45 18.67 47.84 54.71 91.1 86.2 108.949 79.312 212.311 79.487 321.5 0 43.26-31.49 75.65-67.53 91.1-86.2 10.599-12.815 10.654-31.438 0-44.32zm-251.85-89.84c61.76 0 112 50.24 112 112s-50.24 112-112 112-112-50.24-112-112 50.24-112 112-112z" fill="#98A2B3" opacity="1" data-original="#98A2B3"></path></g></svg>
                                            </button>
                                            <button className='btn'>
                                                <svg xmlns="http://www.w3.org/2000/svg" version="1.1" width="14" height="14" x="0" y="0" viewBox="0 0 492.493 492"><g><path d="M304.14 82.473 33.165 353.469a10.799 10.799 0 0 0-2.816 4.949L.313 478.973a10.716 10.716 0 0 0 2.816 10.136 10.675 10.675 0 0 0 7.527 3.114 10.6 10.6 0 0 0 2.582-.32l120.555-30.04a10.655 10.655 0 0 0 4.95-2.812l271-270.977zM476.875 45.523 446.711 15.36c-20.16-20.16-55.297-20.14-75.434 0l-36.949 36.95 105.598 105.597 36.949-36.949c10.07-10.066 15.617-23.465 15.617-37.715s-5.547-27.648-15.617-37.719zm0 0" fill="#98A2B3" opacity="1" data-original="#98A2B3"></path></g></svg>
                                            </button>
                                            <button className='btn'>
                                                <svg xmlns="http://www.w3.org/2000/svg" version="1.1" width="14" height="14" x="0" y="0" viewBox="0 0 384 384"><g><path d="M64 341.333C64 364.907 83.093 384 106.667 384h170.667C300.907 384 320 364.907 320 341.333v-256H64v256zM266.667 21.333 245.333 0H138.667l-21.334 21.333H42.667V64h298.666V21.333z" fill="#98A2B3" opacity="1" data-original="#98A2B3"></path></g></svg>
                                            </button>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                </div>
            </main>
        </div>
    );
};

export default SmsFlowList;

