import React, { useState, useEffect } from 'react';
import Form from 'react-bootstrap/Form';
import { CustomScroll } from "react-custom-scroll";
import Header from '../../Components/Common/Header';
import Sidebar from '../../Components/Common/Sidebar';
import CrmIco from '../../assets/img/crmIco.png';


const Subscription = () => {
    const [isOpen, setIsOpen] = useState(false);
    const [isOn, setIsOn] = useState(true);
    const toggleSidebar = () => {
        setIsOpen(!isOpen);
    };
    const handleToggle = () => {
        setIsOn(!isOn);
    };

    return (
        <div className={isOpen ? "app-container sidebar-open" : "app-container"}>
            <Header toggleSidebar={toggleSidebar} />
            <Sidebar isOpen={isOpen} />
            <main className="content subscriptionPage">
                <div className='subscriptionWrap'>
                    <div className='container-fluid pad0px'>
                        <div className='row'>
                            <div className='col-md-12 pad0px'>
                                <div className='topDataWrap'>
                                    <div className='titleWrap'>
                                        <h2>Purchase a subscription</h2>
                                    </div>
                                    <div className='rightDataWrap'>
                                        <div className='filterWrap'>
                                            <input placeholder='Filter by Featuers'/>
                                            <button className='btn'>
                                                <svg width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M19.5 3.75L17.25 3.75M11.625 3.75L1.5 3.75M19.5 10.5L8.25 10.5M2.625 10.5L1.5 10.5M19.5 17.25L18.375 17.25M11.625 17.25L1.5 17.25M16.125 1.5C16.4234 1.5 16.7095 1.61853 16.9205 1.82951C17.1315 2.04048 17.25 2.32663 17.25 2.625L17.25 4.875C17.25 5.17337 17.1315 5.45952 16.9205 5.6705C16.7095 5.88147 16.4234 6 16.125 6L12.75 6C12.4516 6 12.1655 5.88147 11.9545 5.6705C11.7435 5.45952 11.625 5.17337 11.625 4.875L11.625 2.625C11.625 2.32663 11.7435 2.04048 11.9545 1.8295C12.1655 1.61853 12.4516 1.5 12.75 1.5L16.125 1.5ZM7.125 8.25C7.42337 8.25 7.70952 8.36853 7.9205 8.57951C8.13147 8.79048 8.25 9.07663 8.25 9.375L8.25 11.625C8.25 11.9234 8.13147 12.2095 7.9205 12.4205C7.70952 12.6315 7.42337 12.75 7.125 12.75L3.75 12.75C3.45163 12.75 3.16548 12.6315 2.95451 12.4205C2.74353 12.2095 2.625 11.9234 2.625 11.625L2.625 9.375C2.625 9.07663 2.74353 8.79048 2.95451 8.57951C3.16548 8.36853 3.45163 8.25 3.75 8.25L7.125 8.25ZM17.25 15C17.5484 15 17.8345 15.1185 18.0455 15.3295C18.2565 15.5405 18.375 15.8266 18.375 16.125L18.375 18.375C18.375 18.6734 18.2565 18.9595 18.0455 19.1705C17.8345 19.3815 17.5484 19.5 17.25 19.5L12.75 19.5C12.4516 19.5 12.1655 19.3815 11.9545 19.1705C11.7435 18.9595 11.625 18.6734 11.625 18.375L11.625 16.125C11.625 15.8266 11.7435 15.5405 11.9545 15.3295C12.1655 15.1185 12.4516 15 12.75 15L17.25 15Z" stroke="#939393" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                                </svg>
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='row'>
                            <div className='col-md-12'>
                                <div className='subscriptionTtlWrap'>
                                    <div className='row'>
                                        <div className='col-md-6'>
                                            <h2 className='upgradeTxt'>Upgrade Plan</h2>
                                        </div>
                                        <div className='col-md-6 d-flex justify-content-end'>
                                            <ul className='tabsBtnsList'>
                                                <li>
                                                    <button className='btn active'>Monthly</button>
                                                </li>
                                                <li>
                                                    <button className='btn'>Yearly</button>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                    <div className='row'>
                                        <div className='col-md-9'>
                                            <ul className='subscriptionList'>
                                                <li className='proplan'>
                                                    <div className='itemWrap'>
                                                        <div className='topWrap'>
                                                            <h2>Pro Plan</h2>
                                                            <p>Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an uvnknown printer took a galley of type and scrambled it to make a type ...</p>
                                                        </div>
                                                        <div className='btmWrap'>
                                                            <div className='dataWrap'>
                                                                <a href='#'>View subscription</a>
                                                                <p>Starting</p>
                                                                <h3>000 <span>User / Month</span></h3>
                                                            </div>
                                                            <div className='ctaWrap'>
                                                                <button className='btn'>CURRENT PLAN</button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </li>
                                                <li>
                                                    <div className='itemWrap'>
                                                        <div className='icoWrap'>
                                                            <svg width="20" height="19" viewBox="0 0 20 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                <path d="M10 0L12.2451 6.90983H19.5106L13.6327 11.1803L15.8779 18.0902L10 13.8197L4.12215 18.0902L6.36729 11.1803L0.489435 6.90983H7.75486L10 0Z" fill="#ECAC07" />
                                                            </svg>
                                                        </div>
                                                        <div className='topWrap'>
                                                            <h2>Economy Plan</h2>
                                                            <p>Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an uvnknown printer took a galley of type and scrambled it to make a type ...</p>
                                                        </div>
                                                        <div className='btmWrap'>
                                                            <div className='dataWrap'>
                                                                <p>Starting</p>
                                                                <h3>000 <span>User / Month</span></h3>
                                                            </div>
                                                            <div className='ctaWrap'>
                                                                <button className='btn'>UPGRADE</button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </li>
                                                <li>
                                                    <div className='itemWrap'>
                                                        <div className='icoWrap'>
                                                            <svg width="20" height="19" viewBox="0 0 20 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                <path d="M10 0L12.2451 6.90983H19.5106L13.6327 11.1803L15.8779 18.0902L10 13.8197L4.12215 18.0902L6.36729 11.1803L0.489435 6.90983H7.75486L10 0Z" fill="#ECAC07" />
                                                            </svg>
                                                        </div>
                                                        <div className='topWrap'>
                                                            <h2>Premium Plan</h2>
                                                            <p>Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an uvnknown printer took a galley of type and scrambled it to make a type ...</p>
                                                        </div>
                                                        <div className='btmWrap'>
                                                            <div className='dataWrap'>
                                                                <p>Starting</p>
                                                                <h3>000 <span>User / Month</span></h3>
                                                            </div>
                                                            <div className='ctaWrap'>
                                                                <button className='btn'>UPGRADE</button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='row'>
                            <div className='col-md-12'>
                                <div className='subPurHistoy'>
                                    <div className='row'>
                                        <div className='col-md-12'>
                                            <div className='ttlTxtWrap'>
                                                <h2>Purchase Histoy</h2>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='row'>
                                        <div className='col-md-12'>
                                            <div className='subPurHistoyTbl'>
                                                <table>
                                                    <thead>
                                                        <tr>
                                                            <th><input type="checkbox" /></th>
                                                            <th>Plan Name </th>
                                                            <th>Amount</th>
                                                            <th>Purchase Date</th>
                                                            <th>Status</th>
                                                            <th>Invoice</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr>
                                                            <td><input type="checkbox" /></td>
                                                            <td>Pro Plan</td>
                                                            <td>000</td>
                                                            <td>JUN 05, 2024</td>
                                                            <td>
                                                                <span className='success'>Success</span>
                                                            </td>
                                                            <td>
                                                                <button className='btn'>Download Invoice</button>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td><input type="checkbox" /></td>
                                                            <td>Pro Plan</td>
                                                            <td>000</td>
                                                            <td>JUN 05, 2024</td>
                                                            <td>
                                                                <span className='success'>Success</span>
                                                            </td>
                                                            <td>
                                                                <button className='btn'>Download Invoice</button>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td><input type="checkbox" /></td>
                                                            <td>Pro Plan</td>
                                                            <td>000</td>
                                                            <td>JUN 05, 2024</td>
                                                            <td>
                                                                <span className='decline'>Decline</span>
                                                            </td>
                                                            <td>
                                                                <button className='btn'>Download Invoice</button>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td><input type="checkbox" /></td>
                                                            <td>Pro Plan</td>
                                                            <td>000</td>
                                                            <td>JUN 05, 2024</td>
                                                            <td>
                                                                <span className='success'>Success</span>
                                                            </td>
                                                            <td>
                                                                <button className='btn'>Download Invoice</button>
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </main>
        </div>
    );
};

export default Subscription;

