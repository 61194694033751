import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from "react-router-dom";
import Form from 'react-bootstrap/Form';
import { CustomScroll } from "react-custom-scroll";
import { Modal, Button } from 'react-bootstrap';
import Header from '../../Components/Common/Header';
import Sidebar from '../../Components/Common/Sidebar';
import { deleteContactList, getAllGoups } from '../../services/group-service';
import * as XLSX from 'xlsx';
import { formatDate, formatDateTime, spinnerContainerStyle } from '../../utility/CustomFormating'
import Spinner from 'react-bootstrap/Spinner';

const ContactList = () => {
    let navigate = useNavigate();
    const [isOpen, setIsOpen] = useState(false);
    const [show, setShow] = useState(false);
    const [uploadShow, setUploadShow] = useState(false);
    const [data, SetData] = useState([]);
    const [excelData, setExcelData] = useState([])
    const [error, setError] = useState(false);
    const [filteredData, setFilteredData] = useState([]);
    const [searchQuery, setSearchQuery] = useState('');
    const [isLoad, setIsLoad] = useState(true)
    const toggleSidebar = () => {
        setIsOpen(!isOpen);
    };
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const handleUploadClose = () => setUploadShow(false);
    const handleUploadShow = () => setUploadShow(true);
    useEffect(() => {
        fetchInitialData();
    }, [])
    const token = localStorage.getItem('token')
    const user = JSON.parse(localStorage.getItem('user'))
    const fetchInitialData = async () => {
        try {
            const response = await getAllGoups(token, user.id);
            if (response) {
                SetData(response);
                setIsLoad(false)
            } else {
                setError(response.message);
                setIsLoad(false)
            }
        } catch (error) {
            console.log("Error fetching data:", error)
            setError(error.message);
            // setIsLoad(false)
        }
    }

    const handleClick = () => {
        navigate(`/CreateNewContactList`);
    }
    function handleFileUpload(e) {
        const file = e.target.files[0];
        const reader = new FileReader();

        reader.onload = (event) => {
            const workbook = XLSX.read(event.target.result, { type: 'binary' });
            const sheetName = workbook.SheetNames[0];
            const sheet = workbook.Sheets[sheetName];
            const sheetData = XLSX.utils.sheet_to_json(sheet, {
                header: 1,
                defval: ""
            });

            setExcelData(sheetData);
            navigate(`/MapFields`, { state: { data: sheetData, file: file } });
        };

        reader.readAsBinaryString(file);
    }
    const handleDelete = async (groupId) => {
        await deleteContactList(groupId, onSuccess, onError)
    }
    const onSuccess = (response) => {
        fetchInitialData()
    }
    const onError = (error) => {
        setError(error)
        // setIsLoading(false);
        console.log("error::", error)
    }


    /*-----Delete Popup-----*/
    const [isPopupOpen, setIsPopupOpen] = useState(false);
    const [selectedGroupId, setSelectedGroupId] = useState(null);
    const handleDeleteClick = (groupId) => {
        setSelectedGroupId(groupId);
        setIsPopupOpen(true);
    };
    const handleConfirmDelete = async () => {
        await deleteContactList(selectedGroupId, onSuccess, onError);
        setIsPopupOpen(false);
    };
    const handleCancelDelete = () => {
        setIsPopupOpen(false);
    };
    /*-----Delete Popup-----*/
    const handleSearch = (e) => {
        const query = e.target.value;
        setSearchQuery(query);

        if (query === '') {
            setFilteredData(data); // If search query is empty, show all data
        } else {
            setFilteredData(
                data.filter(item =>
                    item.group_name.toLowerCase().includes(query.toLowerCase())
                )
            );
        }
    };

    return (
        <>
            {isLoad ?
                <div style={spinnerContainerStyle}> <Spinner
                    animation="border"
                    size="lg"
                    style={{ width: '5rem', height: '5rem', color: '#1944F1' }}

                /></div> : token ?
                    <div className={isOpen ? "app-container sidebar-open" : "app-container"}>
                        <Header toggleSidebar={toggleSidebar} />
                        {/* <Sidebar isOpen={isOpen} /> */}

                        <main className="content contactListPage">
                            <div className='contactListWrap'>
                                <div className='topDataWrap'>
                                    <div className='container-fluid'>
                                        <div className='row align-items-center'>
                                            <div className='col-md-5'>
                                                <div className='titleWrap'>
                                                    <div className='icoWrap'>
                                                        <svg width="40" height="44" viewBox="0 0 40 44" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                            <path d="M27.4375 1V10.2632C27.4375 10.8773 27.6811 11.4664 28.1148 11.9007C28.5485 12.335 29.1367 12.5789 29.75 12.5789H39M27.4375 1H11.25C10.0234 1 8.84699 1.48797 7.97963 2.35656C7.11228 3.22515 6.625 4.40321 6.625 5.63158V12.5789M27.4375 1L39 12.5789M39 12.5789V38.0526C39 39.281 38.5127 40.4591 37.6454 41.3277C36.778 42.1962 35.6016 42.6842 34.375 42.6842H22.8125" stroke="#515151" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                                            <path d="M3.47059 18H8.41176L10.8824 24.1765L7.79412 26.0294C9.11707 28.7119 11.2881 30.8829 13.9706 32.2059L15.8235 29.1176L22 31.5882V36.5294C22 37.1847 21.7397 37.8131 21.2764 38.2764C20.8131 38.7397 20.1847 39 19.5294 39C14.7109 38.7072 10.1661 36.661 6.75255 33.2474C3.33903 29.8339 1.29283 25.2891 1 20.4706C1 19.8153 1.26029 19.1869 1.72362 18.7236C2.18694 18.2603 2.81535 18 3.47059 18Z" stroke="#515151" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                                        </svg>
                                                    </div>
                                                    <h2>Contact Lists</h2>
                                                </div>
                                            </div>
                                            <div className='col-md-7'>
                                                <div className='rightWrap'>
                                                    {/* <button className='btnFilter btn' >
                                                        <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                            <path d="M17 3L13 3M9 3L1 3M17 9L7 9M3 9L1 9M17 15L16 15M12 15L0.999999 15M13 1L13 5L9 5L9 1L13 1ZM7 7L7 11L3 11L3 7L7 7ZM16 13L16 17L12 17L12 13L16 13Z" stroke="#939393" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                                        </svg>
                                                    </button> */}
                                                    <button className='btnUpload btn' onClick={handleUploadShow}>
                                                        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                            <path d="M19 4C19 3.20435 18.6839 2.44129 18.1213 1.87868C17.5587 1.31607 16.7956 1 16 1H4C3.20435 1 2.44129 1.31607 1.87868 1.87868C1.31607 2.44129 1 3.20435 1 4M10 13V5M10 5L7 8M10 5L13 8M4 19H16C16.7956 19 17.5587 18.6839 18.1213 18.1213C18.6839 17.5587 19 16.7956 19 16C19 15.2044 18.6839 14.4413 18.1213 13.8787C17.5587 13.3161 16.7956 13 16 13H4C3.20435 13 2.44129 13.3161 1.87868 13.8787C1.31607 14.4413 1 15.2044 1 16C1 16.7956 1.31607 17.5587 1.87868 18.1213C2.44129 18.6839 3.20435 19 4 19Z" stroke="#939393" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                                        </svg>
                                                    </button>
                                                    <div className='searchWrap'>
                                                        <input value={searchQuery}
                                                            onChange={handleSearch} />
                                                        <button className='btnSearch'>
                                                            <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                <path d="M15 15L10.3333 10.3333M1 6.44444C1 7.15942 1.14082 7.86739 1.41443 8.52794C1.68804 9.18849 2.08908 9.78868 2.59464 10.2942C3.1002 10.7998 3.7004 11.2008 4.36095 11.4745C5.0215 11.7481 5.72947 11.8889 6.44444 11.8889C7.15942 11.8889 7.86739 11.7481 8.52794 11.4745C9.18849 11.2008 9.78868 10.7998 10.2942 10.2942C10.7998 9.78868 11.2008 9.18849 11.4745 8.52794C11.7481 7.86739 11.8889 7.15942 11.8889 6.44444C11.8889 5.72947 11.7481 5.0215 11.4745 4.36095C11.2008 3.7004 10.7998 3.1002 10.2942 2.59464C9.78868 2.08908 9.18849 1.68804 8.52794 1.41443C7.86739 1.14082 7.15942 1 6.44444 1C5.72947 1 5.0215 1.14082 4.36095 1.41443C3.7004 1.68804 3.1002 2.08908 2.59464 2.59464C2.08908 3.1002 1.68804 3.7004 1.41443 4.36095C1.14082 5.0215 1 5.72947 1 6.44444Z" stroke="#939393" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                                            </svg>
                                                        </button>
                                                    </div>
                                                    <button className='btnAddNew' onClick={handleShow}>
                                                        <svg width="12" height="12" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                            <path d="M16 8.2963C16 9.01459 15.6177 9.37374 14.853 9.37374H10.638C9.87336 9.37374 9.49104 9.73288 9.49104 10.4512V14.9226C9.49104 15.6409 9.10872 16 8.34409 16H7.59857C6.83393 16 6.45161 15.6409 6.45161 14.9226V10.4512C6.45161 9.73288 6.06929 9.37374 5.30466 9.37374H1.14695C0.382318 9.37374 0 9.01459 0 8.2963V7.75758C0 7.03928 0.382318 6.68013 1.14695 6.68013H5.30466C6.06929 6.68013 6.45161 6.32099 6.45161 5.60269V1.07744C6.45161 0.359147 6.83393 0 7.59857 0H8.34409C9.10872 0 9.49104 0.359147 9.49104 1.07744V5.60269C9.49104 6.32099 9.87336 6.68013 10.638 6.68013H14.853C15.6177 6.68013 16 7.03928 16 7.75758V8.2963Z" fill="white" />
                                                        </svg>
                                                        Add new contacts
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className='contactListTbl'>
                                    <CustomScroll heightRelativeToParent="100%">
                                        <table>
                                            <thead>
                                                <tr>
                                                    <th>List name</th>
                                                    {/* <th>Lables</th> */}
                                                    <th>No. of contacts</th>
                                                    <th>Created On</th>
                                                    <th>Last updated</th>
                                                    <th>Status</th>
                                                    <th>Action</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {(filteredData.length > 0 ? filteredData : data).map((item, index) => (
                                                    <tr key={item.group_id}>
                                                        <td>{item.group_name}</td>
                                                        {/* <td>
                                                            <div className='labelWrap'>
                                                                <span>Label</span>
                                                                <span>Label</span>
                                                            </div>
                                                        </td> */}
                                                        <td>{item.contactCount}</td>
                                                        <td>{formatDate(item.createdAt)}</td>
                                                        <td>{formatDateTime(item.updatedAt)}</td>
                                                        <td className={item.is_active ? 'activeTxt' : ''}>
                                                            {item.is_active ? 'Active' : '--'}
                                                        </td>
                                                        <td>
                                                            <div className='ctaWrap'>

                                                                <button className='btn' onClick={() => navigate(`/CreatedContactList?groupId=${item.group_id}`)}>
                                                                    <svg width="13" height="10" viewBox="0 0 13 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                        <path d="M5.17615 4.94612C5.17615 5.29735 5.31568 5.6342 5.56404 5.88256C5.8124 6.13091 6.14924 6.27044 6.50047 6.27044C6.85171 6.27044 7.18855 6.13091 7.43691 5.88256C7.68527 5.6342 7.8248 5.29735 7.8248 4.94612C7.8248 4.59488 7.68527 4.25804 7.43691 4.00968C7.18855 3.76132 6.85171 3.62179 6.50047 3.62179C6.14924 3.62179 5.8124 3.76132 5.56404 4.00968C5.31568 4.25804 5.17615 4.59488 5.17615 4.94612Z" stroke="#515151" stroke-width="0.993243" stroke-linecap="round" stroke-linejoin="round" />
                                                                        <path d="M12.4599 4.94612C10.8707 7.59477 8.88426 8.91909 6.50047 8.91909C4.11669 8.91909 2.1302 7.59477 0.541016 4.94612C2.1302 2.29747 4.11669 0.973145 6.50047 0.973145C8.88426 0.973145 10.8707 2.29747 12.4599 4.94612Z" stroke="#515151" stroke-width="0.993243" stroke-linecap="round" stroke-linejoin="round" />
                                                                    </svg>
                                                                </button>
                                                                <button className='btn' onClick={() => handleDeleteClick(item.group_id)}>
                                                                    <svg width="13" height="14" viewBox="0 0 13 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                        <path d="M1.40625 3.64865H12.0008M5.37922 6.2973V10.2703M8.02787 6.2973V10.2703M2.06841 3.64865L2.73057 11.5946C2.73057 11.9458 2.8701 12.2827 3.11846 12.531C3.36682 12.7794 3.70367 12.9189 4.0549 12.9189H9.3522C9.70343 12.9189 10.0403 12.7794 10.2886 12.531C10.537 12.2827 10.6765 11.9458 10.6765 11.5946L11.3387 3.64865M4.71706 3.64865V1.66216C4.71706 1.48655 4.78682 1.31812 4.911 1.19394C5.03518 1.06976 5.20361 1 5.37922 1H8.02787C8.20349 1 8.37191 1.06976 8.49609 1.19394C8.62027 1.31812 8.69003 1.48655 8.69003 1.66216V3.64865" stroke="#515151" stroke-width="0.993243" stroke-linecap="round" stroke-linejoin="round" />
                                                                    </svg>
                                                                </button>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </table>
                                    </CustomScroll>
                                </div>
                            </div>
                        </main>

                        <Modal show={show} onHide={handleClose} className='newContactPopup'>
                            <Modal.Header closeButton>
                                <Modal.Title>  Add new contacts</Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                <div className='row'>
                                    <div className='col-md-6'>
                                        <div className='leftBtnWrap'>
                                            <div className="uploadContainer">
                                                <div className="uploadData">
                                                    <span className="uploadPlus">
                                                        <button className='btn' onClick={handleClick}>
                                                            <svg width="86" height="86" viewBox="0 0 86 86" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                <path d="M86 44.5926C86 48.4534 83.945 50.3838 79.8351 50.3838H57.1792C53.0693 50.3838 51.0143 52.3143 51.0143 56.1751V80.2087C51.0143 84.0696 48.9594 86 44.8495 86H40.8423C36.7324 86 34.6774 84.0696 34.6774 80.2087V56.1751C34.6774 52.3143 32.6225 50.3838 28.5125 50.3838H6.16487C2.05496 50.3838 0 48.4534 0 44.5926V41.697C0 37.8361 2.05496 35.9057 6.16487 35.9057H28.5125C32.6225 35.9057 34.6774 33.9753 34.6774 30.1145V5.79125C34.6774 1.93042 36.7324 0 40.8423 0H44.8495C48.9594 0 51.0143 1.93042 51.0143 5.79125V30.1145C51.0143 33.9753 53.0693 35.9057 57.1792 35.9057H79.8351C83.945 35.9057 86 37.8361 86 41.697V44.5926Z" fill="#C1C1C1" />
                                                            </svg>
                                                        </button>

                                                    </span>
                                                    {/* <input type="file" className="uploadInputs" /> */}
                                                </div>
                                                <div className="uploadFooter">
                                                    Create from scratch
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='col-md-6'>
                                        <div className='rightBtnWrap'>
                                            <div className='tblDataWrap'>
                                                <div className='topTtl'>
                                                    <h3>Use existing list</h3>
                                                </div>
                                                <div className='tblWrap'>
                                                    <CustomScroll heightRelativeToParent="100%">
                                                        <table>
                                                            <tr>
                                                                <td>List 01</td>
                                                                <td>July 01, 2024</td>
                                                                <td><button className='btn'>Add</button></td>
                                                            </tr>
                                                            <tr>
                                                                <td>List 01</td>
                                                                <td>July 01, 2024</td>
                                                                <td><button className='btn'>Add</button></td>
                                                            </tr>
                                                            <tr>
                                                                <td>List 01</td>
                                                                <td>July 01, 2024</td>
                                                                <td><button className='btn'>Add</button></td>
                                                            </tr>
                                                            <tr>
                                                                <td>List 01</td>
                                                                <td>July 01, 2024</td>
                                                                <td><button className='btn'>Add</button></td>
                                                            </tr>
                                                            <tr>
                                                                <td>List 01</td>
                                                                <td>July 01, 2024</td>
                                                                <td><button className='btn'>Add</button></td>
                                                            </tr>
                                                            <tr>
                                                                <td>List 01</td>
                                                                <td>July 01, 2024</td>
                                                                <td><button className='btn'>Add</button></td>
                                                            </tr>
                                                            <tr>
                                                                <td>List 01</td>
                                                                <td>July 01, 2024</td>
                                                                <td><button className='btn'>Add</button></td>
                                                            </tr>
                                                            <tr>
                                                                <td>List 01</td>
                                                                <td>July 01, 2024</td>
                                                                <td><button className='btn'>Add</button></td>
                                                            </tr>
                                                            <tr>
                                                                <td>List 01</td>
                                                                <td>July 01, 2024</td>
                                                                <td><button className='btn'>Add</button></td>
                                                            </tr>
                                                            <tr>
                                                                <td>List 01</td>
                                                                <td>July 01, 2024</td>
                                                                <td><button className='btn'>Add</button></td>
                                                            </tr>
                                                            <tr>
                                                                <td>List 01</td>
                                                                <td>July 01, 2024</td>
                                                                <td><button className='btn'>Add</button></td>
                                                            </tr>
                                                        </table>
                                                    </CustomScroll>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className='row'>
                                    <div className='col-md-12'>
                                        <div className="uploadBox">
                                            <div className="icoWrap">
                                                <svg width="34" height="33" viewBox="0 0 34 33" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M23.5305 27.3334C25.4288 27.3471 27.2563 26.6637 28.6588 25.4337C33.2913 21.525 30.8121 13.6803 24.7063 12.9423C22.5246 0.177439 3.44212 5.01549 7.96129 17.1653" stroke="#6F6F6F" stroke-width="2.05002" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
                                                    <path d="M10.3138 17.7261C9.56292 17.3571 8.72708 17.1657 7.89125 17.1794C1.28959 17.6304 1.30375 26.8965 7.89125 27.3475" stroke="#6F6F6F" stroke-width="2.05002" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
                                                    <path d="M22.4121 13.5164C23.1488 13.1611 23.9421 12.9697 24.7638 12.9561" stroke="#6F6F6F" stroke-width="2.05002" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
                                                    <path d="M12.75 21.2653L16.3767 17.7666L20.0033 21.2653" stroke="#6F6F6F" stroke-width="2.05002" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
                                                    <path d="M16.377 31.7615V17.8623" stroke="#6F6F6F" stroke-width="2.05002" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
                                                </svg>
                                            </div>
                                            <div className="uploadText">
                                                <span>Upload Your File </span>
                                                <span className="chooseLink">Choose</span>
                                            </div>
                                            <input type="file" className="fileInput" accept=".xlsx" onChange={handleFileUpload} />
                                            <div className="uploadInfo">
                                                Only support .xls files
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </Modal.Body>
                        </Modal>
                        <Modal show={uploadShow} onHide={handleUploadClose} className='newContactPopup'>
                            <Modal.Header closeButton>
                                <Modal.Title>  Add new contacts</Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                <div className='row'>
                                    <div className='col-md-12'>
                                        <div className="uploadBox">
                                            <div className="icoWrap">
                                                <svg width="34" height="33" viewBox="0 0 34 33" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M23.5305 27.3334C25.4288 27.3471 27.2563 26.6637 28.6588 25.4337C33.2913 21.525 30.8121 13.6803 24.7063 12.9423C22.5246 0.177439 3.44212 5.01549 7.96129 17.1653" stroke="#6F6F6F" stroke-width="2.05002" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
                                                    <path d="M10.3138 17.7261C9.56292 17.3571 8.72708 17.1657 7.89125 17.1794C1.28959 17.6304 1.30375 26.8965 7.89125 27.3475" stroke="#6F6F6F" stroke-width="2.05002" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
                                                    <path d="M22.4121 13.5164C23.1488 13.1611 23.9421 12.9697 24.7638 12.9561" stroke="#6F6F6F" stroke-width="2.05002" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
                                                    <path d="M12.75 21.2653L16.3767 17.7666L20.0033 21.2653" stroke="#6F6F6F" stroke-width="2.05002" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
                                                    <path d="M16.377 31.7615V17.8623" stroke="#6F6F6F" stroke-width="2.05002" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
                                                </svg>
                                            </div>
                                            <div className="uploadText">
                                                <span>Upload Your File </span>
                                                <span className="chooseLink">Choose</span>
                                            </div>
                                            <input type="file" className="fileInput" accept=".xlsx" onChange={handleFileUpload} />
                                            <div className="uploadInfo">
                                                Only support .xls files
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </Modal.Body>
                        </Modal>

                        {/*-----Delete Popup-----*/}
                        <Modal show={isPopupOpen} onHide={handleCancelDelete} centered className='deletePopupWrap'>
                            <Modal.Header closeButton>
                                <Modal.Title>Confirm Deletion</Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                <p>Are you sure you want to delete?</p>
                            </Modal.Body>
                            <Modal.Footer>
                                <Button variant="danger" onClick={handleConfirmDelete}>
                                    Yes
                                </Button>
                                <Button variant="secondary" onClick={handleCancelDelete}>
                                    Cancel
                                </Button>
                            </Modal.Footer>
                        </Modal>
                        {/*-----Delete Popup-----*/}
                    </div>
                    : <h4>Please Log In</h4>}
        </>
    );
};

export default ContactList;