import React from 'react';
import { Handle, Position } from 'react-flow-renderer';
import Message from '../../../assets/img/Message.png'

const CustomNode = ({ data, id }) => {
  
  const handleEditButtonClick = (event) => {
    event.stopPropagation();
    alert('Edit button clicked!');
  };

  return (
    <>

    <div className="messageNodeWrap">
      {/* <Handle type="target" position={Position.Top} isConnectable={isConnectable} style={{ width: '10px', height: '10px' }} /> */}
      <div className="messageCard">
          <div className="headerWrap">
              <div className="iconWrap">
                  <img src={Message} alt="" srcset="" />
              </div>
              <div className="titleWrap">
                  <h4>SMS Message</h4>
              </div>
              <div className="editNode">
                  <button className="btn" onClick={handleEditButtonClick}>
                      <svg width="9" height="10" viewBox="0 0 11 12" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M7.165 2.08191L0.430163 8.73842C0.393329 8.77609 0.372721 8.82671 0.372742 8.87939V10.9573C0.372742 10.985 0.383735 11.0115 0.403316 11.0311C0.422898 11.0506 0.449471 11.0616 0.477164 11.0616H2.23134C2.28388 11.0609 2.33421 11.0405 2.3723 11.0043L9.28985 4.08674L7.165 2.08191Z" fill="#1a43ef"></path><path d="M9.96359 3.36146L7.95334 1.35128L8.77833 0.526258C8.80437 0.500177 8.8353 0.479442 8.86935 0.465325C8.9034 0.451208 8.93989 0.443909 8.97675 0.443909C9.01361 0.443909 9.05009 0.451208 9.08413 0.465325C9.11818 0.479442 9.14911 0.500177 9.17515 0.526258L10.7886 2.1396C10.8147 2.16564 10.8354 2.19657 10.8495 2.23062C10.8636 2.26466 10.8709 2.30122 10.8709 2.33808C10.8709 2.37493 10.8636 2.41137 10.8495 2.44541C10.8354 2.47946 10.8147 2.51039 10.7886 2.53643L9.96359 3.36146Z" fill="#1a43ef"></path></svg>
                  </button>
              </div>
          </div>
          <div className="bodyWrap">
            <p>{data.bodyText}</p>        
          
          </div>
          {data.options && data.options.map((option, index) => (
            <div key={index} className='optionsWrap'>
              {/* Source Point for options */}
                <Handle
                  type="source"
                  position={Position.Right}
                  // id={`option-${index}`}
                  id ={option.id}
                />
                <p>
                  {option.label}
                  <span> {option.optionBodyText}</span>
                  </p>
            </div>
          ))}
          {/* Button to add a new option */}
          <button onClick={() => data.addOption(id)}>Add Option</button>
          <Handle type="target" position={Position.Top} />
          
      </div>
    </div>




   
   
    </>

    
  );
};

export default CustomNode;





