import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import LoginPageImg1 from '../../assets/img/loginPageImg-1.png';

const client_id = '1000.QPYJKRR36L0Y6G8PQY6WAGONDA1W5N';
const redirect_uri = 'http://localhost:3000/oauth/callback';
const scope = 'zohobackstage.portal.READ,zohobackstage.event.READ,zohobackstage.attendee.READ,zohobackstage.order.READ';

const Login = () => {
    const [passwordVisible, setPasswordVisible] = useState(false);

    const togglePasswordVisibility = (event) => {
        event.preventDefault();
        setPasswordVisible(!passwordVisible);
    };

    const handleLogin = () => {
        const authorizationUrl = `https://accounts.zoho.in/oauth/v2/auth?scope=${encodeURIComponent(scope)}&client_id=${client_id}&response_type=code&redirect_uri=${encodeURIComponent(redirect_uri)}`;
        window.location.href = authorizationUrl;
    };

    return (
        <div className='authPageWrap'>
            <div className='container-fluid'>
                <div className='row justify-content-between align-items-center'>
                    <div className='col-md-5 pad0'>
                        <div className='leftDataWrap'>
                            <h2>Make your business <br/> communications <br/> much easier</h2>
                            <div className='imgWrap'>
                                <img src={LoginPageImg1} className='img-fluid' alt='' />
                            </div>
                        </div>
                    </div>
                    <div className='col-md-5'>
                        <div className='formWrap'>
                            <div className='logoWrap'>
                                <h3>Logo</h3>
                            </div>
                            <div className='title'>
                                <h2>Welcome Back</h2>
                            </div>
                            <form>
                                <ul>
                                    <li>
                                        <div className='inputField'>
                                            <input type='email' placeholder='Email' />
                                        </div>
                                    </li>
                                    <li className='martBtm'>
                                        <div className='inputField'>
                                            <input type={passwordVisible ? 'text' : 'password'} placeholder='Password' />
                                            <button className={`btn btnShowPwd ${passwordVisible ? 'active' : ''}`} onClick={togglePasswordVisibility}>
                                                <svg xmlns="http://www.w3.org/2000/svg" version="1.1" width="16" height="16" x="0" y="0" viewBox="0 0 488.85 488.85"><g><path d="M244.425 98.725c-93.4 0-178.1 51.1-240.6 134.1-5.1 6.8-5.1 16.3 0 23.1 62.5 83.1 147.2 134.2 240.6 134.2s178.1-51.1 240.6-134.1c5.1-6.8 5.1-16.3 0-23.1-62.5-83.1-147.2-134.2-240.6-134.2zm6.7 248.3c-62 3.9-113.2-47.2-109.3-109.3 3.2-51.2 44.7-92.7 95.9-95.9 62-3.9 113.2 47.2 109.3 109.3-3.3 51.1-44.8 92.6-95.9 95.9zm-3.1-47.4c-33.4 2.1-61-25.4-58.8-58.8 1.7-27.6 24.1-49.9 51.7-51.7 33.4-2.1 61 25.4 58.8 58.8-1.8 27.7-24.2 50-51.7 51.7z" fill="#434343" opacity="1" data-original="#434343"></path></g></svg>
                                            </button>
                                            <Link className='forgotPwd'>Forget Password?</Link>
                                        </div>
                                    </li>
                                    <li>
                                        <div className='ctaWrap'>
                                            <button type='button' className='btn' onClick={handleLogin}>Log in</button>
                                            <p>Not a ccount yet? <Link>Sign up for free</Link></p>
                                        </div>
                                    </li>
                                </ul>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Login;
