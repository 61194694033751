import React from 'react';
import { Button } from "react-bootstrap";
import { CustomScroll } from "react-custom-scroll";
const Sidebar = ({ selectedNode, updateNodeData, setSelectedNode}) => {
  if (!selectedNode) {
    return <></>
    // <div style={{ width: 300, padding: 20, backgroundColor: '#f4f4f4' }}>Select a node to edit</div>;
  }

  const handleChange = (e) => {    
    const { name, value } = e.target;
    updateNodeData(selectedNode.id, { [name]: value });
  };

  const handleOptionChange = (e, index) => {    
    const { name, value } = e.target;
    
    // Create a deep copy of the options array
    const updatedOptions = [...selectedNode.data.options];
    
    // Update the specific option at the given index
    updatedOptions[index] = {
      ...updatedOptions[index], // Spread the existing option to preserve other fields
      [name]: value // Update the specific field (either label or optionBodyText)
    };
    
    // Update the node data with the modified options
    updateNodeData(selectedNode.id, { options: updatedOptions });
  };

  const handleAddVariable = () => {
    const currentCount = selectedNode.data.dynamicValueCount || 0;
    const newCount = currentCount + 1;
    const updatedBodyText = selectedNode.data.bodyText + `{{${newCount}}}`;

    // Update the node with the new bodyText and increment the dynamicValueCount
    updateNodeData(selectedNode.id, { bodyText: updatedBodyText, dynamicValueCount: newCount });
  };
  

  return (
    <div className='rightCardWrap'>
      <CustomScroll heightRelativeToParent="100%">
        <div className='topTtlWrap'>
          <h3>Edit Node</h3>
          <button onClick={handleAddVariable}>Add Variable</button>
        </div>
        {/* <label>Label:</label>
      <input
        type="text"
        name="label"
        // value={selectedNode.data.label || ''}
        onChange={handleChange}
        style={{ width: '100%', marginBottom: '10px' }}
      /> */}
        <ul>
          <li>
            <div className='inputField'>
              <label>Body Text</label>
              <textarea
                name="bodyText"
                value={selectedNode.data.bodyText || ''}
                onChange={handleChange}
              />
            </div>
          </li>
          <li>
            {selectedNode.data.options.map((option, index) => (
              <div key={index}>
                <div className='inputField'>
                  <label>Option {index + 1} Label</label>
                  <input
                    type="text"
                    name="label"
                    value={option.label || ''}
                    onChange={(e) => handleOptionChange(e, index)}
                  />
                </div>
                <div className='inputField'>
                  <label>Option {index + 1} Body Text</label>
                  <textarea
                    name="optionBodyText"
                    value={option.optionBodyText || ''}
                    onChange={(e) => handleOptionChange(e, index)}
                  />
                </div>
              </div>
            ))}
          </li>
          <li>
            <div className='ctaWrap'>
              <Button onClick={() => {
                setSelectedNode(null)
                // clearAll()
              }}
              >
                Close
              </Button>
            </div>
          </li>
        </ul>
      </CustomScroll>
    </div>
  );
};

export default Sidebar;

