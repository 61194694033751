
export function handleResponseAxios(response) {    
    return response.data;
}

export function handleErrorResponseAxios(error) {
    let response;
    if (error.response && error.response.data) {
        response = error.response.data;
    } else {
        response = { message: "Server not available, try again later" };
    }
    return response;
}