import React, { useState, useEffect } from 'react';
import Form from 'react-bootstrap/Form';
import { CustomScroll } from "react-custom-scroll";
import Header from '../../Components/Common/Header';
import Sidebar from '../../Components/Common/Sidebar';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import whatsAppImg from '../../assets/img/phoneImg.png';

const MessageTemplate = () => {
    const [isOpen, setIsOpen] = useState(false);
    const [selectedDate, setSelectedDate] = useState(new Date());
    const [startDate, setStartDate] = useState(new Date());
    const toggleSidebar = () => {
        setIsOpen(!isOpen);
    };

    return (
        <div className={isOpen ? "app-container sidebar-open" : "app-container"}>
            <Header toggleSidebar={toggleSidebar} />
            <Sidebar isOpen={isOpen} />
            <main className="content messageTempPage">
                <div className='messageTemplateWrap'>
                    <div className='container-fluid pad0px'>
                        <div className='row'>
                            <div className='col-md-9 pad0px'>
                                <div className='leftContentWrap'>
                                    <div className='titleWrap'>
                                        <div className='icoWrap'>
                                            <svg width="27" height="19" viewBox="0 0 27 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M18.477 3.91047C18.516 3.87119 18.5627 3.84057 18.6142 3.82008C18.6656 3.79958 18.7207 3.79001 18.776 3.79169C18.8314 3.79337 18.8858 3.80611 18.9359 3.82968C18.986 3.85325 19.0307 3.8868 19.0673 3.92837C20.4074 5.47858 21.144 7.46001 21.1421 9.50918C21.145 11.55 20.4151 13.5241 19.0852 15.0721C19.0486 15.1137 19.0039 15.1472 18.9538 15.1708C18.9037 15.1943 18.8493 15.2071 18.7939 15.2088C18.7386 15.2104 18.6835 15.2009 18.632 15.1804C18.5806 15.1599 18.5339 15.1288 18.4949 15.0895C18.4194 15.0145 18.3754 14.9135 18.3721 14.807C18.3688 14.7006 18.4063 14.5971 18.477 14.5175C19.6745 13.1176 20.3285 11.3335 20.3193 9.49128C20.319 7.64218 19.6595 5.85365 18.4592 4.44715C18.4222 4.41233 18.393 4.37009 18.3737 4.32313C18.3543 4.27618 18.3452 4.22586 18.3469 4.1751C18.3486 4.12434 18.3611 4.07437 18.3835 4.02881C18.4059 3.98325 18.4378 3.94275 18.477 3.91047Z" fill="black" />
                                                <path d="M22.5918 0.118526C22.6309 0.0794858 22.6776 0.0489151 22.7291 0.0285694C22.7805 0.00822368 22.8355 -0.00148971 22.8907 0.000185015C22.946 0.00185974 23.0004 0.014755 23.0505 0.0381764C23.1005 0.0615978 23.1453 0.0950907 23.182 0.13643C25.4895 2.71251 26.7636 6.0505 26.7593 9.50895C26.7668 12.9594 25.4992 16.2909 23.1999 18.8636C23.1632 18.9049 23.1184 18.9384 23.0683 18.9618C23.0182 18.9852 22.9639 18.9981 22.9086 18.9998C22.8534 19.0015 22.7983 18.9918 22.7469 18.9714C22.6955 18.9511 22.6488 18.9205 22.6097 18.8815C22.5341 18.8064 22.4902 18.7054 22.4869 18.5989C22.4835 18.4925 22.5211 18.3886 22.5918 18.309C24.7536 15.8883 25.9448 12.7544 25.9365 9.50895C25.9418 6.25543 24.7441 3.11494 22.5739 0.691017C22.5032 0.61139 22.4656 0.5075 22.469 0.40106C22.4723 0.29462 22.5162 0.193583 22.5918 0.118526Z" fill="black" />
                                                <path d="M8.30088 3.91047C8.26186 3.87119 8.21514 3.84057 8.16371 3.82008C8.11227 3.79958 8.0572 3.79001 8.00186 3.79169C7.94652 3.79337 7.89208 3.80611 7.84198 3.82968C7.79188 3.85325 7.74717 3.8868 7.71059 3.92837C6.37047 5.47858 5.63385 7.46001 5.63575 9.50918C5.63286 11.55 6.36278 13.5241 7.69269 15.0721C7.72926 15.1137 7.77403 15.1472 7.82413 15.1708C7.87423 15.1943 7.92862 15.2071 7.98396 15.2088C8.0393 15.2104 8.09442 15.2009 8.14586 15.1804C8.19729 15.1599 8.24395 15.1288 8.28298 15.0895C8.35852 15.0145 8.40247 14.9135 8.40579 14.807C8.40912 14.7006 8.37159 14.5971 8.30088 14.5175C7.10335 13.1176 6.44937 11.3335 6.45852 9.49128C6.45879 7.64218 7.11836 5.85365 8.31873 4.44715C8.35351 4.41112 8.38072 4.36856 8.3987 4.32182C8.41668 4.27509 8.42509 4.22515 8.42342 4.1751C8.42176 4.12505 8.41003 4.076 8.38898 4.03056C8.36793 3.98512 8.33798 3.9441 8.30088 3.91047Z" fill="black" />
                                                <path d="M4.16757 0.118526C4.12842 0.0794858 4.0817 0.0489151 4.03029 0.0285694C3.97888 0.00822368 3.92387 -0.00148971 3.86861 0.000185015C3.81334 0.00185974 3.75897 0.014755 3.70889 0.0381764C3.6588 0.0615978 3.61406 0.0950907 3.57734 0.13643C1.26981 2.71251 -0.00424518 6.0505 3.2968e-05 9.50895C-0.007453 12.9594 1.26017 16.2909 3.55943 18.8636C3.59615 18.9049 3.64095 18.9384 3.69104 18.9618C3.74112 18.9852 3.79544 18.9981 3.8507 18.9998C3.90597 19.0015 3.96103 18.9918 4.01244 18.9714C4.06385 18.9511 4.11057 18.9205 4.14972 18.8815C4.22526 18.8064 4.26921 18.7054 4.27254 18.5989C4.27586 18.4925 4.23828 18.3886 4.16757 18.309C2.00572 15.8883 0.814529 12.7544 0.822798 9.50895C0.817568 6.25543 2.01522 3.11494 4.18547 0.691017C4.22241 0.652536 4.25128 0.606744 4.27041 0.556956C4.28954 0.507167 4.29855 0.454371 4.29688 0.40106C4.29522 0.347749 4.28289 0.294971 4.26069 0.246474C4.23849 0.197977 4.20683 0.154626 4.16757 0.118526Z" fill="black" />
                                                <path d="M10.7148 9.50899C10.7153 8.77859 11.0057 8.07829 11.5222 7.56182C12.0386 7.04535 12.739 6.75487 13.4694 6.75439C14.1998 6.75487 14.9001 7.04535 15.4166 7.56182C15.9331 8.07829 16.2235 8.77859 16.2239 9.50899C16.2235 10.2394 15.9331 10.9397 15.4166 11.4562C14.9001 11.9726 14.1998 12.2631 13.4694 12.2636C12.739 12.2631 12.0386 11.9726 11.5222 11.4562C11.0057 10.9397 10.7153 10.2394 10.7148 9.50899ZM15.3833 9.50899C15.3823 8.99695 15.1785 8.50599 14.8164 8.14392C14.4543 7.78185 13.9635 7.57805 13.4515 7.5771C12.9394 7.57805 12.4486 7.78185 12.0866 8.14392C11.7245 8.50599 11.5207 8.99695 11.5198 9.50899C11.5207 10.021 11.7245 10.512 12.0866 10.8741C12.4486 11.2361 12.9394 11.4399 13.4515 11.4409C13.7054 11.4418 13.9571 11.3922 14.1919 11.2955C14.4267 11.1987 14.64 11.0567 14.8196 10.8771C14.9991 10.6975 15.1414 10.484 15.2381 10.2492C15.3349 10.0144 15.3842 9.76293 15.3833 9.50899Z" fill="black" />
                                            </svg>
                                        </div>
                                        <h2>Message Template</h2>
                                    </div>
                                    <div className='messageTempFrmWrap scrollContentWrap'>
                                        <CustomScroll heightRelativeToParent="100%">
                                            <div className='row'>
                                                <div className='col-md-8'>
                                                    <ul>
                                                        <li>
                                                            <div className='inputField'>
                                                                <input type='text' placeholder='Message Template Name' />
                                                            </div>
                                                        </li>
                                                        <li>
                                                            <label>Add a title or choose which type of media you’ll use for this header </label>
                                                            <Form.Select aria-label="Media">
                                                                <option value="">Media </option>
                                                                <option value="1">1</option>
                                                                <option value="2">2</option>
                                                                <option value="3">3</option>
                                                                <option value="4">4</option>
                                                                <option value="5">5</option>
                                                            </Form.Select>
                                                        </li>
                                                        <li>
                                                            <div className="uploadBox">
                                                                <div className="icoWrap">
                                                                    <svg width="34" height="33" viewBox="0 0 34 33" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                        <path d="M23.5305 27.3334C25.4288 27.3471 27.2563 26.6637 28.6588 25.4337C33.2913 21.525 30.8121 13.6803 24.7063 12.9423C22.5246 0.177439 3.44212 5.01549 7.96129 17.1653" stroke="#6F6F6F" stroke-width="2.05002" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
                                                                        <path d="M10.3138 17.7261C9.56292 17.3571 8.72708 17.1657 7.89125 17.1794C1.28959 17.6304 1.30375 26.8965 7.89125 27.3475" stroke="#6F6F6F" stroke-width="2.05002" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
                                                                        <path d="M22.4121 13.5164C23.1488 13.1611 23.9421 12.9697 24.7638 12.9561" stroke="#6F6F6F" stroke-width="2.05002" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
                                                                        <path d="M12.75 21.2653L16.3767 17.7666L20.0033 21.2653" stroke="#6F6F6F" stroke-width="2.05002" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
                                                                        <path d="M16.377 31.7615V17.8623" stroke="#6F6F6F" stroke-width="2.05002" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
                                                                    </svg>
                                                                </div>
                                                                <div className="uploadText">
                                                                    <span>Upload Your File </span>
                                                                    <span className="chooseLink">Choose</span>
                                                                </div>
                                                                <input type="file" className="fileInput" />
                                                                <div className="uploadInfo">
                                                                    Only support .jpg, .png, .mp4, document, and pdf files
                                                                </div>
                                                            </div>
                                                        </li>
                                                        <li>
                                                            <textarea placeholder='Type Message' rows={3}></textarea>
                                                        </li>
                                                        <li>
                                                            <div className='confirmCta'>
                                                                <button className='btn btnConfirm'>
                                                                    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                        <path d="M12.0304 14.6297C7.78899 13.55 4.45005 10.211 3.37028 5.96964C2.96152 4.364 4.34315 3 6 3L6.75 3C7.16421 3 7.49606 3.33662 7.53691 3.74881C7.60595 4.44522 7.77024 5.11361 8.01657 5.74078L6.87711 6.88024C7.76401 8.73352 9.26647 10.236 11.1198 11.1229L12.2592 9.98342C12.8864 10.2298 13.5548 10.3941 14.2512 10.4631C14.6634 10.5039 15 10.8358 15 11.25L15 12C15 13.6569 13.636 15.0385 12.0304 14.6297Z" stroke="#1944F1" stroke-width="0.75" stroke-linecap="round" stroke-linejoin="round" />
                                                                    </svg>
                                                                    Conform
                                                                </button>
                                                            </div>
                                                            <div className='addCta'>
                                                                <button className='btn btnGray'>+ Add New Button</button>
                                                            </div>
                                                            <div className='ctaGroup'>
                                                                <button className='btn btnGreen'>
                                                                    <svg width="13" height="13" viewBox="0 0 13 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                        <path d="M12.564 0.365357C12.4795 0.168443 12.2867 0.039939 12.0724 0.0375977H8.5098C8.21467 0.0375977 7.97541 0.276852 7.97541 0.571987C7.97541 0.867123 8.21467 1.10638 8.5098 1.10638H10.7827L6.70713 5.18199C6.49875 5.39063 6.49875 5.72862 6.70713 5.93726C6.91577 6.14564 7.25376 6.14564 7.4624 5.93726L11.538 1.86165V4.13459C11.538 4.42972 11.7773 4.66898 12.0724 4.66898C12.3675 4.66898 12.6068 4.42972 12.6068 4.13459V0.571987C12.6064 0.500954 12.5919 0.430708 12.564 0.365357Z" fill="#2EB83C" />
                                                                        <path d="M10.6474 5.73776C10.3538 5.74159 10.1168 5.97861 10.113 6.27215V9.12222C10.1091 10.3995 9.07458 11.434 7.79728 11.4379H3.52216C2.24487 11.434 1.21039 10.3995 1.20647 9.12222V4.84711C1.21039 3.56981 2.24487 2.53533 3.52216 2.53142H6.37224C6.66738 2.53142 6.90663 2.29216 6.90663 1.99703C6.90663 1.70189 6.66738 1.46264 6.37224 1.46264H3.52216C1.65297 1.46264 0.137695 2.97792 0.137695 4.84711V9.12222C0.137695 10.9914 1.65297 12.5067 3.52216 12.5067H7.79728C9.66647 12.5067 11.1818 10.9914 11.1818 9.12222V6.27215C11.1779 5.97861 10.9409 5.74159 10.6474 5.73776Z" fill="#2EB83C" />
                                                                    </svg>
                                                                    Place Order
                                                                </button>
                                                                <button className='btn btnGreen'>
                                                                    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                        <path d="M12.0304 14.6297C7.78899 13.55 4.45005 10.211 3.37028 5.96964C2.96152 4.364 4.34315 3 6 3L6.75 3C7.16421 3 7.49606 3.33662 7.53691 3.74881C7.60595 4.44522 7.77024 5.11361 8.01657 5.74078L6.87711 6.88024C7.76401 8.73352 9.26647 10.236 11.1198 11.1229L12.2592 9.98342C12.8864 10.2298 13.5548 10.3941 14.2512 10.4631C14.6634 10.5039 15 10.8358 15 11.25L15 12C15 13.6569 13.636 15.0385 12.0304 14.6297Z" stroke="#2EB83C" stroke-width="0.75" stroke-linecap="round" stroke-linejoin="round" />
                                                                    </svg>
                                                                    Contact Support
                                                                </button>
                                                                <button className='btn btnGreen'>
                                                                    <svg width="15" height="11" viewBox="0 0 15 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                        <path d="M1.125 4.08317H13.875M3.95833 7.62484H3.96542M6.79167 7.62484H8.20833M1.125 2.6665C1.125 2.10292 1.34888 1.56242 1.7474 1.1639C2.14591 0.765387 2.68641 0.541504 3.25 0.541504H11.75C12.3136 0.541504 12.8541 0.765387 13.2526 1.1639C13.6511 1.56242 13.875 2.10292 13.875 2.6665V8.33317C13.875 8.89676 13.6511 9.43726 13.2526 9.83577C12.8541 10.2343 12.3136 10.4582 11.75 10.4582H3.25C2.68641 10.4582 2.14591 10.2343 1.7474 9.83577C1.34888 9.43726 1.125 8.89676 1.125 8.33317V2.6665Z" stroke="#2EB83C" stroke-linecap="round" stroke-linejoin="round" />
                                                                    </svg>
                                                                    Pay Now
                                                                </button>
                                                                <button className='btn btnGray'>
                                                                    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                        <path d="M12.0304 14.6297C7.78899 13.55 4.45005 10.211 3.37028 5.96964C2.96152 4.364 4.34315 3 6 3L6.75 3C7.16421 3 7.49606 3.33662 7.53691 3.74881C7.60595 4.44522 7.77024 5.11361 8.01657 5.74078L6.87711 6.88024C7.76401 8.73352 9.26647 10.236 11.1198 11.1229L12.2592 9.98342C12.8864 10.2298 13.5548 10.3941 14.2512 10.4631C14.6634 10.5039 15 10.8358 15 11.25L15 12C15 13.6569 13.636 15.0385 12.0304 14.6297Z" stroke="#959595" stroke-width="0.75" stroke-linecap="round" stroke-linejoin="round" />
                                                                    </svg>
                                                                    Conform
                                                                </button>
                                                            </div>
                                                        </li>
                                                    </ul>
                                                </div>
                                                <div className='col-md-4'>
                                                    <div className='rightBlankBox'>
                                                        <button className='btnEdit'>
                                                            <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                <path d="M24.5 12.5C24.5 19.1274 19.1274 24.5 12.5 24.5C5.87258 24.5 0.5 19.1274 0.5 12.5C0.5 5.87258 5.87258 0.5 12.5 0.5C19.1274 0.5 24.5 5.87258 24.5 12.5Z" stroke="#1944F1" />
                                                                <path d="M14.9269 7.76074L7.4892 15.112C7.44852 15.1536 7.42576 15.2095 7.42578 15.2677V17.5624C7.42578 17.593 7.43792 17.6223 7.45955 17.6439C7.48117 17.6655 7.51052 17.6777 7.5411 17.6777H9.47836C9.53638 17.6769 9.59197 17.6543 9.63403 17.6143L17.2736 9.97481L14.9269 7.76074Z" fill="#1944F1" />
                                                                <path d="M18.0179 9.17371L15.7979 6.95374L16.7089 6.0426C16.7377 6.0138 16.7719 5.9909 16.8095 5.97531C16.8471 5.95972 16.8874 5.95166 16.9281 5.95166C16.9688 5.95166 17.0091 5.95972 17.0467 5.97531C17.0843 5.9909 17.1184 6.0138 17.1472 6.0426L18.929 7.82433C18.9578 7.85309 18.9807 7.88724 18.9962 7.92484C19.0118 7.96244 19.0199 8.00282 19.0199 8.04352C19.0199 8.08422 19.0118 8.12446 18.9962 8.16206C18.9807 8.19966 18.9578 8.23382 18.929 8.26258L18.0179 9.17371Z" fill="#1944F1" />
                                                            </svg>
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </CustomScroll>
                                        <div className='saveCtaWrap'>
                                            <button className='btn'>Save</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='col-md-3 pad0px'>
                                <div className='previewWrap'>
                                    <div className='titleWrap'>
                                        <h2>Preview</h2>
                                    </div>
                                    <div className='preview'>
                                        <div className='imgWrap'>
                                            <img src={whatsAppImg} className='img-fluid' alt='' />
                                            <div className='messageTempChatWrap'>
                                                <div className='topImgWrap'>
                                                    <div className='imgBox'>
                                                        
                                                    </div>
                                                    <button className='btnEdit'>
                                                        <svg width="17" height="16" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                            <path d="M15.6944 7.52674C15.6944 11.5023 12.4716 14.7252 8.49598 14.7252C4.52039 14.7252 1.29753 11.5023 1.29753 7.52674C1.29753 3.55115 4.52039 0.328292 8.49598 0.328292C12.4716 0.328292 15.6944 3.55115 15.6944 7.52674Z" stroke="#1944F1" stroke-width="0.665372" />
                                                            <path d="M9.95786 4.67041L5.47668 9.09947C5.45217 9.12454 5.43846 9.15821 5.43848 9.19327V10.5758C5.43848 10.5942 5.44579 10.6119 5.45882 10.6249C5.47185 10.638 5.48953 10.6453 5.50796 10.6453H6.67514C6.7101 10.6448 6.74358 10.6312 6.76893 10.6071L11.3717 6.00437L9.95786 4.67041Z" fill="#1944F1" />
                                                            <path d="M11.821 5.52182L10.4834 4.18431L11.0323 3.63536C11.0497 3.61801 11.0702 3.60421 11.0929 3.59482C11.1155 3.58542 11.1398 3.58057 11.1643 3.58057C11.1889 3.58057 11.2131 3.58542 11.2358 3.59482C11.2585 3.60421 11.279 3.61801 11.2964 3.63536L12.3699 4.70883C12.3872 4.72616 12.401 4.74674 12.4104 4.76939C12.4198 4.79204 12.4246 4.81637 12.4246 4.84089C12.4246 4.86542 12.4198 4.88966 12.4104 4.91231C12.401 4.93497 12.3872 4.95555 12.3699 4.97287L11.821 5.52182Z" fill="#1944F1" />
                                                        </svg>
                                                    </button>
                                                </div>
                                                <div className='dataWrap'>
                                                    <p>“ Hey, (User)</p>
                                                    <p>Lorem Ipsum has been the industry's
                                                        standard dummy text ever since the</p>
                                                </div>
                                                <div className='ctaWrap'>
                                                    <button className='btn'>
                                                        <svg width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                            <path d="M9.02729 1.02203C8.96805 0.884121 8.83305 0.79412 8.68296 0.79248H6.18782C5.98111 0.79248 5.81354 0.960048 5.81354 1.16675C5.81354 1.37346 5.98111 1.54102 6.18782 1.54102H7.77972L4.92527 4.39547C4.77933 4.5416 4.77933 4.77832 4.92527 4.92444C5.0714 5.07039 5.30812 5.07039 5.45424 4.92444L8.30869 2.07V3.6619C8.30869 3.8686 8.47626 4.03617 8.68296 4.03617C8.88967 4.03617 9.05723 3.8686 9.05723 3.6619V1.16675C9.05697 1.117 9.04679 1.0678 9.02729 1.02203Z" fill="#1944F1" />
                                                            <path d="M7.6849 4.78472C7.47932 4.7874 7.31332 4.95341 7.31063 5.15899V7.1551C7.30789 8.04969 6.58337 8.77421 5.68878 8.77695H2.69461C1.80003 8.77421 1.0755 8.04969 1.07276 7.1551V4.16093C1.0755 3.26635 1.80003 2.54182 2.69461 2.53908H4.69073C4.89743 2.53908 5.065 2.37152 5.065 2.16481C5.065 1.95811 4.89743 1.79054 4.69073 1.79054H2.69461C1.38548 1.79054 0.324219 2.8518 0.324219 4.16093V7.1551C0.324219 8.46423 1.38548 9.52549 2.69461 9.52549H5.68878C6.99791 9.52549 8.05917 8.46423 8.05917 7.1551V5.15899C8.05649 4.95341 7.89048 4.7874 7.6849 4.78472Z" fill="#1944F1" />
                                                        </svg>
                                                        Order
                                                    </button>
                                                    <button className='btn'>
                                                        <svg width="11" height="11" viewBox="0 0 11 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                            <path d="M7.53461 9.96207C4.24217 9.12389 1.65025 6.53197 0.812067 3.23954C0.494759 1.99313 1.56727 0.934297 2.85343 0.934297L3.43563 0.934297C3.75717 0.934297 4.01477 1.1956 4.04649 1.51558C4.10008 2.05617 4.22761 2.57503 4.41883 3.06188L3.53431 3.94641C4.22278 5.38505 5.38909 6.55136 6.82773 7.23983L7.71226 6.35531C8.19911 6.54653 8.71796 6.67406 9.25856 6.72765C9.57854 6.75937 9.83984 7.01697 9.83984 7.33851L9.83984 7.92071C9.83984 9.20687 8.78101 10.2794 7.53461 9.96207Z" stroke="#1944F1" stroke-width="0.582201" stroke-linecap="round" stroke-linejoin="round" />
                                                        </svg>
                                                        Contact Support
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </main>
        </div>
    );
};

export default MessageTemplate;

