import axios from "axios";
import { handleErrorResponseAxios, handleResponseAxios } from "./common-handler";
import { ADD_FLOW_NODE, DELETE_FLOW, DELETE_NODE, GET_BUTTON_NODE_DATA, GET_CAT_IN_MENU, GET_FLOW_BY_ID, GET_FLOW_LIST, GET_LIST_NODE_DATA, GET_MESSAGE_NODE_DATA, GET_NODE_TYPES, GET_PROD_IN_CAT, GET_TRIGGER_NODE_DATA, UPDATE_BUTTON_NODE_DATA, UPDATE_CHAT_BOT_FLOW, UPDATE_LIST_NODE_DATA, UPDATE_MESSAGE_NODE_DATA, UPDATE_TRIGGER_NODE_DATA } from "../utility/ConstantAPI";

export function get_button_node_data(node_id) {
    let axiosConfig = {
        headers: {
            "Content-Type": "application/json"
        },
    };
    var url = GET_BUTTON_NODE_DATA(node_id)
    return axios
        .get(url,axiosConfig)
        .then(handleResponseAxios)
        .catch(function(error) {
            return handleErrorResponseAxios(error)
        })
}

export async function update_button_node_data(values, onSuccess, onError,node_id) {
    // let token = get_auth_token()
    var url = UPDATE_BUTTON_NODE_DATA(node_id);
    let axiosConfig = {
        headers: {
            "Content-Type": "application/json",
            // Authorization: `Bearer ${token}`,
        },
    };

    try {
        let response = await axios.post(url, values, axiosConfig);
        onSuccess(handleResponseAxios(response));
    } catch (error) {
        onError(handleErrorResponseAxios(error));
    }
}

export function get_list_node_data(node_id) {
    let axiosConfig = {
        headers: {
            "Content-Type": "application/json"
        },
    };
    var url = GET_LIST_NODE_DATA(node_id)
    return axios
        .get(url,axiosConfig)
        .then(handleResponseAxios)
        .catch(function(error) {
            return handleErrorResponseAxios(error)
        })
}

export async function update_list_node_data(values, onSuccess, onError,node_id) {
    // let token = get_auth_token()
    var url = UPDATE_LIST_NODE_DATA(node_id);
    let axiosConfig = {
        headers: {
            "Content-Type": "application/json",
            // Authorization: `Bearer ${token}`,
        },
    };

    try {
        let response = await axios.post(url, values, axiosConfig);
        onSuccess(handleResponseAxios(response));
    } catch (error) {
        onError(handleErrorResponseAxios(error));
    }
}

export function get_message_node_data(node_id) {
    let axiosConfig = {
        headers: {
            "Content-Type": "application/json"
        },
    };
    var url = GET_MESSAGE_NODE_DATA(node_id)
    return axios
        .get(url,axiosConfig)
        .then(handleResponseAxios)
        .catch(function(error) {
            return handleErrorResponseAxios(error)
        })
}

export async function update_message_node_data(values, onSuccess, onError,node_id) {
    // let token = get_auth_token()
    var url = UPDATE_MESSAGE_NODE_DATA(node_id);
    let axiosConfig = {
        headers: {
            "Content-Type": "application/json",
            // Authorization: `Bearer ${token}`,
        },
    };

    try {
        let response = await axios.post(url, values, axiosConfig);
        onSuccess(handleResponseAxios(response));
    } catch (error) {
        onError(handleErrorResponseAxios(error));
    }
}

export function get_trigger_node_data(node_id) {
    let axiosConfig = {
        headers: {
            "Content-Type": "application/json"
        },
    };
    var url = GET_TRIGGER_NODE_DATA(node_id)
    return axios
        .get(url,axiosConfig)
        .then(handleResponseAxios)
        .catch(function(error) {
            return handleErrorResponseAxios(error)
        })
}
export async function update_trigger_node_data(values, onSuccess, onError,node_id) {
    // let token = get_auth_token()
    var url = UPDATE_TRIGGER_NODE_DATA(node_id);
    let axiosConfig = {
        headers: {
            "Content-Type": "application/json",
            // Authorization: `Bearer ${token}`,
        },
    };

    try {
        let response = await axios.post(url, values, axiosConfig);
        onSuccess(handleResponseAxios(response));
    } catch (error) {
        onError(handleErrorResponseAxios(error));
    }
}
export function get_flow_list(token,userId) {
    let axiosConfig = {
        headers: {
            "Content-Type": "application/json",
            "Authorization":`${token}`
        },
    };
    var url = GET_FLOW_LIST(userId)
    return axios
        .get(url,axiosConfig)
        .then(handleResponseAxios)
        .catch(function(error) {
            return handleErrorResponseAxios(error)
        })
}
export async function delete_flow(onSuccess, onError,node_id) {
    // let token = get_auth_token()
    var url = DELETE_FLOW(node_id);
    let axiosConfig = {
        headers: {
            "Content-Type": "application/json",
            // Authorization: `Bearer ${token}`,
        },
    };

    try {
        let response = await axios.post(url, axiosConfig);
        onSuccess(handleResponseAxios(response));
    } catch (error) {
        onError(handleErrorResponseAxios(error));
    }
}
export function get_node_types() {
    let axiosConfig = {
        headers: {
            "Content-Type": "application/json"
        },
    };
    var url = GET_NODE_TYPES
    return axios
        .get(url,axiosConfig)
        .then(handleResponseAxios)
        .catch(function(error) {
            return handleErrorResponseAxios(error)
        })
}

export async function delete_node(value,onSuccess, onError) {
    // let token = get_auth_token()
    var url = DELETE_NODE;
    let axiosConfig = {
        headers: {
            "Content-Type": "application/json",
            // Authorization: `Bearer ${token}`,
        },
    };

    try {
        let response = await axios.post(url,value,axiosConfig);
        onSuccess(handleResponseAxios(response));
    } catch (error) {
        onError(handleErrorResponseAxios(error));
    }
}
export function get_cat_in_menu(menu_id) {
    let axiosConfig = {
        headers: {
            "Content-Type": "application/json"
        },
    };
    var url = GET_CAT_IN_MENU(menu_id)
    return axios
        .get(url,axiosConfig)
        .then(handleResponseAxios)
        .catch(function(error) {
            return handleErrorResponseAxios(error)
        })
}
export function get_prod_in_cat(menu_id) {
    let axiosConfig = {
        headers: {
            "Content-Type": "application/json"
        },
    };
    var url = GET_PROD_IN_CAT(menu_id)
    return axios
        .get(url,axiosConfig)
        .then(handleResponseAxios)
        .catch(function(error) {
            return handleErrorResponseAxios(error)
        })
}

export async function add_flow_node(values,token, onSuccess, onError) {
    // let token = get_auth_token()
    var url = ADD_FLOW_NODE;
    let axiosConfig = {
        headers: {
            "Content-Type": "application/json",
            Authorization: `${token}`,
        },
    };

    try {
        let response = await axios.post(url, values, axiosConfig);
        onSuccess(handleResponseAxios(response));
    } catch (error) {
        onError(handleErrorResponseAxios(error));
    }
}
export function get_flow_by_id(flow_id) {
    let axiosConfig = {
        headers: {
            "Content-Type": "application/json"
        },
    };
    var url = GET_FLOW_BY_ID(flow_id)
    return axios
        .get(url,axiosConfig)
        .then(handleResponseAxios)
        .catch(function(error) {
            return handleErrorResponseAxios(error)
        })
}

export async function update_chat_bot_flow(values,token, onSuccess, onError) {
    // let token = get_auth_token()
    var url = UPDATE_CHAT_BOT_FLOW;
    let axiosConfig = {
        headers: {
            "Content-Type": "application/json",
            Authorization: `${token}`,
        },
    };

    try {
        let response = await axios.post(url, values, axiosConfig);
        onSuccess(handleResponseAxios(response));
    } catch (error) {
        onError(handleErrorResponseAxios(error));
    }
}