import React, { useState, useEffect } from 'react';
import FormBootstrap from 'react-bootstrap/Form';
import { CustomScroll } from "react-custom-scroll";
import { Modal, Button } from 'react-bootstrap';
import Header from '../../Components/Common/Header';
import Sidebar from '../../Components/Common/Sidebar';
import { json, useLocation, useNavigate } from 'react-router-dom';
import { getPredefinedFields } from '../../services/pre-defined-services';
import CustomSelect from './CustomSelect';
import { addGroupData, createNewGroup, getGroupFieldsById } from '../../services/group-service';
import { Formik, Field, FieldArray, Form } from 'formik';
import * as Yup from 'yup';
import Spinner from 'react-bootstrap/Spinner';
import { spinnerContainerStyle } from "../../utility/CustomFormating";

const MapFields = () => {
    const [isOpen, setIsOpen] = useState(false);
    const [show, setShow] = useState(false);
    const [preDefinedFields, setPreFields] = useState([]);
    const [error, setError] = useState(false);
    const [showFields, setShowFields] = useState(false);
    const [selectField, setSelectField] = useState([{ courseSelect: '' }]);
    const [isEditName, setIsEditName] = useState(false)
    const navigate = useNavigate()
    const { state } = useLocation()
    const [groupName, setGroupName] = useState(state.file.name)
    const [fields, setFields] = useState([])
    const [isSpinLoad, setIsSpinLoad] = useState(false)
    const [isLoad, setIsLoad] = useState(true)
    const token = localStorage.getItem('token')
    const user = JSON.parse(localStorage.getItem('user'))
    const toggleSidebar = () => {
        setIsOpen(!isOpen);
    };
    const addFormFields = () => {
        let newField = { courseSelect: '' }
        setSelectField([...selectField, newField])
    }
    const removeFields = (index) => {
        let data = [...selectField];
        data.splice(index, 1)
        setSelectField(data)
    }
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    useEffect(() => {
        fetchInitialData();
    }, [])

    const fetchInitialData = async () => {
        try {
            const response = await getPredefinedFields(token);
            if (response) {
                setPreFields(response);
                setIsLoad(false)
            } else {
                setError(response.message);
                setIsLoad(false)
            }
        } catch (error) {
            console.log("Error fetching data:", error)
            setError(error.message);
            setIsLoad(false)
        }
    }
    const options = [
        { value: '', label: 'Select field type' },
        { value: 'Gender', label: 'Gender' },
        { value: 'Age', label: 'Age' },
        { value: 'Date', label: 'Date' },
        { value: 'Phone number', label: 'Phone number' },
        { value: 'Email', label: 'Email' },
        { value: 'Other', label: 'Other' },
    ];
    const [prefieldData, setPrefieldData] = useState([])
    const [customeFieldData, setCustomFieldData] = useState([]

    )
    const handleSubmitData = async () => {
        const newCustomeField = customeFieldData.map(field => ({
            name: field.name,
            type: field.type?.value
        })).filter(field => field.name !== '' && field.type !== undefined)
        const transforsmedData = {
            group_name: state.file.name,
            custom_fields: newCustomeField,
            predefined_fields: prefieldData
        };


        // await createNewGroup(transforsmedData, onSuccess, onError)
    }
    const handleError = () => {
        let status = true
        if (!groupName) {
            status = false
        }
        return status
    }
    const handleFormSubmission = async (values) => {
        setIsSpinLoad(true)
        try {
            let err = handleError()
            if (err) {
                let f_index = values.predefinedFields.map((field) => (
                    parseInt(field.field_index)
                ))
                const newFieldIndex = values.predefinedFields.map((field) => ({
                    field_index: field.field_index
                }))
                const customFields = state.data[0].filter((_, index) => !f_index.includes(index))
                    .map(field => ({
                        name: field,
                        type: null // Using optional chaining to safely access value
                    }))
                    .filter(field => field.name !== '' && field.type !== undefined); // Filter out invalid fields  
                const predefinedFields1 = values.predefinedFields.map(field => ({
                    predefined_field_id: field.id,
                    name: field.title,
                    custom_label: field.value
                }));
                state.data[0].map((field,index) => {
                    if(!f_index.includes(index)){
                        newFieldIndex.push({ field_index: index })
                    }
                })
                const transforsmedData = {
                    group_name: groupName,
                    custom_fields: customFields,
                    predefined_fields: predefinedFields1
                };

                await createNewGroup(transforsmedData, user.id, onSuccess(newFieldIndex), onError)
            }
            else {
                setError('Please Enter group name ')
                setIsSpinLoad(false)
            }
        }
        catch (error) {
            console.error("Error:", error);
        }

    }
    function createOutput(column, data, field_index) {
        let output = [];

        data.forEach(row => {
            let obj = {};
            field_index.forEach((field, index) => {
                let fieldIndex = parseInt(field.field_index);
                let header = column[index].accessor; // Skipping the first "Select" entry
                obj[header] = row[fieldIndex];
            });
            output.push(obj);
        });

        return output;
    }

    const onSuccess = (demoFieldIndex) => async (response) => {
        const groupId = response.group.group_id;
        const groupFields = await getGroupFieldsById(groupId);
        if (groupFields) {
            const predefinedFields = groupFields?.predefined_field_labels?.map(field => ({
                Header: field.custom_label,
                accessor: field.id,
            })) || [];
            const customFields = groupFields?.custom_fields?.map(field => ({
                Header: field.label,
                accessor: field.name,
            })) || [];
            const columns = [
                ...predefinedFields,
                ...customFields,
            ]
            const output = createOutput(columns, state.data.filter((_, index) => index !== 0), demoFieldIndex);
            let final = {
                group_id: groupId,
                rows: output
            }
            await addGroupData(final, onSuccessGroupData, onError)
        }

        // This method use when sending state
        // navigate(`/AddDataToContactList`,{ state: { groupId } });
        // Sending Query parameter
        // navigate(`/AddDataToContactList?groupId=${groupId}`, { state: { data: state.data, indexes: newFieldIndex } });

    }

    const onSuccessGroupData = (response) => {
        setIsSpinLoad(false)
        navigate(`/ContactList`);
    }
    const onError = (error) => {
        setError(error)
        setIsSpinLoad(false)
        // setIsLoading(false);
        console.log("error::", error)
    }
    const validationSchema = Yup.object().shape({
        predefinedFields: Yup.array().of(
            Yup.object().shape({
                field_index: Yup.string().required('Please select a field index')
            })
        ),
        // fields: Yup.array().of(
        //     Yup.object().shape({
        //         name: Yup.string().required('Field name is required'),
        //         field_index: Yup.string().required('Please select a field index'),
        //         type: Yup.string().required('Please select type')
        //     })
        // )
    });

    return (
        <> {isLoad ?
            <div style={spinnerContainerStyle}> <Spinner
                animation="border"
                size="lg"
                style={{ width: '5rem', height: '5rem', color: '#1944F1' }}

            />
            </div> :
            <div className={isOpen ? "app-container sidebar-open" : "app-container"}>
                <Header toggleSidebar={toggleSidebar} />
                {/* <Sidebar isOpen={isOpen} /> */}
                <main className="content newContactListPage">
                    <div className='newContactListWrap'>
                        <div className='container-fluid ht100'>
                            <div className='row ht100'>
                                <div className='col-md-4 ht100'>
                                    <div className='leftPannelWrap'>
                                        <div className='topWrap'>
                                            <div className='ttlWrap'>
                                                <div className='icoWrap'>
                                                    <svg width="48" height="45" viewBox="0 0 48 45" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <path d="M21.6842 38.4444H6.63158C5.40321 38.4444 4.22515 37.9645 3.35656 37.1101C2.48797 36.2558 2 35.0971 2 33.8889V6.55555C2 5.34734 2.48797 4.18862 3.35656 3.33429C4.22515 2.47996 5.40321 2 6.63158 2H39.0526C40.281 2 41.4591 2.47996 42.3277 3.33429C43.1962 4.18862 43.6842 5.34734 43.6842 6.55555V19.0833M15.8947 31.6111H20.5263M41.8316 38.9L46 43M29.7895 33.8889C29.7895 35.7012 30.5214 37.4393 31.8243 38.7208C33.1272 40.0023 34.8943 40.7222 36.7368 40.7222C38.5794 40.7222 40.3465 40.0023 41.6494 38.7208C42.9523 37.4393 43.6842 35.7012 43.6842 33.8889C43.6842 32.0766 42.9523 30.3385 41.6494 29.057C40.3465 27.7755 38.5794 27.0555 36.7368 27.0555C34.8943 27.0555 33.1272 27.7755 31.8243 29.057C30.5214 30.3385 29.7895 32.0766 29.7895 33.8889Z" stroke="#515151" stroke-width="3" stroke-linecap="round" stroke-linejoin="round" />
                                                    </svg>
                                                </div>
                                                <h2>Map Fields</h2>
                                            </div>
                                        </div>
                                        <div className='contactListLeftScroll'>
                                            <CustomScroll heightRelativeToParent='100%'>
                                                <div className='contactListNameWrap'>
                                                    <div className='ttl'>
                                                        <div className='icoWrap'>
                                                            <svg width="13" height="13" viewBox="0 0 13 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                <path d="M4.05556 12V11.3889C4.05556 11.0647 4.18433 10.7539 4.41354 10.5246C4.64275 10.2954 4.95362 10.1667 5.27778 10.1667H7.72222C8.04638 10.1667 8.35725 10.2954 8.58646 10.5246C8.81567 10.7539 8.94444 11.0647 8.94444 11.3889V12M9.55556 5.27778H10.7778C11.1019 5.27778 11.4128 5.40655 11.642 5.63576C11.8712 5.86497 12 6.17585 12 6.5V7.11111M1 7.11111V6.5C1 6.17585 1.12877 5.86497 1.35798 5.63576C1.58719 5.40655 1.89807 5.27778 2.22222 5.27778H3.44444M5.27778 7.11111C5.27778 7.43526 5.40655 7.74614 5.63576 7.97535C5.86497 8.20456 6.17585 8.33333 6.5 8.33333C6.82415 8.33333 7.13503 8.20456 7.36424 7.97535C7.59345 7.74614 7.72222 7.43526 7.72222 7.11111C7.72222 6.78696 7.59345 6.47608 7.36424 6.24687C7.13503 6.01766 6.82415 5.88889 6.5 5.88889C6.17585 5.88889 5.86497 6.01766 5.63576 6.24687C5.40655 6.47608 5.27778 6.78696 5.27778 7.11111ZM8.33333 2.22222C8.33333 2.54638 8.4621 2.85725 8.69131 3.08646C8.92053 3.31568 9.2314 3.44444 9.55556 3.44444C9.87971 3.44444 10.1906 3.31568 10.4198 3.08646C10.649 2.85725 10.7778 2.54638 10.7778 2.22222C10.7778 1.89807 10.649 1.58719 10.4198 1.35798C10.1906 1.12877 9.87971 1 9.55556 1C9.2314 1 8.92053 1.12877 8.69131 1.35798C8.4621 1.58719 8.33333 1.89807 8.33333 2.22222ZM2.22222 2.22222C2.22222 2.54638 2.35099 2.85725 2.5802 3.08646C2.80941 3.31568 3.12029 3.44444 3.44444 3.44444C3.7686 3.44444 4.07947 3.31568 4.30869 3.08646C4.5379 2.85725 4.66667 2.54638 4.66667 2.22222C4.66667 1.89807 4.5379 1.58719 4.30869 1.35798C4.07947 1.12877 3.7686 1 3.44444 1C3.12029 1 2.80941 1.12877 2.5802 1.35798C2.35099 1.58719 2.22222 1.89807 2.22222 2.22222Z" stroke="black" stroke-linecap="round" stroke-linejoin="round" />
                                                            </svg>
                                                        </div>
                                                        <h3>Contact list </h3>
                                                    </div>
                                                    <div className='inputField'>
                                                        <input placeholder='Xyx.xls' value={groupName} disabled={isEditName ? false : true} onChange={(e) => {
                                                            if (isEditName) {
                                                                setGroupName(e.target.value)
                                                            }
                                                        }} />
                                                        <button className='btn' onClick={() => setIsEditName(true)}>
                                                            <svg width="11" height="11" viewBox="0 0 13 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                <path d="M5.63514 2.96345L9.60811 6.93642M1 8.92291L8.7473 1.17561C8.87107 1.05429 9.03749 0.986328 9.21081 0.986328C9.38413 0.986328 9.55055 1.05429 9.67432 1.17561L11.3959 2.89723C11.5173 3.02101 11.5852 3.18742 11.5852 3.36075C11.5852 3.53407 11.5173 3.70048 11.3959 3.82426L3.64865 11.5716H1V8.92291Z" stroke="#1944F1" stroke-width="0.993243" stroke-linecap="round" stroke-linejoin="round" />
                                                            </svg>
                                                            change name
                                                        </button>
                                                        {error &&
                                                            <div className="errMe">
                                                                <span>{error}</span>
                                                            </div>
                                                        }
                                                    </div>
                                                </div>
                                                <Formik
                                                    initialValues={{
                                                        predefinedFields: preDefinedFields.map(field => ({ title: field.title, id: field.id, value: '', field_index: '' })),
                                                        fields: []
                                                    }}
                                                    validationSchema={validationSchema}
                                                    enableReinitialize
                                                    onSubmit={(values) => {
                                                        handleFormSubmission(values);
                                                    }}

                                                >

                                                    {({ values, setFieldValue, errors, touched, setTouched }) => (
                                                        <Form>
                                                            <div className='contactListFields'>
                                                                <div className='ttl'>
                                                                    <div className='icoWrap'>
                                                                        {/* SVG Icon */}
                                                                    </div>
                                                                    <h3>Contact list fields
                                                                        <svg width="9" height="5" viewBox="0 0 9 5" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                            <path d="M2.39631e-05 0.580174C-0.00134686 0.430982 0.0561445 0.287361 0.160028 0.180271C0.266517 0.0695999 0.412372 0.00554926 0.565911 0.00180441C0.71945 -0.00194044 0.868261 0.0549035 0.98002 0.160252L4.16003 3.16025L7.65002 0.140232C7.767 0.0401109 7.9188 -0.00967651 8.07237 0.00156027C8.22593 0.0127971 8.36886 0.0842072 8.47003 0.200291C8.57015 0.317276 8.62001 0.469085 8.60877 0.622654C8.59754 0.776223 8.52611 0.919194 8.41003 1.02036L4.13003 4.73032L0.190026 1.00034C0.129854 0.948248 0.0817013 0.883643 0.0488979 0.811131C0.0160945 0.738618 -0.000580009 0.659759 2.39631e-05 0.580174Z" fill="black" />
                                                                        </svg>
                                                                    </h3>
                                                                </div>
                                                                <ul className='formList'>
                                                                    {values.predefinedFields.map((item, index) => (
                                                                        <li key={index}>
                                                                            <div className="inputGroup inputGroupWrapper">
                                                                                <label>{item.title}</label>
                                                                                <div className="inputWrap">
                                                                                    {/* <div className='inputField half'> */}
                                                                                        {/* <Field
                                                                                            name={`predefinedFields.${index}.value`}
                                                                                            placeholder={`Label ${item.title}`}
                                                                                            onChange={(e) => {
                                                                                                const newValue = e.target.value
                                                                                                setFieldValue(`predefinedFields.${index}.value`, newValue)
                                                                                                const updatedArray = [...fields]
                                                                                                updatedArray[index] = newValue
                                                                                                setFields([...updatedArray])
                                                                                            }}
                                                                                        />
                                                                                        {errors.predefinedFields?.[index]?.value && touched.predefinedFields?.[index]?.value && (
                                                                                            <div className="error">{errors.predefinedFields[index].value}</div>
                                                                                        )} */}
                                                                                    {/* </div> */}
                                                                                    <div className="inputField half">
                                                                                        <Field as="select" name={`predefinedFields.${index}.field_index`} onChange={(e) => {
                                                                                            setFieldValue(`predefinedFields.${index}.field_index`, e.target.value)
                                                                                            setFieldValue(`predefinedFields.${index}.value`, state.data[0][parseInt(e.target.value)])
                                                                                            let data = [...state.data[0]]
                                                                                            data.filter((_, index) => index !== parseInt(e.target.value))
                                                                                        }}>
                                                                                            <option>Select column name</option>
                                                                                            {state.data[0].map((col, idx) => (
                                                                                                <option value={idx}>{col}</option>
                                                                                            ))}
                                                                                        </Field>
                                                                                        {errors.predefinedFields?.[index]?.field_index && touched.predefinedFields?.[index]?.field_index && (
                                                                                            <div className="error">{errors.predefinedFields[index].field_index}</div>
                                                                                        )}

                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </li>
                                                                    ))}


                                                                </ul>
                                                            </div>
                                                            <div className='createCtaWrap'>
                                                                <Button type="submit">{isSpinLoad &&
                                                                    <Spinner
                                                                        as="span"
                                                                        animation="grow"
                                                                        size="sm"
                                                                        role="status"
                                                                        aria-hidden="true"
                                                                    />} {isSpinLoad ? "Loading" : "Create"}</Button>
                                                            </div>
                                                        </Form>
                                                    )}
                                                </Formik>
                                            </CustomScroll>
                                        </div>
                                        {/* <div className='contactListFields'>
                                        <div className='ttl'>
                                            <div className='icoWrap'>
                                                <svg width="13" height="13" viewBox="0 0 13 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M7.875 6.5H12M7.875 9.25H12M7.875 12H12M1 1.6875C1 1.50516 1.07243 1.3303 1.20136 1.20136C1.3303 1.07243 1.50516 1 1.6875 1H11.3125C11.4948 1 11.6697 1.07243 11.7986 1.20136C11.9276 1.3303 12 1.50516 12 1.6875V3.0625C12 3.24484 11.9276 3.4197 11.7986 3.54864C11.6697 3.67757 11.4948 3.75 11.3125 3.75H1.6875C1.50516 3.75 1.3303 3.67757 1.20136 3.54864C1.07243 3.4197 1 3.24484 1 3.0625V1.6875ZM1 7.1875C1 7.00516 1.07243 6.8303 1.20136 6.70136C1.3303 6.57243 1.50516 6.5 1.6875 6.5H4.4375C4.61984 6.5 4.7947 6.57243 4.92364 6.70136C5.05257 6.8303 5.125 7.00516 5.125 7.1875V11.3125C5.125 11.4948 5.05257 11.6697 4.92364 11.7986C4.7947 11.9276 4.61984 12 4.4375 12H1.6875C1.50516 12 1.3303 11.9276 1.20136 11.7986C1.07243 11.6697 1 11.4948 1 11.3125V7.1875Z" stroke="black" stroke-linecap="round" stroke-linejoin="round" />
                                                </svg>
                                            </div>
                                            <h3>Contact list fields
                                                <svg width="9" height="5" viewBox="0 0 9 5" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M2.39631e-05 0.580174C-0.00134686 0.430982 0.0561445 0.287361 0.160028 0.180271C0.266517 0.0695999 0.412372 0.00554926 0.565911 0.00180441C0.71945 -0.00194044 0.868261 0.0549035 0.98002 0.160252L4.16003 3.16025L7.65002 0.140232C7.767 0.0401109 7.9188 -0.00967651 8.07237 0.00156027C8.22593 0.0127971 8.36886 0.0842072 8.47003 0.200291C8.57015 0.317276 8.62001 0.469085 8.60877 0.622654C8.59754 0.776223 8.52611 0.919194 8.41003 1.02036L4.13003 4.73032L0.190026 1.00034C0.129854 0.948248 0.0817013 0.883643 0.0488979 0.811131C0.0160945 0.738618 -0.000580009 0.659759 2.39631e-05 0.580174Z" fill="black" />
                                                </svg>
                                            </h3>
                                        </div>


                                        <ul className='formList'>
                                            {preDefinedFields.map((field, index) => (
                                                <li>
                                                    <div className='inputField'>
                                                        <label>{field.title}</label>
                                                        <div className='inputsGroup'>
                                                            <input placeholder='Lable name' onChange={(e) => {
                                                                prefieldData.map((obj, key) => {
                                                                    if (obj.name === field.title) {
                                                                        prefieldData.splice(key, 1)
                                                                    }
                                                                })
                                                                prefieldData.push({ name: field.title, custom_label: e.target.value, predefined_field_id: field.id })
                                                                setPrefieldData([...prefieldData])
                                                            }} />
                                                            <Form.Select aria-label="Media" onChange={(e) => {
                                                                prefieldData.map((obj, key) => {
                                                                    if (obj.name === field.title) {
                                                                        obj['field_index'] = e.target.value
                                                                    }
                                                                })
                                                                setPrefieldData([...prefieldData])
                                                            }}>
                                                                <option value=""> </option>
                                                                {state.data[0].map((col, idx) => (
                                                                    <option value={idx}>{col}</option>
                                                                ))}
                                                            </Form.Select>
                                                        </div>
                                                    </div>
                                                </li>
                                            ))}

                                            {showFields ? <>
                                                {selectField.map((_, index) => (
                                                    <ul className='formList'>
                                                        <li>
                                                            <div className='inputGroup'>
                                                                <Form.Select aria-label="Media" onChange={(e) => {
                                                                    let data = { field_index: e.target.value, index: index + 2 }
                                                                    customeFieldData.map((obj, key) => {
                                                                        if (obj.index === index + 2) {
                                                                            customeFieldData.splice(key, 1)
                                                                        }
                                                                    })
                                                                    customeFieldData.push(data)
                                                                    setCustomFieldData([...customeFieldData])
                                                                }}>
                                                                    <option value=""> </option>
                                                                    {state.data[0].map((col, idx) => (
                                                                        <option value={idx}>{col}</option>
                                                                    ))}
                                                                </Form.Select>
                                                                <input placeholder='Lable name' onChange={(e) => {
                                                                    customeFieldData.map((obj, key) => {
                                                                        if (obj.index === index + 2) {
                                                                            obj['name'] = e.target.value
                                                                        }
                                                                    })
                                                                    setCustomLabel([...customLabel])
                                                                }} />
                                                                <CustomSelect
                                                                    // value={field.type}
                                                                    onChange={(value) => {
                                                                        customeFieldData.map((obj, key) => {
                                                                            if (obj.index === index + 2) {
                                                                                // customeFieldData.splice(key, 1)
                                                                                obj['type'] = value
                                                                            }
                                                                        })
                                                                        setCustomFieldData([...customeFieldData])
                                                                    }}
                                                                    options={options}
                                                                />
                                                            </div>
                                                        </li>
                                                    </ul>
                                                ))}
                                            </> :
                                                <li>
                                                    <div className='ctaWrap'>
                                                        <button className='btn' onClick={() => {
                                                            setShowFields(true)
                                                        }}>+ Add fields</button>
                                                    </div>
                                                </li>}
                                            {showFields && <li>
                                                <div className='ctaWrap'>
                                                    <button className='btn' onClick={() => {
                                                        setShowFields(true)
                                                        addFormFields()
                                                    }}>+ Add fields</button>
                                                </div>
                                            </li>}
                                        </ul>
                                    </div> */}
                                        {/* <div className='createCtaWrap'>
                                        <button className='btn' onClick={handleSubmitData}>Create</button>
                                    </div> */}
                                    </div>
                                </div>
                                <div className='col-md-8'>
                                    <div className='rightPannelWrap'>
                                        <div className='topWrap'>
                                            <div className='ttlWrap'>
                                                <div className='icoWrap'>
                                                    <svg width="42" height="42" viewBox="0 0 42 42" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <path d="M12.1111 41V38.7778C12.1111 37.599 12.5794 36.4686 13.4129 35.6351C14.2464 34.8016 15.3768 34.3333 16.5556 34.3333H25.4444C26.6232 34.3333 27.7536 34.8016 28.5871 35.6351C29.4206 36.4686 29.8889 37.599 29.8889 38.7778V41M32.1111 16.5556H36.5556C37.7343 16.5556 38.8648 17.0238 39.6983 17.8573C40.5317 18.6908 41 19.8213 41 21V23.2222M1 23.2222V21C1 19.8213 1.46825 18.6908 2.30175 17.8573C3.13524 17.0238 4.2657 16.5556 5.44444 16.5556H9.88889M16.5556 23.2222C16.5556 24.401 17.0238 25.5314 17.8573 26.3649C18.6908 27.1984 19.8213 27.6667 21 27.6667C22.1787 27.6667 23.3092 27.1984 24.1427 26.3649C24.9762 25.5314 25.4444 24.401 25.4444 23.2222C25.4444 22.0435 24.9762 20.913 24.1427 20.0795C23.3092 19.246 22.1787 18.7778 21 18.7778C19.8213 18.7778 18.6908 19.246 17.8573 20.0795C17.0238 20.913 16.5556 22.0435 16.5556 23.2222ZM27.6667 5.44444C27.6667 6.62318 28.1349 7.75365 28.9684 8.58714C29.8019 9.42064 30.9324 9.88889 32.1111 9.88889C33.2899 9.88889 34.4203 9.42064 35.2538 8.58714C36.0873 7.75365 36.5556 6.62318 36.5556 5.44444C36.5556 4.2657 36.0873 3.13524 35.2538 2.30175C34.4203 1.46825 33.2899 1 32.1111 1C30.9324 1 29.8019 1.46825 28.9684 2.30175C28.1349 3.13524 27.6667 4.2657 27.6667 5.44444ZM5.44444 5.44444C5.44444 6.62318 5.9127 7.75365 6.74619 8.58714C7.57969 9.42064 8.71015 9.88889 9.88889 9.88889C11.0676 9.88889 12.1981 9.42064 13.0316 8.58714C13.8651 7.75365 14.3333 6.62318 14.3333 5.44444C14.3333 4.2657 13.8651 3.13524 13.0316 2.30175C12.1981 1.46825 11.0676 1 9.88889 1C8.71015 1 7.57969 1.46825 6.74619 2.30175C5.9127 3.13524 5.44444 4.2657 5.44444 5.44444Z" stroke="#515151" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                                    </svg>
                                                </div>
                                                <h2>{groupName}</h2>
                                            </div>
                                            <div className='rightActionWrap'>
                                                <button className='btnFilter btn'>
                                                    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <path d="M17 3L13 3M9 3L1 3M17 9L7 9M3 9L1 9M17 15L16 15M12 15L0.999999 15M13 1L13 5L9 5L9 1L13 1ZM7 7L7 11L3 11L3 7L7 7ZM16 13L16 17L12 17L12 13L16 13Z" stroke="#939393" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                                    </svg>
                                                </button>
                                                <button className='btnUpload btn'>
                                                    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <path d="M19 4C19 3.20435 18.6839 2.44129 18.1213 1.87868C17.5587 1.31607 16.7956 1 16 1H4C3.20435 1 2.44129 1.31607 1.87868 1.87868C1.31607 2.44129 1 3.20435 1 4M10 13V5M10 5L7 8M10 5L13 8M4 19H16C16.7956 19 17.5587 18.6839 18.1213 18.1213C18.6839 17.5587 19 16.7956 19 16C19 15.2044 18.6839 14.4413 18.1213 13.8787C17.5587 13.3161 16.7956 13 16 13H4C3.20435 13 2.44129 13.3161 1.87868 13.8787C1.31607 14.4413 1 15.2044 1 16C1 16.7956 1.31607 17.5587 1.87868 18.1213C2.44129 18.6839 3.20435 19 4 19Z" stroke="#939393" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                                    </svg>
                                                </button>
                                                <div className='searchWrap'>
                                                    <input />
                                                    <button className='btnSearch'>
                                                        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                            <path d="M15 15L10.3333 10.3333M1 6.44444C1 7.15942 1.14082 7.86739 1.41443 8.52794C1.68804 9.18849 2.08908 9.78868 2.59464 10.2942C3.1002 10.7998 3.7004 11.2008 4.36095 11.4745C5.0215 11.7481 5.72947 11.8889 6.44444 11.8889C7.15942 11.8889 7.86739 11.7481 8.52794 11.4745C9.18849 11.2008 9.78868 10.7998 10.2942 10.2942C10.7998 9.78868 11.2008 9.18849 11.4745 8.52794C11.7481 7.86739 11.8889 7.15942 11.8889 6.44444C11.8889 5.72947 11.7481 5.0215 11.4745 4.36095C11.2008 3.7004 10.7998 3.1002 10.2942 2.59464C9.78868 2.08908 9.18849 1.68804 8.52794 1.41443C7.86739 1.14082 7.15942 1 6.44444 1C5.72947 1 5.0215 1.14082 4.36095 1.41443C3.7004 1.68804 3.1002 2.08908 2.59464 2.59464C2.08908 3.1002 1.68804 3.7004 1.41443 4.36095C1.14082 5.0215 1 5.72947 1 6.44444Z" stroke="#939393" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                                        </svg>
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='tblWrap'>
                                            <table>
                                                <thead>
                                                    <tr>
                                                        {/* <th>
                                                        <input id="checkAll" name="" type="checkbox" value="yes" />
                                                    </th> */}
                                                        {state.data[0].length > 0 &&
                                                            state.data[0].map((val) => (
                                                                <th>{val}</th>
                                                            ))
                                                        }

                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {state.data.filter((_, index) => index !== 0).map((val, index) => {
                                                        return (
                                                            <tr>
                                                                {val.map((val1) => {
                                                                    return (
                                                                        <td>{val1}</td>
                                                                    )
                                                                })}
                                                            </tr>
                                                        )

                                                    })}
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </main>

                <Modal show={show} onHide={handleClose} className='addNewFieldsPopup'>
                    <Modal.Header>
                        <Modal.Title>Add new Fields</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <ul className='formList'>
                            <li>
                                <div className='inputGroup'>
                                    <input />
                                    <input placeholder='Lable name' />
                                    <Form.Select aria-label="Media">
                                        <option value=""> </option>
                                        <option value="Text">Text</option>
                                        <option value="Text">Text</option>
                                        <option value="Text">Text</option>
                                        <option value="Text">Text</option>
                                        <option value="Text">Text</option>
                                    </Form.Select>
                                </div>
                            </li>
                            <li>
                                <div className='ctaWrap'>
                                    <button className='btn'>+</button>
                                </div>
                            </li>
                        </ul>
                        <div className='addCtaWrap'>
                            <button className='btn'>Add</button>
                        </div>
                    </Modal.Body>
                </Modal>

            </div>}
        </>
    );
};

export default MapFields;

