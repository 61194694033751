import axios from "axios";
import { useNavigate } from "react-router-dom"
import { handleErrorResponseAxios, handleResponseAxios } from "./common-handler"
import {
    GET_ALL_CATEGORIES,
    ADD_NEW_CATEGORIES,
    GET_CATEGORY_BY_ID,
    UPDATE_CATEGORY,
    GET_ALL_MENUS
} from "../utility/ConstantAPI";

export function get_all_categories() {
    // let token = get_auth_token();
    let axiosConfig = {
        headers: {
            "Content-Type": "application/json",
            // Authorization: `Bearer ${token}`,
        },
    };
    var url = GET_ALL_CATEGORIES
    return axios
        .get(url, axiosConfig)
        .then(handleResponseAxios)
        .catch(function (error) {
            return handleErrorResponseAxios(error)
        });

}
export async function add_new_category(values, onSuccess, onError) {
    // let token = get_auth_token()
    var url = ADD_NEW_CATEGORIES;
    let axiosConfig = {
        headers: {
            "Content-Type": "multipart/form-data",
            // Authorization: `Bearer ${token}`,
        },
    };

    try {
        let response = await axios.post(url, values, axiosConfig);
        onSuccess(handleResponseAxios(response));
    } catch (error) {
        onError(handleErrorResponseAxios(error));
    }
}

export function get_category_by_id(category_id) {
    // let token = get_auth_token();
    let axiosConfig = {
        headers: {
            "Content-Type": "application/json",
            // Authorization: `Bearer ${token}`,
        },
    };
    var url = GET_CATEGORY_BY_ID(category_id)
    return axios
        .get(url, axiosConfig)
        .then(handleResponseAxios)
        .catch(function (error) {
            return handleErrorResponseAxios(error)
        });

}



export function get_my_menu() {
    // let token = get_auth_token();
    let axiosConfig = {
        headers: {
            "Content-Type": "application/json",
            // Authorization: `Bearer ${token}`,
        },
    };
    var url = GET_ALL_MENUS
    return axios
        .get(url, axiosConfig)
        .then(handleResponseAxios)
        .catch(function (error) {
            return handleErrorResponseAxios(error)
        });

}

export async function update_category(category_id,values,onSuccess,onError) {
    let axiosConfig = {
        headers: {
            "Content-Type": "multipart/form-data",
            // Authorization: `Bearer ${token}`,
        },
    };
    var url = UPDATE_CATEGORY(category_id)
    try {
        let response = await axios.post(url, values, axiosConfig);
        onSuccess(handleResponseAxios(response));
    } catch (error) {
        onError(handleErrorResponseAxios(error));
    }
}
