import React, { useState, useEffect } from 'react';
import Header from '../../Components/Common/Header';
import "react-datepicker/dist/react-datepicker.css";
import { getAllBroadCast, getOverview , getBroadcastDetailsById} from '../../services/broadcats-services';
import BroadcastHistoryDetails from './BroadcastHistoryDetails';
import AnlyticsOverView from './AnlyticsOverView';
import IndividualBroadcastDeatils from './IndividualBroadcastDeatils';

const BroadcastHistory = () => {
    const [isOpen, setIsOpen] = useState(false);
    const [selectedFromDate, setSelectedFromDate] = useState(new Date());
    const [selectedToDate, setSelectedToDate] = useState(new Date());
    const [data, setData] = useState([])
    const [overviewData, setOverviewData] = useState([]);
    const [error, setError] = useState()
    const [ selectedBroadcastID, setSelectedBroadcastID] = useState(null)
    const token = localStorage.getItem('token')
    const toggleSidebar = () => {
        setIsOpen(!isOpen);
    };
    useEffect(() => {
        fetchData()
        
    }, [])
    const fetchData = async () => {
        try {
            const response = await getAllBroadCast(token);
            const restult = await getOverview(token);
            if (response) {
                setData(response);
            } else {
                setError(response.message);
            }
            if (restult) {
                setOverviewData(restult[0]);
            } else {
                setError(restult.message);
            }
        } catch (error) {
            console.log("Error fetching data:", error)
            setError(error.message);
        }
    }

    const handleSelect = async (broadcast_id, broadcast_details) =>{
        const response = await getBroadcastDetailsById(broadcast_id);
        setOverviewData(broadcast_details)
        setSelectedBroadcastID(broadcast_id)
        setData(response)        

    }

    const handleBack = () => {
        setSelectedBroadcastID(null);
        fetchData();  //refetch data to ensure the broadcast list is up-to-date
    };

    return (
        <div className={isOpen ? "app-container sidebar-open" : "app-container"}>
            <Header toggleSidebar={toggleSidebar} />
            <main className="content broadcastHistoryPage">
                <div className='broadcastHistoryWrap'>
                    <div className='container-fluid pad0'>
                        <div className='row'>
                            <div className='col-md-12 pad0'>
                                <div className='titleWrap'>
                                    <ul>
                                        <li className='active'>
                                            <div className='icoWrap'>
                                                <svg width="27" height="19" viewBox="0 0 27 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M18.477 3.91047C18.516 3.87119 18.5627 3.84057 18.6142 3.82008C18.6656 3.79958 18.7207 3.79001 18.776 3.79169C18.8314 3.79337 18.8858 3.80611 18.9359 3.82968C18.986 3.85325 19.0307 3.8868 19.0673 3.92837C20.4074 5.47858 21.144 7.46001 21.1421 9.50918C21.145 11.55 20.4151 13.5241 19.0852 15.0721C19.0486 15.1137 19.0039 15.1472 18.9538 15.1708C18.9037 15.1943 18.8493 15.2071 18.7939 15.2088C18.7386 15.2104 18.6835 15.2009 18.632 15.1804C18.5806 15.1599 18.5339 15.1288 18.4949 15.0895C18.4194 15.0145 18.3754 14.9135 18.3721 14.807C18.3688 14.7006 18.4063 14.5971 18.477 14.5175C19.6745 13.1176 20.3285 11.3335 20.3193 9.49128C20.319 7.64218 19.6595 5.85365 18.4592 4.44715C18.4222 4.41233 18.393 4.37009 18.3737 4.32313C18.3543 4.27618 18.3452 4.22586 18.3469 4.1751C18.3486 4.12434 18.3611 4.07437 18.3835 4.02881C18.4059 3.98325 18.4378 3.94275 18.477 3.91047Z" fill="black" />
                                                    <path d="M22.5918 0.118526C22.6309 0.0794858 22.6776 0.0489151 22.7291 0.0285694C22.7805 0.00822368 22.8355 -0.00148971 22.8907 0.000185015C22.946 0.00185974 23.0004 0.014755 23.0505 0.0381764C23.1005 0.0615978 23.1453 0.0950907 23.182 0.13643C25.4895 2.71251 26.7636 6.0505 26.7593 9.50895C26.7668 12.9594 25.4992 16.2909 23.1999 18.8636C23.1632 18.9049 23.1184 18.9384 23.0683 18.9618C23.0182 18.9852 22.9639 18.9981 22.9086 18.9998C22.8534 19.0015 22.7983 18.9918 22.7469 18.9714C22.6955 18.9511 22.6488 18.9205 22.6097 18.8815C22.5341 18.8064 22.4902 18.7054 22.4869 18.5989C22.4835 18.4925 22.5211 18.3886 22.5918 18.309C24.7536 15.8883 25.9448 12.7544 25.9365 9.50895C25.9418 6.25543 24.7441 3.11494 22.5739 0.691017C22.5032 0.61139 22.4656 0.5075 22.469 0.40106C22.4723 0.29462 22.5162 0.193583 22.5918 0.118526Z" fill="black" />
                                                    <path d="M8.30088 3.91047C8.26186 3.87119 8.21514 3.84057 8.16371 3.82008C8.11227 3.79958 8.0572 3.79001 8.00186 3.79169C7.94652 3.79337 7.89208 3.80611 7.84198 3.82968C7.79188 3.85325 7.74717 3.8868 7.71059 3.92837C6.37047 5.47858 5.63385 7.46001 5.63575 9.50918C5.63286 11.55 6.36278 13.5241 7.69269 15.0721C7.72926 15.1137 7.77403 15.1472 7.82413 15.1708C7.87423 15.1943 7.92862 15.2071 7.98396 15.2088C8.0393 15.2104 8.09442 15.2009 8.14586 15.1804C8.19729 15.1599 8.24395 15.1288 8.28298 15.0895C8.35852 15.0145 8.40247 14.9135 8.40579 14.807C8.40912 14.7006 8.37159 14.5971 8.30088 14.5175C7.10335 13.1176 6.44937 11.3335 6.45852 9.49128C6.45879 7.64218 7.11836 5.85365 8.31873 4.44715C8.35351 4.41112 8.38072 4.36856 8.3987 4.32182C8.41668 4.27509 8.42509 4.22515 8.42342 4.1751C8.42176 4.12505 8.41003 4.076 8.38898 4.03056C8.36793 3.98512 8.33798 3.9441 8.30088 3.91047Z" fill="black" />
                                                    <path d="M4.16757 0.118526C4.12842 0.0794858 4.0817 0.0489151 4.03029 0.0285694C3.97888 0.00822368 3.92387 -0.00148971 3.86861 0.000185015C3.81334 0.00185974 3.75897 0.014755 3.70889 0.0381764C3.6588 0.0615978 3.61406 0.0950907 3.57734 0.13643C1.26981 2.71251 -0.00424518 6.0505 3.2968e-05 9.50895C-0.007453 12.9594 1.26017 16.2909 3.55943 18.8636C3.59615 18.9049 3.64095 18.9384 3.69104 18.9618C3.74112 18.9852 3.79544 18.9981 3.8507 18.9998C3.90597 19.0015 3.96103 18.9918 4.01244 18.9714C4.06385 18.9511 4.11057 18.9205 4.14972 18.8815C4.22526 18.8064 4.26921 18.7054 4.27254 18.5989C4.27586 18.4925 4.23828 18.3886 4.16757 18.309C2.00572 15.8883 0.814529 12.7544 0.822798 9.50895C0.817568 6.25543 2.01522 3.11494 4.18547 0.691017C4.22241 0.652536 4.25128 0.606744 4.27041 0.556956C4.28954 0.507167 4.29855 0.454371 4.29688 0.40106C4.29522 0.347749 4.28289 0.294971 4.26069 0.246474C4.23849 0.197977 4.20683 0.154626 4.16757 0.118526Z" fill="black" />
                                                    <path d="M10.7148 9.50899C10.7153 8.77859 11.0057 8.07829 11.5222 7.56182C12.0386 7.04535 12.739 6.75487 13.4694 6.75439C14.1998 6.75487 14.9001 7.04535 15.4166 7.56182C15.9331 8.07829 16.2235 8.77859 16.2239 9.50899C16.2235 10.2394 15.9331 10.9397 15.4166 11.4562C14.9001 11.9726 14.1998 12.2631 13.4694 12.2636C12.739 12.2631 12.0386 11.9726 11.5222 11.4562C11.0057 10.9397 10.7153 10.2394 10.7148 9.50899ZM15.3833 9.50899C15.3823 8.99695 15.1785 8.50599 14.8164 8.14392C14.4543 7.78185 13.9635 7.57805 13.4515 7.5771C12.9394 7.57805 12.4486 7.78185 12.0866 8.14392C11.7245 8.50599 11.5207 8.99695 11.5198 9.50899C11.5207 10.021 11.7245 10.512 12.0866 10.8741C12.4486 11.2361 12.9394 11.4399 13.4515 11.4409C13.7054 11.4418 13.9571 11.3922 14.1919 11.2955C14.4267 11.1987 14.64 11.0567 14.8196 10.8771C14.9991 10.6975 15.1414 10.484 15.2381 10.2492C15.3349 10.0144 15.3842 9.76293 15.3833 9.50899Z" fill="black" />
                                                </svg>
                                            </div>
                                            <h2>Analytics</h2>
                                        </li>
                                        <li>
                                            <div className='icoWrap'>
                                                <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M13.5 10H10V5M1 10C1 11.1819 1.23279 12.3522 1.68508 13.4442C2.13738 14.5361 2.80031 15.5282 3.63604 16.364C4.47177 17.1997 5.46392 17.8626 6.55585 18.3149C7.64778 18.7672 8.8181 19 10 19C11.1819 19 12.3522 18.7672 13.4442 18.3149C14.5361 17.8626 15.5282 17.1997 16.364 16.364C17.1997 15.5282 17.8626 14.5361 18.3149 13.4442C18.7672 12.3522 19 11.1819 19 10C19 8.8181 18.7672 7.64778 18.3149 6.55585C17.8626 5.46392 17.1997 4.47177 16.364 3.63604C15.5282 2.80031 14.5361 2.13738 13.4442 1.68508C12.3522 1.23279 11.1819 1 10 1C8.8181 1 7.64778 1.23279 6.55585 1.68508C5.46392 2.13738 4.47177 2.80031 3.63604 3.63604C2.80031 4.47177 2.13738 5.46392 1.68508 6.55585C1.23279 7.64778 1 8.8181 1 10Z" stroke="#B7B7B7" stroke-linecap="round" stroke-linejoin="round" />
                                                </svg>
                                            </div>
                                            <h2>Scheduled  Broadcast</h2>
                                        </li>
                                        <li>
                                            <div className='icoWrap'>
                                                <svg width="20" height="19" viewBox="0 0 20 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M6 6H14M6 10H12M16 1C16.7956 1 17.5587 1.31607 18.1213 1.87868C18.6839 2.44129 19 3.20435 19 4V12C19 12.7956 18.6839 13.5587 18.1213 14.1213C17.5587 14.6839 16.7956 15 16 15H11L6 18V15H4C3.20435 15 2.44129 14.6839 1.87868 14.1213C1.31607 13.5587 1 12.7956 1 12V4C1 3.20435 1.31607 2.44129 1.87868 1.87868C2.44129 1.31607 3.20435 1 4 1H16Z" stroke="#B7B7B7" stroke-linecap="round" stroke-linejoin="round" />
                                                </svg>
                                            </div>
                                            <h2>Message Template</h2>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div> 

                        <AnlyticsOverView overviewData={overviewData} />

                        {selectedBroadcastID ?  (
                            <IndividualBroadcastDeatils data={data}  handleBack={handleBack}/>
                        ):(
                            <BroadcastHistoryDetails onBroadcasttSelect={handleSelect} data={data}/>
                        )}
                        
                    </div>
                </div>
            </main>
        </div>
    );
};

export default BroadcastHistory;

