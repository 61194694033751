export function formatDate (isoDate){
    const options = { year: 'numeric', month: 'long', day: 'numeric' };
    return new Date(isoDate).toLocaleDateString(undefined, options);
};

export function formatDateTime (isoDate){
    const date = new Date(isoDate);
    
    const optionsDate = { year: 'numeric', month: 'long', day: 'numeric' };
    const formattedDate = date.toLocaleDateString(undefined, optionsDate);
  
    const optionsTime = { hour: 'numeric', minute: 'numeric', hour12: true };
    const formattedTime = date.toLocaleTimeString(undefined, optionsTime);
  
    return `${formattedDate} - ${formattedTime}`;
};

export const spinnerContainerStyle = {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100vh',
};
