import axios from "axios";
import {useNavigate } from "react-router-dom"
import {handleErrorResponseAxios, handleResponseAxios} from "./common-handler"
import {
    GROUP_API,
    GET_GROUP_FIELDS_BY_ID,
    ADD_DATA_GROUP_API,
    GET_GROUP_BY_GROUP_ID,
    UPDATE_GROUPS_CONTACTS,
    DELETE_CONTACT_LIST,
    DELETE_CONTACT,
    DELETE_BULK_CONTACT
} from "../utility/ConstantAPI";

export async function createNewGroup(values,userId, onSuccess, onError) {
    // let token = get_auth_token();
    let axiosConfig = {
        headers: {
            "Content-Type": "application/json",
            // Authorization: `Bearer ${token}`,
        },
    };
    var url = GROUP_API(userId)

    try {
        let response = await axios.post(url, values, axiosConfig);        
        onSuccess(handleResponseAxios(response));
    } catch (error) {
        onError(handleErrorResponseAxios(error));
    }

}

export async function getAllGoups(token,userId){
    // let token = get_auth_token();
    let axiosConfig = {
        headers: {
            "Content-Type": "application/json",
            Authorization: `${token}`,
        },
    };
    var url = GROUP_API(userId)
    return axios
        .get(url, axiosConfig)
        .then(handleResponseAxios)
        .catch(function (error) {
            return handleErrorResponseAxios(error)
    });

}

export async function getGroupFieldsById(groupId){
    // let token = get_auth_token();
    let axiosConfig = {
        headers: {
            "Content-Type": "application/json",
            // Authorization: `Bearer ${token}`,
        },
    };
    var url = GET_GROUP_FIELDS_BY_ID(groupId)
    return axios
        .get(url, axiosConfig)
        .then(handleResponseAxios)
        .catch(function (error) {
            return handleErrorResponseAxios(error)
        });
}

export async function getGroupById(groupId,token){
    // let token = get_auth_token();
    let axiosConfig = {
        headers: {
            "Content-Type": "application/json",
            Authorization: `${token}`,
        },
    };
    var url = GET_GROUP_BY_GROUP_ID(groupId)
    return axios
        .get(url, axiosConfig)
        .then(handleResponseAxios)
        .catch(function (error) {
            return handleErrorResponseAxios(error)
        });
}
export async function addGroupData(values, onSuccess, onError) {
    // let token = get_auth_token();
    let axiosConfig = {
        headers: {
            "Content-Type": "application/json",
            // Authorization: `Bearer ${token}`,
        },
    };
    var url = ADD_DATA_GROUP_API

    try {
        let response = await axios.post(url, values, axiosConfig);        
        onSuccess(handleResponseAxios(response));
    } catch (error) {
        onError(handleErrorResponseAxios(error));
    }

}

export async function updateGroupContacts(values,groupId, onSuccess, onError) {
    // let token = get_auth_token();
    let axiosConfig = {
        headers: {
            "Content-Type": "application/json",
            // Authorization: `Bearer ${token}`,
        },
    };
    var url = UPDATE_GROUPS_CONTACTS(groupId)

    try {
        let response = await axios.post(url, values, axiosConfig);        
        onSuccess(handleResponseAxios(response));
    } catch (error) {
        onError(handleErrorResponseAxios(error));
    }

}

export async function deleteContactList(groupId, onSuccess, onError) {
    // let token = get_auth_token();
    let axiosConfig = {
        headers: {
            "Content-Type": "application/json",
            // Authorization: `Bearer ${token}`,
        },
    };
    var url = DELETE_CONTACT_LIST(groupId)

    try {
        let response = await axios.delete(url, axiosConfig);        
        onSuccess(handleResponseAxios(response));
    } catch (error) {
        onError(handleErrorResponseAxios(error));
    }

}

export async function deleteContact(contact_id, group_id, onSuccess, onError) {
    // let token = get_auth_token();
    let axiosConfig = {
        headers: {
            "Content-Type": "application/json",
            // Authorization: `Bearer ${token}`,
        },
    };
    var url = DELETE_CONTACT(contact_id,group_id)

    try {
        let response = await axios.delete(url, axiosConfig);        
        onSuccess(handleResponseAxios(response));
    } catch (error) {
        onError(handleErrorResponseAxios(error));
    }

}

export async function deleteBulkContact(values, onSuccess, onError) {
    // let token = get_auth_token();
    let axiosConfig = {
        headers: {
            "Content-Type": "application/json",
            // Authorization: `Bearer ${token}`,
        },
    };
    var url = DELETE_BULK_CONTACT

    try {
        let response = await axios.post(url,values, axiosConfig);        
        onSuccess(handleResponseAxios(response));
    } catch (error) {
        onError(handleErrorResponseAxios(error));
    }

}

