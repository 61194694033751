import React, { useState, useEffect } from 'react';
import Form from 'react-bootstrap/Form';
import { CustomScroll } from "react-custom-scroll";
import Header from '../../Components/Common/Header';
import Sidebar from '../../Components/Common/Sidebar';
import { getAllBroadCast } from '../../services/broadcats-services';

const BroadcastList = () => {
    const [isOpen, setIsOpen] = useState(false);
    const [data, setData] = useState([])
    const [error, setError] = useState()
    const toggleSidebar = () => {
        setIsOpen(!isOpen);
    };

    const [popupVisible, setPopupVisible] = useState(false);
    const [popupPosition, setPopupPosition] = useState({ top: 0, left: 0 });

    const togglePopup = (event) => {
        const rect = event.target.getBoundingClientRect();
        setPopupPosition({ top: rect.top + window.scrollY, left: rect.left + window.scrollX + 40 });
        setPopupVisible(!popupVisible);
    };
    useEffect(() => {
        fetchData()
    }, [])
    const fetchData = async () => {
        try {
            const response = await getAllBroadCast();
            if (response) {
                setData(response);
            } else {
                setError(response.message);
            }
        } catch (error) {
            console.log("Error fetching data:", error)
            setError(error.message);
        }
    }
    return (
        <div className={isOpen ? "app-container sidebar-open" : "app-container"}>
            <Header toggleSidebar={toggleSidebar} />
            <Sidebar isOpen={isOpen} />
            <main className="content broadcastListPage">
                <div className='broadcastListWrap'>
                    <div className='serachWrap'>
                        <div className='container-fluid'>
                            <div className='row align-items-center'>
                                <div className='col-md-6'>
                                    <form>
                                        <div className='icoWrap'>
                                            <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path fill-rule="evenodd" clip-rule="evenodd" d="M12.5006 11.0006H11.7106L11.4306 10.7306C12.6306 9.33063 13.2506 7.42063 12.9106 5.39063C12.4406 2.61063 10.1206 0.390626 7.32063 0.0506256C3.09063 -0.469374 -0.469374 3.09063 0.0506256 7.32063C0.390626 10.1206 2.61063 12.4406 5.39063 12.9106C7.42063 13.2506 9.33063 12.6306 10.7306 11.4306L11.0006 11.7106V12.5006L15.2506 16.7506C15.6606 17.1606 16.3306 17.1606 16.7406 16.7506C17.1506 16.3406 17.1506 15.6706 16.7406 15.2606L12.5006 11.0006ZM6.50063 11.0006C4.01063 11.0006 2.00063 8.99063 2.00063 6.50063C2.00063 4.01063 4.01063 2.00063 6.50063 2.00063C8.99063 2.00063 11.0006 4.01063 11.0006 6.50063C11.0006 8.99063 8.99063 11.0006 6.50063 11.0006Z" fill="#C3CAD9" />
                                            </svg>
                                        </div>
                                        <input placeholder='Search' />
                                    </form>
                                </div>
                                <div className='col-md-6'>
                                    <div className='rightWrap'>
                                        <button className='btnAddNew'>
                                            <svg width="12" height="12" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M16 8.2963C16 9.01459 15.6177 9.37374 14.853 9.37374H10.638C9.87336 9.37374 9.49104 9.73288 9.49104 10.4512V14.9226C9.49104 15.6409 9.10872 16 8.34409 16H7.59857C6.83393 16 6.45161 15.6409 6.45161 14.9226V10.4512C6.45161 9.73288 6.06929 9.37374 5.30466 9.37374H1.14695C0.382318 9.37374 0 9.01459 0 8.2963V7.75758C0 7.03928 0.382318 6.68013 1.14695 6.68013H5.30466C6.06929 6.68013 6.45161 6.32099 6.45161 5.60269V1.07744C6.45161 0.359147 6.83393 0 7.59857 0H8.34409C9.10872 0 9.49104 0.359147 9.49104 1.07744V5.60269C9.49104 6.32099 9.87336 6.68013 10.638 6.68013H14.853C15.6177 6.68013 16 7.03928 16 7.75758V8.2963Z" fill="white" />
                                            </svg>
                                            Add new Broadcast
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='broadcastListTbl'>
                        <table>
                            <thead>
                                <tr>
                                    <th>
                                        <input id="checkAll" name="" type="checkbox" value="yes" />
                                    </th>
                                    <th>Broadcast Name</th>
                                    <th>Lables</th>
                                    <th>No. of contacts</th>
                                    <th>Message deliver rate</th>
                                    <th>Message read rate</th>
                                    <th>Status</th>
                                    <th>Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                {data.map((val, index) => (
                                    <tr>
                                        <td><input type="checkbox" /></td>
                                        <td>{val.name}</td>
                                        <td>
                                            <ul>
                                                <li className='clrBlue'>Best seller</li>
                                                <li className='clrYellow'>Pune</li>
                                                <li className='clrGray'>
                                                    <button className='btn' onClick={togglePopup}>
                                                        <svg width="9" height="10" viewBox="0 0 9 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                            <path d="M8.64112 5.59081H5.1177V9.16385H3.71164V5.59081H0.204767V4.31709H3.71164V0.727503H5.1177V4.31709H8.64112V5.59081Z" fill="#8E8E8E" />
                                                        </svg>
                                                    </button>
                                                </li>
                                            </ul>
                                            {popupVisible && (
                                                <div className='newLabelPopup' style={{ top: popupPosition.top, left: popupPosition.left }}>
                                                    <div className='titleWrap'>
                                                        <h2>Add new label</h2>
                                                    </div>
                                                    <div className='newLabelFieldWrap'>
                                                        <input placeholder='Type label name' />
                                                        <button className='btn'>Add</button>
                                                    </div>
                                                </div>
                                            )}
                                        </td>
                                        <td>{val.group_contact_length}</td>
                                        <td>1650</td>
                                        <td>1500</td>
                                        <td>
                                            <div className='statusWrap'>
                                                <p>{val.isActive === true ? "Active" : "Deactive"}</p>
                                            </div>
                                        </td>
                                        <td>
                                            <button className='btn'>
                                                <svg width="13" height="13" viewBox="0 0 13 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M5.63514 2.96345L9.60811 6.93642M1 8.92291L8.7473 1.17561C8.87107 1.05429 9.03749 0.986328 9.21081 0.986328C9.38413 0.986328 9.55055 1.05429 9.67432 1.17561L11.3959 2.89723C11.5173 3.02101 11.5852 3.18742 11.5852 3.36075C11.5852 3.53407 11.5173 3.70048 11.3959 3.82426L3.64865 11.5716H1V8.92291Z" stroke="#515151" stroke-width="0.993243" stroke-linecap="round" stroke-linejoin="round" />
                                                </svg>
                                            </button>
                                            <button className='btn'>
                                                <svg width="13" height="10" viewBox="0 0 13 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M5.17615 4.94612C5.17615 5.29735 5.31568 5.6342 5.56404 5.88256C5.8124 6.13091 6.14924 6.27044 6.50047 6.27044C6.85171 6.27044 7.18855 6.13091 7.43691 5.88256C7.68527 5.6342 7.8248 5.29735 7.8248 4.94612C7.8248 4.59488 7.68527 4.25804 7.43691 4.00968C7.18855 3.76132 6.85171 3.62179 6.50047 3.62179C6.14924 3.62179 5.8124 3.76132 5.56404 4.00968C5.31568 4.25804 5.17615 4.59488 5.17615 4.94612Z" stroke="#515151" stroke-width="0.993243" stroke-linecap="round" stroke-linejoin="round" />
                                                    <path d="M12.4599 4.94612C10.8707 7.59477 8.88426 8.91909 6.50047 8.91909C4.11669 8.91909 2.1302 7.59477 0.541016 4.94612C2.1302 2.29747 4.11669 0.973145 6.50047 0.973145C8.88426 0.973145 10.8707 2.29747 12.4599 4.94612Z" stroke="#515151" stroke-width="0.993243" stroke-linecap="round" stroke-linejoin="round" />
                                                </svg>
                                            </button>
                                            <button className='btn'>
                                                <svg width="13" height="14" viewBox="0 0 13 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M1.40625 3.64865H12.0008M5.37922 6.2973V10.2703M8.02787 6.2973V10.2703M2.06841 3.64865L2.73057 11.5946C2.73057 11.9458 2.8701 12.2827 3.11846 12.531C3.36682 12.7794 3.70367 12.9189 4.0549 12.9189H9.3522C9.70343 12.9189 10.0403 12.7794 10.2886 12.531C10.537 12.2827 10.6765 11.9458 10.6765 11.5946L11.3387 3.64865M4.71706 3.64865V1.66216C4.71706 1.48655 4.78682 1.31812 4.911 1.19394C5.03518 1.06976 5.20361 1 5.37922 1H8.02787C8.20349 1 8.37191 1.06976 8.49609 1.19394C8.62027 1.31812 8.69003 1.48655 8.69003 1.66216V3.64865" stroke="#515151" stroke-width="0.993243" stroke-linecap="round" stroke-linejoin="round" />
                                                </svg>
                                            </button>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                </div>
            </main>
        </div>
    );
};

export default BroadcastList;

