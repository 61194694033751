import React, { useState, useEffect } from 'react';
import Form from 'react-bootstrap/Form';
import { CustomScroll } from "react-custom-scroll";
import Header from '../../Components/Common/Header';
import Sidebar from '../../Components/Common/Sidebar';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import Select from 'react-select';
import UPIImg from '../../assets/img/upiImg.png';
import QRImg from '../../assets/img/qrImg.png';
import GPAYImg from '../../assets/img/gPayImg.png';
import InoutImg1 from '../../assets/img/inputImg-1.png';

const BillingPayment = () => {
    const [isOpen, setIsOpen] = useState(false);
    const [activeKey, setActiveKey] = useState('PlanDetails');
    const [selectedValue, setSelectedValue] = useState('option1');
    const toggleSidebar = () => {
        setIsOpen(!isOpen);
    };

    const handleChange = (event) => {
      setSelectedValue(event.target.value);
    };

    const options = [
        { value: (<img src={GPAYImg} alt='' className='img-fluid'/>), label: (<img src={GPAYImg} alt='' className='img-fluid'/>) },
        { value: (<img src={GPAYImg} alt='' className='img-fluid'/>), label: (<img src={GPAYImg} alt='' className='img-fluid'/>) },
        { value: (<img src={GPAYImg} alt='' className='img-fluid'/>), label: (<img src={GPAYImg} alt='' className='img-fluid'/>) },
        { value: (<img src={GPAYImg} alt='' className='img-fluid'/>), label: (<img src={GPAYImg} alt='' className='img-fluid'/>) },
        { value: (<img src={GPAYImg} alt='' className='img-fluid'/>), label: (<img src={GPAYImg} alt='' className='img-fluid'/>) },
      ];

    return (
        <div className={isOpen ? "app-container sidebar-open" : "app-container"}>
            <Header toggleSidebar={toggleSidebar} />
            <Sidebar isOpen={isOpen} />
            <main className="content billingPayPage">
                <div className='billingPayWrap'>
                    <div className='container-fluid pad0'>
                        <div className='row'>
                            <div className='col-md-12'>
                                <Tabs
                                    defaultActiveKey="PlanDetails"
                                    id="uncontrolled-tab-example"
                                    activeKey={activeKey} 
                                    onSelect={(k) => setActiveKey(k)}
                                >
                                    <Tab eventKey="PlanDetails"
                                        title={
                                            <div className={`tab-title ${activeKey === 'PlanDetails' || activeKey === 'PaymentPlan' || activeKey === 'Checkout' ? 'completed' : ''}`}>
                                                <span className="circle">1</span>
                                                Plan Details
                                            </div>
                                        }
                                    >
                                        <div className='planDtlTblWrap scrollContentWrap'>
                                            <CustomScroll heightRelativeToParent="100%">
                                                <table>
                                                    <tr>
                                                        <th>Services</th>
                                                        <th>Current Plan</th>
                                                        <th>Economy Plan</th>
                                                    </tr>
                                                    <tr>
                                                        <td>Services 01</td>
                                                        <td>
                                                            <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                <circle cx="8" cy="8" r="8" fill="#78AE32" />
                                                                <g clip-path="url(#clip0_931_795)">
                                                                    <path d="M11.8463 5.42682L6.92781 11.329L4.18945 8.51237L5.14545 7.58293L6.85153 9.33776L10.822 4.57324L11.8463 5.42682Z" fill="white" />
                                                                </g>
                                                                <defs>
                                                                    <clipPath id="clip0_931_795">
                                                                        <rect width="8" height="8" fill="white" transform="translate(4 4)" />
                                                                    </clipPath>
                                                                </defs>
                                                            </svg>
                                                            Available
                                                        </td>
                                                        <td>
                                                            <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                <circle cx="8" cy="8" r="8" fill="#78AE32" />
                                                                <g clip-path="url(#clip0_931_795)">
                                                                    <path d="M11.8463 5.42682L6.92781 11.329L4.18945 8.51237L5.14545 7.58293L6.85153 9.33776L10.822 4.57324L11.8463 5.42682Z" fill="white" />
                                                                </g>
                                                                <defs>
                                                                    <clipPath id="clip0_931_795">
                                                                        <rect width="8" height="8" fill="white" transform="translate(4 4)" />
                                                                    </clipPath>
                                                                </defs>
                                                            </svg>
                                                            Available
                                                            <span>Lorem Ipsum has been the industry's standard dummy text ever </span>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td>Services 01</td>
                                                        <td>
                                                            <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                <circle cx="8" cy="8" r="8" fill="#E93737" />
                                                                <path d="M10.0982 11.0486L8.09854 9.04889L6.04935 11.0981L5 10.0487L7.0492 7.99955L5.0495 5.99985L5.99985 5.0495L7.99955 7.0492L10.0487 5L11.0981 6.04935L9.04889 8.09854L11.0486 10.0982L10.0982 11.0486Z" fill="white" />
                                                            </svg>
                                                            Not Available
                                                        </td>
                                                        <td>
                                                            <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                <circle cx="8" cy="8" r="8" fill="#78AE32" />
                                                                <g clip-path="url(#clip0_931_795)">
                                                                    <path d="M11.8463 5.42682L6.92781 11.329L4.18945 8.51237L5.14545 7.58293L6.85153 9.33776L10.822 4.57324L11.8463 5.42682Z" fill="white" />
                                                                </g>
                                                                <defs>
                                                                    <clipPath id="clip0_931_795">
                                                                        <rect width="8" height="8" fill="white" transform="translate(4 4)" />
                                                                    </clipPath>
                                                                </defs>
                                                            </svg>
                                                            Available
                                                            <span>Lorem Ipsum has been the industry's standard dummy text ever </span>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td>Services 01</td>
                                                        <td>
                                                            <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                <circle cx="8" cy="8" r="8" fill="#78AE32" />
                                                                <g clip-path="url(#clip0_931_795)">
                                                                    <path d="M11.8463 5.42682L6.92781 11.329L4.18945 8.51237L5.14545 7.58293L6.85153 9.33776L10.822 4.57324L11.8463 5.42682Z" fill="white" />
                                                                </g>
                                                                <defs>
                                                                    <clipPath id="clip0_931_795">
                                                                        <rect width="8" height="8" fill="white" transform="translate(4 4)" />
                                                                    </clipPath>
                                                                </defs>
                                                            </svg>
                                                            Available
                                                        </td>
                                                        <td>
                                                            <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                <circle cx="8" cy="8" r="8" fill="#78AE32" />
                                                                <g clip-path="url(#clip0_931_795)">
                                                                    <path d="M11.8463 5.42682L6.92781 11.329L4.18945 8.51237L5.14545 7.58293L6.85153 9.33776L10.822 4.57324L11.8463 5.42682Z" fill="white" />
                                                                </g>
                                                                <defs>
                                                                    <clipPath id="clip0_931_795">
                                                                        <rect width="8" height="8" fill="white" transform="translate(4 4)" />
                                                                    </clipPath>
                                                                </defs>
                                                            </svg>
                                                            Available
                                                            <span>Lorem Ipsum has been the industry's standard dummy text ever </span>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td>Services 01</td>
                                                        <td>
                                                            <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                <circle cx="8" cy="8" r="8" fill="#78AE32" />
                                                                <g clip-path="url(#clip0_931_795)">
                                                                    <path d="M11.8463 5.42682L6.92781 11.329L4.18945 8.51237L5.14545 7.58293L6.85153 9.33776L10.822 4.57324L11.8463 5.42682Z" fill="white" />
                                                                </g>
                                                                <defs>
                                                                    <clipPath id="clip0_931_795">
                                                                        <rect width="8" height="8" fill="white" transform="translate(4 4)" />
                                                                    </clipPath>
                                                                </defs>
                                                            </svg>
                                                            Available
                                                        </td>
                                                        <td>
                                                            <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                <circle cx="8" cy="8" r="8" fill="#78AE32" />
                                                                <g clip-path="url(#clip0_931_795)">
                                                                    <path d="M11.8463 5.42682L6.92781 11.329L4.18945 8.51237L5.14545 7.58293L6.85153 9.33776L10.822 4.57324L11.8463 5.42682Z" fill="white" />
                                                                </g>
                                                                <defs>
                                                                    <clipPath id="clip0_931_795">
                                                                        <rect width="8" height="8" fill="white" transform="translate(4 4)" />
                                                                    </clipPath>
                                                                </defs>
                                                            </svg>
                                                            Available
                                                            <span>Lorem Ipsum has been the industry's standard dummy text ever </span>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td>Services 01</td>
                                                        <td>
                                                            <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                <circle cx="8" cy="8" r="8" fill="#78AE32" />
                                                                <g clip-path="url(#clip0_931_795)">
                                                                    <path d="M11.8463 5.42682L6.92781 11.329L4.18945 8.51237L5.14545 7.58293L6.85153 9.33776L10.822 4.57324L11.8463 5.42682Z" fill="white" />
                                                                </g>
                                                                <defs>
                                                                    <clipPath id="clip0_931_795">
                                                                        <rect width="8" height="8" fill="white" transform="translate(4 4)" />
                                                                    </clipPath>
                                                                </defs>
                                                            </svg>
                                                            Available
                                                        </td>
                                                        <td>
                                                            <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                <circle cx="8" cy="8" r="8" fill="#78AE32" />
                                                                <g clip-path="url(#clip0_931_795)">
                                                                    <path d="M11.8463 5.42682L6.92781 11.329L4.18945 8.51237L5.14545 7.58293L6.85153 9.33776L10.822 4.57324L11.8463 5.42682Z" fill="white" />
                                                                </g>
                                                                <defs>
                                                                    <clipPath id="clip0_931_795">
                                                                        <rect width="8" height="8" fill="white" transform="translate(4 4)" />
                                                                    </clipPath>
                                                                </defs>
                                                            </svg>
                                                            Available
                                                            <span>Lorem Ipsum has been the industry's standard dummy text ever </span>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td>Services 01</td>
                                                        <td>
                                                            <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                <circle cx="8" cy="8" r="8" fill="#78AE32" />
                                                                <g clip-path="url(#clip0_931_795)">
                                                                    <path d="M11.8463 5.42682L6.92781 11.329L4.18945 8.51237L5.14545 7.58293L6.85153 9.33776L10.822 4.57324L11.8463 5.42682Z" fill="white" />
                                                                </g>
                                                                <defs>
                                                                    <clipPath id="clip0_931_795">
                                                                        <rect width="8" height="8" fill="white" transform="translate(4 4)" />
                                                                    </clipPath>
                                                                </defs>
                                                            </svg>
                                                            Available
                                                        </td>
                                                        <td>
                                                            <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                <circle cx="8" cy="8" r="8" fill="#78AE32" />
                                                                <g clip-path="url(#clip0_931_795)">
                                                                    <path d="M11.8463 5.42682L6.92781 11.329L4.18945 8.51237L5.14545 7.58293L6.85153 9.33776L10.822 4.57324L11.8463 5.42682Z" fill="white" />
                                                                </g>
                                                                <defs>
                                                                    <clipPath id="clip0_931_795">
                                                                        <rect width="8" height="8" fill="white" transform="translate(4 4)" />
                                                                    </clipPath>
                                                                </defs>
                                                            </svg>
                                                            Available
                                                            <span>Lorem Ipsum has been the industry's standard dummy text ever </span>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td>Services 01</td>
                                                        <td>
                                                            <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                <circle cx="8" cy="8" r="8" fill="#78AE32" />
                                                                <g clip-path="url(#clip0_931_795)">
                                                                    <path d="M11.8463 5.42682L6.92781 11.329L4.18945 8.51237L5.14545 7.58293L6.85153 9.33776L10.822 4.57324L11.8463 5.42682Z" fill="white" />
                                                                </g>
                                                                <defs>
                                                                    <clipPath id="clip0_931_795">
                                                                        <rect width="8" height="8" fill="white" transform="translate(4 4)" />
                                                                    </clipPath>
                                                                </defs>
                                                            </svg>
                                                            Available
                                                        </td>
                                                        <td>
                                                            <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                <circle cx="8" cy="8" r="8" fill="#78AE32" />
                                                                <g clip-path="url(#clip0_931_795)">
                                                                    <path d="M11.8463 5.42682L6.92781 11.329L4.18945 8.51237L5.14545 7.58293L6.85153 9.33776L10.822 4.57324L11.8463 5.42682Z" fill="white" />
                                                                </g>
                                                                <defs>
                                                                    <clipPath id="clip0_931_795">
                                                                        <rect width="8" height="8" fill="white" transform="translate(4 4)" />
                                                                    </clipPath>
                                                                </defs>
                                                            </svg>
                                                            Available
                                                            <span>Lorem Ipsum has been the industry's standard dummy text ever </span>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td>Services 01</td>
                                                        <td>
                                                            <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                <circle cx="8" cy="8" r="8" fill="#78AE32" />
                                                                <g clip-path="url(#clip0_931_795)">
                                                                    <path d="M11.8463 5.42682L6.92781 11.329L4.18945 8.51237L5.14545 7.58293L6.85153 9.33776L10.822 4.57324L11.8463 5.42682Z" fill="white" />
                                                                </g>
                                                                <defs>
                                                                    <clipPath id="clip0_931_795">
                                                                        <rect width="8" height="8" fill="white" transform="translate(4 4)" />
                                                                    </clipPath>
                                                                </defs>
                                                            </svg>
                                                            Available
                                                        </td>
                                                        <td>
                                                            <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                <circle cx="8" cy="8" r="8" fill="#78AE32" />
                                                                <g clip-path="url(#clip0_931_795)">
                                                                    <path d="M11.8463 5.42682L6.92781 11.329L4.18945 8.51237L5.14545 7.58293L6.85153 9.33776L10.822 4.57324L11.8463 5.42682Z" fill="white" />
                                                                </g>
                                                                <defs>
                                                                    <clipPath id="clip0_931_795">
                                                                        <rect width="8" height="8" fill="white" transform="translate(4 4)" />
                                                                    </clipPath>
                                                                </defs>
                                                            </svg>
                                                            Available
                                                            <span>Lorem Ipsum has been the industry's standard dummy text ever </span>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td>Services 01</td>
                                                        <td>
                                                            <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                <circle cx="8" cy="8" r="8" fill="#78AE32" />
                                                                <g clip-path="url(#clip0_931_795)">
                                                                    <path d="M11.8463 5.42682L6.92781 11.329L4.18945 8.51237L5.14545 7.58293L6.85153 9.33776L10.822 4.57324L11.8463 5.42682Z" fill="white" />
                                                                </g>
                                                                <defs>
                                                                    <clipPath id="clip0_931_795">
                                                                        <rect width="8" height="8" fill="white" transform="translate(4 4)" />
                                                                    </clipPath>
                                                                </defs>
                                                            </svg>
                                                            Available
                                                        </td>
                                                        <td>
                                                            <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                <circle cx="8" cy="8" r="8" fill="#78AE32" />
                                                                <g clip-path="url(#clip0_931_795)">
                                                                    <path d="M11.8463 5.42682L6.92781 11.329L4.18945 8.51237L5.14545 7.58293L6.85153 9.33776L10.822 4.57324L11.8463 5.42682Z" fill="white" />
                                                                </g>
                                                                <defs>
                                                                    <clipPath id="clip0_931_795">
                                                                        <rect width="8" height="8" fill="white" transform="translate(4 4)" />
                                                                    </clipPath>
                                                                </defs>
                                                            </svg>
                                                            Available
                                                            <span>Lorem Ipsum has been the industry's standard dummy text ever </span>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td>Services 01</td>
                                                        <td>
                                                            <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                <circle cx="8" cy="8" r="8" fill="#78AE32" />
                                                                <g clip-path="url(#clip0_931_795)">
                                                                    <path d="M11.8463 5.42682L6.92781 11.329L4.18945 8.51237L5.14545 7.58293L6.85153 9.33776L10.822 4.57324L11.8463 5.42682Z" fill="white" />
                                                                </g>
                                                                <defs>
                                                                    <clipPath id="clip0_931_795">
                                                                        <rect width="8" height="8" fill="white" transform="translate(4 4)" />
                                                                    </clipPath>
                                                                </defs>
                                                            </svg>
                                                            Available
                                                        </td>
                                                        <td>
                                                            <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                <circle cx="8" cy="8" r="8" fill="#78AE32" />
                                                                <g clip-path="url(#clip0_931_795)">
                                                                    <path d="M11.8463 5.42682L6.92781 11.329L4.18945 8.51237L5.14545 7.58293L6.85153 9.33776L10.822 4.57324L11.8463 5.42682Z" fill="white" />
                                                                </g>
                                                                <defs>
                                                                    <clipPath id="clip0_931_795">
                                                                        <rect width="8" height="8" fill="white" transform="translate(4 4)" />
                                                                    </clipPath>
                                                                </defs>
                                                            </svg>
                                                            Available
                                                            <span>Lorem Ipsum has been the industry's standard dummy text ever </span>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td>Services 01</td>
                                                        <td>
                                                            <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                <circle cx="8" cy="8" r="8" fill="#E93737" />
                                                                <path d="M10.0982 11.0486L8.09854 9.04889L6.04935 11.0981L5 10.0487L7.0492 7.99955L5.0495 5.99985L5.99985 5.0495L7.99955 7.0492L10.0487 5L11.0981 6.04935L9.04889 8.09854L11.0486 10.0982L10.0982 11.0486Z" fill="white" />
                                                            </svg>
                                                            Not Available
                                                        </td>
                                                        <td>
                                                            <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                <circle cx="8" cy="8" r="8" fill="#78AE32" />
                                                                <g clip-path="url(#clip0_931_795)">
                                                                    <path d="M11.8463 5.42682L6.92781 11.329L4.18945 8.51237L5.14545 7.58293L6.85153 9.33776L10.822 4.57324L11.8463 5.42682Z" fill="white" />
                                                                </g>
                                                                <defs>
                                                                    <clipPath id="clip0_931_795">
                                                                        <rect width="8" height="8" fill="white" transform="translate(4 4)" />
                                                                    </clipPath>
                                                                </defs>
                                                            </svg>
                                                            Available
                                                            <span>Lorem Ipsum has been the industry's standard dummy text ever </span>
                                                        </td>
                                                    </tr>
                                                </table>
                                            </CustomScroll>
                                        </div>
                                        <div className='ctaNextWrap'>
                                            <button className='btn'>Next</button>
                                        </div>
                                    </Tab>
                                    <Tab eventKey="PaymentPlan"
                                        title={
                                            <div className={`tab-title ${activeKey === 'PaymentPlan' || activeKey === 'Checkout' ? 'completed' : ''}`}>
                                                <span className="circle">2</span>
                                                Payment Plan
                                            </div>
                                        }
                                    >
                                        <div className='row'>
                                            <div className='col-md-9'>
                                                <div className='paymentPlanWrap scrollContentWrap'>
                                                    <CustomScroll heightRelativeToParent="100%">
                                                        <div className='topTtl'>
                                                            <h2>Payment Plan</h2>
                                                        </div>
                                                        <div className='payPlanList'>
                                                            <ul>
                                                                <li>
                                                                    <p>Selected Plan</p>
                                                                    <h2>Economy Plan </h2>
                                                                </li>
                                                                <li>
                                                                    <p>Licenses
                                                                        <svg width="13" height="14" viewBox="0 0 19 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                            <path d="M9.53558 13.6601V13.6696M9.53558 10.8201C9.96135 10.8214 10.3751 10.6792 10.7101 10.4163C11.045 10.1535 11.2816 9.78544 11.3816 9.37159C11.4816 8.95774 11.4392 8.52226 11.2613 8.13547C11.0833 7.74868 10.7802 7.43316 10.4008 7.23987C10.0242 7.04695 9.59343 6.98713 9.17851 7.07015C8.76359 7.15317 8.38897 7.37413 8.11559 7.69711M9.53558 1.35352C16.3516 1.35352 18.0555 3.05751 18.0555 9.87347C18.0555 16.6894 16.3516 18.3934 9.53558 18.3934C2.71962 18.3934 1.01562 16.6894 1.01562 9.87347C1.01562 3.05751 2.71962 1.35352 9.53558 1.35352Z" stroke="#A3A3A3" stroke-width="1.89332" stroke-linecap="round" stroke-linejoin="round" />
                                                                        </svg>
                                                                    </p>
                                                                    <h2>00  assigned</h2>
                                                                </li>
                                                                <li>
                                                                    <p>Estimated monthly bill
                                                                        <svg width="13" height="14" viewBox="0 0 19 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                            <path d="M9.53558 13.6601V13.6696M9.53558 10.8201C9.96135 10.8214 10.3751 10.6792 10.7101 10.4163C11.045 10.1535 11.2816 9.78544 11.3816 9.37159C11.4816 8.95774 11.4392 8.52226 11.2613 8.13547C11.0833 7.74868 10.7802 7.43316 10.4008 7.23987C10.0242 7.04695 9.59343 6.98713 9.17851 7.07015C8.76359 7.15317 8.38897 7.37413 8.11559 7.69711M9.53558 1.35352C16.3516 1.35352 18.0555 3.05751 18.0555 9.87347C18.0555 16.6894 16.3516 18.3934 9.53558 18.3934C2.71962 18.3934 1.01562 16.6894 1.01562 9.87347C1.01562 3.05751 2.71962 1.35352 9.53558 1.35352Z" stroke="#A3A3A3" stroke-width="1.89332" stroke-linecap="round" stroke-linejoin="round" />
                                                                        </svg>
                                                                    </p>
                                                                    <h2>000.00 INR</h2>
                                                                </li>
                                                            </ul>
                                                        </div>
                                                        <div className='datePriceTbl'>
                                                            <ul>
                                                                <li className='topHeader'>
                                                                    <div className='leftWrap'>
                                                                        <h4>Date</h4>
                                                                    </div>
                                                                    <div className='rightWrap'>
                                                                        <h4>Price</h4>
                                                                    </div>
                                                                </li>
                                                                <li>
                                                                    <div className='leftWrap'>
                                                                        <p>June 14,  2024 </p>
                                                                    </div>
                                                                    <div className='rightWrap'>
                                                                        <span>For more than 20 users </span>
                                                                        <h6>000.00 INR Per user/month  (000 .00 INR <span>25%off</span>  )</h6>
                                                                    </div>
                                                                </li>
                                                                <li>
                                                                    <div className='leftWrap'>
                                                                        <p>July 14, 2024</p>
                                                                    </div>
                                                                    <div className='rightWrap'>
                                                                        <span>For more than 20 users </span>
                                                                        <h6>000.00 INR Per user/month  (000 .00 INR <span>25%off</span>  )</h6>
                                                                    </div>
                                                                </li>
                                                                <li>
                                                                    <div className='leftWrap'>
                                                                        <p className='txtClrBlue'>After July 14,  2024 </p>
                                                                    </div>
                                                                    <div className='rightWrap'>
                                                                        <span>For more than 20 users </span>
                                                                        <h6>000.00 INR Per user/month  (000 .00 INR <span>25%off</span>  )</h6>
                                                                    </div>
                                                                </li>
                                                            </ul>
                                                        </div>
                                                        <div className='billingDtlTbl'>
                                                            <table>
                                                                <tr>
                                                                    <th colSpan={3}>Billing details</th>
                                                                </tr>
                                                                <tr>
                                                                    <td>
                                                                        <h4>
                                                                            Renewal date
                                                                            <svg width="19" height="19" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                                <path d="M9.51996 13.3066V13.3161M9.51996 10.4666C9.94572 10.4679 10.3595 10.3256 10.6944 10.0628C11.0294 9.79998 11.266 9.43192 11.366 9.01807C11.466 8.60422 11.4236 8.16874 11.2457 7.78195C11.0677 7.39516 10.7646 7.07965 10.3852 6.88635C10.0086 6.69343 9.5778 6.63362 9.16289 6.71663C8.74797 6.79965 8.37335 7.02062 8.09997 7.34359M9.51996 1C16.3359 1 18.0399 2.70399 18.0399 9.51996C18.0399 16.3359 16.3359 18.0399 9.51996 18.0399C2.70399 18.0399 1 16.3359 1 9.51996C1 2.70399 2.70399 1 9.51996 1Z" stroke="#A3A3A3" stroke-width="1.89332" stroke-linecap="round" stroke-linejoin="round" />
                                                                            </svg>
                                                                        </h4>
                                                                        <h2>July 14, 2024</h2>
                                                                        <a href='#'>View Invoice</a>
                                                                    </td>
                                                                    <td>
                                                                        <h4>
                                                                            Payment account ID
                                                                            <svg width="19" height="19" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                                <path d="M9.51996 13.3066V13.3161M9.51996 10.4666C9.94572 10.4679 10.3595 10.3256 10.6944 10.0628C11.0294 9.79998 11.266 9.43192 11.366 9.01807C11.466 8.60422 11.4236 8.16874 11.2457 7.78195C11.0677 7.39516 10.7646 7.07965 10.3852 6.88635C10.0086 6.69343 9.5778 6.63362 9.16289 6.71663C8.74797 6.79965 8.37335 7.02062 8.09997 7.34359M9.51996 1C16.3359 1 18.0399 2.70399 18.0399 9.51996C18.0399 16.3359 16.3359 18.0399 9.51996 18.0399C2.70399 18.0399 1 16.3359 1 9.51996C1 2.70399 2.70399 1 9.51996 1Z" stroke="#A3A3A3" stroke-width="1.89332" stroke-linecap="round" stroke-linejoin="round" />
                                                                            </svg>
                                                                        </h4>
                                                                        <a href='#'>0214587-8547-5487</a>
                                                                        <a href='#'>View payment methods</a>
                                                                    </td>
                                                                    <td>
                                                                        <h4>
                                                                            Payment Profile
                                                                            <svg width="19" height="19" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                                <path d="M9.51996 13.3066V13.3161M9.51996 10.4666C9.94572 10.4679 10.3595 10.3256 10.6944 10.0628C11.0294 9.79998 11.266 9.43192 11.366 9.01807C11.466 8.60422 11.4236 8.16874 11.2457 7.78195C11.0677 7.39516 10.7646 7.07965 10.3852 6.88635C10.0086 6.69343 9.5778 6.63362 9.16289 6.71663C8.74797 6.79965 8.37335 7.02062 8.09997 7.34359M9.51996 1C16.3359 1 18.0399 2.70399 18.0399 9.51996C18.0399 16.3359 16.3359 18.0399 9.51996 18.0399C2.70399 18.0399 1 16.3359 1 9.51996C1 2.70399 2.70399 1 9.51996 1Z" stroke="#A3A3A3" stroke-width="1.89332" stroke-linecap="round" stroke-linejoin="round" />
                                                                            </svg>
                                                                        </h4>
                                                                        <a href='#'>View payment setting</a>
                                                                        <a href='#'>Manage Invoice</a>
                                                                    </td>
                                                                </tr>
                                                            </table>
                                                        </div>
                                                    </CustomScroll>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='ctaNextWrap'>
                                            <button className='btn'>Next</button>
                                        </div>
                                    </Tab>
                                    <Tab eventKey="Checkout"
                                        title={
                                            <div className={`tab-title noContent ${activeKey === 'Checkout' ? 'completed' : ''}`}>
                                                <span className="circle">3</span>
                                                Checkout
                                            </div>
                                        }
                                    >
                                        <div className='paymentTypeWrap scrollContentWrap'>
                                            <CustomScroll heightRelativeToParent="100%">
                                                <div className='topTtl'>
                                                    <h2>Payment type</h2>
                                                </div>
                                                <div className='row'>
                                                    <div className='col-md-7'>
                                                        <div className='optionWrap optionOneWrap'>
                                                            <div className='titleWrap'>
                                                                <h2>Option 1</h2>
                                                            </div>
                                                            <div className='cardDtlsWrap'>
                                                                <div className='leftTxtWrap'>
                                                                    <h3>Card details</h3>
                                                                    <p>Select default payment method</p>
                                                                </div>
                                                                <div className='rightWrap'>
                                                                    <ul>
                                                                        <li>
                                                                            <div className='imputField'>
                                                                                <label>Name on card</label>
                                                                                <input placeholder='Text'/>
                                                                            </div>
                                                                        </li>
                                                                        <li>
                                                                            <div className='imputGroup'>
                                                                                <div className='imputField wid1'>
                                                                                    <label>Card number</label>
                                                                                    <div className='inputImg'>
                                                                                        <img src={InoutImg1} alt='' className='img-fluid'/>
                                                                                        <input placeholder='0000-0000-0000' />
                                                                                    </div>
                                                                                </div>
                                                                                <div className='imputField wid2'>
                                                                                    <label>CVV</label>
                                                                                    <input placeholder='000' />
                                                                                </div>
                                                                                <div className='imputField wid3'>
                                                                                    <label>Expiry date</label>
                                                                                    <input placeholder='06-24' />
                                                                                </div>
                                                                            </div>
                                                                        </li>
                                                                        <li>
                                                                            <div className='imputField'>
                                                                                <label>Billing address</label>
                                                                                <input placeholder='Text'/>
                                                                            </div>
                                                                        </li>
                                                                        <li>
                                                                            <div className='imputGroup'>
                                                                                <div className='imputField half'>
                                                                                    <label>State</label>
                                                                                    <Form.Select aria-label="Filter">
                                                                                        <option value="">Text</option>
                                                                                        <option value="1">1</option>
                                                                                        <option value="2">2</option>
                                                                                        <option value="3">3</option>
                                                                                        <option value="4">4</option>
                                                                                        <option value="5">5</option>
                                                                                    </Form.Select>
                                                                                </div>
                                                                                <div className='imputField half'>
                                                                                    <label>Pincode</label>
                                                                                    <input/>
                                                                                </div>
                                                                            </div>
                                                                        </li>
                                                                        <li>
                                                                            <div className='imputField'>
                                                                                <label>Country</label>
                                                                                <Form.Select aria-label="Filter">
                                                                                    <option value="">Text</option>
                                                                                    <option value="1">1</option>
                                                                                    <option value="2">2</option>
                                                                                    <option value="3">3</option>
                                                                                    <option value="4">4</option>
                                                                                    <option value="5">5</option>
                                                                                </Form.Select>
                                                                            </div>
                                                                        </li>
                                                                    </ul>
                                                                </div>
                                                            </div>
                                                            <div className='masterCardWrap'>
                                                                <div className='leftWrap'>
                                                                    <label htmlFor="radio1" className={`custom-radio ${selectedValue === 'option1' ? 'checked' : ''}`}>
                                                                        <input
                                                                            type="radio"
                                                                            id="radio1"
                                                                            name="group1"
                                                                            value="option1"
                                                                            checked={selectedValue === 'option1'}
                                                                            onChange={handleChange}
                                                                            className="hidden-radio"
                                                                        />
                                                                        <span className="custom-radio-button"></span>
                                                                    </label>
                                                                </div>
                                                                <div className='rightWrap'>
                                                                    <div className='imgWrap'>
                                                                        <img src={InoutImg1} alt='' className='img-fluid'/>
                                                                    </div>
                                                                    <div className='dataWrap'>
                                                                        <h2>Master Card</h2>
                                                                        <h4>**** **** **** 4002</h4>
                                                                        <p>Expiry on 20/2024</p>
                                                                        <span>
                                                                            <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                                <path d="M12.5233 15.3074H5.47781C3.36417 15.3074 1.95508 14.2505 1.95508 11.7846V6.85281C1.95508 4.3869 3.36417 3.33008 5.47781 3.33008H12.5233C14.6369 3.33008 16.046 4.3869 16.046 6.85281V11.7846C16.046 14.2505 14.6369 15.3074 12.5233 15.3074Z" stroke="#696969" stroke-width="1.12727" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
                                                                                <path d="M12.524 7.20508L10.3187 8.96644C9.59306 9.54417 8.40238 9.54417 7.67669 8.96644L5.47852 7.20508" stroke="#696969" stroke-width="1.12727" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
                                                                            </svg>
                                                                            billing@acme.corp
                                                                        </span>
                                                                        <div className='btnChangeWrap'>
                                                                            <button className='btn'>Change</button>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className='col-md-5'>
                                                        <div className='optionWrap optionTwoWrap'>
                                                            <div className='titleWrap'>
                                                                <h2>Option 2</h2>
                                                            </div>
                                                            <div className='payModeWrap'>
                                                                <div className='upiImgWrap'>
                                                                    <img src={UPIImg} className='img-fluid' alt=''/>
                                                                </div>
                                                                <ul>
                                                                    <li>
                                                                        <div className='imputField SelectWrap'>
                                                                            <Select options={options} />
                                                                        </div>
                                                                    </li>
                                                                    <li>
                                                                        <div className='imputField'>
                                                                            <input placeholder='Enter UPI ID'/>
                                                                        </div>
                                                                    </li>
                                                                </ul>
                                                                <div className='qrImgWrap'>
                                                                    <img src={QRImg} alt='' className='img-fluid'/>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </CustomScroll>
                                        </div>
                                        <div className='ctaNextWrap'>
                                            <button className='btn'>Checkout</button>
                                        </div>
                                    </Tab>
                                </Tabs>
                            </div>
                        </div>
                    </div>
                </div>
            </main>
        </div>
    );
};

export default BillingPayment;

