import React, { useState } from "react";
import { Handle, Position } from 'reactflow';
import Goto from '../../assets/img/Skip to Start.png'
const GotoNode = ({ data, isConnectable }) => {
    const [selectField, setSelectField] = useState([{ courseSelect: '' }]);
    const addFormFields = () => {
        let newField = { courseSelect: '' }
        setSelectField([...selectField, newField])
    }
    const removeFields = (index) => {
        let data = [...selectField];
        data.splice(index, 1)
        setSelectField(data)
    }
    return (
        <>
            <div className="gotoNodeWrap">
                <Handle type="target" position={Position.Top} isConnectable={isConnectable} style={{ width: '10px', height: '10px' }} />
                <div className="gotoCard">
                    <div className="headerWrap">
                        <div className="iconWrap">
                            <img src={Goto} alt="" srcset="" />
                        </div>
                        <div className="titleWrap">
                            <h4>Goto</h4>
                        </div>
                    </div>
                    <div className="bodyWrap">
                        <div className="inputWrap">
                            {selectField.map((selecet, index) => {
                                return (
                                    <input type="text" placeholder="text" />
                                )
                            })}
                            <div className="addMoreBtn">
                                <div className="iconWrap">
                                    <button type="button" className="btn" onClick={addFormFields}>
                                        <svg xmlns="http://www.w3.org/2000/svg" version="1.1" width="9" height="9" x="0" y="0" viewBox="0 0 512 512">
                                            <g>
                                                <path d="M467 211H301V45c0-24.853-20.147-45-45-45s-45 20.147-45 45v166H45c-24.853 0-45 20.147-45 45s20.147 45 45 45h166v166c0 24.853 20.147 45 45 45s45-20.147 45-45V301h166c24.853 0 45-20.147 45-45s-20.147-45-45-45z" fill="#000000" opacity="1" data-original="#dcdcdc"></path>
                                            </g>
                                        </svg>
                                    </button>
                                </div>

                            </div>
                        </div>

                    </div>
                </div>
                <Handle type="source" position={Position.Bottom} isConnectable={isConnectable} style={{ width: '10px', height: '10px' }} />
                <Handle type="source" position={Position.Left} isConnectable={isConnectable} style={{ width: '10px', height: '10px' }} />
                <Handle type="source" position={Position.Right} isConnectable={isConnectable} style={{ width: '10px', height: '10px' }} />

            </div>
        </>
    )
}
export default GotoNode;