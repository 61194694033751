import axios from "axios";
import {useNavigate } from "react-router-dom"
import {handleErrorResponseAxios, handleResponseAxios} from "./common-handler"
import {
    GET_ALL_PREDEFINED_FIELDS
} from "../utility/ConstantAPI";

export function getPredefinedFields(token) {
    // let token = get_auth_token();
    let axiosConfig = {
        headers: {
            "Content-Type": "application/json",
            Authorization: `${token}`,
        },
    };
    var url = GET_ALL_PREDEFINED_FIELDS
    return axios
        .get(url, axiosConfig)
        .then(handleResponseAxios)
        .catch(function (error) {
            return handleErrorResponseAxios(error)
        });

}

