import React, { useState, useEffect } from 'react';
import Form from 'react-bootstrap/Form';
import { CustomScroll } from "react-custom-scroll";
import Header from '../../Components/Common/Header';
import Sidebar from '../../Components/Common/Sidebar';
import "react-datepicker/dist/react-datepicker.css";
import userImg3 from '../../assets/img/userImg-3.png';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';

const UserManagementListEdit = () => {
    const [isOpen, setIsOpen] = useState(false);
    const [selectedDate, setSelectedDate] = useState(new Date());
    const [startDate, setStartDate] = useState(new Date());
    const toggleSidebar = () => {
        setIsOpen(!isOpen);
    };


    return (
        <div className={isOpen ? "app-container sidebar-open" : "app-container"}>
            <Header toggleSidebar={toggleSidebar} />
            <Sidebar isOpen={isOpen} />
            <main className="content userManagePage">
                <div className='userManageWrap'>
                    <div className='container-fluid pad0px'>
                        <div className='row'>
                            <div className='col-md-8 pad0px'>
                                <div className='leftContentWrap'>
                                    <div className='topDataWrap'>
                                        <div className='titleWrap'>
                                            <div className='icoWrap'>
                                                <svg width="19" height="22" viewBox="0 0 19 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M11.7541 10.9069C11.7502 10.8373 11.7723 10.7682 11.8159 10.7137C11.8595 10.6592 11.9215 10.6229 11.9904 10.6113H18.724C18.7942 10.6197 18.8584 10.6556 18.9026 10.7108C18.9468 10.7661 18.9675 10.8366 18.9603 10.9069C18.9641 10.9766 18.9421 11.0452 18.8985 11.0997C18.8549 11.1542 18.7928 11.1906 18.724 11.2021H11.9904C11.9215 11.1906 11.8595 11.1542 11.8159 11.0997C11.7723 11.0452 11.7502 10.9766 11.7541 10.9069Z" fill="black" />
                                                    <path d="M12.7781 12.3249C12.7781 12.1674 12.8765 12.0293 12.9946 12.0293H18.7831C18.9013 12.0293 18.9997 12.1674 18.9997 12.3249C18.9997 12.4824 18.9013 12.6201 18.7831 12.6201H12.9946C12.8568 12.6201 12.7781 12.4824 12.7781 12.3249Z" fill="black" />
                                                    <path d="M18.7046 9.8642H11.1244C11.0869 9.86293 11.05 9.85425 11.0158 9.83872C10.9817 9.82319 10.951 9.80078 10.9254 9.77335C10.8998 9.74592 10.8799 9.71379 10.8667 9.67865C10.8536 9.64351 10.8475 9.60607 10.8488 9.56858C10.8475 9.53108 10.8536 9.49364 10.8667 9.4585C10.8799 9.42336 10.8998 9.39123 10.9254 9.36381C10.951 9.33638 10.9817 9.31445 11.0158 9.29891C11.05 9.28338 11.0869 9.27471 11.1244 9.27344H18.7046C18.7421 9.27471 18.779 9.28338 18.8131 9.29891C18.8473 9.31445 18.878 9.33638 18.9036 9.36381C18.9292 9.39123 18.9491 9.42336 18.9623 9.4585C18.9754 9.49364 18.9816 9.53108 18.9803 9.56858C18.9816 9.60607 18.9754 9.64351 18.9623 9.67865C18.9491 9.71379 18.9292 9.74592 18.9036 9.77335C18.878 9.80078 18.8473 9.82319 18.8131 9.83872C18.779 9.85425 18.7421 9.86293 18.7046 9.8642Z" fill="black" />
                                                    <path d="M11.439 8.24924C11.4357 8.17658 11.4604 8.10521 11.508 8.05024C11.5556 7.99527 11.6226 7.9611 11.695 7.9541H18.7239C18.7963 7.9611 18.8632 7.99527 18.9109 8.05024C18.9585 8.10521 18.9832 8.17658 18.9799 8.24924C18.9832 8.3219 18.9585 8.39327 18.9109 8.44824C18.8632 8.50321 18.7963 8.53739 18.7239 8.54438H11.695C11.6238 8.53439 11.5587 8.49917 11.5116 8.44488C11.4646 8.39059 11.4388 8.32109 11.439 8.24924Z" fill="black" />
                                                    <path d="M18.7633 7.20599H12.4825C12.4137 7.19447 12.3516 7.1581 12.308 7.10361C12.2645 7.04912 12.2424 6.98053 12.2463 6.91085C12.2391 6.84048 12.2598 6.76998 12.304 6.71474C12.3482 6.65949 12.4123 6.62364 12.4825 6.61523H18.7633C18.8321 6.62676 18.8943 6.66361 18.9379 6.7181C18.9815 6.77259 19.0034 6.84118 18.9995 6.91085C19.0034 6.98053 18.9815 7.04912 18.9379 7.10361C18.8943 7.1581 18.8321 7.19447 18.7633 7.20599Z" fill="black" />
                                                    <path d="M13.3491 13.7429C13.3491 13.5854 13.4279 13.4473 13.5461 13.4473H18.7832C18.8817 13.4473 18.9802 13.5854 18.9802 13.7429C18.9802 13.9004 18.9014 14.038 18.7832 14.038H13.5461C13.4476 14.038 13.3491 13.9004 13.3491 13.7429Z" fill="black" />
                                                    <path d="M6.55626 10.3957C5.17835 10.3937 3.85746 9.84534 2.88312 8.87101C1.90879 7.89668 1.36048 6.57554 1.3584 5.19763C1.36048 3.81972 1.90879 2.49906 2.88312 1.52473C3.85746 0.550398 5.17835 0.00208638 6.55626 3.36197e-06C7.23907 -0.000773882 7.91531 0.133223 8.54629 0.394163C9.17727 0.655104 9.75055 1.03759 10.2334 1.5204C10.7162 2.00322 11.099 2.57662 11.36 3.2076C11.6209 3.83858 11.7548 4.51482 11.754 5.19763C11.7519 6.57554 11.2036 7.89668 10.2293 8.87101C9.25495 9.84534 7.93418 10.3937 6.55626 10.3957ZM6.55626 0.590282C5.33485 0.591845 4.16379 1.07781 3.30012 1.94148C2.43644 2.80516 1.9506 3.97621 1.94904 5.19763C1.9506 6.41905 2.43644 7.5901 3.30012 8.45378C4.16379 9.31745 5.33485 9.80341 6.55626 9.80498C7.77768 9.80341 8.94862 9.31745 9.81229 8.45378C10.676 7.5901 11.1618 6.41905 11.1634 5.19763C11.1618 3.97621 10.676 2.80516 9.81229 1.94148C8.94862 1.07781 7.77768 0.591845 6.55626 0.590282Z" fill="black" />
                                                    <path d="M13.0537 21.1648H0.29526C0.217561 21.1628 0.143645 21.1309 0.088686 21.0759C0.0337267 21.021 0.00199325 20.9469 0 20.8692V16.459C0.00156404 14.6892 0.705248 12.9924 1.95662 11.741C3.20799 10.4897 4.90482 9.78623 6.67452 9.78467C8.44423 9.78623 10.1409 10.4897 11.3923 11.741C12.6437 12.9924 13.3474 14.6892 13.3489 16.459V20.8692C13.3512 20.9086 13.3452 20.9482 13.3311 20.9851C13.3171 21.0219 13.2955 21.0552 13.2676 21.0831C13.2397 21.111 13.2061 21.133 13.1693 21.1471C13.1324 21.1611 13.0931 21.1671 13.0537 21.1648ZM0.610347 20.5741H12.778V16.459C12.7728 14.847 12.1302 13.3029 10.9903 12.1631C9.85052 11.0233 8.30605 10.3806 6.69411 10.3754C5.08218 10.3806 3.53782 11.0233 2.39801 12.1631C1.25819 13.3029 0.615539 14.847 0.610347 16.459V20.5741Z" fill="black" />
                                                </svg>
                                            </div>
                                            <h2>User management</h2>
                                        </div>
                                        <div className='rightDataWrap'>
                                            <div className='searchWrap'>
                                                <input placeholder='Search' type='text' />
                                                <button className='btn'>
                                                    <svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <path d="M16.1009 15.0563L11.9196 10.8749C12.5863 10.0234 13.0227 9.01486 13.1867 7.94591C13.3506 6.87696 13.2366 5.78375 12.8557 4.77159C12.8104 4.63317 12.7519 4.49934 12.681 4.3721C12.0085 2.9567 10.8337 1.84187 9.38507 1.24441C7.9364 0.646946 6.31728 0.60974 4.84254 1.13958C4.70524 1.18951 4.55551 1.25178 4.41821 1.31419C2.98461 1.9783 1.85142 3.155 1.24161 4.61253C0.631807 6.07005 0.589525 7.70296 1.12307 9.1901C1.17065 9.33168 1.22909 9.46948 1.29783 9.60209C1.80135 10.6918 2.60578 11.6147 3.61633 12.2626C4.62688 12.9105 5.80156 13.2563 7.00197 13.2591C8.37977 13.2605 9.71885 12.8038 10.8087 11.961L15.0025 16.1549L16.1009 15.0563ZM3.69434 10.3383C3.25803 9.90524 2.91193 9.39007 2.67626 8.8223C2.44058 8.25452 2.32003 7.64556 2.3214 7.03082C2.32003 6.41608 2.44058 5.80712 2.67626 5.23934C2.91193 4.67157 3.25803 4.1561 3.69434 3.72304C4.1274 3.28672 4.64272 2.94093 5.2105 2.70526C5.77827 2.46958 6.38723 2.34888 7.00197 2.35025C7.61672 2.34881 8.22551 2.46927 8.79329 2.70495C9.36108 2.94063 9.87643 3.28667 10.3095 3.72304C10.8864 4.29861 11.3036 5.01444 11.5203 5.80004C11.7369 6.58564 11.7455 7.4141 11.5451 8.20401C11.4419 8.63083 11.2736 9.03916 11.046 9.41468C10.6302 10.1174 10.0387 10.6998 9.32961 11.1047C8.62056 11.5095 7.81847 11.723 7.00197 11.7239C5.76229 11.7046 4.57767 11.2083 3.69434 10.3383Z" fill="#919191" />
                                                    </svg>
                                                </button>
                                            </div>
                                            <button className='btnAddNew'>
                                                <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M10.0326 19.822C15.4081 19.822 19.7659 15.4643 19.7659 10.0887C19.7659 4.71321 15.4081 0.355469 10.0326 0.355469C4.65705 0.355469 0.299316 4.71321 0.299316 10.0887C0.299316 15.4643 4.65705 19.822 10.0326 19.822Z" fill="white" />
                                                    <path d="M14.3758 10.7534H10.7413V14.4329H9.28969V10.7534H5.67773V9.43693H9.28969V5.73486H10.7413V9.43693H14.3758V10.7534Z" fill="#1944F1" />
                                                </svg>
                                                Add New User
                                            </button>
                                        </div>
                                    </div>
                                    <div className='usersTblWrap'>
                                        <table>
                                            <thead>
                                                <tr>
                                                    <th><input id="checkAll" name="" type="checkbox" value="yes"/></th>
                                                    <th>Name</th>
                                                    <th>User Role</th>
                                                    <th>Last Log in</th>
                                                    <th>Action</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td colSpan={5}>
                                                        <div className='toprowWrap'>
                                                            <div className='leftWrap'>
                                                                <p>Showing 11 of 50 total users</p>
                                                            </div>
                                                            <ul className='rightWrap'>
                                                                <li className='active'>Active <span>11</span></li>
                                                                <li className='inActive'>Inactive <span>45</span></li>
                                                            </ul>
                                                        </div>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td><input type="checkbox" /></td>
                                                    <td>
                                                        <div className='userWrap'>
                                                            <div className='imgWrap'>
                                                                <img src={userImg3} className='img-fluid' alt=''/>
                                                            </div>
                                                            <div className='dataWrap'>
                                                                <h2>Neeraja Sen</h2>
                                                                <p>nirajasen@gmail.com</p>
                                                            </div>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <span>Admin</span>
                                                        <span>Manager</span>
                                                        <span>Manager</span>
                                                    </td>
                                                    <td>01-07-2024</td>
                                                    <td>
                                                        <button className='btn'>
                                                            <svg version="1.1" width="20" height="20" x="0" y="0" viewBox="0 0 512 512" class=""><g><path fill="#00000080" d="M256 0C114.836 0 0 114.836 0 256s114.836 256 256 256 256-114.836 256-256S397.164 0 256 0zm0 0" opacity="1" data-original="#00000080" class=""></path><path fill="#fafafa" d="M350.273 320.105c8.34 8.344 8.34 21.825 0 30.168a21.275 21.275 0 0 1-15.086 6.25c-5.46 0-10.921-2.09-15.082-6.25L256 286.164l-64.105 64.11a21.273 21.273 0 0 1-15.083 6.25 21.275 21.275 0 0 1-15.085-6.25c-8.34-8.344-8.34-21.825 0-30.169L225.836 256l-64.11-64.105c-8.34-8.344-8.34-21.825 0-30.168 8.344-8.34 21.825-8.34 30.169 0L256 225.836l64.105-64.11c8.344-8.34 21.825-8.34 30.168 0 8.34 8.344 8.34 21.825 0 30.169L286.164 256zm0 0" opacity="1" data-original="#fafafa" class=""></path></g></svg>
                                                            Remove
                                                        </button>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td><input type="checkbox" /></td>
                                                    <td>
                                                        <div className='userWrap'>
                                                            <div className='imgWrap'>
                                                                <img src={userImg3} className='img-fluid' alt=''/>
                                                            </div>
                                                            <div className='dataWrap'>
                                                                <h2>Neeraja Sen</h2>
                                                                <p>nirajasen@gmail.com</p>
                                                            </div>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <span>Admin</span>
                                                        <span>Manager</span>
                                                        <span>Manager</span>
                                                    </td>
                                                    <td>01-07-2024</td>
                                                    <td>
                                                        <button className='btn'>
                                                            <svg version="1.1" width="20" height="20" x="0" y="0" viewBox="0 0 512 512" class=""><g><path fill="#00000080" d="M256 0C114.836 0 0 114.836 0 256s114.836 256 256 256 256-114.836 256-256S397.164 0 256 0zm0 0" opacity="1" data-original="#00000080" class=""></path><path fill="#fafafa" d="M350.273 320.105c8.34 8.344 8.34 21.825 0 30.168a21.275 21.275 0 0 1-15.086 6.25c-5.46 0-10.921-2.09-15.082-6.25L256 286.164l-64.105 64.11a21.273 21.273 0 0 1-15.083 6.25 21.275 21.275 0 0 1-15.085-6.25c-8.34-8.344-8.34-21.825 0-30.169L225.836 256l-64.11-64.105c-8.34-8.344-8.34-21.825 0-30.168 8.344-8.34 21.825-8.34 30.169 0L256 225.836l64.105-64.11c8.344-8.34 21.825-8.34 30.168 0 8.34 8.344 8.34 21.825 0 30.169L286.164 256zm0 0" opacity="1" data-original="#fafafa" class=""></path></g></svg>
                                                            Remove
                                                        </button>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td><input type="checkbox" /></td>
                                                    <td>
                                                        <div className='userWrap'>
                                                            <div className='imgWrap'>
                                                                <img src={userImg3} className='img-fluid' alt=''/>
                                                            </div>
                                                            <div className='dataWrap'>
                                                                <h2>Neeraja Sen</h2>
                                                                <p>nirajasen@gmail.com</p>
                                                            </div>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <span>Admin</span>
                                                        <span>Manager</span>
                                                        <span>Manager</span>
                                                    </td>
                                                    <td>01-07-2024</td>
                                                    <td>
                                                        <button className='btn'>
                                                            <svg version="1.1" width="20" height="20" x="0" y="0" viewBox="0 0 512 512" class=""><g><path fill="#00000080" d="M256 0C114.836 0 0 114.836 0 256s114.836 256 256 256 256-114.836 256-256S397.164 0 256 0zm0 0" opacity="1" data-original="#00000080" class=""></path><path fill="#fafafa" d="M350.273 320.105c8.34 8.344 8.34 21.825 0 30.168a21.275 21.275 0 0 1-15.086 6.25c-5.46 0-10.921-2.09-15.082-6.25L256 286.164l-64.105 64.11a21.273 21.273 0 0 1-15.083 6.25 21.275 21.275 0 0 1-15.085-6.25c-8.34-8.344-8.34-21.825 0-30.169L225.836 256l-64.11-64.105c-8.34-8.344-8.34-21.825 0-30.168 8.344-8.34 21.825-8.34 30.169 0L256 225.836l64.105-64.11c8.344-8.34 21.825-8.34 30.168 0 8.34 8.344 8.34 21.825 0 30.169L286.164 256zm0 0" opacity="1" data-original="#fafafa" class=""></path></g></svg>
                                                            Remove
                                                        </button>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td><input type="checkbox" /></td>
                                                    <td>
                                                        <div className='userWrap'>
                                                            <div className='imgWrap'>
                                                                <img src={userImg3} className='img-fluid' alt=''/>
                                                            </div>
                                                            <div className='dataWrap'>
                                                                <h2>Neeraja Sen</h2>
                                                                <p>nirajasen@gmail.com</p>
                                                            </div>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <span>Admin</span>
                                                        <span>Manager</span>
                                                        <span>Manager</span>
                                                    </td>
                                                    <td>01-07-2024</td>
                                                    <td>
                                                        <button className='btn'>
                                                            <svg version="1.1" width="20" height="20" x="0" y="0" viewBox="0 0 512 512" class=""><g><path fill="#00000080" d="M256 0C114.836 0 0 114.836 0 256s114.836 256 256 256 256-114.836 256-256S397.164 0 256 0zm0 0" opacity="1" data-original="#00000080" class=""></path><path fill="#fafafa" d="M350.273 320.105c8.34 8.344 8.34 21.825 0 30.168a21.275 21.275 0 0 1-15.086 6.25c-5.46 0-10.921-2.09-15.082-6.25L256 286.164l-64.105 64.11a21.273 21.273 0 0 1-15.083 6.25 21.275 21.275 0 0 1-15.085-6.25c-8.34-8.344-8.34-21.825 0-30.169L225.836 256l-64.11-64.105c-8.34-8.344-8.34-21.825 0-30.168 8.344-8.34 21.825-8.34 30.169 0L256 225.836l64.105-64.11c8.344-8.34 21.825-8.34 30.168 0 8.34 8.344 8.34 21.825 0 30.169L286.164 256zm0 0" opacity="1" data-original="#fafafa" class=""></path></g></svg>
                                                            Remove
                                                        </button>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td><input type="checkbox" /></td>
                                                    <td>
                                                        <div className='userWrap'>
                                                            <div className='imgWrap'>
                                                                <img src={userImg3} className='img-fluid' alt=''/>
                                                            </div>
                                                            <div className='dataWrap'>
                                                                <h2>Neeraja Sen</h2>
                                                                <p>nirajasen@gmail.com</p>
                                                            </div>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <span>Admin</span>
                                                        <span>Manager</span>
                                                        <span>Manager</span>
                                                    </td>
                                                    <td>01-07-2024</td>
                                                    <td>
                                                        <button className='btn'>
                                                            <svg version="1.1" width="20" height="20" x="0" y="0" viewBox="0 0 512 512" class=""><g><path fill="#00000080" d="M256 0C114.836 0 0 114.836 0 256s114.836 256 256 256 256-114.836 256-256S397.164 0 256 0zm0 0" opacity="1" data-original="#00000080" class=""></path><path fill="#fafafa" d="M350.273 320.105c8.34 8.344 8.34 21.825 0 30.168a21.275 21.275 0 0 1-15.086 6.25c-5.46 0-10.921-2.09-15.082-6.25L256 286.164l-64.105 64.11a21.273 21.273 0 0 1-15.083 6.25 21.275 21.275 0 0 1-15.085-6.25c-8.34-8.344-8.34-21.825 0-30.169L225.836 256l-64.11-64.105c-8.34-8.344-8.34-21.825 0-30.168 8.344-8.34 21.825-8.34 30.169 0L256 225.836l64.105-64.11c8.344-8.34 21.825-8.34 30.168 0 8.34 8.344 8.34 21.825 0 30.169L286.164 256zm0 0" opacity="1" data-original="#fafafa" class=""></path></g></svg>
                                                            Remove
                                                        </button>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td><input type="checkbox" /></td>
                                                    <td>
                                                        <div className='userWrap'>
                                                            <div className='imgWrap'>
                                                                <img src={userImg3} className='img-fluid' alt=''/>
                                                            </div>
                                                            <div className='dataWrap'>
                                                                <h2>Neeraja Sen</h2>
                                                                <p>nirajasen@gmail.com</p>
                                                            </div>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <span>Admin</span>
                                                        <span>Manager</span>
                                                        <span>Manager</span>
                                                    </td>
                                                    <td>01-07-2024</td>
                                                    <td>
                                                        <button className='btn'>
                                                            <svg version="1.1" width="20" height="20" x="0" y="0" viewBox="0 0 512 512" class=""><g><path fill="#00000080" d="M256 0C114.836 0 0 114.836 0 256s114.836 256 256 256 256-114.836 256-256S397.164 0 256 0zm0 0" opacity="1" data-original="#00000080" class=""></path><path fill="#fafafa" d="M350.273 320.105c8.34 8.344 8.34 21.825 0 30.168a21.275 21.275 0 0 1-15.086 6.25c-5.46 0-10.921-2.09-15.082-6.25L256 286.164l-64.105 64.11a21.273 21.273 0 0 1-15.083 6.25 21.275 21.275 0 0 1-15.085-6.25c-8.34-8.344-8.34-21.825 0-30.169L225.836 256l-64.11-64.105c-8.34-8.344-8.34-21.825 0-30.168 8.344-8.34 21.825-8.34 30.169 0L256 225.836l64.105-64.11c8.344-8.34 21.825-8.34 30.168 0 8.34 8.344 8.34 21.825 0 30.169L286.164 256zm0 0" opacity="1" data-original="#fafafa" class=""></path></g></svg>
                                                            Remove
                                                        </button>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td><input type="checkbox" /></td>
                                                    <td>
                                                        <div className='userWrap'>
                                                            <div className='imgWrap'>
                                                                <img src={userImg3} className='img-fluid' alt=''/>
                                                            </div>
                                                            <div className='dataWrap'>
                                                                <h2>Neeraja Sen</h2>
                                                                <p>nirajasen@gmail.com</p>
                                                            </div>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <span>Admin</span>
                                                        <span>Manager</span>
                                                        <span>Manager</span>
                                                    </td>
                                                    <td>01-07-2024</td>
                                                    <td>
                                                        <button className='btn'>
                                                            <svg version="1.1" width="20" height="20" x="0" y="0" viewBox="0 0 512 512" class=""><g><path fill="#00000080" d="M256 0C114.836 0 0 114.836 0 256s114.836 256 256 256 256-114.836 256-256S397.164 0 256 0zm0 0" opacity="1" data-original="#00000080" class=""></path><path fill="#fafafa" d="M350.273 320.105c8.34 8.344 8.34 21.825 0 30.168a21.275 21.275 0 0 1-15.086 6.25c-5.46 0-10.921-2.09-15.082-6.25L256 286.164l-64.105 64.11a21.273 21.273 0 0 1-15.083 6.25 21.275 21.275 0 0 1-15.085-6.25c-8.34-8.344-8.34-21.825 0-30.169L225.836 256l-64.11-64.105c-8.34-8.344-8.34-21.825 0-30.168 8.344-8.34 21.825-8.34 30.169 0L256 225.836l64.105-64.11c8.344-8.34 21.825-8.34 30.168 0 8.34 8.344 8.34 21.825 0 30.169L286.164 256zm0 0" opacity="1" data-original="#fafafa" class=""></path></g></svg>
                                                            Remove
                                                        </button>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                            <div className='col-md-4 pad0px'>
                                <div className='profileWrap profileEditWrap'>
                                    <div className='titleWrap'>
                                        <div className='btnBackWrap'>
                                            <button className='btn'>
                                                <svg width="13" height="22" viewBox="0 0 13 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M10.9365 2L1.99976 10.9368L10.9365 19.8735" stroke="#ADA7A7" stroke-width="4" stroke-linecap="round" stroke-linejoin="round" />
                                                </svg>
                                            </button>
                                        </div>
                                        <div className='icoWrap'>
                                            <svg width="14" height="22" viewBox="0 0 14 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M6.55626 10.3957C5.17835 10.3937 3.85746 9.84534 2.88312 8.87101C1.90879 7.89668 1.36048 6.57554 1.3584 5.19763C1.36048 3.81972 1.90879 2.49906 2.88312 1.52473C3.85746 0.550398 5.17835 0.00208638 6.55626 3.36197e-06C7.23907 -0.000773882 7.91531 0.133223 8.54629 0.394163C9.17727 0.655104 9.75055 1.03759 10.2334 1.5204C10.7162 2.00322 11.099 2.57662 11.36 3.2076C11.6209 3.83858 11.7548 4.51482 11.754 5.19763C11.7519 6.57554 11.2036 7.89668 10.2293 8.87101C9.25495 9.84534 7.93418 10.3937 6.55626 10.3957ZM6.55626 0.590282C5.33485 0.591845 4.16379 1.07781 3.30012 1.94148C2.43644 2.80516 1.9506 3.97621 1.94904 5.19763C1.9506 6.41905 2.43644 7.5901 3.30012 8.45378C4.16379 9.31745 5.33485 9.80341 6.55626 9.80498C7.77768 9.80341 8.94862 9.31745 9.81229 8.45378C10.676 7.5901 11.1618 6.41905 11.1634 5.19763C11.1618 3.97621 10.676 2.80516 9.81229 1.94148C8.94862 1.07781 7.77768 0.591845 6.55626 0.590282Z" fill="black" />
                                                <path d="M13.0537 21.1648H0.29526C0.217561 21.1628 0.143645 21.1309 0.088686 21.0759C0.0337267 21.021 0.00199325 20.9469 0 20.8692V16.459C0.00156404 14.6892 0.705248 12.9924 1.95662 11.741C3.20799 10.4897 4.90482 9.78623 6.67452 9.78467C8.44423 9.78623 10.1409 10.4897 11.3923 11.741C12.6437 12.9924 13.3474 14.6892 13.3489 16.459V20.8692C13.3512 20.9086 13.3452 20.9482 13.3311 20.9851C13.3171 21.0219 13.2955 21.0552 13.2676 21.0831C13.2397 21.111 13.2061 21.133 13.1693 21.1471C13.1324 21.1611 13.0931 21.1671 13.0537 21.1648ZM0.610347 20.5741H12.778V16.459C12.7728 14.847 12.1302 13.3029 10.9903 12.1631C9.85052 11.0233 8.30605 10.3806 6.69411 10.3754C5.08218 10.3806 3.53782 11.0233 2.39801 12.1631C1.25819 13.3029 0.615539 14.847 0.610347 16.459V20.5741Z" fill="black" />
                                            </svg>
                                        </div>
                                        <h2>My Profile</h2>
                                    </div>
                                    <div className='scrollContentWrap'>
                                        <CustomScroll heightRelativeToParent="100%">
                                            <div className='profileEdit'>
                                                <div class="uploadContainer">
                                                    <div class="icoWrap">
                                                        <svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                            <path fill-rule="evenodd" clip-rule="evenodd" d="M9.99994 7.75839C10.74 7.26392 11.61 7 12.5 7C13.0714 7 13.5346 7.46322 13.5346 8.03464C13.5346 8.60606 13.0714 9.06928 12.5 9.06928C12.0193 9.06928 11.5493 9.21184 11.1496 9.47893C10.7498 9.74602 10.4383 10.1256 10.2543 10.5698C10.0703 11.014 10.0222 11.5027 10.116 11.9742C10.2098 12.4457 10.4413 12.8788 10.7812 13.2188C11.1212 13.5587 11.5543 13.7902 12.0258 13.884C12.4973 13.9778 12.986 13.9297 13.4302 13.7457C13.8744 13.5617 14.254 13.2502 14.5211 12.8504C14.7882 12.4507 14.9307 11.9807 14.9307 11.5C14.9307 10.9286 15.3939 10.4654 15.9654 10.4654C16.5368 10.4654 17 10.9286 17 11.5C17 12.39 16.7361 13.26 16.2416 14.0001C15.7471 14.7401 15.0443 15.3169 14.2221 15.6575C13.3998 15.998 12.495 16.0872 11.6221 15.9135C10.7492 15.7399 9.94736 15.3113 9.31802 14.682C8.68868 14.0526 8.2601 13.2508 8.08647 12.3779C7.91283 11.505 8.00195 10.6002 8.34254 9.77792C8.68314 8.95566 9.25991 8.25285 9.99994 7.75839Z" fill="#BEBEBE" />
                                                            <path fill-rule="evenodd" clip-rule="evenodd" d="M34.9802 13C35.5434 13 36 13.4661 36 14.041V22.7574C36 27.2164 35.1375 30.6085 32.9439 32.8604C30.7489 35.1136 27.4407 36 23.094 36H12.906C8.56063 36 5.25258 35.114 3.05723 32.861C0.863226 30.6094 0 27.2173 0 22.7574V19.2528C0 18.6779 0.456559 18.2118 1.01975 18.2118C1.58295 18.2118 2.03951 18.6779 2.03951 19.2528V22.7574C2.03951 27.014 2.87587 29.7227 4.50321 31.3928C6.12922 33.0615 8.76429 33.9181 12.906 33.9181H23.094C27.2371 33.9181 29.8721 33.0611 31.4977 31.3923C33.1248 29.7221 33.9605 27.0134 33.9605 22.7574V14.041C33.9605 13.4661 34.4171 13 34.9802 13Z" fill="#BEBEBE" />
                                                            <path fill-rule="evenodd" clip-rule="evenodd" d="M3.09967 3.07527C5.32422 0.868234 8.6763 0 13.0827 0H19.9716C20.5396 0 21 0.456808 21 1.02031C21 1.58381 20.5396 2.04062 19.9716 2.04062H13.0827C8.87924 2.04062 6.20419 2.88106 4.55405 4.51821C2.90392 6.15535 2.05681 8.80935 2.05681 12.9797C2.05681 13.5432 1.59638 14 1.0284 14C0.460432 14 0 13.5432 0 12.9797C0 8.60801 0.875122 5.28231 3.09967 3.07527Z" fill="#BEBEBE" />
                                                            <path fill-rule="evenodd" clip-rule="evenodd" d="M23 6C23 5.44772 23.4792 5 24.0704 5H33.9296C34.5208 5 35 5.44772 35 6C35 6.55228 34.5208 7 33.9296 7H24.0704C23.4792 7 23 6.55228 23 6Z" fill="#BEBEBE" />
                                                            <path fill-rule="evenodd" clip-rule="evenodd" d="M29 0C29.5523 0 30 0.479239 30 1.07041V10.9296C30 11.5208 29.5523 12 29 12C28.4477 12 28 11.5208 28 10.9296V1.07041C28 0.479239 28.4477 0 29 0Z" fill="#BEBEBE" />
                                                            <path fill-rule="evenodd" clip-rule="evenodd" d="M28.0496 19.675L20.9723 25.8014L20.9535 25.8173C20.0931 26.5267 19.0135 26.9145 17.8993 26.9145C16.7851 26.9145 15.7055 26.5267 14.8451 25.8173C14.8358 25.8096 14.8267 25.8018 14.8177 25.7939L14.2713 25.3101C13.8139 24.941 13.2519 24.7253 12.6651 24.694C12.0746 24.6624 11.4894 24.8191 10.9934 25.1414L2.59721 30.8241C2.12855 31.1413 1.49204 31.0177 1.17551 30.5481C0.858988 30.0784 0.982315 29.4405 1.45097 29.1233L9.86814 23.4264C10.7295 22.8634 11.7473 22.5897 12.7741 22.6445C13.801 22.6994 14.7839 23.0802 15.5806 23.7318C15.5909 23.7402 15.6011 23.7489 15.6111 23.7578L16.1591 24.2429C16.6508 24.6434 17.2653 24.8622 17.8993 24.8622C18.5351 24.8622 19.1512 24.6422 19.6436 24.2396L26.7209 18.1131L26.7397 18.0972C27.6002 17.3878 28.6797 17 29.7939 17C30.9081 17 31.9877 17.3878 32.8481 18.0972L32.867 18.1132L35.6454 20.5185C36.0734 20.889 36.1206 21.537 35.7509 21.9659C35.3812 22.3947 34.7345 22.4421 34.3066 22.0716L31.5382 19.6749C31.0458 19.2723 30.4297 19.0523 29.7939 19.0523C29.1582 19.0523 28.5421 19.2723 28.0496 19.675Z" fill="#BEBEBE" />
                                                        </svg>
                                                    </div>
                                                    <input type="file" id="file" accept="image/*" />
                                                    <label for="file" class="uploadLabel">Upload your photo</label>
                                                </div>
                                                <ul>
                                                    <li>
                                                        <div className='inputField'>
                                                            <label>User name</label>
                                                            <input value={'Neeraj Pande'} />
                                                        </div>
                                                    </li>
                                                    <li>
                                                        <div className='inputField'>
                                                            <label>User ID</label>
                                                            <input value={'NP0024578'} />
                                                        </div>
                                                    </li>
                                                    <li>
                                                        <div className='inputField'>
                                                            <label>Password</label>
                                                            <input value={'NeerajP1234'} />
                                                        </div>
                                                    </li>
                                                    <li>
                                                        <div className='inputField'>
                                                            <label>Email</label>
                                                            <input value={'neerajpande@gmail.com'} />
                                                        </div>
                                                    </li>
                                                    <li>
                                                        <div className='inputField'>
                                                            <label>Phone number</label>
                                                            <PhoneInput
                                                                inputProps={{
                                                                    name: 'phone',
                                                                    required: true,
                                                                    autoFocus: true,
                                                                }}
                                                                country={'in'}
                                                                disableCountryCode={false}
                                                                disableDropdown={false}
                                                                enableLongNumbers={true}
                                                                countryCodeEditable={false}
                                                            />
                                                        </div>
                                                    </li>
                                                    <li>
                                                        <div className='inputField'>
                                                            <label>Location</label>
                                                            <Form.Select aria-label="India">
                                                                <option value="">India</option>
                                                                <option value="1">1</option>
                                                                <option value="2">2</option>
                                                                <option value="3">3</option>
                                                                <option value="4">4</option>
                                                                <option value="5">5</option>
                                                            </Form.Select>
                                                        </div>
                                                    </li>
                                                    <li>
                                                        <div className='ctaWrap'>
                                                            <button className='btn'>Update Profile</button>
                                                        </div>
                                                    </li>
                                                </ul>
                                            </div>
                                        </CustomScroll>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </main>
        </div>
    );
};

export default UserManagementListEdit;

