import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Table, Button } from 'react-bootstrap';
import AddUserModal from './AddUserModal';
import {GET_TENANTS} from '../../utility/ConstantAPI'

const TenantsList = () => {
  const [tenants, setTenants] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [selectedTenantId, setSelectedTenantId] = useState(null);

  useEffect(() => {
    const fetchTenants = async () => {
      try {
        const url = GET_TENANTS
        const response = await axios.get(url);
        setTenants(response.data);
      } catch (error) {
        console.error('Error fetching tenants:', error);
      }
    };

    fetchTenants();
  }, []);

  const handleOpenModal = (tenantId) => {
    setSelectedTenantId(tenantId);
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
    setSelectedTenantId(null);
  };

  return (
    <div>
      <h1>Tenants List</h1>
      <Table striped bordered hover>
        <thead>
          <tr>
            <th>Name</th>
            <th>Description</th>
            <th>Type</th>
            <th>Mobile</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {tenants.map((tenant) => (
            <tr key={tenant.id}>
              <td>{tenant.name}</td>
              <td>{tenant.description}</td>
              <td>{tenant.type}</td>
              <td>{tenant.mobile}</td>
              <td>
                <Button variant="primary" onClick={() => handleOpenModal(tenant.id)}>
                  Add User
                </Button>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>

      <AddUserModal show={showModal} handleClose={handleCloseModal} tenantId={selectedTenantId} />
    </div>
  );
};

export default TenantsList;
