import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from "react-router-dom";
import Header from '../../Components/Common/Header';
import WorkProgressImg from '../../assets/img/WorkProgressImg.png';

const WorkProgress = () => {
    let navigate = useNavigate();
    const [isOpen, setIsOpen] = useState(false);
    const toggleSidebar = () => {
        setIsOpen(!isOpen);
    };

    return (
        <div className={isOpen ? "app-container sidebar-open" : "app-container"}>
            <Header toggleSidebar={toggleSidebar} />
            <main className="content workinProgressWrap">
                <div className='container-fluid ht100'>
                    <div className='row ht100'>
                        <div className='col-md-12 ht100'>
                            <div className='itemWrap'>
                                <div className='imgWrap'>
                                    <img src={WorkProgressImg} className='img-fluid' alt='' />
                                </div>
                                <div className='dataWrap'>
                                    <h2>Work in progress...</h2>
                                    <p>Will be Back Soon!</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </main>
        </div>
    );
};

export default WorkProgress;