import axios from "axios";
import { handleErrorResponseAxios, handleResponseAxios } from "./common-handler"
import { GET_TEMPLATES, GET_TEMPLATES_BY_CATEGORY, GET_TEMPLATES_BY_LANGUAGE } from "../utility/ConstantAPI";

export function get_all_templates(token) {
    let axiosConfig = {
        headers: {
            "Content-Type": "application/json",
            Authorization: `${token}`,
        },
    };
    var url = GET_TEMPLATES
    return axios
        .get(url,axiosConfig)
        .then(handleResponseAxios)
        .catch(function (error) {
            return handleErrorResponseAxios(error)
        });

}

export function get_all_templates_by_category(category, token) {
    let axiosConfig = {
        headers: {
            "Content-Type": "application/json",
            Authorization: `${token}`,
        },
    };
    var url = GET_TEMPLATES_BY_CATEGORY(category)
    return axios
        .get(url, axiosConfig)
        .then(handleResponseAxios)
        .catch(function (error) {
            return handleErrorResponseAxios(error)
        });

}

export function get_all_templates_by_language(language, token) {
    let axiosConfig = {
        headers: {
            "Content-Type": "application/json",
            Authorization: `${token}`,
        },
    };
    var url = GET_TEMPLATES_BY_LANGUAGE(language)
    return axios
        .get(url, axiosConfig)
        .then(handleResponseAxios)
        .catch(function (error) {
            return handleErrorResponseAxios(error)
        });

}