import React from "react";
import { Link } from "react-router-dom";

export const customerFormatter = (additionalParam) => (cell, row, rowIndex, formatExtraData) => {
    return <span>{(row.customer[additionalParam])}</span>;
};
export const itemCount = (cell, row, rowIndex, formatExtraData) => {
    return <span>{(row.OrderItems.length)}</span>;
};

export const formatDate = (dateTimeString) => {
    // Parse the date string into a Date object
    const date = new Date(dateTimeString);
  
    // Format options for Intl.DateTimeFormat
    const options = {
      year: 'numeric',
      month: 'short',
      day: '2-digit',
      hour: 'numeric',
      minute: '2-digit',
      hour12: true // 12-hour clock format
    };
  
    // Format the date according to options
    const formattedDate = new Intl.DateTimeFormat('en-US', options).format(date);
  
    return formattedDate;
  };