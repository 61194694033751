import React, { useState, useEffect } from "react";
import Form from "react-bootstrap/Form";
import { CustomScroll } from "react-custom-scroll";
import Header from "../../Components/Common/Header";
import Sidebar from "../../Components/Common/Sidebar";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import whatsAppImg from "../../assets/img/phoneImg.png";
import { get_all_templates } from "../../services/template-services";
import BootstrapTable from 'react-bootstrap-table-next';
import { Modal, Button } from 'react-bootstrap';
import axios from "axios";
import { getAllGoups, getGroupById } from "../../services/group-service";
import { SEND_SMS_BROADCAST } from "../../utility/ConstantAPI";
import { get_sms_flow_list} from "../../services/sms_flow_services"


const SmsBroadcast = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [newBroadcastName, setBroadcastName] = useState("");
  const [isTemplateList, setTemplateList] = useState([]);
  const [selectedTemplate, setSelectedTemplate] = useState(null);
  const [selectedList, setSelectedList] = useState();
  const [contacts, setContacts] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(false);
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [startDate, setStartDate] = useState(new Date());
  const [show, setShow] = useState(false);
  const [selectedMap, setSelectedMap] = useState([])
  const [previewMessage, setPreviewMessage] = useState()
  const [groupData, setGroupData] = useState([])
  const [selectedGroup, setSelectedGroup] = useState({})
  const [showSuccessMsg, setShowSuccessMsg] = useState(false)
  const [flows, setFLows] =useState([])
  const toggleSidebar = () => {
    setIsOpen(!isOpen);
  };

  const [headerfields, setHeaderFields] = useState([]);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const handleSuccessClose = () => setShowSuccessMsg(false);
  const handleSuccessShow = () => setShowSuccessMsg(true);
  useEffect(() => {
    fetchData();
    getGroups();
  }, []);

  const fetchData = async () => {
    try {
      const response = await get_all_templates();
      const result = await get_sms_flow_list();
      setFLows(result)
      if (response) {
        setTemplateList(response.data);
      } else {
        setError(response.message);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
      setError(error.message);
    }
    setIsLoading(false);
  };

  const handleInputChange = (event) => {
    setBroadcastName(event.target.value);
  };
  const [dynamicVar, setDynamicVar] = useState([])
  const handleSelectChange = (e) => {
    const selectedObject = JSON.parse(e.target.value);
    const variables = selectedObject.components.map((val) => {
      if (val.type === 'BODY' && val.example) {
        return val.example.body_text
      }
      else {
        return null
      }
    })
    setDynamicVar(variables.filter(item => item !== undefined)[0][0])
    setSelectedTemplate(selectedObject);
  };
  const [selectedUser, setSelectedUser] = useState([])
  const onCheckChange = (e, row) => {
    if (e.target.checked === true) {
      selectedUser.push(row)
      setSelectedUser([...selectedUser])
    }
    else {
      selectedUser.splice(selectedUser.findIndex(a => a.number === row.number), 1)
      setSelectedUser([...selectedUser])
    }
  }
  const RadioFormatter = (cell, row, rowIndex, formatExtraData) => {
    return (
      <>
        <input type="checkbox" name="" id={rowIndex} onChange={(e) => onCheckChange(e, row)} />
      </>
    )
  }
  const handleListSelection = async (e) => {
    const list_item = JSON.parse(e.target.value)
    setSelectedGroup(list_item)
    //here get all the contacts of selected list by listid. Create call the function that gets the contacts
    setContacts(true)
    const response = await getGroupById(list_item.group_id)
    let commonFields = []
    response.predefined_field_labels.forEach(field => {
      commonFields.push(field.custom_label)
    })
    response.custom_fields.forEach(field => {
      commonFields.push(field.label)
    })
    const predefinedFieldLabels = {};
    response.predefined_field_labels.forEach(field => {
      predefinedFieldLabels[field.id] = {
        custom_label: field.custom_label,
        predefined_label: field.predefined_field.title
      };
    });
    const customFields = {};
    response.custom_fields.forEach(field => {
      customFields[field.id] = field.label;
    });

    const transformedContacts = response.contacts.map(contact => {
      const predefinedValues = {};
      const predefinedLabels = {};
      let contact_id = contact.id;
      contact.contact_predefined_values.forEach(value => {
        const label = predefinedFieldLabels[value.predefined_field_label_id].custom_label;
        const predefined_label = predefinedFieldLabels[value.predefined_field_label_id].predefined_label;
        predefinedValues[label] = value.value;
        predefinedLabels[predefined_label] = label; // Add predefined_label
      });

      const customValues = {};
      contact.contact_custom_values.forEach(value => {
        const label = customFields[value.custom_field_id];
        customValues[label] = value.value;
      });

      return {
        ...predefinedValues,
        ...customValues,
        predefined_label: predefinedLabels,
        contact_id
      };
    });
    setGroupData(transformedContacts)

    const fetchedColumns = commonFields.map(column => ({
      dataField: column,
      text: column,
      // formatter: column.formatter ? eval(column.formatter) : undefined, // Assume the formatter function names are sent as strings
    }));
    fetchedColumns.splice(0, 0, { formatter: RadioFormatter }) //Add CheckBox 
    // set the contact list header fields
    setHeaderFields(fetchedColumns);
  }


  const handleMapping = (e, index) => {
    selectedMap.map((obj, key) => {
      if (obj.field_index === index) {
        selectedMap.splice(key, 1)
      }
    })
    selectedMap.push({ 'field_index': index, 'field': e.target.value })
    setSelectedMap([...selectedMap])
  }
  const generateMessages = (template, fields, data) => {
    return data.map(item => {
      let message = template;
      fields.forEach(field => {
        const placeholder = `{{${field.field_index + 1}}}`;
        message = message.replace(placeholder, item[field.field]);
      });
      return { name: item.name, number: item.number, message };
    });
  };
  const setMap = () => {
    let templateString = ''
    selectedTemplate.components.map((item, i) => {
      if (item.type === 'BODY') {
        templateString = item.text
      }
    })
    const me = generateMessages(templateString, selectedMap, selectedUser)
    setPreviewMessage(me[0])
    handleClose()
  }
  const handleSendMessage = () => {
    let data = {
      users: selectedUser,
      variable: selectedMap,
      template: selectedTemplate,
      name: newBroadcastName,
      group_id: selectedGroup.group_id
    }
    let url = SEND_SMS_BROADCAST
    axios.post(url, data)
      .then((res) => {
        setIsLoading(true)
        if (res.status === 200) {
          setIsLoading(false)
          handleSuccessShow()
        }
      })
      .catch((err) => {
        console.error(err)
      })
  }
  const [groupsList, setGroupsList] = useState(false)
  const getGroups = async () => {
    const response = await getAllGoups();
    if (response) {
      setGroupsList(response);
    } else {
      setError(response.message);
    }
  }
  return (
    <div className={isOpen ? "app-container sidebar-open" : "app-container"}>
      <Header toggleSidebar={toggleSidebar} />
      {/* <Sidebar isOpen={isOpen} /> */}
      <main className="content newBroadcastPage">
        <div className="newBroadcastWrap">
          <div className="container-fluid pad0px">
            <div className="row">
              <div className="col-md-9">
                <div className="leftContentWrap">
                  <div className="titleWrap">
                    <div className="icoWrap">
                      <svg
                        width="27"
                        height="19"
                        viewBox="0 0 27 19"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M18.477 3.91047C18.516 3.87119 18.5627 3.84057 18.6142 3.82008C18.6656 3.79958 18.7207 3.79001 18.776 3.79169C18.8314 3.79337 18.8858 3.80611 18.9359 3.82968C18.986 3.85325 19.0307 3.8868 19.0673 3.92837C20.4074 5.47858 21.144 7.46001 21.1421 9.50918C21.145 11.55 20.4151 13.5241 19.0852 15.0721C19.0486 15.1137 19.0039 15.1472 18.9538 15.1708C18.9037 15.1943 18.8493 15.2071 18.7939 15.2088C18.7386 15.2104 18.6835 15.2009 18.632 15.1804C18.5806 15.1599 18.5339 15.1288 18.4949 15.0895C18.4194 15.0145 18.3754 14.9135 18.3721 14.807C18.3688 14.7006 18.4063 14.5971 18.477 14.5175C19.6745 13.1176 20.3285 11.3335 20.3193 9.49128C20.319 7.64218 19.6595 5.85365 18.4592 4.44715C18.4222 4.41233 18.393 4.37009 18.3737 4.32313C18.3543 4.27618 18.3452 4.22586 18.3469 4.1751C18.3486 4.12434 18.3611 4.07437 18.3835 4.02881C18.4059 3.98325 18.4378 3.94275 18.477 3.91047Z"
                          fill="black"
                        />
                        <path
                          d="M22.5918 0.118526C22.6309 0.0794858 22.6776 0.0489151 22.7291 0.0285694C22.7805 0.00822368 22.8355 -0.00148971 22.8907 0.000185015C22.946 0.00185974 23.0004 0.014755 23.0505 0.0381764C23.1005 0.0615978 23.1453 0.0950907 23.182 0.13643C25.4895 2.71251 26.7636 6.0505 26.7593 9.50895C26.7668 12.9594 25.4992 16.2909 23.1999 18.8636C23.1632 18.9049 23.1184 18.9384 23.0683 18.9618C23.0182 18.9852 22.9639 18.9981 22.9086 18.9998C22.8534 19.0015 22.7983 18.9918 22.7469 18.9714C22.6955 18.9511 22.6488 18.9205 22.6097 18.8815C22.5341 18.8064 22.4902 18.7054 22.4869 18.5989C22.4835 18.4925 22.5211 18.3886 22.5918 18.309C24.7536 15.8883 25.9448 12.7544 25.9365 9.50895C25.9418 6.25543 24.7441 3.11494 22.5739 0.691017C22.5032 0.61139 22.4656 0.5075 22.469 0.40106C22.4723 0.29462 22.5162 0.193583 22.5918 0.118526Z"
                          fill="black"
                        />
                        <path
                          d="M8.30088 3.91047C8.26186 3.87119 8.21514 3.84057 8.16371 3.82008C8.11227 3.79958 8.0572 3.79001 8.00186 3.79169C7.94652 3.79337 7.89208 3.80611 7.84198 3.82968C7.79188 3.85325 7.74717 3.8868 7.71059 3.92837C6.37047 5.47858 5.63385 7.46001 5.63575 9.50918C5.63286 11.55 6.36278 13.5241 7.69269 15.0721C7.72926 15.1137 7.77403 15.1472 7.82413 15.1708C7.87423 15.1943 7.92862 15.2071 7.98396 15.2088C8.0393 15.2104 8.09442 15.2009 8.14586 15.1804C8.19729 15.1599 8.24395 15.1288 8.28298 15.0895C8.35852 15.0145 8.40247 14.9135 8.40579 14.807C8.40912 14.7006 8.37159 14.5971 8.30088 14.5175C7.10335 13.1176 6.44937 11.3335 6.45852 9.49128C6.45879 7.64218 7.11836 5.85365 8.31873 4.44715C8.35351 4.41112 8.38072 4.36856 8.3987 4.32182C8.41668 4.27509 8.42509 4.22515 8.42342 4.1751C8.42176 4.12505 8.41003 4.076 8.38898 4.03056C8.36793 3.98512 8.33798 3.9441 8.30088 3.91047Z"
                          fill="black"
                        />
                        <path
                          d="M4.16757 0.118526C4.12842 0.0794858 4.0817 0.0489151 4.03029 0.0285694C3.97888 0.00822368 3.92387 -0.00148971 3.86861 0.000185015C3.81334 0.00185974 3.75897 0.014755 3.70889 0.0381764C3.6588 0.0615978 3.61406 0.0950907 3.57734 0.13643C1.26981 2.71251 -0.00424518 6.0505 3.2968e-05 9.50895C-0.007453 12.9594 1.26017 16.2909 3.55943 18.8636C3.59615 18.9049 3.64095 18.9384 3.69104 18.9618C3.74112 18.9852 3.79544 18.9981 3.8507 18.9998C3.90597 19.0015 3.96103 18.9918 4.01244 18.9714C4.06385 18.9511 4.11057 18.9205 4.14972 18.8815C4.22526 18.8064 4.26921 18.7054 4.27254 18.5989C4.27586 18.4925 4.23828 18.3886 4.16757 18.309C2.00572 15.8883 0.814529 12.7544 0.822798 9.50895C0.817568 6.25543 2.01522 3.11494 4.18547 0.691017C4.22241 0.652536 4.25128 0.606744 4.27041 0.556956C4.28954 0.507167 4.29855 0.454371 4.29688 0.40106C4.29522 0.347749 4.28289 0.294971 4.26069 0.246474C4.23849 0.197977 4.20683 0.154626 4.16757 0.118526Z"
                          fill="black"
                        />
                        <path
                          d="M10.7148 9.50899C10.7153 8.77859 11.0057 8.07829 11.5222 7.56182C12.0386 7.04535 12.739 6.75487 13.4694 6.75439C14.1998 6.75487 14.9001 7.04535 15.4166 7.56182C15.9331 8.07829 16.2235 8.77859 16.2239 9.50899C16.2235 10.2394 15.9331 10.9397 15.4166 11.4562C14.9001 11.9726 14.1998 12.2631 13.4694 12.2636C12.739 12.2631 12.0386 11.9726 11.5222 11.4562C11.0057 10.9397 10.7153 10.2394 10.7148 9.50899ZM15.3833 9.50899C15.3823 8.99695 15.1785 8.50599 14.8164 8.14392C14.4543 7.78185 13.9635 7.57805 13.4515 7.5771C12.9394 7.57805 12.4486 7.78185 12.0866 8.14392C11.7245 8.50599 11.5207 8.99695 11.5198 9.50899C11.5207 10.021 11.7245 10.512 12.0866 10.8741C12.4486 11.2361 12.9394 11.4399 13.4515 11.4409C13.7054 11.4418 13.9571 11.3922 14.1919 11.2955C14.4267 11.1987 14.64 11.0567 14.8196 10.8771C14.9991 10.6975 15.1414 10.484 15.2381 10.2492C15.3349 10.0144 15.3842 9.76293 15.3833 9.50899Z"
                          fill="black"
                        />
                      </svg>
                    </div>
                    <h2>New SMS Broadcast</h2>
                  </div>
                  <div className="scrollContentWrap">
                    <CustomScroll heightRelativeToParent="100%">
                      <div className="messageTempWrap">
                        <div className="row">
                          <div className="col-md-12">
                            <div className="messageTempBox">
                              <ul>
                                <li>
                                  <div className="inputField">
                                    <label>Broadcast Name</label>
                                    <input
                                      type="text"
                                      value={newBroadcastName}
                                      onChange={handleInputChange}
                                    />
                                  </div>
                                </li>
                                <li>
                                  <div className="inputField">
                                    <label>Select template message</label>
                                    <Form.Select
                                      aria-label=""
                                      onChange={handleSelectChange}
                                    >
                                      <option value=""></option>
                                      {isTemplateList &&
                                        isTemplateList.map((item, i) => (
                                          <option value={JSON.stringify(item)}>
                                            {item.name}
                                          </option>
                                        ))}
                                    </Form.Select>
                                    <div className="addTempCta">
                                      <button className="btn">
                                        + Add New Template
                                      </button>
                                    </div>
                                  </div>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="broadcastListImpWrap">
                        <div className="contentWrap">
                          <div className="leftWrap">
                            <div className="inputWrap">
                              <label>Who do you want to send it to?</label>
                              <Form.Select
                                aria-label="Select Contacts List"
                                onChange={handleListSelection}
                              >
                                <option value="">Select Contacts List</option>
                                {groupsList && groupsList.map((item, index) => (
                                  <option
                                    value={JSON.stringify(item)}
                                  >{item.group_name}</option>
                                ))}
                              </Form.Select>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="newBroadcasttblWrap">
                        <div className="contentWrap">
                          <div className="topWrap">
                            <div className="searchWrap">
                              <form>
                                <div className="inputWrap">
                                  <div className="icoWrap">
                                    <svg
                                      width="18"
                                      height="18"
                                      viewBox="0 0 18 18"
                                      fill="none"
                                      xmlns="http://www.w3.org/2000/svg"
                                    >
                                      <path
                                        fill-rule="evenodd"
                                        clip-rule="evenodd"
                                        d="M12.5006 11.0006H11.7106L11.4306 10.7306C12.6306 9.33063 13.2506 7.42063 12.9106 5.39063C12.4406 2.61063 10.1206 0.390626 7.32063 0.0506256C3.09063 -0.469374 -0.469374 3.09063 0.0506256 7.32063C0.390626 10.1206 2.61063 12.4406 5.39063 12.9106C7.42063 13.2506 9.33063 12.6306 10.7306 11.4306L11.0006 11.7106V12.5006L15.2506 16.7506C15.6606 17.1606 16.3306 17.1606 16.7406 16.7506C17.1506 16.3406 17.1506 15.6706 16.7406 15.2606L12.5006 11.0006ZM6.50063 11.0006C4.01063 11.0006 2.00063 8.99063 2.00063 6.50063C2.00063 4.01063 4.01063 2.00063 6.50063 2.00063C8.99063 2.00063 11.0006 4.01063 11.0006 6.50063C11.0006 8.99063 8.99063 11.0006 6.50063 11.0006Z"
                                        fill="#C3CAD9"
                                      />
                                    </svg>
                                  </div>
                                  <input placeholder="Search" />
                                </div>
                              </form>
                            </div>

                            <div className="rightData">
                              <p>Total {groupData.length} contacts</p>
                            </div>
                          </div>
                          <div className="newBroadcastListTbl">
                            {contacts ? headerfields.length > 0 && groupData.length > 0 && (
                              <BootstrapTable
                                keyField="id"
                                data={groupData}
                                columns={headerfields}
                              // rowEvents={{ onClick: togglePaySumSidebar }}
                              />
                              // <h4> yes</h4>
                            ) : null}

                          </div>
                        </div>
                      </div>
                    </CustomScroll>
                  </div>
                  <div className='ctaSubmit'>
                    {/* {previewMessage && 
                    <button className='btn' onClick={handleShow}>
                      Remap Variables
                    </button>
                    } */}
                    <button className='btn' onClick={() => {
                      if (previewMessage)
                        handleSendMessage()
                      else
                        handleShow()
                    }}>{previewMessage ? 'Send Message' : 'Map Variables'}</button>
                  </div>
                </div>
              </div>
              {selectedTemplate ? (
                <div className="col-md-3 pad0px">
                  <div className="previewWrap">
                    <div className="titleWrap">
                      <h2>Preview-2</h2>
                    </div>
                    <div className="preview">
                      <div className="imgWrap">
                        <img src={whatsAppImg} className="img-fluid" alt="" />
                        <div className="messageTempChatWrap">
                          {selectedTemplate.components &&
                            selectedTemplate.components.map((item, i) => (
                              <>
                                {item.type === "HEADER" &&
                                  item.format === "IMAGE" ? (
                                  <div className="topImgWrap">
                                    <div className="imgBox">
                                      <img
                                        src={item.example.header_handle[0]}
                                      />
                                    </div>
                                    <button className="btnEdit">
                                      <svg
                                        width="17"
                                        height="16"
                                        viewBox="0 0 17 16"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                      >
                                        <path
                                          d="M15.6944 7.52674C15.6944 11.5023 12.4716 14.7252 8.49598 14.7252C4.52039 14.7252 1.29753 11.5023 1.29753 7.52674C1.29753 3.55115 4.52039 0.328292 8.49598 0.328292C12.4716 0.328292 15.6944 3.55115 15.6944 7.52674Z"
                                          stroke="#1944F1"
                                          stroke-width="0.665372"
                                        />
                                        <path
                                          d="M9.95786 4.67041L5.47668 9.09947C5.45217 9.12454 5.43846 9.15821 5.43848 9.19327V10.5758C5.43848 10.5942 5.44579 10.6119 5.45882 10.6249C5.47185 10.638 5.48953 10.6453 5.50796 10.6453H6.67514C6.7101 10.6448 6.74358 10.6312 6.76893 10.6071L11.3717 6.00437L9.95786 4.67041Z"
                                          fill="#1944F1"
                                        />
                                        <path
                                          d="M11.821 5.52182L10.4834 4.18431L11.0323 3.63536C11.0497 3.61801 11.0702 3.60421 11.0929 3.59482C11.1155 3.58542 11.1398 3.58057 11.1643 3.58057C11.1889 3.58057 11.2131 3.58542 11.2358 3.59482C11.2585 3.60421 11.279 3.61801 11.2964 3.63536L12.3699 4.70883C12.3872 4.72616 12.401 4.74674 12.4104 4.76939C12.4198 4.79204 12.4246 4.81637 12.4246 4.84089C12.4246 4.86542 12.4198 4.88966 12.4104 4.91231C12.401 4.93497 12.3872 4.95555 12.3699 4.97287L11.821 5.52182Z"
                                          fill="#1944F1"
                                        />
                                      </svg>
                                    </button>
                                  </div>
                                ) : null}
                                {item.type === "BODY" ? (
                                  <div className="dataWrap">
                                    {previewMessage ? <p>{previewMessage.message}</p> : <p>{item.text}</p>}

                                  </div>
                                ) : null}

                                {item.type === "FOOTER" ? (
                                  <div className="dataWrap">
                                    <p>{item.text}</p>
                                  </div>
                                ) : null}

                                {item.type === "BUTTONS" ? (
                                  <div className="ctaWrap">
                                    {item.buttons &&
                                      item.buttons.map((btn, i) => (
                                        <>
                                          {btn.type === "URL" ? (
                                            <button className="btn">
                                              <svg
                                                width="10"
                                                height="10"
                                                viewBox="0 0 10 10"
                                                fill="none"
                                                xmlns="http://www.w3.org/2000/svg"
                                              >
                                                <path
                                                  d="M9.02729 1.02203C8.96805 0.884121 8.83305 0.79412 8.68296 0.79248H6.18782C5.98111 0.79248 5.81354 0.960048 5.81354 1.16675C5.81354 1.37346 5.98111 1.54102 6.18782 1.54102H7.77972L4.92527 4.39547C4.77933 4.5416 4.77933 4.77832 4.92527 4.92444C5.0714 5.07039 5.30812 5.07039 5.45424 4.92444L8.30869 2.07V3.6619C8.30869 3.8686 8.47626 4.03617 8.68296 4.03617C8.88967 4.03617 9.05723 3.8686 9.05723 3.6619V1.16675C9.05697 1.117 9.04679 1.0678 9.02729 1.02203Z"
                                                  fill="#1944F1"
                                                />
                                                <path
                                                  d="M7.6849 4.78472C7.47932 4.7874 7.31332 4.95341 7.31063 5.15899V7.1551C7.30789 8.04969 6.58337 8.77421 5.68878 8.77695H2.69461C1.80003 8.77421 1.0755 8.04969 1.07276 7.1551V4.16093C1.0755 3.26635 1.80003 2.54182 2.69461 2.53908H4.69073C4.89743 2.53908 5.065 2.37152 5.065 2.16481C5.065 1.95811 4.89743 1.79054 4.69073 1.79054H2.69461C1.38548 1.79054 0.324219 2.8518 0.324219 4.16093V7.1551C0.324219 8.46423 1.38548 9.52549 2.69461 9.52549H5.68878C6.99791 9.52549 8.05917 8.46423 8.05917 7.1551V5.15899C8.05649 4.95341 7.89048 4.7874 7.6849 4.78472Z"
                                                  fill="#1944F1"
                                                />
                                              </svg>
                                              {btn.text}
                                            </button>
                                          ) : null}

                                          {btn.type === "PHONE_NUMBER" ? (
                                            <button className="btn">
                                              <svg
                                                width="11"
                                                height="11"
                                                viewBox="0 0 11 11"
                                                fill="none"
                                                xmlns="http://www.w3.org/2000/svg"
                                              >
                                                <path
                                                  d="M7.53461 9.96207C4.24217 9.12389 1.65025 6.53197 0.812067 3.23954C0.494759 1.99313 1.56727 0.934297 2.85343 0.934297L3.43563 0.934297C3.75717 0.934297 4.01477 1.1956 4.04649 1.51558C4.10008 2.05617 4.22761 2.57503 4.41883 3.06188L3.53431 3.94641C4.22278 5.38505 5.38909 6.55136 6.82773 7.23983L7.71226 6.35531C8.19911 6.54653 8.71796 6.67406 9.25856 6.72765C9.57854 6.75937 9.83984 7.01697 9.83984 7.33851L9.83984 7.92071C9.83984 9.20687 8.78101 10.2794 7.53461 9.96207Z"
                                                  stroke="#1944F1"
                                                  stroke-width="0.582201"
                                                  stroke-linecap="round"
                                                  stroke-linejoin="round"
                                                />
                                              </svg>
                                              {btn.text}
                                            </button>
                                          ) : null}
                                        </>
                                      ))}
                                  </div>
                                ) : null}
                              </>
                            ))}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ) : (
                <div className="col-md-3 pad0px">
                  <div className="previewWrap">
                    <div className="titleWrap">
                      <h2>Preview</h2>
                    </div>
                    <div className="preview">
                      <div className="imgWrap">
                        <img src={whatsAppImg} className="img-fluid" alt="" />
                        <div className="messageTempChatWrap">
                          <div className="topImgWrap">
                            <div className="imgBox"></div>
                            <button className="btnEdit">
                              <svg
                                width="17"
                                height="16"
                                viewBox="0 0 17 16"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M15.6944 7.52674C15.6944 11.5023 12.4716 14.7252 8.49598 14.7252C4.52039 14.7252 1.29753 11.5023 1.29753 7.52674C1.29753 3.55115 4.52039 0.328292 8.49598 0.328292C12.4716 0.328292 15.6944 3.55115 15.6944 7.52674Z"
                                  stroke="#1944F1"
                                  stroke-width="0.665372"
                                />
                                <path
                                  d="M9.95786 4.67041L5.47668 9.09947C5.45217 9.12454 5.43846 9.15821 5.43848 9.19327V10.5758C5.43848 10.5942 5.44579 10.6119 5.45882 10.6249C5.47185 10.638 5.48953 10.6453 5.50796 10.6453H6.67514C6.7101 10.6448 6.74358 10.6312 6.76893 10.6071L11.3717 6.00437L9.95786 4.67041Z"
                                  fill="#1944F1"
                                />
                                <path
                                  d="M11.821 5.52182L10.4834 4.18431L11.0323 3.63536C11.0497 3.61801 11.0702 3.60421 11.0929 3.59482C11.1155 3.58542 11.1398 3.58057 11.1643 3.58057C11.1889 3.58057 11.2131 3.58542 11.2358 3.59482C11.2585 3.60421 11.279 3.61801 11.2964 3.63536L12.3699 4.70883C12.3872 4.72616 12.401 4.74674 12.4104 4.76939C12.4198 4.79204 12.4246 4.81637 12.4246 4.84089C12.4246 4.86542 12.4198 4.88966 12.4104 4.91231C12.401 4.93497 12.3872 4.95555 12.3699 4.97287L11.821 5.52182Z"
                                  fill="#1944F1"
                                />
                              </svg>
                            </button>
                          </div>
                          <div className="dataWrap">
                            <p>“ Hey, (User)</p>
                            <p>
                              Lorem Ipsum has been the industry's standard dummy
                              text ever since the
                            </p>
                          </div>
                          <div className="ctaWrap">
                            <button className="btn">
                              <svg
                                width="10"
                                height="10"
                                viewBox="0 0 10 10"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M9.02729 1.02203C8.96805 0.884121 8.83305 0.79412 8.68296 0.79248H6.18782C5.98111 0.79248 5.81354 0.960048 5.81354 1.16675C5.81354 1.37346 5.98111 1.54102 6.18782 1.54102H7.77972L4.92527 4.39547C4.77933 4.5416 4.77933 4.77832 4.92527 4.92444C5.0714 5.07039 5.30812 5.07039 5.45424 4.92444L8.30869 2.07V3.6619C8.30869 3.8686 8.47626 4.03617 8.68296 4.03617C8.88967 4.03617 9.05723 3.8686 9.05723 3.6619V1.16675C9.05697 1.117 9.04679 1.0678 9.02729 1.02203Z"
                                  fill="#1944F1"
                                />
                                <path
                                  d="M7.6849 4.78472C7.47932 4.7874 7.31332 4.95341 7.31063 5.15899V7.1551C7.30789 8.04969 6.58337 8.77421 5.68878 8.77695H2.69461C1.80003 8.77421 1.0755 8.04969 1.07276 7.1551V4.16093C1.0755 3.26635 1.80003 2.54182 2.69461 2.53908H4.69073C4.89743 2.53908 5.065 2.37152 5.065 2.16481C5.065 1.95811 4.89743 1.79054 4.69073 1.79054H2.69461C1.38548 1.79054 0.324219 2.8518 0.324219 4.16093V7.1551C0.324219 8.46423 1.38548 9.52549 2.69461 9.52549H5.68878C6.99791 9.52549 8.05917 8.46423 8.05917 7.1551V5.15899C8.05649 4.95341 7.89048 4.7874 7.6849 4.78472Z"
                                  fill="#1944F1"
                                />
                              </svg>
                              Order
                            </button>
                            <button className="btn">
                              <svg
                                width="11"
                                height="11"
                                viewBox="0 0 11 11"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M7.53461 9.96207C4.24217 9.12389 1.65025 6.53197 0.812067 3.23954C0.494759 1.99313 1.56727 0.934297 2.85343 0.934297L3.43563 0.934297C3.75717 0.934297 4.01477 1.1956 4.04649 1.51558C4.10008 2.05617 4.22761 2.57503 4.41883 3.06188L3.53431 3.94641C4.22278 5.38505 5.38909 6.55136 6.82773 7.23983L7.71226 6.35531C8.19911 6.54653 8.71796 6.67406 9.25856 6.72765C9.57854 6.75937 9.83984 7.01697 9.83984 7.33851L9.83984 7.92071C9.83984 9.20687 8.78101 10.2794 7.53461 9.96207Z"
                                  stroke="#1944F1"
                                  stroke-width="0.582201"
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                />
                              </svg>
                              Contact Support
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </main>
      <Modal show={show} onHide={handleClose} className='addNewFieldsPopup'>
        <Modal.Header>
          <Modal.Title>Configure Dynamic variables</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <ul className='formList confDyVarList'>
            
            {dynamicVar !== undefined && dynamicVar.map((_, index) => {
              return (
                <li>
                  <div className='inputGroup'>
                    <label htmlFor="">Variable {index + 1}</label>
                    <div className="inputField">
                      <input value={`{{${index + 1}}}`} />
                    </div>
                    <div className="inputField">
                      <Form.Select aria-label="Media" onChange={(e) => handleMapping(e, index)}>
                        <option value=""> </option>
                        {headerfields.map((val) => {
                          if (val.text) {
                            return (
                              <option value={val.text}>{val.text}</option>
                            )
                          }
                        })}
                      </Form.Select>
                    </div>
                  </div>
                </li>
              )
            })}

            {headerfields.length === 0 && dynamicVar.length === 0 &&
              <>
                <h4>Please Select Template & Contact List</h4>
              </>
            }
            {/* <li>
              <div className='ctaWrap'>
                <button className='btn'>+</button>
              </div>
            </li> */}
          </ul>
          <div className='addCtaWrap'>
            <button className='btn' onClick={setMap}>Set Map</button>
          </div>
        </Modal.Body>
      </Modal>
      <Modal show={showSuccessMsg} onHide={handleSuccessClose} className='addNewFieldsPopup'>
        <Modal.Header>
          <Modal.Title></Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <h4>Message Sent Successfully</h4>
          <div className='addCtaWrap'>
            <button className='btn' onClick={() => {
              handleSuccessClose()
              window.location.reload()
            }}>Ok</button>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default SmsBroadcast;