import React, { useState } from 'react';
import Form from 'react-bootstrap/Form';
import Header from '../Common/Header';
// import Sidebar from '../Common/Sidebar';
import Sidebar from '../../Components/Common/Sidebar';
import UserImg1 from '../../assets/img/UserImg-1.png';
import UserImg2 from '../../assets/img/UserImg-2.png';
import ChatIco1 from '../../assets/img/chatIco.png';
import ChatIco2 from '../../assets/img/Happy.png';
import BotImg from '../../assets/img/Bot.png';
import { Link } from 'react-router-dom';
const Chat = () => {
    const [isOpen, setIsOpen] = useState(true);
    const [isRightSideOpen, setIsRightSideOpen] = useState(false);
    const toggleSidebar = () => {
        setIsOpen(!isOpen);
    };

    const openRightSideSidebar = () => {
        setIsRightSideOpen(true);
    };
    const closeRightSideSidebar = () => {
        setIsRightSideOpen(false);
    };

    return (
        <div className={isOpen ? "app-container sidebar-open" : "app-container"}>
            <Header />
            <Sidebar isOpen={isOpen} toggleSidebar={toggleSidebar} />
            <main className="content">
                <div className="chatWrap">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="chatPannelWrap">
                                    <div className="chatApplication">
                                        <div className="chatSidebar">
                                            <div className="chatSidebarContent">
                                                <div className="chatSearch">
                                                    <form>
                                                        <div className="inputField">
                                                            <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                <path fill-rule="evenodd" clip-rule="evenodd" d="M12.5006 11.0006H11.7106L11.4306 10.7306C12.6306 9.33063 13.2506 7.42063 12.9106 5.39063C12.4406 2.61063 10.1206 0.390626 7.32063 0.0506256C3.09063 -0.469374 -0.469374 3.09063 0.0506256 7.32063C0.390626 10.1206 2.61063 12.4406 5.39063 12.9106C7.42063 13.2506 9.33063 12.6306 10.7306 11.4306L11.0006 11.7106V12.5006L15.2506 16.7506C15.6606 17.1606 16.3306 17.1606 16.7406 16.7506C17.1506 16.3406 17.1506 15.6706 16.7406 15.2606L12.5006 11.0006ZM6.50063 11.0006C4.01063 11.0006 2.00063 8.99063 2.00063 6.50063C2.00063 4.01063 4.01063 2.00063 6.50063 2.00063C8.99063 2.00063 11.0006 4.01063 11.0006 6.50063C11.0006 8.99063 8.99063 11.0006 6.50063 11.0006Z" fill="#C3CAD9" />
                                                            </svg>
                                                            <input type="text" placeholder='Search in Messages' />
                                                        </div>
                                                    </form>
                                                </div>
                                                <div id="userslist" class="listGroup">
                                                    <ul>
                                                        <li>
                                                            <div className='itemWrap'>
                                                                <Link>
                                                                    <div className='imgWrap'>
                                                                        <img src={UserImg1} alt='' className='img-fluid' />
                                                                        <span className='statusBadge'></span>
                                                                    </div>
                                                                    <div className='dataWrap'>
                                                                        <div className='topTxt'>
                                                                            <h2>Alberta Reyes</h2>
                                                                            <span>12:45</span>
                                                                        </div>
                                                                        <p>Adwords Keyword Research For Beginners</p>
                                                                    </div>
                                                                </Link>
                                                            </div>
                                                        </li>
                                                        <li>
                                                            <div className='itemWrap'>
                                                                <Link>
                                                                    <div className='imgWrap'>
                                                                        <img src={UserImg1} alt='' className='img-fluid' />
                                                                        <span className='statusBadge'></span>
                                                                    </div>
                                                                    <div className='dataWrap'>
                                                                        <div className='topTxt'>
                                                                            <h2>Alberta Reyes</h2>
                                                                            <span>12:45</span>
                                                                        </div>
                                                                        <p>Adwords Keyword Research For Beginners</p>
                                                                    </div>
                                                                </Link>
                                                            </div>
                                                        </li>
                                                        <li>
                                                            <div className='itemWrap'>
                                                                <Link>
                                                                    <div className='imgWrap'>
                                                                        <img src={UserImg1} alt='' className='img-fluid' />
                                                                        <span className='statusBadge'></span>
                                                                    </div>
                                                                    <div className='dataWrap'>
                                                                        <div className='topTxt'>
                                                                            <h2>Alberta Reyes</h2>
                                                                            <span>12:45</span>
                                                                        </div>
                                                                        <p>Adwords Keyword Research For Beginners</p>
                                                                    </div>
                                                                </Link>
                                                            </div>
                                                        </li>
                                                        <li>
                                                            <div className='itemWrap'>
                                                                <Link>
                                                                    <div className='imgWrap'>
                                                                        <img src={UserImg1} alt='' className='img-fluid' />
                                                                        <span className='statusBadge'></span>
                                                                    </div>
                                                                    <div className='dataWrap'>
                                                                        <div className='topTxt'>
                                                                            <h2>Alberta Reyes</h2>
                                                                            <span>12:45</span>
                                                                        </div>
                                                                        <p>Adwords Keyword Research For Beginners</p>
                                                                    </div>
                                                                </Link>
                                                            </div>
                                                        </li>
                                                        <li>
                                                            <div className='itemWrap'>
                                                                <Link>
                                                                    <div className='imgWrap'>
                                                                        <img src={UserImg1} alt='' className='img-fluid' />
                                                                        <span className='statusBadge'></span>
                                                                    </div>
                                                                    <div className='dataWrap'>
                                                                        <div className='topTxt'>
                                                                            <h2>Alberta Reyes</h2>
                                                                            <span>12:45</span>
                                                                        </div>
                                                                        <p>Adwords Keyword Research For Beginners</p>
                                                                    </div>
                                                                </Link>
                                                            </div>
                                                        </li>
                                                        <li>
                                                            <div className='itemWrap'>
                                                                <Link>
                                                                    <div className='imgWrap'>
                                                                        <img src={UserImg1} alt='' className='img-fluid' />
                                                                        <span className='statusBadge'></span>
                                                                    </div>
                                                                    <div className='dataWrap'>
                                                                        <div className='topTxt'>
                                                                            <h2>Alberta Reyes</h2>
                                                                            <span>12:45</span>
                                                                        </div>
                                                                        <p>Adwords Keyword Research For Beginners</p>
                                                                    </div>
                                                                </Link>
                                                            </div>
                                                        </li>
                                                        <li>
                                                            <div className='itemWrap'>
                                                                <Link>
                                                                    <div className='imgWrap'>
                                                                        <img src={UserImg1} alt='' className='img-fluid' />
                                                                        <span className='statusBadge'></span>
                                                                    </div>
                                                                    <div className='dataWrap'>
                                                                        <div className='topTxt'>
                                                                            <h2>Alberta Reyes</h2>
                                                                            <span>12:45</span>
                                                                        </div>
                                                                        <p>Adwords Keyword Research For Beginners</p>
                                                                    </div>
                                                                </Link>
                                                            </div>
                                                        </li>
                                                        <li>
                                                            <div className='itemWrap'>
                                                                <Link>
                                                                    <div className='imgWrap'>
                                                                        <img src={UserImg1} alt='' className='img-fluid' />
                                                                        <span className='statusBadge'></span>
                                                                    </div>
                                                                    <div className='dataWrap'>
                                                                        <div className='topTxt'>
                                                                            <h2>Alberta Reyes</h2>
                                                                            <span>12:45</span>
                                                                        </div>
                                                                        <p>Adwords Keyword Research For Beginners</p>
                                                                    </div>
                                                                </Link>
                                                            </div>
                                                        </li>
                                                        <li>
                                                            <div className='itemWrap'>
                                                                <Link>
                                                                    <div className='imgWrap'>
                                                                        <img src={UserImg1} alt='' className='img-fluid' />
                                                                        <span className='statusBadge'></span>
                                                                    </div>
                                                                    <div className='dataWrap'>
                                                                        <div className='topTxt'>
                                                                            <h2>Alberta Reyes</h2>
                                                                            <span>12:45</span>
                                                                        </div>
                                                                        <p>Adwords Keyword Research For Beginners</p>
                                                                    </div>
                                                                </Link>
                                                            </div>
                                                        </li>
                                                        <li>
                                                            <div className='itemWrap'>
                                                                <Link>
                                                                    <div className='imgWrap'>
                                                                        <img src={UserImg1} alt='' className='img-fluid' />
                                                                        <span className='statusBadge'></span>
                                                                    </div>
                                                                    <div className='dataWrap'>
                                                                        <div className='topTxt'>
                                                                            <h2>Alberta Reyes</h2>
                                                                            <span>12:45</span>
                                                                        </div>
                                                                        <p>Adwords Keyword Research For Beginners</p>
                                                                    </div>
                                                                </Link>
                                                            </div>
                                                        </li>
                                                        <li>
                                                            <div className='itemWrap'>
                                                                <Link>
                                                                    <div className='imgWrap'>
                                                                        <img src={UserImg1} alt='' className='img-fluid' />
                                                                        <span className='statusBadge'></span>
                                                                    </div>
                                                                    <div className='dataWrap'>
                                                                        <div className='topTxt'>
                                                                            <h2>Alberta Reyes</h2>
                                                                            <span>12:45</span>
                                                                        </div>
                                                                        <p>Adwords Keyword Research For Beginners</p>
                                                                    </div>
                                                                </Link>
                                                            </div>
                                                        </li>
                                                        <li>
                                                            <div className='itemWrap'>
                                                                <Link>
                                                                    <div className='imgWrap'>
                                                                        <img src={UserImg1} alt='' className='img-fluid' />
                                                                        <span className='statusBadge'></span>
                                                                    </div>
                                                                    <div className='dataWrap'>
                                                                        <div className='topTxt'>
                                                                            <h2>Alberta Reyes</h2>
                                                                            <span>12:45</span>
                                                                        </div>
                                                                        <p>Adwords Keyword Research For Beginners</p>
                                                                    </div>
                                                                </Link>
                                                            </div>
                                                        </li>
                                                        <li>
                                                            <div className='itemWrap'>
                                                                <Link>
                                                                    <div className='imgWrap'>
                                                                        <img src={UserImg1} alt='' className='img-fluid' />
                                                                        <span className='statusBadge'></span>
                                                                    </div>
                                                                    <div className='dataWrap'>
                                                                        <div className='topTxt'>
                                                                            <h2>Alberta Reyes</h2>
                                                                            <span>12:45</span>
                                                                        </div>
                                                                        <p>Adwords Keyword Research For Beginners</p>
                                                                    </div>
                                                                </Link>
                                                            </div>
                                                        </li>
                                                        <li>
                                                            <div className='itemWrap'>
                                                                <Link>
                                                                    <div className='imgWrap'>
                                                                        <img src={UserImg1} alt='' className='img-fluid' />
                                                                        <span className='statusBadge'></span>
                                                                    </div>
                                                                    <div className='dataWrap'>
                                                                        <div className='topTxt'>
                                                                            <h2>Alberta Reyes</h2>
                                                                            <span>12:45</span>
                                                                        </div>
                                                                        <p>Adwords Keyword Research For Beginners</p>
                                                                    </div>
                                                                </Link>
                                                            </div>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='chatContent'>
                                            <div className='topChatContent'>
                                                <div className='chatName'>
                                                    <span className='statusBadge'></span>
                                                    <h2>Alberta Reyes</h2>
                                                </div>
                                                <div className='chatSetting'>
                                                    <button className='btn' onClick={openRightSideSidebar}>
                                                        <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                            <path fill-rule="evenodd" clip-rule="evenodd" d="M11.7729 7.6535C11.7995 7.44016 11.8195 7.22683 11.8195 7.00016C11.8195 6.7735 11.7995 6.56016 11.7729 6.34683L13.1795 5.24683C13.3062 5.14683 13.3395 4.96683 13.2595 4.82016L11.9262 2.5135C11.8462 2.36683 11.6662 2.3135 11.5195 2.36683L9.85953 3.0335C9.51287 2.76683 9.13953 2.54683 8.73287 2.38016L8.47953 0.613496C8.45953 0.453496 8.31953 0.333496 8.15287 0.333496H5.4862C5.31953 0.333496 5.17953 0.453496 5.15953 0.613496L4.9062 2.38016C4.49953 2.54683 4.1262 2.7735 3.77953 3.0335L2.11953 2.36683C1.9662 2.30683 1.79287 2.36683 1.71287 2.5135L0.379534 4.82016C0.292867 4.96683 0.332867 5.14683 0.459534 5.24683L1.8662 6.34683C1.83953 6.56016 1.81953 6.78016 1.81953 7.00016C1.81953 7.22016 1.83953 7.44016 1.8662 7.6535L0.459534 8.7535C0.332867 8.8535 0.299534 9.0335 0.379534 9.18016L1.71287 11.4868C1.79287 11.6335 1.97287 11.6868 2.11953 11.6335L3.77953 10.9668C4.1262 11.2335 4.49953 11.4535 4.9062 11.6202L5.15953 13.3868C5.17953 13.5468 5.31953 13.6668 5.4862 13.6668H8.15287C8.31953 13.6668 8.45953 13.5468 8.47953 13.3868L8.73287 11.6202C9.13953 11.4535 9.51287 11.2268 9.85953 10.9668L11.5195 11.6335C11.6729 11.6935 11.8462 11.6335 11.9262 11.4868L13.2595 9.18016C13.3395 9.0335 13.3062 8.8535 13.1795 8.7535L11.7729 7.6535ZM6.81953 9.3335C5.53287 9.3335 4.4862 8.28683 4.4862 7.00016C4.4862 5.7135 5.53287 4.66683 6.81953 4.66683C8.1062 4.66683 9.15287 5.7135 9.15287 7.00016C9.15287 8.28683 8.1062 9.3335 6.81953 9.3335Z" fill="#C3CAD9" />
                                                        </svg>
                                                    </button>
                                                </div>
                                            </div>
                                            <div className='chatAppWindow'>
                                                <div className="chats">
                                                    <div className="chat chat-left">
                                                        <div className='chatTimeWrap'>
                                                            <p>12:45</p>
                                                        </div>
                                                        <div className="chat-avatar">
                                                            <div className="avatar avatar-md">
                                                                <img src={UserImg1} alt="avatar" className="avatar img-fluid" />
                                                            </div>
                                                        </div>
                                                        <div className="chat-body">
                                                            <div className="chat-content">
                                                                <p>Hello! How you doing</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="chat">
                                                        <div className='chatTimeWrap'>
                                                            <p>12:45</p>
                                                        </div>
                                                        <div className="chat-avatar">
                                                            <div className="avatar avatar-md">
                                                                <img src={UserImg1} alt="avatar" className="avatar img-fluid" />
                                                            </div>
                                                        </div>
                                                        <div className="chat-body">
                                                            <div className="chat-content">
                                                                <p>Hello akanksha</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='chatFormWrap'>
                                                <form className="d-flex align-items-center">
                                                    <div className='imgWrap'>
                                                        <img src={ChatIco1} alt='' className='img-fluid' />
                                                    </div>
                                                    <input type="text" className="form-control chat-message-send" placeholder="Type Message" />
                                                    <div className='ctasWrap'>
                                                        <button className='btn'>
                                                            <img src={BotImg} alt='' className='img-fluid' />
                                                        </button>
                                                        <button className='btn'>
                                                            <svg width="11" height="22" viewBox="0 0 11 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                <path fill-rule="evenodd" clip-rule="evenodd" d="M1.5 16.2539L1.5 5.67393C1.5 3.58393 3.03 1.72393 5.11 1.52393C7.49 1.29393 9.5 3.16393 9.5 5.50393L9.5 17.8639C9.5 19.1739 8.56 20.3639 7.26 20.4939C5.76 20.6439 4.5 19.4739 4.5 18.0039L4.5 7.50393C4.5 6.95393 4.95 6.50393 5.5 6.50393C6.05 6.50393 6.5 6.95393 6.5 7.50393L6.5 16.2539C6.5 16.6639 6.84 17.0039 7.25 17.0039C7.66 17.0039 8 16.6639 8 16.2539L8 7.64393C8 6.33393 7.06 5.14393 5.76 5.01393C4.26 4.86393 3 6.03393 3 7.50393L3 17.8339C3 19.9239 4.53 21.7839 6.61 21.9839C9 22.2139 11 20.3439 11 18.0039L11 5.73393C11 2.86393 8.9 0.293929 6.04 0.0239286C2.75 -0.276072 -9.98337e-08 2.28393 -2.40584e-07 5.50393L-7.10482e-07 16.2539C-7.28403e-07 16.6639 0.339999 17.0039 0.749999 17.0039C1.16 17.0039 1.5 16.6639 1.5 16.2539Z" fill="#C3C3C3" />
                                                            </svg>
                                                        </button>
                                                        <button className='btn'>
                                                            <img src={ChatIco2} alt='' className='img-fluid' />
                                                        </button>
                                                        <button className='btn'>
                                                            <svg width="20" height="17" viewBox="0 0 20 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                <path fill-rule="evenodd" clip-rule="evenodd" d="M1.4 16.8849L18.85 9.40488C19.66 9.05488 19.66 7.91488 18.85 7.56488L1.4 0.0848802C0.74 -0.20512 0.01 0.28488 0.01 0.99488L0 5.60488C0 6.10488 0.37 6.53488 0.87 6.59488L15 8.48488L0.87 10.3649C0.37 10.4349 0 10.8649 0 11.3649L0.01 15.9749C0.01 16.6849 0.74 17.1749 1.4 16.8849Z" fill="#3361FF" />
                                                            </svg>
                                                        </button>
                                                    </div>
                                                </form>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='col-md-3'>
                                <div className={`rightPanelWrap ${isRightSideOpen ? 'showRightSidebar' : ''}`}>
                                    <button className='closeBtn' onClick={closeRightSideSidebar}>
                                        <svg width="6" height="11" viewBox="0 0 6 11" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M5.26391 11C5.45312 11.0016 5.63538 10.928 5.77129 10.7954C5.91166 10.6593 5.99306 10.4732 5.99781 10.277C6.00256 10.0809 5.93029 9.89081 5.79666 9.74803L1.99142 5.68531L5.82201 1.22679C5.94901 1.07734 6.01228 0.883715 5.99802 0.687528C5.98377 0.49134 5.89315 0.308694 5.74591 0.179453C5.59753 0.0515467 5.40499 -0.0123694 5.2102 0.00198574C5.01541 0.0163409 4.83414 0.107569 4.70582 0.255867L0 5.72367L4.73118 10.7573C4.79725 10.8342 4.8791 10.8957 4.97108 10.9376C5.06305 10.9795 5.16296 11.0007 5.26391 11Z" fill="#9B9B9B"></path></svg>
                                    </button>
                                    <div className='topPannel'>
                                        <div className='itemWrap'>
                                            <div className='topTTlWrp'>
                                                <div className='imgWrap'>
                                                    <img src={UserImg2} alt='' className='img-fluid' />
                                                </div>
                                                <h2>Display Name</h2>
                                            </div>
                                            <div className='dataWrap'>
                                                <textarea placeholder='Text'></textarea>
                                            </div>
                                        </div>
                                        <div className='itemWrap'>
                                            <div className='topTTlWrp'>
                                                <h2>Description</h2>
                                            </div>
                                            <div className='dataWrap'>
                                                <textarea placeholder='Text'></textarea>
                                            </div>
                                        </div>
                                        <div className='conditionsWrap'>
                                            <div className='ttlWrap'>
                                                <h2>Condition For Trigger</h2>
                                            </div>
                                            <ul>
                                                <li>
                                                    <span>Hii</span>
                                                </li>
                                                <li>
                                                    <span>Hello!</span>
                                                </li>
                                                <li>
                                                    <span>Hey!</span>
                                                </li>
                                                <li>
                                                    <span>Text</span>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                    <div className='bottmPannle'>
                                        <div className='conversBoxWrap'>
                                            <h2>Conversation Box</h2>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </main>
        </div>
    );
};

export default Chat;