import React, { useState } from 'react';
import Form from 'react-bootstrap/Form';
import { Link, useNavigate } from 'react-router-dom';
import LoginPageImg1 from '../../assets/img/loginPageImg-1.png';
import GoogleImg from '../../assets/img/Google.png';
import MicrosoftImg from '../../assets/img/microsoft.png';
import { GOOGLE_AUTH_API} from '../../utility/ConstantAPI';

const Register = () => {
    const [passwordVisible, setPasswordVisible] = useState(false);
    const navigate = useNavigate()
    const togglePasswordVisibility = (event) => {
        event.preventDefault();
        setPasswordVisible(!passwordVisible);
    }
    return (
        <div className='authPageWrap'>
            <div className='container-fluid'>
                <div className='row justify-content-between align-items-center'>
                    <div className='col-md-5 pad0'>
                        <div className='leftDataWrap'>
                            <h2>Make your business <br /> communications <br /> much easier</h2>
                            <div className='imgWrap'>
                                <img src={LoginPageImg1} className='img-fluid' alt='' />
                            </div>
                        </div>
                    </div>
                    <div className='col-md-5'>
                        <div className='formWrap'>
                            <div className='logoWrap'>
                                <h3>Logo</h3>
                            </div>
                            <div className='title'>
                                <h2>Sign up</h2>
                            </div>
                            <form>
                                <ul>
                                    <li className='half'>
                                        <div className='inputField'>
                                            <input type='text' placeholder='First Name' />
                                        </div>
                                    </li>
                                    <li className='half'>
                                        <div className='inputField'>
                                            <input type='text' placeholder='Last Name' />
                                        </div>
                                    </li>
                                    <li>
                                        <div className='inputField'>
                                            <input type='email' placeholder='Email' />
                                        </div>
                                    </li>
                                    <li>
                                        <div className='inputField'>
                                            <input type={passwordVisible ? 'text' : 'password'} placeholder='Password' />
                                            <button className={`btn btnShowPwd ${passwordVisible ? 'active' : ''}`}
                                                onClick={togglePasswordVisibility}>
                                                <svg xmlns="http://www.w3.org/2000/svg" version="1.1" width="16" height="16" x="0" y="0" viewBox="0 0 488.85 488.85"><g><path d="M244.425 98.725c-93.4 0-178.1 51.1-240.6 134.1-5.1 6.8-5.1 16.3 0 23.1 62.5 83.1 147.2 134.2 240.6 134.2s178.1-51.1 240.6-134.1c5.1-6.8 5.1-16.3 0-23.1-62.5-83.1-147.2-134.2-240.6-134.2zm6.7 248.3c-62 3.9-113.2-47.2-109.3-109.3 3.2-51.2 44.7-92.7 95.9-95.9 62-3.9 113.2 47.2 109.3 109.3-3.3 51.1-44.8 92.6-95.9 95.9zm-3.1-47.4c-33.4 2.1-61-25.4-58.8-58.8 1.7-27.6 24.1-49.9 51.7-51.7 33.4-2.1 61 25.4 58.8 58.8-1.8 27.7-24.2 50-51.7 51.7z" fill="#434343" opacity="1" data-original="#434343"></path></g></svg>
                                            </button>
                                        </div>
                                    </li>
                                    <li>
                                        <div className='inputField'>
                                            <input type='text' placeholder='Your Company name' />
                                        </div>
                                    </li>
                                    <li className='martBtmLess'>
                                        <div className='checkBoxWrap'>
                                            <Form.Check type="checkbox" id="checkbox">
                                                <Form.Check.Input type="checkbox" />
                                                <Form.Check.Label>I agree to the Service </Form.Check.Label>
                                            </Form.Check>
                                            <p> <Link>Terms And Conditions</Link> & <Link> Privacy Policy</Link></p>
                                        </div>
                                    </li>
                                    <li>
                                        <div className='ctaWrap'>
                                            <div className='socialIcoWrap'>
                                                <a href={GOOGLE_AUTH_API} className='btn'>
                                                    <img src={GoogleImg} alt='' className='img-fluid' />
                                                </a>
                                                <button className='btn'>
                                                    <img src={MicrosoftImg} alt='' className='img-fluid' />
                                                </button>
                                            </div>
                                            <button className='btn'>Sign up</button>
                                        </div>
                                    </li>
                                </ul>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Register;
